// js file because this is used in both components and js (charts)
var PFAColors = PFAColors || {
  white: '#ffffff',
  black: '#000000',

  primary_100: '#990000',
  primary_80: '#ad3333',
  primary_60: '#c16666',
  primary_40: '#d69999',
  primary_20: '#eacccc',
  primary_10: '#f5e6e6',
  primary_5: '#f9f2f2',

  secondary_100: '#006666',
  secondary_80: '#338484',
  secondary_60: '#66a3a3',
  secondary_40: '#99c1c1',
  secondary_20: '#cce0e0',
  secondary_10: '#e6f0f0',
  secondary_5: '#f2f7f7',

  grey_80: '#333333',
  grey_60: '#666666',
  grey_40: '#999999',
  grey_20: '#CCCCCC',
  grey_10: '#e5e5e5',
  grey_7: '#ededed',
  grey_5: '#f2f2f2',
  grey_2: '#fafafa'
};

PFAColors.paletteColors = [
  PFAColors.primary_100,
  PFAColors.primary_80,
  PFAColors.primary_60,
  PFAColors.primary_40,
  PFAColors.primary_20,
  PFAColors.secondary_100,
  PFAColors.secondary_80,
  PFAColors.secondary_60,
  PFAColors.secondary_40,
  PFAColors.secondary_20
].join(',');
