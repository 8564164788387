/*! jquery-intelecomchat - v1.16.4 - 2021-06-24 */ !(function (a) {
  'function' == typeof define && define.amd
    ? define('starrr', ['jquery'], a)
    : a(jQuery);
})(function (a) {
  var b,
    c = [].slice;
  return (
    (b = (function () {
      function b(b, c) {
        (this.options = a.extend({}, this.defaults, c)),
          (this.$el = b),
          this.createStars(),
          this.syncRating(),
          this.options.readOnly ||
            (this.$el.on(
              'mouseover.starrr',
              'i',
              (function (a) {
                return function (b) {
                  return a.syncRating(a.getStars().index(b.currentTarget) + 1);
                };
              })(this)
            ),
            this.$el.on(
              'mouseout.starrr',
              (function (a) {
                return function () {
                  return a.syncRating();
                };
              })(this)
            ),
            this.$el.on(
              'focusin.starrr',
              'i',
              (function (a) {
                return function (b) {
                  return a.syncRating(a.getStars().index(b.currentTarget) + 1);
                };
              })(this)
            ),
            this.$el.on(
              'focusout.starrr',
              (function (a) {
                return function () {
                  return a.syncRating();
                };
              })(this)
            ),
            this.$el.on(
              'click.starrr',
              'i',
              (function (a) {
                return function (b) {
                  return a.setRating(a.getStars().index(b.currentTarget) + 1);
                };
              })(this)
            ),
            this.$el.on(
              'keypress.starrr',
              'i',
              (function (a) {
                return function (b) {
                  if (13 == b.which)
                    return a.setRating(a.getStars().index(b.currentTarget) + 1);
                };
              })(this)
            ),
            this.$el.on('starrr:change', this.options.change));
      }
      return (
        (b.prototype.defaults = {
          rating: void 0,
          max: 5,
          readOnly: !1,
          emptyClass: 'intelecom_icon-star-empty',
          fullClass: 'intelecom_icon-star',
          change: function (a, b) {}
        }),
        (b.prototype.getStars = function () {
          return this.$el.find('i');
        }),
        (b.prototype.createStars = function () {
          var a, b, c;
          for (
            c = [], a = 1, b = this.options.max;
            1 <= b ? a <= b : a >= b;
            1 <= b ? a++ : a--
          )
            c.push(
              this.$el.append(
                '<i tabindex="0" role="button" aria-label="' + a + '" />'
              )
            );
          return c;
        }),
        (b.prototype.setRating = function (a) {
          return (
            this.options.rating === a && (a = void 0),
            (this.options.rating = a),
            this.syncRating(),
            this.$el.trigger('starrr:change', a)
          );
        }),
        (b.prototype.getRating = function () {
          return this.options.rating;
        }),
        (b.prototype.syncRating = function (a) {
          var b, c, d, e, f;
          for (
            a || (a = this.options.rating),
              b = this.getStars(),
              f = [],
              c = d = 1,
              e = this.options.max;
            1 <= e ? d <= e : d >= e;
            c = 1 <= e ? ++d : --d
          )
            f.push(
              b
                .eq(c - 1)
                .removeClass(
                  a >= c ? this.options.emptyClass : this.options.fullClass
                )
                .addClass(
                  a >= c ? this.options.fullClass : this.options.emptyClass
                )
            );
          return f;
        }),
        b
      );
    })()),
    a.fn.extend({
      starrr: function () {
        var d, e;
        return (
          (e = arguments[0]),
          (d = 2 <= arguments.length ? c.call(arguments, 1) : []),
          this.each(function () {
            var c;
            if (
              ((c = a(this).data('starrr')),
              c || a(this).data('starrr', (c = new b(a(this), e))),
              'string' == typeof e)
            )
              return c[e].apply(c, d);
          })
        );
      }
    })
  );
}),
  (window.Modernizr = (function (a, b, c) {
    function d(a) {
      t.cssText = a;
    }
    function e(a, b) {
      return d(x.join(a + ';') + (b || ''));
    }
    function f(a, b) {
      return typeof a === b;
    }
    function g(a, b) {
      return !!~('' + a).indexOf(b);
    }
    function h(a, b) {
      for (var d in a) {
        var e = a[d];
        if (!g(e, '-') && t[e] !== c) return 'pfx' != b || e;
      }
      return !1;
    }
    function i(a, b, d) {
      for (var e in a) {
        var g = b[a[e]];
        if (g !== c)
          return !1 === d ? a[e] : f(g, 'function') ? g.bind(d || b) : g;
      }
      return !1;
    }
    function j(a, b, c) {
      var d = a.charAt(0).toUpperCase() + a.slice(1),
        e = (a + ' ' + z.join(d + ' ') + d).split(' ');
      return f(b, 'string') || f(b, 'undefined')
        ? h(e, b)
        : ((e = (a + ' ' + A.join(d + ' ') + d).split(' ')), i(e, b, c));
    }
    function k() {
      (o.input = (function (c) {
        for (var d = 0, e = c.length; d < e; d++) E[c[d]] = !!(c[d] in u);
        return (
          E.list &&
            (E.list = !(
              !b.createElement('datalist') || !a.HTMLDataListElement
            )),
          E
        );
      })(
        'autocomplete autofocus list placeholder max min multiple pattern required step'.split(
          ' '
        )
      )),
        (o.inputtypes = (function (a) {
          for (var d, e, f, g = 0, h = a.length; g < h; g++)
            u.setAttribute('type', (e = a[g])),
              (d = 'text' !== u.type),
              d &&
                ((u.value = v),
                (u.style.cssText = 'position:absolute;visibility:hidden;'),
                /^range$/.test(e) && u.style.WebkitAppearance !== c
                  ? (q.appendChild(u),
                    (f = b.defaultView),
                    (d =
                      f.getComputedStyle &&
                      'textfield' !==
                        f.getComputedStyle(u, null).WebkitAppearance &&
                      0 !== u.offsetHeight),
                    q.removeChild(u))
                  : /^(search|tel)$/.test(e) ||
                    (d = /^(url|email)$/.test(e)
                      ? u.checkValidity && !1 === u.checkValidity()
                      : u.value != v)),
              (D[a[g]] = !!d);
          return D;
        })(
          'search tel url email datetime date month week time datetime-local number range color'.split(
            ' '
          )
        ));
    }
    var l,
      m,
      n = '2.8.3',
      o = {},
      p = !0,
      q = b.documentElement,
      r = 'modernizr',
      s = b.createElement(r),
      t = s.style,
      u = b.createElement('input'),
      v = ':)',
      w = {}.toString,
      x = ' -webkit- -moz- -o- -ms- '.split(' '),
      y = 'Webkit Moz O ms',
      z = y.split(' '),
      A = y.toLowerCase().split(' '),
      B = { svg: 'http://www.w3.org/2000/svg' },
      C = {},
      D = {},
      E = {},
      F = [],
      G = F.slice,
      H = function (a, c, d, e) {
        var f,
          g,
          h,
          i,
          j = b.createElement('div'),
          k = b.body,
          l = k || b.createElement('body');
        if (parseInt(d, 10))
          for (; d--; )
            (h = b.createElement('div')),
              (h.id = e ? e[d] : r + (d + 1)),
              j.appendChild(h);
        return (
          (f = ['&#173;', '<style id="s', r, '">', a, '</style>'].join('')),
          (j.id = r),
          ((k ? j : l).innerHTML += f),
          l.appendChild(j),
          k ||
            ((l.style.background = ''),
            (l.style.overflow = 'hidden'),
            (i = q.style.overflow),
            (q.style.overflow = 'hidden'),
            q.appendChild(l)),
          (g = c(j, a)),
          k
            ? j.parentNode.removeChild(j)
            : (l.parentNode.removeChild(l), (q.style.overflow = i)),
          !!g
        );
      },
      I = function (b) {
        var c = a.matchMedia || a.msMatchMedia;
        if (c) return (c(b) && c(b).matches) || !1;
        var d;
        return (
          H(
            '@media ' + b + ' { #' + r + ' { position: absolute; } }',
            function (b) {
              d =
                'absolute' ==
                (a.getComputedStyle
                  ? getComputedStyle(b, null)
                  : b.currentStyle
                ).position;
            }
          ),
          d
        );
      },
      J = (function () {
        function a(a, e) {
          (e = e || b.createElement(d[a] || 'div')), (a = 'on' + a);
          var g = a in e;
          return (
            g ||
              (e.setAttribute || (e = b.createElement('div')),
              e.setAttribute &&
                e.removeAttribute &&
                (e.setAttribute(a, ''),
                (g = f(e[a], 'function')),
                f(e[a], 'undefined') || (e[a] = c),
                e.removeAttribute(a))),
            (e = null),
            g
          );
        }
        var d = {
          select: 'input',
          change: 'input',
          submit: 'form',
          reset: 'form',
          error: 'img',
          load: 'img',
          abort: 'img'
        };
        return a;
      })(),
      K = {}.hasOwnProperty;
    (m =
      f(K, 'undefined') || f(K.call, 'undefined')
        ? function (a, b) {
            return b in a && f(a.constructor.prototype[b], 'undefined');
          }
        : function (a, b) {
            return K.call(a, b);
          }),
      Function.prototype.bind ||
        (Function.prototype.bind = function (a) {
          var b = this;
          if ('function' != typeof b) throw new TypeError();
          var c = G.call(arguments, 1),
            d = function () {
              if (this instanceof d) {
                var e = function () {};
                e.prototype = b.prototype;
                var f = new e(),
                  g = b.apply(f, c.concat(G.call(arguments)));
                return Object(g) === g ? g : f;
              }
              return b.apply(a, c.concat(G.call(arguments)));
            };
          return d;
        }),
      (C.flexbox = function () {
        return j('flexWrap');
      }),
      (C.flexboxlegacy = function () {
        return j('boxDirection');
      }),
      (C.canvas = function () {
        var a = b.createElement('canvas');
        return !(!a.getContext || !a.getContext('2d'));
      }),
      (C.canvastext = function () {
        return !(
          !o.canvas ||
          !f(b.createElement('canvas').getContext('2d').fillText, 'function')
        );
      }),
      (C.webgl = function () {
        return !!a.WebGLRenderingContext;
      }),
      (C.touch = function () {
        var c;
        return (
          'ontouchstart' in a || (a.DocumentTouch && b instanceof DocumentTouch)
            ? (c = !0)
            : H(
                [
                  '@media (',
                  x.join('touch-enabled),('),
                  r,
                  ')',
                  '{#modernizr{top:9px;position:absolute}}'
                ].join(''),
                function (a) {
                  c = 9 === a.offsetTop;
                }
              ),
          c
        );
      }),
      (C.geolocation = function () {
        return 'geolocation' in navigator;
      }),
      (C.postmessage = function () {
        return !!a.postMessage;
      }),
      (C.websqldatabase = function () {
        return !!a.openDatabase;
      }),
      (C.indexedDB = function () {
        return !!j('indexedDB', a);
      }),
      (C.hashchange = function () {
        return (
          J('hashchange', a) && (b.documentMode === c || b.documentMode > 7)
        );
      }),
      (C.history = function () {
        return !(!a.history || !history.pushState);
      }),
      (C.draganddrop = function () {
        var a = b.createElement('div');
        return 'draggable' in a || ('ondragstart' in a && 'ondrop' in a);
      }),
      (C.websockets = function () {
        return 'WebSocket' in a || 'MozWebSocket' in a;
      }),
      (C.rgba = function () {
        return (
          d('background-color:rgba(150,255,150,.5)'),
          g(t.backgroundColor, 'rgba')
        );
      }),
      (C.hsla = function () {
        return (
          d('background-color:hsla(120,40%,100%,.5)'),
          g(t.backgroundColor, 'rgba') || g(t.backgroundColor, 'hsla')
        );
      }),
      (C.multiplebgs = function () {
        return (
          d('background:url(https://),url(https://),red url(https://)'),
          /(url\s*\(.*?){3}/.test(t.background)
        );
      }),
      (C.backgroundsize = function () {
        return j('backgroundSize');
      }),
      (C.borderimage = function () {
        return j('borderImage');
      }),
      (C.borderradius = function () {
        return j('borderRadius');
      }),
      (C.boxshadow = function () {
        return j('boxShadow');
      }),
      (C.textshadow = function () {
        return '' === b.createElement('div').style.textShadow;
      }),
      (C.opacity = function () {
        return e('opacity:.55'), /^0.55$/.test(t.opacity);
      }),
      (C.cssanimations = function () {
        return j('animationName');
      }),
      (C.csscolumns = function () {
        return j('columnCount');
      }),
      (C.cssgradients = function () {
        var a = 'background-image:';
        return (
          d(
            (
              a +
              '-webkit- '
                .split(' ')
                .join(
                  'gradient(linear,left top,right bottom,from(#9f9),to(white));' +
                    a
                ) +
              x.join('linear-gradient(left top,#9f9, white);' + a)
            ).slice(0, -a.length)
          ),
          g(t.backgroundImage, 'gradient')
        );
      }),
      (C.cssreflections = function () {
        return j('boxReflect');
      }),
      (C.csstransforms = function () {
        return !!j('transform');
      }),
      (C.csstransforms3d = function () {
        var a = !!j('perspective');
        return (
          a &&
            'webkitPerspective' in q.style &&
            H(
              '@media (transform-3d),(-webkit-transform-3d){#modernizr{left:9px;position:absolute;height:3px;}}',
              function (b, c) {
                a = 9 === b.offsetLeft && 3 === b.offsetHeight;
              }
            ),
          a
        );
      }),
      (C.csstransitions = function () {
        return j('transition');
      }),
      (C.fontface = function () {
        var a;
        return (
          H(
            '@font-face {font-family:"font";src:url("https://")}',
            function (c, d) {
              var e = b.getElementById('smodernizr'),
                f = e.sheet || e.styleSheet,
                g = f
                  ? f.cssRules && f.cssRules[0]
                    ? f.cssRules[0].cssText
                    : f.cssText || ''
                  : '';
              a = /src/i.test(g) && 0 === g.indexOf(d.split(' ')[0]);
            }
          ),
          a
        );
      }),
      (C.generatedcontent = function () {
        var a;
        return (
          H(
            [
              '#',
              r,
              '{font:0/0 a}#',
              r,
              ':after{content:"',
              v,
              '";visibility:hidden;font:3px/1 a}'
            ].join(''),
            function (b) {
              a = b.offsetHeight >= 3;
            }
          ),
          a
        );
      }),
      (C.video = function () {
        var a = b.createElement('video'),
          c = !1;
        try {
          (c = !!a.canPlayType) &&
            ((c = new Boolean(c)),
            (c.ogg = a
              .canPlayType('video/ogg; codecs="theora"')
              .replace(/^no$/, '')),
            (c.h264 = a
              .canPlayType('video/mp4; codecs="avc1.42E01E"')
              .replace(/^no$/, '')),
            (c.webm = a
              .canPlayType('video/webm; codecs="vp8, vorbis"')
              .replace(/^no$/, '')));
        } catch (a) {}
        return c;
      }),
      (C.audio = function () {
        var a = b.createElement('audio'),
          c = !1;
        try {
          (c = !!a.canPlayType) &&
            ((c = new Boolean(c)),
            (c.ogg = a
              .canPlayType('audio/ogg; codecs="vorbis"')
              .replace(/^no$/, '')),
            (c.mp3 = a.canPlayType('audio/mpeg;').replace(/^no$/, '')),
            (c.wav = a
              .canPlayType('audio/wav; codecs="1"')
              .replace(/^no$/, '')),
            (c.m4a = (
              a.canPlayType('audio/x-m4a;') || a.canPlayType('audio/aac;')
            ).replace(/^no$/, '')));
        } catch (a) {}
        return c;
      }),
      (C.localstorage = function () {
        try {
          return localStorage.setItem(r, r), localStorage.removeItem(r), !0;
        } catch (a) {
          return !1;
        }
      }),
      (C.sessionstorage = function () {
        try {
          return sessionStorage.setItem(r, r), sessionStorage.removeItem(r), !0;
        } catch (a) {
          return !1;
        }
      }),
      (C.webworkers = function () {
        return !!a.Worker;
      }),
      (C.applicationcache = function () {
        return !!a.applicationCache;
      }),
      (C.svg = function () {
        return (
          !!b.createElementNS && !!b.createElementNS(B.svg, 'svg').createSVGRect
        );
      }),
      (C.inlinesvg = function () {
        var a = b.createElement('div');
        return (
          (a.innerHTML = '<svg/>'),
          (a.firstChild && a.firstChild.namespaceURI) == B.svg
        );
      }),
      (C.smil = function () {
        return (
          !!b.createElementNS &&
          /SVGAnimate/.test(w.call(b.createElementNS(B.svg, 'animate')))
        );
      }),
      (C.svgclippaths = function () {
        return (
          !!b.createElementNS &&
          /SVGClipPath/.test(w.call(b.createElementNS(B.svg, 'clipPath')))
        );
      });
    for (var L in C)
      m(C, L) &&
        ((l = L.toLowerCase()),
        (o[l] = C[L]()),
        F.push((o[l] ? '' : 'no-') + l));
    return (
      o.input || k(),
      (o.addTest = function (a, b) {
        if ('object' == typeof a)
          for (var d in a) m(a, d) && o.addTest(d, a[d]);
        else {
          if (((a = a.toLowerCase()), o[a] !== c)) return o;
          (b = 'function' == typeof b ? b() : b),
            void 0 !== p && p && (q.className += ' ' + (b ? '' : 'no-') + a),
            (o[a] = b);
        }
        return o;
      }),
      d(''),
      (s = u = null),
      (function (a, b) {
        function c(a, b) {
          var c = a.createElement('p'),
            d = a.getElementsByTagName('head')[0] || a.documentElement;
          return (
            (c.innerHTML = 'x<style>' + b + '</style>'),
            d.insertBefore(c.lastChild, d.firstChild)
          );
        }
        function d() {
          var a = s.elements;
          return 'string' == typeof a ? a.split(' ') : a;
        }
        function e(a) {
          var b = r[a[p]];
          return b || ((b = {}), q++, (a[p] = q), (r[q] = b)), b;
        }
        function f(a, c, d) {
          if ((c || (c = b), k)) return c.createElement(a);
          d || (d = e(c));
          var f;
          return (
            (f = d.cache[a]
              ? d.cache[a].cloneNode()
              : o.test(a)
                ? (d.cache[a] = d.createElem(a)).cloneNode()
                : d.createElem(a)),
            !f.canHaveChildren || n.test(a) || f.tagUrn
              ? f
              : d.frag.appendChild(f)
          );
        }
        function g(a, c) {
          if ((a || (a = b), k)) return a.createDocumentFragment();
          c = c || e(a);
          for (
            var f = c.frag.cloneNode(), g = 0, h = d(), i = h.length;
            g < i;
            g++
          )
            f.createElement(h[g]);
          return f;
        }
        function h(a, b) {
          b.cache ||
            ((b.cache = {}),
            (b.createElem = a.createElement),
            (b.createFrag = a.createDocumentFragment),
            (b.frag = b.createFrag())),
            (a.createElement = function (c) {
              return s.shivMethods ? f(c, a, b) : b.createElem(c);
            }),
            (a.createDocumentFragment = Function(
              'h,f',
              'return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(' +
                d()
                  .join()
                  .replace(/[\w\-]+/g, function (a) {
                    return (
                      b.createElem(a), b.frag.createElement(a), 'c("' + a + '")'
                    );
                  }) +
                ');return n}'
            )(s, b.frag));
        }
        function i(a) {
          a || (a = b);
          var d = e(a);
          return (
            !s.shivCSS ||
              j ||
              d.hasCSS ||
              (d.hasCSS = !!c(
                a,
                'article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}'
              )),
            k || h(a, d),
            a
          );
        }
        var j,
          k,
          l = '3.7.0',
          m = a.html5 || {},
          n =
            /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
          o =
            /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
          p = '_html5shiv',
          q = 0,
          r = {};
        !(function () {
          try {
            var a = b.createElement('a');
            (a.innerHTML = '<xyz></xyz>'),
              (j = 'hidden' in a),
              (k =
                1 == a.childNodes.length ||
                (function () {
                  b.createElement('a');
                  var a = b.createDocumentFragment();
                  return (
                    void 0 === a.cloneNode ||
                    void 0 === a.createDocumentFragment ||
                    void 0 === a.createElement
                  );
                })());
          } catch (a) {
            (j = !0), (k = !0);
          }
        })();
        var s = {
          elements:
            m.elements ||
            'abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output progress section summary template time video',
          version: l,
          shivCSS: !1 !== m.shivCSS,
          supportsUnknownElements: k,
          shivMethods: !1 !== m.shivMethods,
          type: 'default',
          shivDocument: i,
          createElement: f,
          createDocumentFragment: g
        };
        (a.html5 = s), i(b);
      })(this, b),
      (o._version = n),
      (o._prefixes = x),
      (o._domPrefixes = A),
      (o._cssomPrefixes = z),
      (o.mq = I),
      (o.hasEvent = J),
      (o.testProp = function (a) {
        return h([a]);
      }),
      (o.testAllProps = j),
      (o.testStyles = H),
      (o.prefixed = function (a, b, c) {
        return b ? j(a, b, c) : j(a, 'pfx');
      }),
      (q.className =
        q.className.replace(/(^|\s)no-js(\s|$)/, '$1$2') +
        (p ? ' js ' + F.join(' ') : '')),
      o
    );
  })(this, this.document)),
  (function () {
    !(function (a) {
      var b = this || (0, eval)('this'),
        c = b.document,
        d = b.navigator,
        e = b.jQuery,
        f = b.JSON;
      e || 'undefined' == typeof jQuery || (e = jQuery),
        (function (a) {
          'function' == typeof define && define.amd
            ? define(['exports', 'require'], a)
            : a(
                'object' == typeof exports && 'object' == typeof module
                  ? module.exports || exports
                  : (b.ko = {})
              );
        })(function (g, h) {
          function i(a, b) {
            return (null === a || (typeof a) in p) && a === b;
          }
          function j(b, c) {
            var d;
            return function () {
              d ||
                (d = o.a.setTimeout(function () {
                  (d = a), b();
                }, c));
            };
          }
          function k(a, b) {
            var c;
            return function () {
              clearTimeout(c), (c = o.a.setTimeout(a, b));
            };
          }
          function l(a, b) {
            b && 'change' !== b
              ? 'beforeChange' === b
                ? this.pc(a)
                : this.gb(a, b)
              : this.qc(a);
          }
          function m(a, b) {
            null !== b && b.s && b.s();
          }
          function n(a, b) {
            var c = this.qd,
              d = c[u];
            d.ra ||
              (this.Qb && this.mb[b]
                ? (c.uc(b, a, this.mb[b]), (this.mb[b] = null), --this.Qb)
                : d.I[b] || c.uc(b, a, d.J ? { da: a } : c.$c(a)),
              a.Ja && a.gd());
          }
          var o = void 0 !== g ? g : {};
          (o.b = function (a, b) {
            for (var c = a.split('.'), d = o, e = 0; e < c.length - 1; e++)
              d = d[c[e]];
            d[c[c.length - 1]] = b;
          }),
            (o.L = function (a, b, c) {
              a[b] = c;
            }),
            (o.version = '3.5.1'),
            o.b('version', o.version),
            (o.options = {
              deferUpdates: !1,
              useOnlyNativeEvents: !1,
              foreachHidesDestroyed: !1
            }),
            (o.a = (function () {
              function g(a, b) {
                for (var c in a) k.call(a, c) && b(c, a[c]);
              }
              function h(a, b) {
                if (b) for (var c in b) k.call(b, c) && (a[c] = b[c]);
                return a;
              }
              function i(a, b) {
                return (a.__proto__ = b), a;
              }
              function j(a, b, c, d) {
                var e = a[b].match(t) || [];
                o.a.D(c.match(t), function (a) {
                  o.a.Na(e, a, d);
                }),
                  (a[b] = e.join(' '));
              }
              var k = Object.prototype.hasOwnProperty,
                l = { __proto__: [] } instanceof Array,
                m = 'function' == typeof Symbol,
                n = {},
                p = {};
              (n[
                d && /Firefox\/2/i.test(d.userAgent)
                  ? 'KeyboardEvent'
                  : 'UIEvents'
              ] = ['keyup', 'keydown', 'keypress']),
                (n.MouseEvents =
                  'click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave'.split(
                    ' '
                  )),
                g(n, function (a, b) {
                  if (b.length)
                    for (var c = 0, d = b.length; c < d; c++) p[b[c]] = a;
                });
              var q,
                r = { propertychange: !0 },
                s =
                  c &&
                  (function () {
                    for (
                      var b = 3,
                        d = c.createElement('div'),
                        e = d.getElementsByTagName('i');
                      (d.innerHTML =
                        '\x3c!--[if gt IE ' + ++b + ']><i></i><![endif]--\x3e'),
                        e[0];

                    );
                    return 4 < b ? b : a;
                  })(),
                t = /\S+/g;
              return {
                Jc: [
                  'authenticity_token',
                  /^__RequestVerificationToken(_.*)?$/
                ],
                D: function (a, b, c) {
                  for (var d = 0, e = a.length; d < e; d++)
                    b.call(c, a[d], d, a);
                },
                A:
                  'function' == typeof Array.prototype.indexOf
                    ? function (a, b) {
                        return Array.prototype.indexOf.call(a, b);
                      }
                    : function (a, b) {
                        for (var c = 0, d = a.length; c < d; c++)
                          if (a[c] === b) return c;
                        return -1;
                      },
                Lb: function (b, c, d) {
                  for (var e = 0, f = b.length; e < f; e++)
                    if (c.call(d, b[e], e, b)) return b[e];
                  return a;
                },
                Pa: function (a, b) {
                  var c = o.a.A(a, b);
                  0 < c ? a.splice(c, 1) : 0 === c && a.shift();
                },
                wc: function (a) {
                  var b = [];
                  return (
                    a &&
                      o.a.D(a, function (a) {
                        0 > o.a.A(b, a) && b.push(a);
                      }),
                    b
                  );
                },
                Mb: function (a, b, c) {
                  var d = [];
                  if (a)
                    for (var e = 0, f = a.length; e < f; e++)
                      d.push(b.call(c, a[e], e));
                  return d;
                },
                jb: function (a, b, c) {
                  var d = [];
                  if (a)
                    for (var e = 0, f = a.length; e < f; e++)
                      b.call(c, a[e], e) && d.push(a[e]);
                  return d;
                },
                Nb: function (a, b) {
                  if (b instanceof Array) a.push.apply(a, b);
                  else for (var c = 0, d = b.length; c < d; c++) a.push(b[c]);
                  return a;
                },
                Na: function (a, b, c) {
                  var d = o.a.A(o.a.bc(a), b);
                  0 > d ? c && a.push(b) : c || a.splice(d, 1);
                },
                Ba: l,
                extend: h,
                setPrototypeOf: i,
                Ab: l ? i : h,
                P: g,
                Ga: function (a, b, c) {
                  if (!a) return a;
                  var d,
                    e = {};
                  for (d in a) k.call(a, d) && (e[d] = b.call(c, a[d], d, a));
                  return e;
                },
                Tb: function (a) {
                  for (; a.firstChild; ) o.removeNode(a.firstChild);
                },
                Yb: function (a) {
                  a = o.a.la(a);
                  for (
                    var b = ((a[0] && a[0].ownerDocument) || c).createElement(
                        'div'
                      ),
                      d = 0,
                      e = a.length;
                    d < e;
                    d++
                  )
                    b.appendChild(o.oa(a[d]));
                  return b;
                },
                Ca: function (a, b) {
                  for (var c = 0, d = a.length, e = []; c < d; c++) {
                    var f = a[c].cloneNode(!0);
                    e.push(b ? o.oa(f) : f);
                  }
                  return e;
                },
                va: function (a, b) {
                  if ((o.a.Tb(a), b))
                    for (var c = 0, d = b.length; c < d; c++)
                      a.appendChild(b[c]);
                },
                Xc: function (a, b) {
                  var c = a.nodeType ? [a] : a;
                  if (0 < c.length) {
                    for (
                      var d = c[0], e = d.parentNode, f = 0, g = b.length;
                      f < g;
                      f++
                    )
                      e.insertBefore(b[f], d);
                    for (f = 0, g = c.length; f < g; f++) o.removeNode(c[f]);
                  }
                },
                Ua: function (a, b) {
                  if (a.length) {
                    for (
                      b = (8 === b.nodeType && b.parentNode) || b;
                      a.length && a[0].parentNode !== b;

                    )
                      a.splice(0, 1);
                    for (; 1 < a.length && a[a.length - 1].parentNode !== b; )
                      a.length--;
                    if (1 < a.length) {
                      var c = a[0],
                        d = a[a.length - 1];
                      for (a.length = 0; c !== d; )
                        a.push(c), (c = c.nextSibling);
                      a.push(d);
                    }
                  }
                  return a;
                },
                Zc: function (a, b) {
                  7 > s ? a.setAttribute('selected', b) : (a.selected = b);
                },
                Db: function (b) {
                  return null === b || b === a
                    ? ''
                    : b.trim
                      ? b.trim()
                      : b.toString().replace(/^[\s\xa0]+|[\s\xa0]+$/g, '');
                },
                Ud: function (a, b) {
                  return (
                    (a = a || ''),
                    !(b.length > a.length) && a.substring(0, b.length) === b
                  );
                },
                vd: function (a, b) {
                  if (a === b) return !0;
                  if (11 === a.nodeType) return !1;
                  if (b.contains)
                    return b.contains(1 !== a.nodeType ? a.parentNode : a);
                  if (b.compareDocumentPosition)
                    return 16 == (16 & b.compareDocumentPosition(a));
                  for (; a && a != b; ) a = a.parentNode;
                  return !!a;
                },
                Sb: function (a) {
                  return o.a.vd(a, a.ownerDocument.documentElement);
                },
                kd: function (a) {
                  return !!o.a.Lb(a, o.a.Sb);
                },
                R: function (a) {
                  return a && a.tagName && a.tagName.toLowerCase();
                },
                Ac: function (a) {
                  return o.onError
                    ? function () {
                        try {
                          return a.apply(this, arguments);
                        } catch (a) {
                          throw (o.onError && o.onError(a), a);
                        }
                      }
                    : a;
                },
                setTimeout: function (a, b) {
                  return setTimeout(o.a.Ac(a), b);
                },
                Gc: function (a) {
                  setTimeout(function () {
                    throw (o.onError && o.onError(a), a);
                  }, 0);
                },
                B: function (a, b, c) {
                  var d = o.a.Ac(c);
                  if (((c = r[b]), o.options.useOnlyNativeEvents || c || !e))
                    if (c || 'function' != typeof a.addEventListener) {
                      if (void 0 === a.attachEvent)
                        throw Error(
                          "Browser doesn't support addEventListener or attachEvent"
                        );
                      var f = function (b) {
                          d.call(a, b);
                        },
                        g = 'on' + b;
                      a.attachEvent(g, f),
                        o.a.K.za(a, function () {
                          a.detachEvent(g, f);
                        });
                    } else a.addEventListener(b, d, !1);
                  else
                    q || (q = 'function' == typeof e(a).on ? 'on' : 'bind'),
                      e(a)[q](b, d);
                },
                Fb: function (a, d) {
                  if (!a || !a.nodeType)
                    throw Error(
                      'element must be a DOM node when calling triggerEvent'
                    );
                  var f;
                  if (
                    ('input' === o.a.R(a) &&
                    a.type &&
                    'click' == d.toLowerCase()
                      ? ((f = a.type), (f = 'checkbox' == f || 'radio' == f))
                      : (f = !1),
                    o.options.useOnlyNativeEvents || !e || f)
                  )
                    if ('function' == typeof c.createEvent) {
                      if ('function' != typeof a.dispatchEvent)
                        throw Error(
                          "The supplied element doesn't support dispatchEvent"
                        );
                      (f = c.createEvent(p[d] || 'HTMLEvents')),
                        f.initEvent(
                          d,
                          !0,
                          !0,
                          b,
                          0,
                          0,
                          0,
                          0,
                          0,
                          !1,
                          !1,
                          !1,
                          !1,
                          0,
                          a
                        ),
                        a.dispatchEvent(f);
                    } else if (f && a.click) a.click();
                    else {
                      if (void 0 === a.fireEvent)
                        throw Error(
                          "Browser doesn't support triggering events"
                        );
                      a.fireEvent('on' + d);
                    }
                  else e(a).trigger(d);
                },
                f: function (a) {
                  return o.O(a) ? a() : a;
                },
                bc: function (a) {
                  return o.O(a) ? a.v() : a;
                },
                Eb: function (a, b, c) {
                  var d;
                  b &&
                    ('object' == typeof a.classList
                      ? ((d = a.classList[c ? 'add' : 'remove']),
                        o.a.D(b.match(t), function (b) {
                          d.call(a.classList, b);
                        }))
                      : 'string' == typeof a.className.baseVal
                        ? j(a.className, 'baseVal', b, c)
                        : j(a, 'className', b, c));
                },
                Bb: function (b, c) {
                  var d = o.a.f(c);
                  (null !== d && d !== a) || (d = '');
                  var e = o.h.firstChild(b);
                  !e || 3 != e.nodeType || o.h.nextSibling(e)
                    ? o.h.va(b, [b.ownerDocument.createTextNode(d)])
                    : (e.data = d),
                    o.a.Ad(b);
                },
                Yc: function (a, b) {
                  if (((a.name = b), 7 >= s))
                    try {
                      var d = a.name.replace(/[&<>'"]/g, function (a) {
                        return '&#' + a.charCodeAt(0) + ';';
                      });
                      a.mergeAttributes(
                        c.createElement("<input name='" + d + "'/>"),
                        !1
                      );
                    } catch (a) {}
                },
                Ad: function (a) {
                  9 <= s &&
                    ((a = 1 == a.nodeType ? a : a.parentNode),
                    a.style && (a.style.zoom = a.style.zoom));
                },
                wd: function (a) {
                  if (s) {
                    var b = a.style.width;
                    (a.style.width = 0), (a.style.width = b);
                  }
                },
                Pd: function (a, b) {
                  (a = o.a.f(a)), (b = o.a.f(b));
                  for (var c = [], d = a; d <= b; d++) c.push(d);
                  return c;
                },
                la: function (a) {
                  for (var b = [], c = 0, d = a.length; c < d; c++)
                    b.push(a[c]);
                  return b;
                },
                Da: function (a) {
                  return m ? Symbol(a) : a;
                },
                Zd: 6 === s,
                $d: 7 === s,
                W: s,
                Lc: function (a, b) {
                  for (
                    var c = o.a
                        .la(a.getElementsByTagName('input'))
                        .concat(o.a.la(a.getElementsByTagName('textarea'))),
                      d =
                        'string' == typeof b
                          ? function (a) {
                              return a.name === b;
                            }
                          : function (a) {
                              return b.test(a.name);
                            },
                      e = [],
                      f = c.length - 1;
                    0 <= f;
                    f--
                  )
                    d(c[f]) && e.push(c[f]);
                  return e;
                },
                Nd: function (a) {
                  return 'string' == typeof a && (a = o.a.Db(a))
                    ? f && f.parse
                      ? f.parse(a)
                      : new Function('return ' + a)()
                    : null;
                },
                hc: function (a, b, c) {
                  if (!f || !f.stringify)
                    throw Error(
                      "Cannot find JSON.stringify(). Some browsers (e.g., IE < 8) don't support it natively, but you can overcome this by adding a script reference to json2.js, downloadable from http://www.json.org/json2.js"
                    );
                  return f.stringify(o.a.f(a), b, c);
                },
                Od: function (a, b, d) {
                  d = d || {};
                  var e = d.params || {},
                    f = d.includeFields || this.Jc,
                    h = a;
                  if ('object' == typeof a && 'form' === o.a.R(a))
                    for (var h = a.action, i = f.length - 1; 0 <= i; i--)
                      for (
                        var j = o.a.Lc(a, f[i]), k = j.length - 1;
                        0 <= k;
                        k--
                      )
                        e[j[k].name] = j[k].value;
                  b = o.a.f(b);
                  var l = c.createElement('form');
                  (l.style.display = 'none'),
                    (l.action = h),
                    (l.method = 'post');
                  for (var m in b)
                    (a = c.createElement('input')),
                      (a.type = 'hidden'),
                      (a.name = m),
                      (a.value = o.a.hc(o.a.f(b[m]))),
                      l.appendChild(a);
                  g(e, function (a, b) {
                    var d = c.createElement('input');
                    (d.type = 'hidden'),
                      (d.name = a),
                      (d.value = b),
                      l.appendChild(d);
                  }),
                    c.body.appendChild(l),
                    d.submitter ? d.submitter(l) : l.submit(),
                    setTimeout(function () {
                      l.parentNode.removeChild(l);
                    }, 0);
                }
              };
            })()),
            o.b('utils', o.a),
            o.b('utils.arrayForEach', o.a.D),
            o.b('utils.arrayFirst', o.a.Lb),
            o.b('utils.arrayFilter', o.a.jb),
            o.b('utils.arrayGetDistinctValues', o.a.wc),
            o.b('utils.arrayIndexOf', o.a.A),
            o.b('utils.arrayMap', o.a.Mb),
            o.b('utils.arrayPushAll', o.a.Nb),
            o.b('utils.arrayRemoveItem', o.a.Pa),
            o.b('utils.cloneNodes', o.a.Ca),
            o.b('utils.createSymbolOrString', o.a.Da),
            o.b('utils.extend', o.a.extend),
            o.b('utils.fieldsIncludedWithJsonPost', o.a.Jc),
            o.b('utils.getFormFields', o.a.Lc),
            o.b('utils.objectMap', o.a.Ga),
            o.b('utils.peekObservable', o.a.bc),
            o.b('utils.postJson', o.a.Od),
            o.b('utils.parseJson', o.a.Nd),
            o.b('utils.registerEventHandler', o.a.B),
            o.b('utils.stringifyJson', o.a.hc),
            o.b('utils.range', o.a.Pd),
            o.b('utils.toggleDomNodeCssClass', o.a.Eb),
            o.b('utils.triggerEvent', o.a.Fb),
            o.b('utils.unwrapObservable', o.a.f),
            o.b('utils.objectForEach', o.a.P),
            o.b('utils.addOrRemoveItem', o.a.Na),
            o.b('utils.setTextContent', o.a.Bb),
            o.b('unwrap', o.a.f),
            Function.prototype.bind ||
              (Function.prototype.bind = function (a) {
                var b = this;
                if (1 === arguments.length)
                  return function () {
                    return b.apply(a, arguments);
                  };
                var c = Array.prototype.slice.call(arguments, 1);
                return function () {
                  var d = c.slice(0);
                  return d.push.apply(d, arguments), b.apply(a, d);
                };
              }),
            (o.a.g = new (function () {
              var b,
                c,
                d = 0,
                e = '__ko__' + new Date().getTime(),
                f = {};
              return (
                o.a.W
                  ? ((b = function (b, c) {
                      var g = b[e];
                      if (!g || 'null' === g || !f[g]) {
                        if (!c) return a;
                        (g = b[e] = 'ko' + d++), (f[g] = {});
                      }
                      return f[g];
                    }),
                    (c = function (a) {
                      var b = a[e];
                      return !!b && (delete f[b], (a[e] = null), !0);
                    }))
                  : ((b = function (a, b) {
                      var c = a[e];
                      return !c && b && (c = a[e] = {}), c;
                    }),
                    (c = function (a) {
                      return !!a[e] && (delete a[e], !0);
                    })),
                {
                  get: function (a, c) {
                    var d = b(a, !1);
                    return d && d[c];
                  },
                  set: function (c, d, e) {
                    (c = b(c, e !== a)) && (c[d] = e);
                  },
                  Ub: function (a, c, d) {
                    return (a = b(a, !0)), a[c] || (a[c] = d);
                  },
                  clear: c,
                  Z: function () {
                    return d++ + e;
                  }
                }
              );
            })()),
            o.b('utils.domData', o.a.g),
            o.b('utils.domData.clear', o.a.g.clear),
            (o.a.K = new (function () {
              function b(b, c) {
                var d = o.a.g.get(b, f);
                return d === a && c && ((d = []), o.a.g.set(b, f, d)), d;
              }
              function c(a) {
                var c = b(a, !1);
                if (c)
                  for (var c = c.slice(0), e = 0; e < c.length; e++) c[e](a);
                o.a.g.clear(a),
                  o.a.K.cleanExternalData(a),
                  h[a.nodeType] && d(a.childNodes, !0);
              }
              function d(a, b) {
                for (var d, e = [], f = 0; f < a.length; f++)
                  if (
                    (!b || 8 === a[f].nodeType) &&
                    (c((e[e.length] = d = a[f])), a[f] !== d)
                  )
                    for (; f-- && -1 == o.a.A(e, a[f]); );
              }
              var f = o.a.g.Z(),
                g = { 1: !0, 8: !0, 9: !0 },
                h = { 1: !0, 9: !0 };
              return {
                za: function (a, c) {
                  if ('function' != typeof c)
                    throw Error('Callback must be a function');
                  b(a, !0).push(c);
                },
                yb: function (c, d) {
                  var e = b(c, !1);
                  e && (o.a.Pa(e, d), 0 == e.length && o.a.g.set(c, f, a));
                },
                oa: function (a) {
                  return (
                    o.u.G(function () {
                      g[a.nodeType] &&
                        (c(a), h[a.nodeType] && d(a.getElementsByTagName('*')));
                    }),
                    a
                  );
                },
                removeNode: function (a) {
                  o.oa(a), a.parentNode && a.parentNode.removeChild(a);
                },
                cleanExternalData: function (a) {
                  e && 'function' == typeof e.cleanData && e.cleanData([a]);
                }
              };
            })()),
            (o.oa = o.a.K.oa),
            (o.removeNode = o.a.K.removeNode),
            o.b('cleanNode', o.oa),
            o.b('removeNode', o.removeNode),
            o.b('utils.domNodeDisposal', o.a.K),
            o.b('utils.domNodeDisposal.addDisposeCallback', o.a.K.za),
            o.b('utils.domNodeDisposal.removeDisposeCallback', o.a.K.yb),
            (function () {
              var d = [0, '', ''],
                f = [1, '<table>', '</table>'],
                g = [3, '<table><tbody><tr>', '</tr></tbody></table>'],
                h = [1, "<select multiple='multiple'>", '</select>'],
                i = {
                  thead: f,
                  tbody: f,
                  tfoot: f,
                  tr: [2, '<table><tbody>', '</tbody></table>'],
                  td: g,
                  th: g,
                  option: h,
                  optgroup: h
                },
                j = 8 >= o.a.W;
              (o.a.ua = function (a, f) {
                var g;
                if (e) {
                  if (e.parseHTML) g = e.parseHTML(a, f) || [];
                  else if ((g = e.clean([a], f)) && g[0]) {
                    for (
                      var h = g[0];
                      h.parentNode && 11 !== h.parentNode.nodeType;

                    )
                      h = h.parentNode;
                    h.parentNode && h.parentNode.removeChild(h);
                  }
                } else {
                  (g = f) || (g = c);
                  var k,
                    h = g.parentWindow || g.defaultView || b,
                    l = o.a.Db(a).toLowerCase(),
                    m = g.createElement('div');
                  for (
                    k =
                      ((l = l.match(
                        /^(?:\x3c!--.*?--\x3e\s*?)*?<([a-z]+)[\s>]/
                      )) &&
                        i[l[1]]) ||
                      d,
                      l = k[0],
                      k = 'ignored<div>' + k[1] + a + k[2] + '</div>',
                      'function' == typeof h.innerShiv
                        ? m.appendChild(h.innerShiv(k))
                        : (j && g.body.appendChild(m),
                          (m.innerHTML = k),
                          j && m.parentNode.removeChild(m));
                    l--;

                  )
                    m = m.lastChild;
                  g = o.a.la(m.lastChild.childNodes);
                }
                return g;
              }),
                (o.a.Md = function (a, b) {
                  var c = o.a.ua(a, b);
                  return (c.length && c[0].parentElement) || o.a.Yb(c);
                }),
                (o.a.fc = function (b, c) {
                  if ((o.a.Tb(b), null !== (c = o.a.f(c)) && c !== a))
                    if (('string' != typeof c && (c = c.toString()), e))
                      e(b).html(c);
                    else
                      for (
                        var d = o.a.ua(c, b.ownerDocument), f = 0;
                        f < d.length;
                        f++
                      )
                        b.appendChild(d[f]);
                });
            })(),
            o.b('utils.parseHtmlFragment', o.a.ua),
            o.b('utils.setHtml', o.a.fc),
            (o.aa = (function () {
              function b(a, c) {
                if (a)
                  if (8 == a.nodeType) {
                    var d = o.aa.Uc(a.nodeValue);
                    null != d && c.push({ ud: a, Kd: d });
                  } else if (1 == a.nodeType)
                    for (var d = 0, e = a.childNodes, f = e.length; d < f; d++)
                      b(e[d], c);
              }
              var c = {};
              return {
                Xb: function (a) {
                  if ('function' != typeof a)
                    throw Error(
                      'You can only pass a function to ko.memoization.memoize()'
                    );
                  var b =
                    ((4294967296 * (1 + Math.random())) | 0)
                      .toString(16)
                      .substring(1) +
                    ((4294967296 * (1 + Math.random())) | 0)
                      .toString(16)
                      .substring(1);
                  return (c[b] = a), '\x3c!--[ko_memo:' + b + ']--\x3e';
                },
                bd: function (b, d) {
                  var e = c[b];
                  if (e === a)
                    throw Error(
                      "Couldn't find any memo with ID " +
                        b +
                        ". Perhaps it's already been unmemoized."
                    );
                  try {
                    return e.apply(null, d || []), !0;
                  } finally {
                    delete c[b];
                  }
                },
                cd: function (a, c) {
                  var d = [];
                  b(a, d);
                  for (var e = 0, f = d.length; e < f; e++) {
                    var g = d[e].ud,
                      h = [g];
                    c && o.a.Nb(h, c),
                      o.aa.bd(d[e].Kd, h),
                      (g.nodeValue = ''),
                      g.parentNode && g.parentNode.removeChild(g);
                  }
                },
                Uc: function (a) {
                  return (a = a.match(/^\[ko_memo\:(.*?)\]$/)) ? a[1] : null;
                }
              };
            })()),
            o.b('memoization', o.aa),
            o.b('memoization.memoize', o.aa.Xb),
            o.b('memoization.unmemoize', o.aa.bd),
            o.b('memoization.parseMemoText', o.aa.Uc),
            o.b('memoization.unmemoizeDomNodeAndDescendants', o.aa.cd),
            (o.na = (function () {
              function a() {
                if (g)
                  for (var a, b = g, c = 0; i < g; )
                    if ((a = f[i++])) {
                      if (i > b) {
                        if (5e3 <= ++c) {
                          (i = g),
                            o.a.Gc(
                              Error(
                                "'Too much recursion' after processing " +
                                  c +
                                  ' task groups.'
                              )
                            );
                          break;
                        }
                        b = g;
                      }
                      try {
                        a();
                      } catch (a) {
                        o.a.Gc(a);
                      }
                    }
              }
              function d() {
                a(), (i = g = f.length = 0);
              }
              var e,
                f = [],
                g = 0,
                h = 1,
                i = 0;
              return (
                (e = b.MutationObserver
                  ? (function (a) {
                      var b = c.createElement('div');
                      return (
                        new MutationObserver(a).observe(b, { attributes: !0 }),
                        function () {
                          b.classList.toggle('foo');
                        }
                      );
                    })(d)
                  : c && 'onreadystatechange' in c.createElement('script')
                    ? function (a) {
                        var b = c.createElement('script');
                        (b.onreadystatechange = function () {
                          (b.onreadystatechange = null),
                            c.documentElement.removeChild(b),
                            (b = null),
                            a();
                        }),
                          c.documentElement.appendChild(b);
                      }
                    : function (a) {
                        setTimeout(a, 0);
                      }),
                {
                  scheduler: e,
                  zb: function (a) {
                    return g || o.na.scheduler(d), (f[g++] = a), h++;
                  },
                  cancel: function (a) {
                    (a -= h - g) >= i && a < g && (f[a] = null);
                  },
                  resetForTesting: function () {
                    var a = g - i;
                    return (i = g = f.length = 0), a;
                  },
                  Sd: a
                }
              );
            })()),
            o.b('tasks', o.na),
            o.b('tasks.schedule', o.na.zb),
            o.b('tasks.runEarly', o.na.Sd),
            (o.Ta = {
              throttle: function (a, b) {
                a.throttleEvaluation = b;
                var c = null;
                return o.$({
                  read: a,
                  write: function (d) {
                    clearTimeout(c),
                      (c = o.a.setTimeout(function () {
                        a(d);
                      }, b));
                  }
                });
              },
              rateLimit: function (a, b) {
                var c, d, e;
                'number' == typeof b
                  ? (c = b)
                  : ((c = b.timeout), (d = b.method)),
                  (a.Hb = !1),
                  (e =
                    'function' == typeof d
                      ? d
                      : 'notifyWhenChangesStop' == d
                        ? k
                        : j),
                  a.ub(function (a) {
                    return e(a, c, b);
                  });
              },
              deferred: function (b, c) {
                if (!0 !== c)
                  throw Error(
                    "The 'deferred' extender only accepts the value 'true', because it is not supported to turn deferral off once enabled."
                  );
                b.Hb ||
                  ((b.Hb = !0),
                  b.ub(function (c) {
                    var d,
                      e = !1;
                    return function () {
                      if (!e) {
                        o.na.cancel(d), (d = o.na.zb(c));
                        try {
                          (e = !0), b.notifySubscribers(a, 'dirty');
                        } finally {
                          e = !1;
                        }
                      }
                    };
                  }));
              },
              notify: function (a, b) {
                a.equalityComparer = 'always' == b ? null : i;
              }
            });
          var p = { undefined: 1, boolean: 1, number: 1, string: 1 };
          o.b('extenders', o.Ta),
            (o.ic = function (a, b, c) {
              (this.da = a),
                (this.lc = b),
                (this.mc = c),
                (this.Ib = !1),
                (this.fb = this.Jb = null),
                o.L(this, 'dispose', this.s),
                o.L(this, 'disposeWhenNodeIsRemoved', this.l);
            }),
            (o.ic.prototype.s = function () {
              this.Ib ||
                (this.fb && o.a.K.yb(this.Jb, this.fb),
                (this.Ib = !0),
                this.mc(),
                (this.da = this.lc = this.mc = this.Jb = this.fb = null));
            }),
            (o.ic.prototype.l = function (a) {
              (this.Jb = a), o.a.K.za(a, (this.fb = this.s.bind(this)));
            }),
            (o.T = function () {
              o.a.Ab(this, q), q.qb(this);
            });
          var q = {
            qb: function (a) {
              (a.U = { change: [] }), (a.sc = 1);
            },
            subscribe: function (a, b, c) {
              var d = this;
              c = c || 'change';
              var e = new o.ic(d, b ? a.bind(b) : a, function () {
                o.a.Pa(d.U[c], e), d.hb && d.hb(c);
              });
              return (
                d.Qa && d.Qa(c), d.U[c] || (d.U[c] = []), d.U[c].push(e), e
              );
            },
            notifySubscribers: function (a, b) {
              if (
                ((b = b || 'change'), 'change' === b && this.Gb(), this.Wa(b))
              ) {
                var c = ('change' === b && this.ed) || this.U[b].slice(0);
                try {
                  o.u.xc();
                  for (var d, e = 0; (d = c[e]); ++e) d.Ib || d.lc(a);
                } finally {
                  o.u.end();
                }
              }
            },
            ob: function () {
              return this.sc;
            },
            Dd: function (a) {
              return this.ob() !== a;
            },
            Gb: function () {
              ++this.sc;
            },
            ub: function (a) {
              var b,
                c,
                d,
                e,
                f,
                g = this,
                h = o.O(g);
              g.gb || ((g.gb = g.notifySubscribers), (g.notifySubscribers = l));
              var i = a(function () {
                (g.Ja = !1), h && e === g && (e = g.nc ? g.nc() : g());
                var a = c || (f && g.sb(d, e));
                (f = c = b = !1), a && g.gb((d = e));
              });
              (g.qc = function (a, c) {
                (c && g.Ja) || (f = !c),
                  (g.ed = g.U.change.slice(0)),
                  (g.Ja = b = !0),
                  (e = a),
                  i();
              }),
                (g.pc = function (a) {
                  b || ((d = a), g.gb(a, 'beforeChange'));
                }),
                (g.rc = function () {
                  f = !0;
                }),
                (g.gd = function () {
                  g.sb(d, g.v(!0)) && (c = !0);
                });
            },
            Wa: function (a) {
              return this.U[a] && this.U[a].length;
            },
            Bd: function (a) {
              if (a) return (this.U[a] && this.U[a].length) || 0;
              var b = 0;
              return (
                o.a.P(this.U, function (a, c) {
                  'dirty' !== a && (b += c.length);
                }),
                b
              );
            },
            sb: function (a, b) {
              return !this.equalityComparer || !this.equalityComparer(a, b);
            },
            toString: function () {
              return '[object Object]';
            },
            extend: function (a) {
              var b = this;
              return (
                a &&
                  o.a.P(a, function (a, c) {
                    var d = o.Ta[a];
                    'function' == typeof d && (b = d(b, c) || b);
                  }),
                b
              );
            }
          };
          o.L(q, 'init', q.qb),
            o.L(q, 'subscribe', q.subscribe),
            o.L(q, 'extend', q.extend),
            o.L(q, 'getSubscriptionsCount', q.Bd),
            o.a.Ba && o.a.setPrototypeOf(q, Function.prototype),
            (o.T.fn = q),
            (o.Qc = function (a) {
              return (
                null != a &&
                'function' == typeof a.subscribe &&
                'function' == typeof a.notifySubscribers
              );
            }),
            o.b('subscribable', o.T),
            o.b('isSubscribable', o.Qc),
            (o.S = o.u =
              (function () {
                function a(a) {
                  d.push(c), (c = a);
                }
                function b() {
                  c = d.pop();
                }
                var c,
                  d = [],
                  e = 0;
                return {
                  xc: a,
                  end: b,
                  cc: function (a) {
                    if (c) {
                      if (!o.Qc(a))
                        throw Error(
                          'Only subscribable things can act as dependencies'
                        );
                      c.od.call(c.pd, a, a.fd || (a.fd = ++e));
                    }
                  },
                  G: function (c, d, e) {
                    try {
                      return a(), c.apply(d, e || []);
                    } finally {
                      b();
                    }
                  },
                  qa: function () {
                    if (c) return c.o.qa();
                  },
                  Va: function () {
                    if (c) return c.o.Va();
                  },
                  Ya: function () {
                    if (c) return c.Ya;
                  },
                  o: function () {
                    if (c) return c.o;
                  }
                };
              })()),
            o.b('computedContext', o.S),
            o.b('computedContext.getDependenciesCount', o.S.qa),
            o.b('computedContext.getDependencies', o.S.Va),
            o.b('computedContext.isInitial', o.S.Ya),
            o.b('computedContext.registerDependency', o.S.cc),
            o.b('ignoreDependencies', (o.Yd = o.u.G));
          var r = o.a.Da('_latestValue');
          o.ta = function (a) {
            function b() {
              return 0 < arguments.length
                ? (b.sb(b[r], arguments[0]) &&
                    (b.ya(), (b[r] = arguments[0]), b.xa()),
                  this)
                : (o.u.cc(b), b[r]);
            }
            return (
              (b[r] = a),
              o.a.Ba || o.a.extend(b, o.T.fn),
              o.T.fn.qb(b),
              o.a.Ab(b, s),
              o.options.deferUpdates && o.Ta.deferred(b, !0),
              b
            );
          };
          var s = {
            equalityComparer: i,
            v: function () {
              return this[r];
            },
            xa: function () {
              this.notifySubscribers(this[r], 'spectate'),
                this.notifySubscribers(this[r]);
            },
            ya: function () {
              this.notifySubscribers(this[r], 'beforeChange');
            }
          };
          o.a.Ba && o.a.setPrototypeOf(s, o.T.fn);
          var t = (o.ta.Ma = '__ko_proto__');
          (s[t] = o.ta),
            (o.O = function (a) {
              if (
                (a = 'function' == typeof a && a[t]) &&
                a !== s[t] &&
                a !== o.o.fn[t]
              )
                throw Error(
                  'Invalid object that looks like an observable; possibly from another Knockout instance'
                );
              return !!a;
            }),
            (o.Za = function (a) {
              return (
                'function' == typeof a &&
                (a[t] === s[t] || (a[t] === o.o.fn[t] && a.Nc))
              );
            }),
            o.b('observable', o.ta),
            o.b('isObservable', o.O),
            o.b('isWriteableObservable', o.Za),
            o.b('isWritableObservable', o.Za),
            o.b('observable.fn', s),
            o.L(s, 'peek', s.v),
            o.L(s, 'valueHasMutated', s.xa),
            o.L(s, 'valueWillMutate', s.ya),
            (o.Ha = function (a) {
              if ('object' != typeof (a = a || []) || !('length' in a))
                throw Error(
                  'The argument passed when initializing an observable array must be an array, or null, or undefined.'
                );
              return (
                (a = o.ta(a)),
                o.a.Ab(a, o.Ha.fn),
                a.extend({ trackArrayChanges: !0 })
              );
            }),
            (o.Ha.fn = {
              remove: function (a) {
                for (
                  var b = this.v(),
                    c = [],
                    d =
                      'function' != typeof a || o.O(a)
                        ? function (b) {
                            return b === a;
                          }
                        : a,
                    e = 0;
                  e < b.length;
                  e++
                ) {
                  var f = b[e];
                  if (d(f)) {
                    if ((0 === c.length && this.ya(), b[e] !== f))
                      throw Error(
                        'Array modified during remove; cannot remove item'
                      );
                    c.push(f), b.splice(e, 1), e--;
                  }
                }
                return c.length && this.xa(), c;
              },
              removeAll: function (b) {
                if (b === a) {
                  var c = this.v(),
                    d = c.slice(0);
                  return this.ya(), c.splice(0, c.length), this.xa(), d;
                }
                return b
                  ? this.remove(function (a) {
                      return 0 <= o.a.A(b, a);
                    })
                  : [];
              },
              destroy: function (a) {
                var b = this.v(),
                  c =
                    'function' != typeof a || o.O(a)
                      ? function (b) {
                          return b === a;
                        }
                      : a;
                this.ya();
                for (var d = b.length - 1; 0 <= d; d--) {
                  var e = b[d];
                  c(e) && (e._destroy = !0);
                }
                this.xa();
              },
              destroyAll: function (b) {
                return b === a
                  ? this.destroy(function () {
                      return !0;
                    })
                  : b
                    ? this.destroy(function (a) {
                        return 0 <= o.a.A(b, a);
                      })
                    : [];
              },
              indexOf: function (a) {
                var b = this();
                return o.a.A(b, a);
              },
              replace: function (a, b) {
                var c = this.indexOf(a);
                0 <= c && (this.ya(), (this.v()[c] = b), this.xa());
              },
              sorted: function (a) {
                var b = this().slice(0);
                return a ? b.sort(a) : b.sort();
              },
              reversed: function () {
                return this().slice(0).reverse();
              }
            }),
            o.a.Ba && o.a.setPrototypeOf(o.Ha.fn, o.ta.fn),
            o.a.D(
              'pop push reverse shift sort splice unshift'.split(' '),
              function (a) {
                o.Ha.fn[a] = function () {
                  var b = this.v();
                  this.ya(), this.zc(b, a, arguments);
                  var c = b[a].apply(b, arguments);
                  return this.xa(), c === b ? this : c;
                };
              }
            ),
            o.a.D(['slice'], function (a) {
              o.Ha.fn[a] = function () {
                var b = this();
                return b[a].apply(b, arguments);
              };
            }),
            (o.Pc = function (a) {
              return (
                o.O(a) &&
                'function' == typeof a.remove &&
                'function' == typeof a.push
              );
            }),
            o.b('observableArray', o.Ha),
            o.b('isObservableArray', o.Pc),
            (o.Ta.trackArrayChanges = function (b, c) {
              function d() {
                function a() {
                  if (j) {
                    var a,
                      c = [].concat(b.v() || []);
                    b.Wa('arrayChange') &&
                      ((!i || 1 < j) && (i = o.a.Pb(g, c, b.Ob)), (a = i)),
                      (g = c),
                      (i = null),
                      (j = 0),
                      a && a.length && b.notifySubscribers(a, 'arrayChange');
                  }
                }
                h
                  ? a()
                  : ((h = !0),
                    (f = b.subscribe(
                      function () {
                        ++j;
                      },
                      null,
                      'spectate'
                    )),
                    (g = [].concat(b.v() || [])),
                    (i = null),
                    (e = b.subscribe(a)));
              }
              if (
                ((b.Ob = {}),
                c && 'object' == typeof c && o.a.extend(b.Ob, c),
                (b.Ob.sparse = !0),
                !b.zc)
              ) {
                var e,
                  f,
                  g,
                  h = !1,
                  i = null,
                  j = 0,
                  k = b.Qa,
                  l = b.hb;
                (b.Qa = function (a) {
                  k && k.call(b, a), 'arrayChange' === a && d();
                }),
                  (b.hb = function (c) {
                    l && l.call(b, c),
                      'arrayChange' !== c ||
                        b.Wa('arrayChange') ||
                        (e && e.s(),
                        f && f.s(),
                        (f = e = null),
                        (h = !1),
                        (g = a));
                  }),
                  (b.zc = function (a, b, c) {
                    function d(a, b, c) {
                      return (e[e.length] = { status: a, value: b, index: c });
                    }
                    if (h && !j) {
                      var e = [],
                        f = a.length,
                        g = c.length,
                        k = 0;
                      switch (b) {
                        case 'push':
                          k = f;
                        case 'unshift':
                          for (b = 0; b < g; b++) d('added', c[b], k + b);
                          break;
                        case 'pop':
                          k = f - 1;
                        case 'shift':
                          f && d('deleted', a[k], k);
                          break;
                        case 'splice':
                          b = Math.min(
                            Math.max(0, 0 > c[0] ? f + c[0] : c[0]),
                            f
                          );
                          for (
                            var f = 1 === g ? f : Math.min(b + (c[1] || 0), f),
                              g = b + g - 2,
                              k = Math.max(f, g),
                              l = [],
                              m = [],
                              n = 2;
                            b < k;
                            ++b, ++n
                          )
                            b < f && m.push(d('deleted', a[b], b)),
                              b < g && l.push(d('added', c[n], b));
                          o.a.Kc(m, l);
                          break;
                        default:
                          return;
                      }
                      i = e;
                    }
                  });
              }
            });
          var u = o.a.Da('_state');
          o.o = o.$ = function (b, c, d) {
            function e() {
              if (0 < arguments.length) {
                if ('function' != typeof f)
                  throw Error(
                    "Cannot write a value to a ko.computed unless you specify a 'write' option. If you wish to read the current value, don't pass any parameters."
                  );
                return f.apply(g.nb, arguments), this;
              }
              return (
                g.ra || o.u.cc(e), (g.ka || (g.J && e.Xa())) && e.ha(), g.X
              );
            }
            if (
              ('object' == typeof b
                ? (d = b)
                : ((d = d || {}), b && (d.read = b)),
              'function' != typeof d.read)
            )
              throw Error(
                'Pass a function that returns the value of the ko.computed'
              );
            var f = d.write,
              g = {
                X: a,
                sa: !0,
                ka: !0,
                rb: !1,
                jc: !1,
                ra: !1,
                wb: !1,
                J: !1,
                Wc: d.read,
                nb: c || d.owner,
                l: d.disposeWhenNodeIsRemoved || d.l || null,
                Sa: d.disposeWhen || d.Sa,
                Rb: null,
                I: {},
                V: 0,
                Ic: null
              };
            return (
              (e[u] = g),
              (e.Nc = 'function' == typeof f),
              o.a.Ba || o.a.extend(e, o.T.fn),
              o.T.fn.qb(e),
              o.a.Ab(e, v),
              d.pure
                ? ((g.wb = !0), (g.J = !0), o.a.extend(e, w))
                : d.deferEvaluation && o.a.extend(e, x),
              o.options.deferUpdates && o.Ta.deferred(e, !0),
              g.l && ((g.jc = !0), g.l.nodeType || (g.l = null)),
              g.J || d.deferEvaluation || e.ha(),
              g.l &&
                e.ja() &&
                o.a.K.za(
                  g.l,
                  (g.Rb = function () {
                    e.s();
                  })
                ),
              e
            );
          };
          var v = {
              equalityComparer: i,
              qa: function () {
                return this[u].V;
              },
              Va: function () {
                var a = [];
                return (
                  o.a.P(this[u].I, function (b, c) {
                    a[c.Ka] = c.da;
                  }),
                  a
                );
              },
              Vb: function (a) {
                if (!this[u].V) return !1;
                var b = this.Va();
                return (
                  -1 !== o.a.A(b, a) ||
                  !!o.a.Lb(b, function (b) {
                    return b.Vb && b.Vb(a);
                  })
                );
              },
              uc: function (a, b, c) {
                if (this[u].wb && b === this)
                  throw Error(
                    "A 'pure' computed must not be called recursively"
                  );
                (this[u].I[a] = c), (c.Ka = this[u].V++), (c.La = b.ob());
              },
              Xa: function () {
                var a,
                  b,
                  c = this[u].I;
                for (a in c)
                  if (
                    Object.prototype.hasOwnProperty.call(c, a) &&
                    ((b = c[a]), (this.Ia && b.da.Ja) || b.da.Dd(b.La))
                  )
                    return !0;
              },
              Jd: function () {
                this.Ia && !this[u].rb && this.Ia(!1);
              },
              ja: function () {
                var a = this[u];
                return a.ka || 0 < a.V;
              },
              Rd: function () {
                this.Ja ? this[u].ka && (this[u].sa = !0) : this.Hc();
              },
              $c: function (a) {
                if (a.Hb) {
                  var b = a.subscribe(this.Jd, this, 'dirty'),
                    c = a.subscribe(this.Rd, this);
                  return {
                    da: a,
                    s: function () {
                      b.s(), c.s();
                    }
                  };
                }
                return a.subscribe(this.Hc, this);
              },
              Hc: function () {
                var a = this,
                  b = a.throttleEvaluation;
                b && 0 <= b
                  ? (clearTimeout(this[u].Ic),
                    (this[u].Ic = o.a.setTimeout(function () {
                      a.ha(!0);
                    }, b)))
                  : a.Ia
                    ? a.Ia(!0)
                    : a.ha(!0);
              },
              ha: function (a) {
                var b = this[u],
                  c = b.Sa,
                  d = !1;
                if (!b.rb && !b.ra) {
                  if ((b.l && !o.a.Sb(b.l)) || (c && c())) {
                    if (!b.jc) return void this.s();
                  } else b.jc = !1;
                  b.rb = !0;
                  try {
                    d = this.zd(a);
                  } finally {
                    b.rb = !1;
                  }
                  return d;
                }
              },
              zd: function (b) {
                var c = this[u],
                  d = !1,
                  e = c.wb ? a : !c.V,
                  d = { qd: this, mb: c.I, Qb: c.V };
                o.u.xc({ pd: d, od: n, o: this, Ya: e }), (c.I = {}), (c.V = 0);
                var f = this.yd(c, d);
                return (
                  c.V ? (d = this.sb(c.X, f)) : (this.s(), (d = !0)),
                  d &&
                    (c.J
                      ? this.Gb()
                      : this.notifySubscribers(c.X, 'beforeChange'),
                    (c.X = f),
                    this.notifySubscribers(c.X, 'spectate'),
                    !c.J && b && this.notifySubscribers(c.X),
                    this.rc && this.rc()),
                  e && this.notifySubscribers(c.X, 'awake'),
                  d
                );
              },
              yd: function (a, b) {
                try {
                  var c = a.Wc;
                  return a.nb ? c.call(a.nb) : c();
                } finally {
                  o.u.end(), b.Qb && !a.J && o.a.P(b.mb, m), (a.sa = a.ka = !1);
                }
              },
              v: function (a) {
                var b = this[u];
                return (
                  ((b.ka && (a || !b.V)) || (b.J && this.Xa())) && this.ha(),
                  b.X
                );
              },
              ub: function (a) {
                o.T.fn.ub.call(this, a),
                  (this.nc = function () {
                    return (
                      this[u].J || (this[u].sa ? this.ha() : (this[u].ka = !1)),
                      this[u].X
                    );
                  }),
                  (this.Ia = function (a) {
                    this.pc(this[u].X),
                      (this[u].ka = !0),
                      a && (this[u].sa = !0),
                      this.qc(this, !a);
                  });
              },
              s: function () {
                var b = this[u];
                !b.J &&
                  b.I &&
                  o.a.P(b.I, function (a, b) {
                    b.s && b.s();
                  }),
                  b.l && b.Rb && o.a.K.yb(b.l, b.Rb),
                  (b.I = a),
                  (b.V = 0),
                  (b.ra = !0),
                  (b.sa = !1),
                  (b.ka = !1),
                  (b.J = !1),
                  (b.l = a),
                  (b.Sa = a),
                  (b.Wc = a),
                  this.Nc || (b.nb = a);
              }
            },
            w = {
              Qa: function (a) {
                var b = this,
                  c = b[u];
                if (!c.ra && c.J && 'change' == a) {
                  if (((c.J = !1), c.sa || b.Xa()))
                    (c.I = null), (c.V = 0), b.ha() && b.Gb();
                  else {
                    var d = [];
                    o.a.P(c.I, function (a, b) {
                      d[b.Ka] = a;
                    }),
                      o.a.D(d, function (a, d) {
                        var e = c.I[a],
                          f = b.$c(e.da);
                        (f.Ka = d), (f.La = e.La), (c.I[a] = f);
                      }),
                      b.Xa() && b.ha() && b.Gb();
                  }
                  c.ra || b.notifySubscribers(c.X, 'awake');
                }
              },
              hb: function (b) {
                var c = this[u];
                c.ra ||
                  'change' != b ||
                  this.Wa('change') ||
                  (o.a.P(c.I, function (a, b) {
                    b.s && ((c.I[a] = { da: b.da, Ka: b.Ka, La: b.La }), b.s());
                  }),
                  (c.J = !0),
                  this.notifySubscribers(a, 'asleep'));
              },
              ob: function () {
                var a = this[u];
                return (
                  a.J && (a.sa || this.Xa()) && this.ha(), o.T.fn.ob.call(this)
                );
              }
            },
            x = {
              Qa: function (a) {
                ('change' != a && 'beforeChange' != a) || this.v();
              }
            };
          o.a.Ba && o.a.setPrototypeOf(v, o.T.fn);
          var y = o.ta.Ma;
          (v[y] = o.o),
            (o.Oc = function (a) {
              return 'function' == typeof a && a[y] === v[y];
            }),
            (o.Fd = function (a) {
              return o.Oc(a) && a[u] && a[u].wb;
            }),
            o.b('computed', o.o),
            o.b('dependentObservable', o.o),
            o.b('isComputed', o.Oc),
            o.b('isPureComputed', o.Fd),
            o.b('computed.fn', v),
            o.L(v, 'peek', v.v),
            o.L(v, 'dispose', v.s),
            o.L(v, 'isActive', v.ja),
            o.L(v, 'getDependenciesCount', v.qa),
            o.L(v, 'getDependencies', v.Va),
            (o.xb = function (a, b) {
              return 'function' == typeof a
                ? o.o(a, b, { pure: !0 })
                : ((a = o.a.extend({}, a)), (a.pure = !0), o.o(a, b));
            }),
            o.b('pureComputed', o.xb),
            (function () {
              function b(e, f, g) {
                if (
                  ((g = g || new d()),
                  'object' != typeof (e = f(e)) ||
                    null === e ||
                    e === a ||
                    e instanceof RegExp ||
                    e instanceof Date ||
                    e instanceof String ||
                    e instanceof Number ||
                    e instanceof Boolean)
                )
                  return e;
                var h = e instanceof Array ? [] : {};
                return (
                  g.save(e, h),
                  c(e, function (c) {
                    var d = f(e[c]);
                    switch (typeof d) {
                      case 'boolean':
                      case 'number':
                      case 'string':
                      case 'function':
                        h[c] = d;
                        break;
                      case 'object':
                      case 'undefined':
                        var i = g.get(d);
                        h[c] = i !== a ? i : b(d, f, g);
                    }
                  }),
                  h
                );
              }
              function c(a, b) {
                if (a instanceof Array) {
                  for (var c = 0; c < a.length; c++) b(c);
                  'function' == typeof a.toJSON && b('toJSON');
                } else for (c in a) b(c);
              }
              function d() {
                (this.keys = []), (this.values = []);
              }
              (o.ad = function (a) {
                if (0 == arguments.length)
                  throw Error(
                    'When calling ko.toJS, pass the object you want to convert.'
                  );
                return b(a, function (a) {
                  for (var b = 0; o.O(a) && 10 > b; b++) a = a();
                  return a;
                });
              }),
                (o.toJSON = function (a, b, c) {
                  return (a = o.ad(a)), o.a.hc(a, b, c);
                }),
                (d.prototype = {
                  constructor: d,
                  save: function (a, b) {
                    var c = o.a.A(this.keys, a);
                    0 <= c
                      ? (this.values[c] = b)
                      : (this.keys.push(a), this.values.push(b));
                  },
                  get: function (b) {
                    return (
                      (b = o.a.A(this.keys, b)), 0 <= b ? this.values[b] : a
                    );
                  }
                });
            })(),
            o.b('toJS', o.ad),
            o.b('toJSON', o.toJSON),
            (o.Wd = function (a, b, c) {
              function d(b) {
                var d = o.xb(a, c).extend({ ma: 'always' }),
                  e = d.subscribe(function (a) {
                    a && (e.s(), b(a));
                  });
                return d.notifySubscribers(d.v()), e;
              }
              return 'function' != typeof Promise || b
                ? d(b.bind(c))
                : new Promise(d);
            }),
            o.b('when', o.Wd),
            (function () {
              o.w = {
                M: function (b) {
                  switch (o.a.R(b)) {
                    case 'option':
                      return !0 === b.__ko__hasDomDataOptionValue__
                        ? o.a.g.get(b, o.c.options.$b)
                        : 7 >= o.a.W
                          ? b.getAttributeNode('value') &&
                            b.getAttributeNode('value').specified
                            ? b.value
                            : b.text
                          : b.value;
                    case 'select':
                      return 0 <= b.selectedIndex
                        ? o.w.M(b.options[b.selectedIndex])
                        : a;
                    default:
                      return b.value;
                  }
                },
                cb: function (b, c, d) {
                  switch (o.a.R(b)) {
                    case 'option':
                      'string' == typeof c
                        ? (o.a.g.set(b, o.c.options.$b, a),
                          '__ko__hasDomDataOptionValue__' in b &&
                            delete b.__ko__hasDomDataOptionValue__,
                          (b.value = c))
                        : (o.a.g.set(b, o.c.options.$b, c),
                          (b.__ko__hasDomDataOptionValue__ = !0),
                          (b.value = 'number' == typeof c ? c : ''));
                      break;
                    case 'select':
                      ('' !== c && null !== c) || (c = a);
                      for (
                        var e, f = -1, g = 0, h = b.options.length;
                        g < h;
                        ++g
                      )
                        if (
                          (e = o.w.M(b.options[g])) == c ||
                          ('' === e && c === a)
                        ) {
                          f = g;
                          break;
                        }
                      (d || 0 <= f || (c === a && 1 < b.size)) &&
                        ((b.selectedIndex = f),
                        6 === o.a.W &&
                          o.a.setTimeout(function () {
                            b.selectedIndex = f;
                          }, 0));
                      break;
                    default:
                      (null !== c && c !== a) || (c = ''), (b.value = c);
                  }
                }
              };
            })(),
            o.b('selectExtensions', o.w),
            o.b('selectExtensions.readValue', o.w.M),
            o.b('selectExtensions.writeValue', o.w.cb),
            (o.m = (function () {
              function a(a) {
                (a = o.a.Db(a)),
                  123 === a.charCodeAt(0) && (a = a.slice(1, -1)),
                  (a += '\n,');
                var b,
                  c = [],
                  g = a.match(d),
                  h = [],
                  i = 0;
                if (1 < g.length) {
                  for (var j, k = 0; (j = g[k]); ++k) {
                    var l = j.charCodeAt(0);
                    if (44 === l) {
                      if (0 >= i) {
                        c.push(
                          b && h.length
                            ? { key: b, value: h.join('') }
                            : { unknown: b || h.join('') }
                        ),
                          (b = i = 0),
                          (h = []);
                        continue;
                      }
                    } else if (58 === l) {
                      if (!i && !b && 1 === h.length) {
                        b = h.pop();
                        continue;
                      }
                    } else {
                      if (
                        47 === l &&
                        1 < j.length &&
                        (47 === j.charCodeAt(1) || 42 === j.charCodeAt(1))
                      )
                        continue;
                      47 === l && k && 1 < j.length
                        ? (l = g[k - 1].match(e)) &&
                          !f[l[0]] &&
                          ((a = a.substr(a.indexOf(j) + 1)),
                          (g = a.match(d)),
                          (k = -1),
                          (j = '/'))
                        : 40 === l || 123 === l || 91 === l
                          ? ++i
                          : 41 === l || 125 === l || 93 === l
                            ? --i
                            : b ||
                              h.length ||
                              (34 !== l && 39 !== l) ||
                              (j = j.slice(1, -1));
                    }
                    h.push(j);
                  }
                  if (0 < i)
                    throw Error('Unbalanced parentheses, braces, or brackets');
                }
                return c;
              }
              var b = ['true', 'false', 'null', 'undefined'],
                c = /^(?:[$_a-z][$\w]*|(.+)(\.\s*[$_a-z][$\w]*|\[.+\]))$/i,
                d = RegExp(
                  '"(?:\\\\.|[^"])*"|\'(?:\\\\.|[^\'])*\'|`(?:\\\\.|[^`])*`|/\\*(?:[^*]|\\*+[^*/])*\\*+/|//.*\n|/(?:\\\\.|[^/])+/w*|[^\\s:,/][^,"\'`{}()/:[\\]]*[^\\s,"\'`{}()/:[\\]]|[^\\s]',
                  'g'
                ),
                e = /[\])"'A-Za-z0-9_$]+$/,
                f = { in: 1, return: 1, typeof: 1 },
                g = {};
              return {
                Ra: [],
                wa: g,
                ac: a,
                vb: function (d, e) {
                  function f(a, d) {
                    var e;
                    if (!k) {
                      var l = o.getBindingHandler(a);
                      if (l && l.preprocess && !(d = l.preprocess(d, a, f)))
                        return;
                      (l = g[a]) &&
                        ((e = d),
                        0 <= o.a.A(b, e)
                          ? (e = !1)
                          : ((l = e.match(c)),
                            (e =
                              null !== l &&
                              (l[1] ? 'Object(' + l[1] + ')' + l[2] : e))),
                        (l = e)),
                        l &&
                          i.push(
                            "'" +
                              ('string' == typeof g[a] ? g[a] : a) +
                              "':function(_z){" +
                              e +
                              '=_z}'
                          );
                    }
                    j && (d = 'function(){return ' + d + ' }'),
                      h.push("'" + a + "':" + d);
                  }
                  e = e || {};
                  var h = [],
                    i = [],
                    j = e.valueAccessors,
                    k = e.bindingParams,
                    l = 'string' == typeof d ? a(d) : d;
                  return (
                    o.a.D(l, function (a) {
                      f(a.key || a.unknown, a.value);
                    }),
                    i.length &&
                      f('_ko_property_writers', '{' + i.join(',') + ' }'),
                    h.join(',')
                  );
                },
                Id: function (a, b) {
                  for (var c = 0; c < a.length; c++)
                    if (a[c].key == b) return !0;
                  return !1;
                },
                eb: function (a, b, c, d, e) {
                  a && o.O(a)
                    ? !o.Za(a) || (e && a.v() === d) || a(d)
                    : (a = b.get('_ko_property_writers')) && a[c] && a[c](d);
                }
              };
            })()),
            o.b('expressionRewriting', o.m),
            o.b('expressionRewriting.bindingRewriteValidators', o.m.Ra),
            o.b('expressionRewriting.parseObjectLiteral', o.m.ac),
            o.b('expressionRewriting.preProcessBindings', o.m.vb),
            o.b('expressionRewriting._twoWayBindings', o.m.wa),
            o.b('jsonExpressionRewriting', o.m),
            o.b(
              'jsonExpressionRewriting.insertPropertyAccessorsIntoJson',
              o.m.vb
            ),
            (function () {
              function a(a) {
                return 8 == a.nodeType && g.test(f ? a.text : a.nodeValue);
              }
              function b(a) {
                return 8 == a.nodeType && h.test(f ? a.text : a.nodeValue);
              }
              function d(c, d) {
                for (var e = c, f = 1, g = []; (e = e.nextSibling); ) {
                  if (b(e) && (o.a.g.set(e, j, !0), 0 === --f)) return g;
                  g.push(e), a(e) && f++;
                }
                if (!d)
                  throw Error(
                    'Cannot find closing comment tag to match: ' + c.nodeValue
                  );
                return null;
              }
              function e(a, b) {
                var c = d(a, b);
                return c
                  ? 0 < c.length
                    ? c[c.length - 1].nextSibling
                    : a.nextSibling
                  : null;
              }
              var f = c && '\x3c!--test--\x3e' === c.createComment('test').text,
                g = f
                  ? /^\x3c!--\s*ko(?:\s+([\s\S]+))?\s*--\x3e$/
                  : /^\s*ko(?:\s+([\s\S]+))?\s*$/,
                h = f ? /^\x3c!--\s*\/ko\s*--\x3e$/ : /^\s*\/ko\s*$/,
                i = { ul: !0, ol: !0 },
                j = '__ko_matchedEndComment__';
              o.h = {
                ea: {},
                childNodes: function (b) {
                  return a(b) ? d(b) : b.childNodes;
                },
                Ea: function (b) {
                  if (a(b)) {
                    b = o.h.childNodes(b);
                    for (var c = 0, d = b.length; c < d; c++)
                      o.removeNode(b[c]);
                  } else o.a.Tb(b);
                },
                va: function (b, c) {
                  if (a(b)) {
                    o.h.Ea(b);
                    for (var d = b.nextSibling, e = 0, f = c.length; e < f; e++)
                      d.parentNode.insertBefore(c[e], d);
                  } else o.a.va(b, c);
                },
                Vc: function (b, c) {
                  var d;
                  a(b)
                    ? ((d = b.nextSibling), (b = b.parentNode))
                    : (d = b.firstChild),
                    d ? c !== d && b.insertBefore(c, d) : b.appendChild(c);
                },
                Wb: function (b, c, d) {
                  d
                    ? ((d = d.nextSibling),
                      a(b) && (b = b.parentNode),
                      d ? c !== d && b.insertBefore(c, d) : b.appendChild(c))
                    : o.h.Vc(b, c);
                },
                firstChild: function (c) {
                  if (a(c))
                    return !c.nextSibling || b(c.nextSibling)
                      ? null
                      : c.nextSibling;
                  if (c.firstChild && b(c.firstChild))
                    throw Error(
                      'Found invalid end comment, as the first child of ' + c
                    );
                  return c.firstChild;
                },
                nextSibling: function (c) {
                  if ((a(c) && (c = e(c)), c.nextSibling && b(c.nextSibling))) {
                    var d = c.nextSibling;
                    if (b(d) && !o.a.g.get(d, j))
                      throw Error(
                        'Found end comment without a matching opening comment, as child of ' +
                          c
                      );
                    return null;
                  }
                  return c.nextSibling;
                },
                Cd: a,
                Vd: function (a) {
                  return (a = (f ? a.text : a.nodeValue).match(g))
                    ? a[1]
                    : null;
                },
                Sc: function (c) {
                  if (i[o.a.R(c)]) {
                    var d = c.firstChild;
                    if (d)
                      do {
                        if (1 === d.nodeType) {
                          var f;
                          f = d.firstChild;
                          var g = null;
                          if (f)
                            do {
                              if (g) g.push(f);
                              else if (a(f)) {
                                var h = e(f, !0);
                                h ? (f = h) : (g = [f]);
                              } else b(f) && (g = [f]);
                            } while ((f = f.nextSibling));
                          if ((f = g))
                            for (g = d.nextSibling, h = 0; h < f.length; h++)
                              g ? c.insertBefore(f[h], g) : c.appendChild(f[h]);
                        }
                      } while ((d = d.nextSibling));
                  }
                }
              };
            })(),
            o.b('virtualElements', o.h),
            o.b('virtualElements.allowedBindings', o.h.ea),
            o.b('virtualElements.emptyNode', o.h.Ea),
            o.b('virtualElements.insertAfter', o.h.Wb),
            o.b('virtualElements.prepend', o.h.Vc),
            o.b('virtualElements.setDomNodeChildren', o.h.va),
            (function () {
              (o.ga = function () {
                this.nd = {};
              }),
                o.a.extend(o.ga.prototype, {
                  nodeHasBindings: function (a) {
                    switch (a.nodeType) {
                      case 1:
                        return (
                          null != a.getAttribute('data-bind') ||
                          o.j.getComponentNameForNode(a)
                        );
                      case 8:
                        return o.h.Cd(a);
                      default:
                        return !1;
                    }
                  },
                  getBindings: function (a, b) {
                    var c = this.getBindingsString(a, b),
                      c = c ? this.parseBindingsString(c, b, a) : null;
                    return o.j.tc(c, a, b, !1);
                  },
                  getBindingAccessors: function (a, b) {
                    var c = this.getBindingsString(a, b),
                      c = c
                        ? this.parseBindingsString(c, b, a, {
                            valueAccessors: !0
                          })
                        : null;
                    return o.j.tc(c, a, b, !0);
                  },
                  getBindingsString: function (a) {
                    switch (a.nodeType) {
                      case 1:
                        return a.getAttribute('data-bind');
                      case 8:
                        return o.h.Vd(a);
                      default:
                        return null;
                    }
                  },
                  parseBindingsString: function (a, b, c, d) {
                    try {
                      var e,
                        f = this.nd,
                        g = a + ((d && d.valueAccessors) || '');
                      if (!(e = f[g])) {
                        var h,
                          i =
                            'with($context){with($data||{}){return{' +
                            o.m.vb(a, d) +
                            '}}}';
                        (h = new Function('$context', '$element', i)),
                          (e = f[g] = h);
                      }
                      return e(b, c);
                    } catch (b) {
                      throw (
                        ((b.message =
                          'Unable to parse bindings.\nBindings value: ' +
                          a +
                          '\nMessage: ' +
                          b.message),
                        b)
                      );
                    }
                  }
                }),
                (o.ga.instance = new o.ga());
            })(),
            o.b('bindingProvider', o.ga),
            (function () {
              function d(a) {
                var b = (a = o.a.g.get(a, w)) && a.N;
                b && ((a.N = null), b.Tc());
              }
              function f(a, b, c) {
                (this.node = a),
                  (this.yc = b),
                  (this.kb = []),
                  (this.H = !1),
                  b.N || o.a.K.za(a, d),
                  c && c.N && (c.N.kb.push(a), (this.Kb = c));
              }
              function g(a) {
                return function () {
                  return a;
                };
              }
              function h(a) {
                return a();
              }
              function i(a) {
                return o.a.Ga(o.u.G(a), function (b, c) {
                  return function () {
                    return a()[c];
                  };
                });
              }
              function j(a, b, c) {
                return 'function' == typeof a
                  ? i(a.bind(null, b, c))
                  : o.a.Ga(a, g);
              }
              function k(a, b) {
                return i(this.getBindings.bind(this, a, b));
              }
              function l(a, b) {
                var c = o.h.firstChild(b);
                if (c) {
                  var d,
                    e = o.ga.instance,
                    f = e.preprocessNode;
                  if (f) {
                    for (; (d = c); ) (c = o.h.nextSibling(d)), f.call(e, d);
                    c = o.h.firstChild(b);
                  }
                  for (; (d = c); ) (c = o.h.nextSibling(d)), m(a, d);
                }
                o.i.ma(b, o.i.H);
              }
              function m(a, b) {
                var c = a,
                  d = 1 === b.nodeType;
                d && o.h.Sc(b),
                  (d || o.ga.instance.nodeHasBindings(b)) &&
                    (c = p(b, null, a).bindingContextForDescendants),
                  c && !u[o.a.R(b)] && l(c, b);
              }
              function n(a) {
                var b = [],
                  c = {},
                  d = [];
                return (
                  o.a.P(a, function e(f) {
                    if (!c[f]) {
                      var g = o.getBindingHandler(f);
                      g &&
                        (g.after &&
                          (d.push(f),
                          o.a.D(g.after, function (b) {
                            if (a[b]) {
                              if (-1 !== o.a.A(d, b))
                                throw Error(
                                  'Cannot combine the following bindings, because they have a cyclic dependency: ' +
                                    d.join(', ')
                                );
                              e(b);
                            }
                          }),
                          d.length--),
                        b.push({ key: f, Mc: g })),
                        (c[f] = !0);
                    }
                  }),
                  b
                );
              }
              function p(b, c, d) {
                var e = o.a.g.Ub(b, w, {}),
                  f = e.hd;
                if (!c) {
                  if (f)
                    throw Error(
                      'You cannot apply bindings multiple times to the same element.'
                    );
                  e.hd = !0;
                }
                f || (e.context = d), e.Zb || (e.Zb = {});
                var g;
                if (c && 'function' != typeof c) g = c;
                else {
                  var i = o.ga.instance,
                    j = i.getBindingAccessors || k,
                    l = o.$(
                      function () {
                        return (
                          (g = c ? c(d, b) : j.call(i, b, d)) &&
                            (d[r] && d[r](), d[t] && d[t]()),
                          g
                        );
                      },
                      null,
                      { l: b }
                    );
                  (g && l.ja()) || (l = null);
                }
                var m,
                  p = d;
                if (g) {
                  var q = function () {
                      return o.a.Ga(l ? l() : g, h);
                    },
                    s = l
                      ? function (a) {
                          return function () {
                            return h(l()[a]);
                          };
                        }
                      : function (a) {
                          return g[a];
                        };
                  (q.get = function (a) {
                    return g[a] && h(s(a));
                  }),
                    (q.has = function (a) {
                      return a in g;
                    }),
                    o.i.H in g &&
                      o.i.subscribe(b, o.i.H, function () {
                        var a = (0, g[o.i.H])();
                        if (a) {
                          var c = o.h.childNodes(b);
                          c.length && a(c, o.Ec(c[0]));
                        }
                      }),
                    o.i.pa in g &&
                      ((p = o.i.Cb(b, d)),
                      o.i.subscribe(b, o.i.pa, function () {
                        var a = (0, g[o.i.pa])();
                        a && o.h.firstChild(b) && a(b);
                      })),
                    (e = n(g)),
                    o.a.D(e, function (c) {
                      var d = c.Mc.init,
                        e = c.Mc.update,
                        f = c.key;
                      if (8 === b.nodeType && !o.h.ea[f])
                        throw Error(
                          "The binding '" +
                            f +
                            "' cannot be used with virtual elements"
                        );
                      try {
                        'function' == typeof d &&
                          o.u.G(function () {
                            var c = d(b, s(f), q, p.$data, p);
                            if (c && c.controlsDescendantBindings) {
                              if (m !== a)
                                throw Error(
                                  'Multiple bindings (' +
                                    m +
                                    ' and ' +
                                    f +
                                    ') are trying to control descendant bindings of the same element. You cannot use these bindings together on the same element.'
                                );
                              m = f;
                            }
                          }),
                          'function' == typeof e &&
                            o.$(
                              function () {
                                e(b, s(f), q, p.$data, p);
                              },
                              null,
                              { l: b }
                            );
                      } catch (a) {
                        throw (
                          ((a.message =
                            'Unable to process binding "' +
                            f +
                            ': ' +
                            g[f] +
                            '"\nMessage: ' +
                            a.message),
                          a)
                        );
                      }
                    });
                }
                return (
                  (e = m === a),
                  {
                    shouldBindDescendants: e,
                    bindingContextForDescendants: e && p
                  }
                );
              }
              function q(b, c) {
                return b && b instanceof o.fa ? b : new o.fa(b, a, a, c);
              }
              var r = o.a.Da('_subscribable'),
                s = o.a.Da('_ancestorBindingInfo'),
                t = o.a.Da('_dataDependency');
              o.c = {};
              var u = { script: !0, textarea: !0, template: !0 };
              o.getBindingHandler = function (a) {
                return o.c[a];
              };
              var v = {};
              (o.fa = function (b, c, d, e, f) {
                function g() {
                  var a = l ? k() : k,
                    b = o.a.f(a);
                  return (
                    c
                      ? (o.a.extend(i, c), s in c && (i[s] = c[s]))
                      : ((i.$parents = []), (i.$root = b), (i.ko = o)),
                    (i[r] = h),
                    j ? (b = i.$data) : ((i.$rawData = a), (i.$data = b)),
                    d && (i[d] = b),
                    e && e(i, c, b),
                    c && c[r] && !o.S.o().Vb(c[r]) && c[r](),
                    m && (i[t] = m),
                    i.$data
                  );
                }
                var h,
                  i = this,
                  j = b === v,
                  k = j ? a : b,
                  l = 'function' == typeof k && !o.O(k),
                  m = f && f.dataDependency;
                f && f.exportDependencies
                  ? g()
                  : ((h = o.xb(g)),
                    h.v(),
                    h.ja() ? (h.equalityComparer = null) : (i[r] = a));
              }),
                (o.fa.prototype.createChildContext = function (a, b, c, d) {
                  if (
                    (!d &&
                      b &&
                      'object' == typeof b &&
                      ((d = b), (b = d.as), (c = d.extend)),
                    b && d && d.noChildContext)
                  ) {
                    var e = 'function' == typeof a && !o.O(a);
                    return new o.fa(
                      v,
                      this,
                      null,
                      function (d) {
                        c && c(d), (d[b] = e ? a() : a);
                      },
                      d
                    );
                  }
                  return new o.fa(
                    a,
                    this,
                    b,
                    function (a, b) {
                      (a.$parentContext = b),
                        (a.$parent = b.$data),
                        (a.$parents = (b.$parents || []).slice(0)),
                        a.$parents.unshift(a.$parent),
                        c && c(a);
                    },
                    d
                  );
                }),
                (o.fa.prototype.extend = function (a, b) {
                  return new o.fa(
                    v,
                    this,
                    null,
                    function (b) {
                      o.a.extend(b, 'function' == typeof a ? a(b) : a);
                    },
                    b
                  );
                });
              var w = o.a.g.Z();
              (f.prototype.Tc = function () {
                this.Kb && this.Kb.N && this.Kb.N.sd(this.node);
              }),
                (f.prototype.sd = function (a) {
                  o.a.Pa(this.kb, a), !this.kb.length && this.H && this.Cc();
                }),
                (f.prototype.Cc = function () {
                  (this.H = !0),
                    this.yc.N &&
                      !this.kb.length &&
                      ((this.yc.N = null),
                      o.a.K.yb(this.node, d),
                      o.i.ma(this.node, o.i.pa),
                      this.Tc());
                }),
                (o.i = {
                  H: 'childrenComplete',
                  pa: 'descendantsComplete',
                  subscribe: function (a, b, c, d, e) {
                    var f = o.a.g.Ub(a, w, {});
                    return (
                      f.Fa || (f.Fa = new o.T()),
                      e && e.notifyImmediately && f.Zb[b] && o.u.G(c, d, [a]),
                      f.Fa.subscribe(c, d, b)
                    );
                  },
                  ma: function (b, c) {
                    var d = o.a.g.get(b, w);
                    if (
                      d &&
                      ((d.Zb[c] = !0),
                      d.Fa && d.Fa.notifySubscribers(b, c),
                      c == o.i.H)
                    )
                      if (d.N) d.N.Cc();
                      else if (d.N === a && d.Fa && d.Fa.Wa(o.i.pa))
                        throw Error(
                          'descendantsComplete event not supported for bindings on this node'
                        );
                  },
                  Cb: function (a, b) {
                    var c = o.a.g.Ub(a, w, {});
                    return (
                      c.N || (c.N = new f(a, c, b[s])),
                      b[s] == c
                        ? b
                        : b.extend(function (a) {
                            a[s] = c;
                          })
                    );
                  }
                }),
                (o.Td = function (a) {
                  return (a = o.a.g.get(a, w)) && a.context;
                }),
                (o.ib = function (a, b, c) {
                  return 1 === a.nodeType && o.h.Sc(a), p(a, b, q(c));
                }),
                (o.ld = function (a, b, c) {
                  return (c = q(c)), o.ib(a, j(b, c, a), c);
                }),
                (o.Oa = function (a, b) {
                  (1 !== b.nodeType && 8 !== b.nodeType) || l(q(a), b);
                }),
                (o.vc = function (a, d, f) {
                  if (
                    (!e && b.jQuery && (e = b.jQuery), 2 > arguments.length)
                  ) {
                    if (!(d = c.body))
                      throw Error(
                        'ko.applyBindings: could not find document.body; has the document been loaded?'
                      );
                  } else if (!d || (1 !== d.nodeType && 8 !== d.nodeType))
                    throw Error(
                      'ko.applyBindings: first parameter should be your view model; second parameter should be a DOM node'
                    );
                  m(q(a, f), d);
                }),
                (o.Dc = function (b) {
                  return !b || (1 !== b.nodeType && 8 !== b.nodeType)
                    ? a
                    : o.Td(b);
                }),
                (o.Ec = function (b) {
                  return (b = o.Dc(b)) ? b.$data : a;
                }),
                o.b('bindingHandlers', o.c),
                o.b('bindingEvent', o.i),
                o.b('bindingEvent.subscribe', o.i.subscribe),
                o.b('bindingEvent.startPossiblyAsyncContentBinding', o.i.Cb),
                o.b('applyBindings', o.vc),
                o.b('applyBindingsToDescendants', o.Oa),
                o.b('applyBindingAccessorsToNode', o.ib),
                o.b('applyBindingsToNode', o.ld),
                o.b('contextFor', o.Dc),
                o.b('dataFor', o.Ec);
            })(),
            (function (a) {
              function b(b, d) {
                var g,
                  h = Object.prototype.hasOwnProperty.call(e, b) ? e[b] : a;
                h
                  ? h.subscribe(d)
                  : ((h = e[b] = new o.T()),
                    h.subscribe(d),
                    c(b, function (a, c) {
                      var d = !(!c || !c.synchronous);
                      (f[b] = { definition: a, Gd: d }),
                        delete e[b],
                        g || d
                          ? h.notifySubscribers(a)
                          : o.na.zb(function () {
                              h.notifySubscribers(a);
                            });
                    }),
                    (g = !0));
              }
              function c(a, b) {
                d('getConfig', [a], function (c) {
                  c
                    ? d('loadComponent', [a, c], function (a) {
                        b(a, c);
                      })
                    : b(null, null);
                });
              }
              function d(b, c, e, f) {
                f || (f = o.j.loaders.slice(0));
                var g = f.shift();
                if (g) {
                  var h = g[b];
                  if (h) {
                    var i = !1;
                    if (
                      h.apply(
                        g,
                        c.concat(function (a) {
                          i ? e(null) : null !== a ? e(a) : d(b, c, e, f);
                        })
                      ) !== a &&
                      ((i = !0), !g.suppressLoaderExceptions)
                    )
                      throw Error(
                        'Component loaders must supply values by invoking the callback, not by returning values synchronously.'
                      );
                  } else d(b, c, e, f);
                } else e(null);
              }
              var e = {},
                f = {};
              (o.j = {
                get: function (c, d) {
                  var e = Object.prototype.hasOwnProperty.call(f, c) ? f[c] : a;
                  e
                    ? e.Gd
                      ? o.u.G(function () {
                          d(e.definition);
                        })
                      : o.na.zb(function () {
                          d(e.definition);
                        })
                    : b(c, d);
                },
                Bc: function (a) {
                  delete f[a];
                },
                oc: d
              }),
                (o.j.loaders = []),
                o.b('components', o.j),
                o.b('components.get', o.j.get),
                o.b('components.clearCachedDefinition', o.j.Bc);
            })(),
            (function () {
              function a(a, b, c, d) {
                function e() {
                  0 == --h && d(f);
                }
                var f = {},
                  h = 2,
                  i = c.template;
                (c = c.viewModel),
                  i
                    ? g(b, i, function (b) {
                        o.j.oc('loadTemplate', [a, b], function (a) {
                          (f.template = a), e();
                        });
                      })
                    : e(),
                  c
                    ? g(b, c, function (b) {
                        o.j.oc('loadViewModel', [a, b], function (a) {
                          (f[k] = a), e();
                        });
                      })
                    : e();
              }
              function d(a, b, c) {
                if ('function' == typeof b)
                  c(function (a) {
                    return new b(a);
                  });
                else if ('function' == typeof b[k]) c(b[k]);
                else if ('instance' in b) {
                  var e = b.instance;
                  c(function () {
                    return e;
                  });
                } else
                  'viewModel' in b
                    ? d(a, b.viewModel, c)
                    : a('Unknown viewModel value: ' + b);
              }
              function e(a) {
                switch (o.a.R(a)) {
                  case 'script':
                    return o.a.ua(a.text);
                  case 'textarea':
                    return o.a.ua(a.value);
                  case 'template':
                    if (f(a.content)) return o.a.Ca(a.content.childNodes);
                }
                return o.a.Ca(a.childNodes);
              }
              function f(a) {
                return b.DocumentFragment
                  ? a instanceof DocumentFragment
                  : a && 11 === a.nodeType;
              }
              function g(a, c, d) {
                'string' == typeof c.require
                  ? h || b.require
                    ? (h || b.require)([c.require], function (a) {
                        a &&
                          'object' == typeof a &&
                          a.Xd &&
                          a.default &&
                          (a = a.default),
                          d(a);
                      })
                    : a('Uses require, but no AMD loader is present')
                  : d(c);
              }
              function i(a) {
                return function (b) {
                  throw Error("Component '" + a + "': " + b);
                };
              }
              var j = {};
              (o.j.register = function (a, b) {
                if (!b) throw Error('Invalid configuration for ' + a);
                if (o.j.tb(a))
                  throw Error('Component ' + a + ' is already registered');
                j[a] = b;
              }),
                (o.j.tb = function (a) {
                  return Object.prototype.hasOwnProperty.call(j, a);
                }),
                (o.j.unregister = function (a) {
                  delete j[a], o.j.Bc(a);
                }),
                (o.j.Fc = {
                  getConfig: function (a, b) {
                    b(o.j.tb(a) ? j[a] : null);
                  },
                  loadComponent: function (b, c, d) {
                    var e = i(b);
                    g(e, c, function (c) {
                      a(b, e, c, d);
                    });
                  },
                  loadTemplate: function (a, d, g) {
                    if (((a = i(a)), 'string' == typeof d)) g(o.a.ua(d));
                    else if (d instanceof Array) g(d);
                    else if (f(d)) g(o.a.la(d.childNodes));
                    else if (d.element)
                      if (
                        ((d = d.element),
                        b.HTMLElement
                          ? d instanceof HTMLElement
                          : d && d.tagName && 1 === d.nodeType)
                      )
                        g(e(d));
                      else if ('string' == typeof d) {
                        var h = c.getElementById(d);
                        h ? g(e(h)) : a('Cannot find element with ID ' + d);
                      } else a('Unknown element type: ' + d);
                    else a('Unknown template value: ' + d);
                  },
                  loadViewModel: function (a, b, c) {
                    d(i(a), b, c);
                  }
                });
              var k = 'createViewModel';
              o.b('components.register', o.j.register),
                o.b('components.isRegistered', o.j.tb),
                o.b('components.unregister', o.j.unregister),
                o.b('components.defaultLoader', o.j.Fc),
                o.j.loaders.push(o.j.Fc),
                (o.j.dd = j);
            })(),
            (function () {
              function a(a, c) {
                var d = a.getAttribute('params');
                if (d) {
                  var d = b.parseBindingsString(d, c, a, {
                      valueAccessors: !0,
                      bindingParams: !0
                    }),
                    d = o.a.Ga(d, function (b) {
                      return o.o(b, null, { l: a });
                    }),
                    e = o.a.Ga(d, function (b) {
                      var c = b.v();
                      return b.ja()
                        ? o.o({
                            read: function () {
                              return o.a.f(b());
                            },
                            write:
                              o.Za(c) &&
                              function (a) {
                                b()(a);
                              },
                            l: a
                          })
                        : c;
                    });
                  return (
                    Object.prototype.hasOwnProperty.call(e, '$raw') ||
                      (e.$raw = d),
                    e
                  );
                }
                return { $raw: {} };
              }
              (o.j.getComponentNameForNode = function (a) {
                var b = o.a.R(a);
                if (
                  o.j.tb(b) &&
                  (-1 != b.indexOf('-') ||
                    '[object HTMLUnknownElement]' == '' + a ||
                    (8 >= o.a.W && a.tagName === b))
                )
                  return b;
              }),
                (o.j.tc = function (b, c, d, e) {
                  if (1 === c.nodeType) {
                    var f = o.j.getComponentNameForNode(c);
                    if (f) {
                      if (((b = b || {}), b.component))
                        throw Error(
                          'Cannot use the "component" binding on a custom element matching a component'
                        );
                      var g = { name: f, params: a(c, d) };
                      b.component = e
                        ? function () {
                            return g;
                          }
                        : g;
                    }
                  }
                  return b;
                });
              var b = new o.ga();
              9 > o.a.W &&
                ((o.j.register = (function (a) {
                  return function (b) {
                    return a.apply(this, arguments);
                  };
                })(o.j.register)),
                (c.createDocumentFragment = (function (a) {
                  return function () {
                    var b,
                      c = a(),
                      d = o.j.dd;
                    for (b in d);
                    return c;
                  };
                })(c.createDocumentFragment)));
            })(),
            (function () {
              function a(a, b, c) {
                if (!(b = b.template))
                  throw Error("Component '" + a + "' has no template");
                (a = o.a.Ca(b)), o.h.va(c, a);
              }
              function b(a, b, c) {
                var d = a.createViewModel;
                return d ? d.call(a, b, c) : b;
              }
              var c = 0;
              (o.c.component = {
                init: function (d, e, f, g, h) {
                  function i() {
                    var a = j && j.dispose;
                    'function' == typeof a && a.call(j),
                      l && l.s(),
                      (k = j = l = null);
                  }
                  var j,
                    k,
                    l,
                    m = o.a.la(o.h.childNodes(d));
                  return (
                    o.h.Ea(d),
                    o.a.K.za(d, i),
                    o.o(
                      function () {
                        var f,
                          g,
                          n = o.a.f(e());
                        if (
                          ('string' == typeof n
                            ? (f = n)
                            : ((f = o.a.f(n.name)), (g = o.a.f(n.params))),
                          !f)
                        )
                          throw Error('No component name specified');
                        var p = o.i.Cb(d, h),
                          q = (k = ++c);
                        o.j.get(f, function (c) {
                          if (k === q) {
                            if ((i(), !c))
                              throw Error("Unknown component '" + f + "'");
                            a(f, c, d);
                            var e = b(c, g, { element: d, templateNodes: m });
                            (c = p.createChildContext(e, {
                              extend: function (a) {
                                (a.$component = e),
                                  (a.$componentTemplateNodes = m);
                              }
                            })),
                              e &&
                                e.koDescendantsComplete &&
                                (l = o.i.subscribe(
                                  d,
                                  o.i.pa,
                                  e.koDescendantsComplete,
                                  e
                                )),
                              (j = e),
                              o.Oa(c, d);
                          }
                        });
                      },
                      null,
                      { l: d }
                    ),
                    { controlsDescendantBindings: !0 }
                  );
                }
              }),
                (o.h.ea.component = !0);
            })();
          var z = { class: 'className', for: 'htmlFor' };
          (o.c.attr = {
            update: function (b, c) {
              var d = o.a.f(c()) || {};
              o.a.P(d, function (c, d) {
                d = o.a.f(d);
                var e = c.indexOf(':'),
                  e =
                    'lookupNamespaceURI' in b &&
                    0 < e &&
                    b.lookupNamespaceURI(c.substr(0, e)),
                  f = !1 === d || null === d || d === a;
                f
                  ? e
                    ? b.removeAttributeNS(e, c)
                    : b.removeAttribute(c)
                  : (d = d.toString()),
                  8 >= o.a.W && c in z
                    ? ((c = z[c]), f ? b.removeAttribute(c) : (b[c] = d))
                    : f ||
                      (e ? b.setAttributeNS(e, c, d) : b.setAttribute(c, d)),
                  'name' === c && o.a.Yc(b, f ? '' : d);
              });
            }
          }),
            (function () {
              (o.c.checked = {
                after: ['value', 'attr'],
                init: function (b, c, d) {
                  function e() {
                    var e = b.checked,
                      f = g();
                    if (!o.S.Ya() && (e || (!i && !o.S.qa()))) {
                      var j = o.u.G(c);
                      if (k) {
                        var m = l ? j.v() : j,
                          p = n;
                        (n = f),
                          p !== f
                            ? e && (o.a.Na(m, f, !0), o.a.Na(m, p, !1))
                            : o.a.Na(m, f, e),
                          l && o.Za(j) && j(m);
                      } else
                        h && (f === a ? (f = e) : e || (f = a)),
                          o.m.eb(j, d, 'checked', f, !0);
                    }
                  }
                  function f() {
                    var d = o.a.f(c()),
                      e = g();
                    k
                      ? ((b.checked = 0 <= o.a.A(d, e)), (n = e))
                      : (b.checked = h && e === a ? !!d : g() === d);
                  }
                  var g = o.xb(function () {
                      return d.has('checkedValue')
                        ? o.a.f(d.get('checkedValue'))
                        : m
                          ? d.has('value')
                            ? o.a.f(d.get('value'))
                            : b.value
                          : void 0;
                    }),
                    h = 'checkbox' == b.type,
                    i = 'radio' == b.type;
                  if (h || i) {
                    var j = c(),
                      k = h && o.a.f(j) instanceof Array,
                      l = !(k && j.push && j.splice),
                      m = i || k,
                      n = k ? g() : a;
                    i &&
                      !b.name &&
                      o.c.uniqueName.init(b, function () {
                        return !0;
                      }),
                      o.o(e, null, { l: b }),
                      o.a.B(b, 'click', e),
                      o.o(f, null, { l: b }),
                      (j = a);
                  }
                }
              }),
                (o.m.wa.checked = !0),
                (o.c.checkedValue = {
                  update: function (a, b) {
                    a.value = o.a.f(b());
                  }
                });
            })(),
            (o.c.class = {
              update: function (a, b) {
                var c = o.a.Db(o.a.f(b()));
                o.a.Eb(a, a.__ko__cssValue, !1),
                  (a.__ko__cssValue = c),
                  o.a.Eb(a, c, !0);
              }
            }),
            (o.c.css = {
              update: function (a, b) {
                var c = o.a.f(b());
                null !== c && 'object' == typeof c
                  ? o.a.P(c, function (b, c) {
                      (c = o.a.f(c)), o.a.Eb(a, b, c);
                    })
                  : o.c.class.update(a, b);
              }
            }),
            (o.c.enable = {
              update: function (a, b) {
                var c = o.a.f(b());
                c && a.disabled
                  ? a.removeAttribute('disabled')
                  : c || a.disabled || (a.disabled = !0);
              }
            }),
            (o.c.disable = {
              update: function (a, b) {
                o.c.enable.update(a, function () {
                  return !o.a.f(b());
                });
              }
            }),
            (o.c.event = {
              init: function (a, b, c, d, e) {
                var f = b() || {};
                o.a.P(f, function (f) {
                  'string' == typeof f &&
                    o.a.B(a, f, function (a) {
                      var g,
                        h = b()[f];
                      if (h) {
                        try {
                          var i = o.a.la(arguments);
                          (d = e.$data), i.unshift(d), (g = h.apply(d, i));
                        } finally {
                          !0 !== g &&
                            (a.preventDefault
                              ? a.preventDefault()
                              : (a.returnValue = !1));
                        }
                        !1 === c.get(f + 'Bubble') &&
                          ((a.cancelBubble = !0),
                          a.stopPropagation && a.stopPropagation());
                      }
                    });
                });
              }
            }),
            (o.c.foreach = {
              Rc: function (a) {
                return function () {
                  var b = a(),
                    c = o.a.bc(b);
                  return c && 'number' != typeof c.length
                    ? (o.a.f(b),
                      {
                        foreach: c.data,
                        as: c.as,
                        noChildContext: c.noChildContext,
                        includeDestroyed: c.includeDestroyed,
                        afterAdd: c.afterAdd,
                        beforeRemove: c.beforeRemove,
                        afterRender: c.afterRender,
                        beforeMove: c.beforeMove,
                        afterMove: c.afterMove,
                        templateEngine: o.ba.Ma
                      })
                    : { foreach: b, templateEngine: o.ba.Ma };
                };
              },
              init: function (a, b) {
                return o.c.template.init(a, o.c.foreach.Rc(b));
              },
              update: function (a, b, c, d, e) {
                return o.c.template.update(a, o.c.foreach.Rc(b), c, d, e);
              }
            }),
            (o.m.Ra.foreach = !1),
            (o.h.ea.foreach = !0),
            (o.c.hasfocus = {
              init: function (a, b, c) {
                function d(d) {
                  a.__ko_hasfocusUpdating = !0;
                  var e = a.ownerDocument;
                  if ('activeElement' in e) {
                    var f;
                    try {
                      f = e.activeElement;
                    } catch (a) {
                      f = e.body;
                    }
                    d = f === a;
                  }
                  (e = b()),
                    o.m.eb(e, c, 'hasfocus', d, !0),
                    (a.__ko_hasfocusLastValue = d),
                    (a.__ko_hasfocusUpdating = !1);
                }
                var e = d.bind(null, !0),
                  f = d.bind(null, !1);
                o.a.B(a, 'focus', e),
                  o.a.B(a, 'focusin', e),
                  o.a.B(a, 'blur', f),
                  o.a.B(a, 'focusout', f),
                  (a.__ko_hasfocusLastValue = !1);
              },
              update: function (a, b) {
                var c = !!o.a.f(b());
                a.__ko_hasfocusUpdating ||
                  a.__ko_hasfocusLastValue === c ||
                  (c ? a.focus() : a.blur(),
                  !c &&
                    a.__ko_hasfocusLastValue &&
                    a.ownerDocument.body.focus(),
                  o.u.G(o.a.Fb, null, [a, c ? 'focusin' : 'focusout']));
              }
            }),
            (o.m.wa.hasfocus = !0),
            (o.c.hasFocus = o.c.hasfocus),
            (o.m.wa.hasFocus = 'hasfocus'),
            (o.c.html = {
              init: function () {
                return { controlsDescendantBindings: !0 };
              },
              update: function (a, b) {
                o.a.fc(a, b());
              }
            }),
            (function () {
              function a(a, b, c) {
                (o.c[a] = {
                  init: function (a, d, e, f, g) {
                    var h,
                      i,
                      j,
                      k,
                      l,
                      m = {};
                    if (b) {
                      f = e.get('as');
                      var n = e.get('noChildContext');
                      (l = !(f && n)),
                        (m = {
                          as: f,
                          noChildContext: n,
                          exportDependencies: l
                        });
                    }
                    return (
                      (k =
                        (j = 'render' == e.get('completeOn')) || e.has(o.i.pa)),
                      o.o(
                        function () {
                          var e,
                            f = o.a.f(d()),
                            n = !c != !f,
                            p = !i;
                          (l || n !== h) &&
                            (k && (g = o.i.Cb(a, g)),
                            n &&
                              ((b && !l) || (m.dataDependency = o.S.o()),
                              (e = b
                                ? g.createChildContext(
                                    'function' == typeof f ? f : d,
                                    m
                                  )
                                : o.S.qa()
                                  ? g.extend(null, m)
                                  : g)),
                            p &&
                              o.S.qa() &&
                              (i = o.a.Ca(o.h.childNodes(a), !0)),
                            n
                              ? (p || o.h.va(a, o.a.Ca(i)), o.Oa(e, a))
                              : (o.h.Ea(a), j || o.i.ma(a, o.i.H)),
                            (h = n));
                        },
                        null,
                        { l: a }
                      ),
                      { controlsDescendantBindings: !0 }
                    );
                  }
                }),
                  (o.m.Ra[a] = !1),
                  (o.h.ea[a] = !0);
              }
              a('if'), a('ifnot', !1, !0), a('with', !0);
            })(),
            (o.c.let = {
              init: function (a, b, c, d, e) {
                return (
                  (b = e.extend(b)),
                  o.Oa(b, a),
                  { controlsDescendantBindings: !0 }
                );
              }
            }),
            (o.h.ea.let = !0);
          var A = {};
          (o.c.options = {
            init: function (a) {
              if ('select' !== o.a.R(a))
                throw Error('options binding applies only to SELECT elements');
              for (; 0 < a.length; ) a.remove(0);
              return { controlsDescendantBindings: !0 };
            },
            update: function (b, c, d) {
              function e() {
                return o.a.jb(b.options, function (a) {
                  return a.selected;
                });
              }
              function f(a, b, c) {
                var d = typeof b;
                return 'function' == d ? b(a) : 'string' == d ? a[b] : c;
              }
              function g(a, c) {
                if (p && k) o.i.ma(b, o.i.H);
                else if (n.length) {
                  var d = 0 <= o.a.A(n, o.w.M(c[0]));
                  o.a.Zc(c[0], d),
                    p && !d && o.u.G(o.a.Fb, null, [b, 'change']);
                }
              }
              var h = b.multiple,
                i = 0 != b.length && h ? b.scrollTop : null,
                j = o.a.f(c()),
                k = d.get('valueAllowUnset') && d.has('value'),
                l = d.get('optionsIncludeDestroyed');
              c = {};
              var m,
                n = [];
              k ||
                (h
                  ? (n = o.a.Mb(e(), o.w.M))
                  : 0 <= b.selectedIndex &&
                    n.push(o.w.M(b.options[b.selectedIndex]))),
                j &&
                  (void 0 === j.length && (j = [j]),
                  (m = o.a.jb(j, function (b) {
                    return l || b === a || null === b || !o.a.f(b._destroy);
                  })),
                  d.has('optionsCaption') &&
                    null !== (j = o.a.f(d.get('optionsCaption'))) &&
                    j !== a &&
                    m.unshift(A));
              var p = !1;
              if (
                ((c.beforeRemove = function (a) {
                  b.removeChild(a);
                }),
                (j = g),
                d.has('optionsAfterRender') &&
                  'function' == typeof d.get('optionsAfterRender') &&
                  (j = function (b, c) {
                    g(0, c),
                      o.u.G(d.get('optionsAfterRender'), null, [
                        c[0],
                        b !== A ? b : a
                      ]);
                  }),
                o.a.ec(
                  b,
                  m,
                  function (c, e, g) {
                    return (
                      g.length &&
                        ((n = !k && g[0].selected ? [o.w.M(g[0])] : []),
                        (p = !0)),
                      (e = b.ownerDocument.createElement('option')),
                      c === A
                        ? (o.a.Bb(e, d.get('optionsCaption')), o.w.cb(e, a))
                        : ((g = f(c, d.get('optionsValue'), c)),
                          o.w.cb(e, o.a.f(g)),
                          (c = f(c, d.get('optionsText'), g)),
                          o.a.Bb(e, c)),
                      [e]
                    );
                  },
                  c,
                  j
                ),
                !k)
              ) {
                var q;
                (q = h
                  ? n.length && e().length < n.length
                  : n.length && 0 <= b.selectedIndex
                    ? o.w.M(b.options[b.selectedIndex]) !== n[0]
                    : n.length || 0 <= b.selectedIndex),
                  q && o.u.G(o.a.Fb, null, [b, 'change']);
              }
              (k || o.S.Ya()) && o.i.ma(b, o.i.H),
                o.a.wd(b),
                i && 20 < Math.abs(i - b.scrollTop) && (b.scrollTop = i);
            }
          }),
            (o.c.options.$b = o.a.g.Z()),
            (o.c.selectedOptions = {
              init: function (a, b, c) {
                function d() {
                  var d = b(),
                    e = [];
                  o.a.D(a.getElementsByTagName('option'), function (a) {
                    a.selected && e.push(o.w.M(a));
                  }),
                    o.m.eb(d, c, 'selectedOptions', e);
                }
                function e() {
                  var c = o.a.f(b()),
                    d = a.scrollTop;
                  c &&
                    'number' == typeof c.length &&
                    o.a.D(a.getElementsByTagName('option'), function (a) {
                      var b = 0 <= o.a.A(c, o.w.M(a));
                      a.selected != b && o.a.Zc(a, b);
                    }),
                    (a.scrollTop = d);
                }
                if ('select' != o.a.R(a))
                  throw Error(
                    'selectedOptions binding applies only to SELECT elements'
                  );
                var f;
                o.i.subscribe(
                  a,
                  o.i.H,
                  function () {
                    f
                      ? d()
                      : (o.a.B(a, 'change', d), (f = o.o(e, null, { l: a })));
                  },
                  null,
                  { notifyImmediately: !0 }
                );
              },
              update: function () {}
            }),
            (o.m.wa.selectedOptions = !0),
            (o.c.style = {
              update: function (b, c) {
                var d = o.a.f(c() || {});
                o.a.P(d, function (c, d) {
                  if (
                    ((d = o.a.f(d)),
                    (null !== d && d !== a && !1 !== d) || (d = ''),
                    e)
                  )
                    e(b).css(c, d);
                  else if (/^--/.test(c)) b.style.setProperty(c, d);
                  else {
                    c = c.replace(/-(\w)/g, function (a, b) {
                      return b.toUpperCase();
                    });
                    var f = b.style[c];
                    (b.style[c] = d),
                      d === f ||
                        b.style[c] != f ||
                        isNaN(d) ||
                        (b.style[c] = d + 'px');
                  }
                });
              }
            }),
            (o.c.submit = {
              init: function (a, b, c, d, e) {
                if ('function' != typeof b())
                  throw Error(
                    'The value for a submit binding must be a function'
                  );
                o.a.B(a, 'submit', function (c) {
                  var d,
                    f = b();
                  try {
                    d = f.call(e.$data, a);
                  } finally {
                    !0 !== d &&
                      (c.preventDefault
                        ? c.preventDefault()
                        : (c.returnValue = !1));
                  }
                });
              }
            }),
            (o.c.text = {
              init: function () {
                return { controlsDescendantBindings: !0 };
              },
              update: function (a, b) {
                o.a.Bb(a, b());
              }
            }),
            (o.h.ea.text = !0),
            (function () {
              if (b && b.navigator) {
                var c,
                  d,
                  e,
                  f,
                  g,
                  h = function (a) {
                    if (a) return parseFloat(a[1]);
                  },
                  i = b.navigator.userAgent;
                (c =
                  b.opera && b.opera.version && parseInt(b.opera.version())) ||
                  (g = h(i.match(/Edge\/([^ ]+)$/))) ||
                  h(i.match(/Chrome\/([^ ]+)/)) ||
                  (d = h(i.match(/Version\/([^ ]+) Safari/))) ||
                  (e = h(i.match(/Firefox\/([^ ]+)/))) ||
                  (f = o.a.W || h(i.match(/MSIE ([^ ]+)/))) ||
                  (f = h(i.match(/rv:([^ )]+)/)));
              }
              if (8 <= f && 10 > f)
                var j = o.a.g.Z(),
                  k = o.a.g.Z(),
                  l = function (a) {
                    var b = this.activeElement;
                    (b = b && o.a.g.get(b, k)) && b(a);
                  },
                  m = function (a, b) {
                    var c = a.ownerDocument;
                    o.a.g.get(c, j) ||
                      (o.a.g.set(c, j, !0), o.a.B(c, 'selectionchange', l)),
                      o.a.g.set(a, k, b);
                  };
              (o.c.textInput = {
                init: function (b, h, i) {
                  function j(a, c) {
                    o.a.B(b, a, c);
                  }
                  function k() {
                    var c = o.a.f(h());
                    (null !== c && c !== a) || (c = ''),
                      q !== a && c === q
                        ? o.a.setTimeout(k, 4)
                        : b.value !== c &&
                          ((t = !0), (b.value = c), (t = !1), (r = b.value));
                  }
                  function l() {
                    p || ((q = b.value), (p = o.a.setTimeout(n, 4)));
                  }
                  function n() {
                    clearTimeout(p), (q = p = a);
                    var c = b.value;
                    r !== c && ((r = c), o.m.eb(h(), i, 'textInput', c));
                  }
                  var p,
                    q,
                    r = b.value,
                    s = 9 == o.a.W ? l : n,
                    t = !1;
                  f && j('keypress', n),
                    11 > f &&
                      j('propertychange', function (a) {
                        t || 'value' !== a.propertyName || s(a);
                      }),
                    8 == f && (j('keyup', n), j('keydown', n)),
                    m && (m(b, s), j('dragend', l)),
                    (!f || 9 <= f) && j('input', s),
                    5 > d && 'textarea' === o.a.R(b)
                      ? (j('keydown', l), j('paste', l), j('cut', l))
                      : 11 > c
                        ? j('keydown', l)
                        : 4 > e
                          ? (j('DOMAutoComplete', n),
                            j('dragdrop', n),
                            j('drop', n))
                          : g && 'number' === b.type && j('keydown', l),
                    j('change', n),
                    j('blur', n),
                    o.o(k, null, { l: b });
                }
              }),
                (o.m.wa.textInput = !0),
                (o.c.textinput = {
                  preprocess: function (a, b, c) {
                    c('textInput', a);
                  }
                });
            })(),
            (o.c.uniqueName = {
              init: function (a, b) {
                if (b()) {
                  var c = 'ko_unique_' + ++o.c.uniqueName.rd;
                  o.a.Yc(a, c);
                }
              }
            }),
            (o.c.uniqueName.rd = 0),
            (o.c.using = {
              init: function (a, b, c, d, e) {
                var f;
                return (
                  c.has('as') &&
                    (f = {
                      as: c.get('as'),
                      noChildContext: c.get('noChildContext')
                    }),
                  (b = e.createChildContext(b, f)),
                  o.Oa(b, a),
                  { controlsDescendantBindings: !0 }
                );
              }
            }),
            (o.h.ea.using = !0),
            (o.c.value = {
              init: function (b, c, d) {
                var e = o.a.R(b),
                  f = 'input' == e;
                if (!f || ('checkbox' != b.type && 'radio' != b.type)) {
                  var g = [],
                    h = d.get('valueUpdate'),
                    i = !1,
                    j = null;
                  h &&
                    ((g = 'string' == typeof h ? [h] : o.a.wc(h)),
                    o.a.Pa(g, 'change'));
                  var k = function () {
                    (j = null), (i = !1);
                    var a = c(),
                      e = o.w.M(b);
                    o.m.eb(a, d, 'value', e);
                  };
                  !o.a.W ||
                    !f ||
                    'text' != b.type ||
                    'off' == b.autocomplete ||
                    (b.form && 'off' == b.form.autocomplete) ||
                    -1 != o.a.A(g, 'propertychange') ||
                    (o.a.B(b, 'propertychange', function () {
                      i = !0;
                    }),
                    o.a.B(b, 'focus', function () {
                      i = !1;
                    }),
                    o.a.B(b, 'blur', function () {
                      i && k();
                    })),
                    o.a.D(g, function (a) {
                      var c = k;
                      o.a.Ud(a, 'after') &&
                        ((c = function () {
                          (j = o.w.M(b)), o.a.setTimeout(k, 0);
                        }),
                        (a = a.substring(5))),
                        o.a.B(b, a, c);
                    });
                  var l;
                  if (
                    ((l =
                      f && 'file' == b.type
                        ? function () {
                            var d = o.a.f(c());
                            null === d || d === a || '' === d
                              ? (b.value = '')
                              : o.u.G(k);
                          }
                        : function () {
                            var f = o.a.f(c()),
                              g = o.w.M(b);
                            null !== j && f === j
                              ? o.a.setTimeout(l, 0)
                              : (f === g && g !== a) ||
                                ('select' === e
                                  ? ((g = d.get('valueAllowUnset')),
                                    o.w.cb(b, f, g),
                                    g || f === o.w.M(b) || o.u.G(k))
                                  : o.w.cb(b, f));
                          }),
                    'select' === e)
                  ) {
                    var m;
                    o.i.subscribe(
                      b,
                      o.i.H,
                      function () {
                        m
                          ? d.get('valueAllowUnset')
                            ? l()
                            : k()
                          : (o.a.B(b, 'change', k),
                            (m = o.o(l, null, { l: b })));
                      },
                      null,
                      { notifyImmediately: !0 }
                    );
                  } else o.a.B(b, 'change', k), o.o(l, null, { l: b });
                } else o.ib(b, { checkedValue: c });
              },
              update: function () {}
            }),
            (o.m.wa.value = !0),
            (o.c.visible = {
              update: function (a, b) {
                var c = o.a.f(b()),
                  d = 'none' != a.style.display;
                c && !d
                  ? (a.style.display = '')
                  : !c && d && (a.style.display = 'none');
              }
            }),
            (o.c.hidden = {
              update: function (a, b) {
                o.c.visible.update(a, function () {
                  return !o.a.f(b());
                });
              }
            }),
            (function (a) {
              o.c[a] = {
                init: function (b, c, d, e, f) {
                  return o.c.event.init.call(
                    this,
                    b,
                    function () {
                      var b = {};
                      return (b[a] = c()), b;
                    },
                    d,
                    e,
                    f
                  );
                }
              };
            })('click'),
            (o.ca = function () {}),
            (o.ca.prototype.renderTemplateSource = function () {
              throw Error('Override renderTemplateSource');
            }),
            (o.ca.prototype.createJavaScriptEvaluatorBlock = function () {
              throw Error('Override createJavaScriptEvaluatorBlock');
            }),
            (o.ca.prototype.makeTemplateSource = function (a, b) {
              if ('string' == typeof a) {
                b = b || c;
                var d = b.getElementById(a);
                if (!d) throw Error('Cannot find template with ID ' + a);
                return new o.C.F(d);
              }
              if (1 == a.nodeType || 8 == a.nodeType) return new o.C.ia(a);
              throw Error('Unknown template type: ' + a);
            }),
            (o.ca.prototype.renderTemplate = function (a, b, c, d) {
              return (
                (a = this.makeTemplateSource(a, d)),
                this.renderTemplateSource(a, b, c, d)
              );
            }),
            (o.ca.prototype.isTemplateRewritten = function (a, b) {
              return (
                !1 === this.allowTemplateRewriting ||
                this.makeTemplateSource(a, b).data('isRewritten')
              );
            }),
            (o.ca.prototype.rewriteTemplate = function (a, b, c) {
              (a = this.makeTemplateSource(a, c)),
                (b = b(a.text())),
                a.text(b),
                a.data('isRewritten', !0);
            }),
            o.b('templateEngine', o.ca),
            (o.kc = (function () {
              function a(a, b, c, d) {
                a = o.m.ac(a);
                for (var e = o.m.Ra, f = 0; f < a.length; f++) {
                  var g = a[f].key;
                  if (Object.prototype.hasOwnProperty.call(e, g)) {
                    var h = e[g];
                    if ('function' == typeof h) {
                      if ((g = h(a[f].value))) throw Error(g);
                    } else if (!h)
                      throw Error(
                        "This template engine does not support the '" +
                          g +
                          "' binding within its templates"
                      );
                  }
                }
                return (
                  (c =
                    'ko.__tr_ambtns(function($context,$element){return(function(){return{ ' +
                    o.m.vb(a, { valueAccessors: !0 }) +
                    " } })()},'" +
                    c.toLowerCase() +
                    "')"),
                  d.createJavaScriptEvaluatorBlock(c) + b
                );
              }
              var b =
                  /(<([a-z]+\d*)(?:\s+(?!data-bind\s*=\s*)[a-z0-9\-]+(?:=(?:\"[^\"]*\"|\'[^\']*\'|[^>]*))?)*\s+)data-bind\s*=\s*(["'])([\s\S]*?)\3/gi,
                c = /\x3c!--\s*ko\b\s*([\s\S]*?)\s*--\x3e/g;
              return {
                xd: function (a, b, c) {
                  b.isTemplateRewritten(a, c) ||
                    b.rewriteTemplate(
                      a,
                      function (a) {
                        return o.kc.Ld(a, b);
                      },
                      c
                    );
                },
                Ld: function (d, e) {
                  return d
                    .replace(b, function (b, c, d, f, g) {
                      return a(g, c, d, e);
                    })
                    .replace(c, function (b, c) {
                      return a(c, '\x3c!-- ko --\x3e', '#comment', e);
                    });
                },
                md: function (a, b) {
                  return o.aa.Xb(function (c, d) {
                    var e = c.nextSibling;
                    e && e.nodeName.toLowerCase() === b && o.ib(e, a, d);
                  });
                }
              };
            })()),
            o.b('__tr_ambtns', o.kc.md),
            (function () {
              (o.C = {}),
                (o.C.F = function (a) {
                  if ((this.F = a)) {
                    var b = o.a.R(a);
                    this.ab =
                      'script' === b
                        ? 1
                        : 'textarea' === b
                          ? 2
                          : 'template' == b &&
                              a.content &&
                              11 === a.content.nodeType
                            ? 3
                            : 4;
                  }
                }),
                (o.C.F.prototype.text = function () {
                  var a =
                    1 === this.ab
                      ? 'text'
                      : 2 === this.ab
                        ? 'value'
                        : 'innerHTML';
                  if (0 == arguments.length) return this.F[a];
                  var b = arguments[0];
                  'innerHTML' === a ? o.a.fc(this.F, b) : (this.F[a] = b);
                });
              var b = o.a.g.Z() + '_';
              o.C.F.prototype.data = function (a) {
                if (1 === arguments.length) return o.a.g.get(this.F, b + a);
                o.a.g.set(this.F, b + a, arguments[1]);
              };
              var c = o.a.g.Z();
              (o.C.F.prototype.nodes = function () {
                var b = this.F;
                if (0 == arguments.length) {
                  var d = o.a.g.get(b, c) || {},
                    e =
                      d.lb ||
                      (3 === this.ab ? b.content : 4 === this.ab ? b : a);
                  if (!e || d.jd) {
                    var f = this.text();
                    f &&
                      f !== d.bb &&
                      ((e = o.a.Md(f, b.ownerDocument)),
                      o.a.g.set(b, c, { lb: e, bb: f, jd: !0 }));
                  }
                  return e;
                }
                (d = arguments[0]),
                  this.ab !== a && this.text(''),
                  o.a.g.set(b, c, { lb: d });
              }),
                (o.C.ia = function (a) {
                  this.F = a;
                }),
                (o.C.ia.prototype = new o.C.F()),
                (o.C.ia.prototype.constructor = o.C.ia),
                (o.C.ia.prototype.text = function () {
                  if (0 == arguments.length) {
                    var b = o.a.g.get(this.F, c) || {};
                    return b.bb === a && b.lb && (b.bb = b.lb.innerHTML), b.bb;
                  }
                  o.a.g.set(this.F, c, { bb: arguments[0] });
                }),
                o.b('templateSources', o.C),
                o.b('templateSources.domElement', o.C.F),
                o.b('templateSources.anonymousTemplate', o.C.ia);
            })(),
            (function () {
              function b(a, b, c) {
                var d;
                for (b = o.h.nextSibling(b); a && (d = a) !== b; )
                  (a = o.h.nextSibling(d)), c(d, a);
              }
              function c(a, c) {
                if (a.length) {
                  var d = a[0],
                    e = a[a.length - 1],
                    f = d.parentNode,
                    g = o.ga.instance,
                    h = g.preprocessNode;
                  if (h) {
                    if (
                      (b(d, e, function (a, b) {
                        var c = a.previousSibling,
                          f = h.call(g, a);
                        f &&
                          (a === d && (d = f[0] || b),
                          a === e && (e = f[f.length - 1] || c));
                      }),
                      (a.length = 0),
                      !d)
                    )
                      return;
                    d === e ? a.push(d) : (a.push(d, e), o.a.Ua(a, f));
                  }
                  b(d, e, function (a) {
                    (1 !== a.nodeType && 8 !== a.nodeType) || o.vc(c, a);
                  }),
                    b(d, e, function (a) {
                      (1 !== a.nodeType && 8 !== a.nodeType) || o.aa.cd(a, [c]);
                    }),
                    o.a.Ua(a, f);
                }
              }
              function d(a) {
                return a.nodeType ? a : 0 < a.length ? a[0] : null;
              }
              function e(a, b, e, f, h) {
                h = h || {};
                var i = ((a && d(a)) || e || {}).ownerDocument,
                  j = h.templateEngine || g;
                if (
                  (o.kc.xd(e, j, i),
                  (e = j.renderTemplate(e, f, h, i)),
                  'number' != typeof e.length ||
                    (0 < e.length && 'number' != typeof e[0].nodeType))
                )
                  throw Error(
                    'Template engine must return an array of DOM nodes'
                  );
                switch (((i = !1), b)) {
                  case 'replaceChildren':
                    o.h.va(a, e), (i = !0);
                    break;
                  case 'replaceNode':
                    o.a.Xc(a, e), (i = !0);
                    break;
                  case 'ignoreTargetNode':
                    break;
                  default:
                    throw Error('Unknown renderMode: ' + b);
                }
                return (
                  i &&
                    (c(e, f),
                    h.afterRender &&
                      o.u.G(h.afterRender, null, [e, f[h.as || '$data']]),
                    'replaceChildren' == b && o.i.ma(a, o.i.H)),
                  e
                );
              }
              function f(a, b, c) {
                return o.O(a) ? a() : 'function' == typeof a ? a(b, c) : a;
              }
              var g;
              (o.gc = function (b) {
                if (b != a && !(b instanceof o.ca))
                  throw Error(
                    'templateEngine must inherit from ko.templateEngine'
                  );
                g = b;
              }),
                (o.dc = function (b, c, h, i, j) {
                  if (((h = h || {}), (h.templateEngine || g) == a))
                    throw Error(
                      'Set a template engine before calling renderTemplate'
                    );
                  if (((j = j || 'replaceChildren'), i)) {
                    var k = d(i);
                    return o.$(
                      function () {
                        var a =
                            c && c instanceof o.fa
                              ? c
                              : new o.fa(c, null, null, null, {
                                  exportDependencies: !0
                                }),
                          g = f(b, a.$data, a),
                          a = e(i, j, g, a, h);
                        'replaceNode' == j && ((i = a), (k = d(i)));
                      },
                      null,
                      {
                        Sa: function () {
                          return !k || !o.a.Sb(k);
                        },
                        l: k && 'replaceNode' == j ? k.parentNode : k
                      }
                    );
                  }
                  return o.aa.Xb(function (a) {
                    o.dc(b, c, h, a, 'replaceNode');
                  });
                }),
                (o.Qd = function (b, d, g, h, i) {
                  function j(a, b) {
                    o.u.G(o.a.ec, null, [h, a, l, g, k, b]), o.i.ma(h, o.i.H);
                  }
                  function k(a, b) {
                    c(b, m), g.afterRender && g.afterRender(b, a), (m = null);
                  }
                  function l(a, c) {
                    m = i.createChildContext(a, {
                      as: n,
                      noChildContext: g.noChildContext,
                      extend: function (a) {
                        (a.$index = c), n && (a[n + 'Index'] = c);
                      }
                    });
                    var d = f(b, a, m);
                    return e(h, 'ignoreTargetNode', d, m, g);
                  }
                  var m,
                    n = g.as,
                    p =
                      !1 === g.includeDestroyed ||
                      (o.options.foreachHidesDestroyed && !g.includeDestroyed);
                  if (p || g.beforeRemove || !o.Pc(d))
                    return o.$(
                      function () {
                        var b = o.a.f(d) || [];
                        void 0 === b.length && (b = [b]),
                          p &&
                            (b = o.a.jb(b, function (b) {
                              return (
                                b === a || null === b || !o.a.f(b._destroy)
                              );
                            })),
                          j(b);
                      },
                      null,
                      { l: h }
                    );
                  j(d.v());
                  var q = d.subscribe(
                    function (a) {
                      j(d(), a);
                    },
                    null,
                    'arrayChange'
                  );
                  return q.l(h), q;
                });
              var h = o.a.g.Z(),
                i = o.a.g.Z();
              (o.c.template = {
                init: function (a, b) {
                  var c = o.a.f(b());
                  if ('string' == typeof c || 'name' in c) o.h.Ea(a);
                  else if ('nodes' in c) {
                    if (((c = c.nodes || []), o.O(c)))
                      throw Error(
                        'The "nodes" option must be a plain, non-observable array.'
                      );
                    var d = c[0] && c[0].parentNode;
                    (d && o.a.g.get(d, i)) ||
                      ((d = o.a.Yb(c)), o.a.g.set(d, i, !0)),
                      new o.C.ia(a).nodes(d);
                  } else {
                    if (((c = o.h.childNodes(a)), !(0 < c.length)))
                      throw Error(
                        'Anonymous template defined, but no template content was provided'
                      );
                    (d = o.a.Yb(c)), new o.C.ia(a).nodes(d);
                  }
                  return { controlsDescendantBindings: !0 };
                },
                update: function (b, c, d, e, f) {
                  var g = c();
                  (c = o.a.f(g)),
                    (d = !0),
                    (e = null),
                    'string' == typeof c
                      ? (c = {})
                      : ((g = 'name' in c ? c.name : b),
                        'if' in c && (d = o.a.f(c.if)),
                        d && 'ifnot' in c && (d = !o.a.f(c.ifnot)),
                        d && !g && (d = !1)),
                    'foreach' in c
                      ? (e = o.Qd(g, (d && c.foreach) || [], c, b, f))
                      : d
                        ? ((d = f),
                          'data' in c &&
                            (d = f.createChildContext(c.data, {
                              as: c.as,
                              noChildContext: c.noChildContext,
                              exportDependencies: !0
                            })),
                          (e = o.dc(g, d, c, b)))
                        : o.h.Ea(b),
                    (f = e),
                    (c = o.a.g.get(b, h)) && 'function' == typeof c.s && c.s(),
                    o.a.g.set(b, h, !f || (f.ja && !f.ja()) ? a : f);
                }
              }),
                (o.m.Ra.template = function (a) {
                  return (
                    (a = o.m.ac(a)),
                    (1 == a.length && a[0].unknown) || o.m.Id(a, 'name')
                      ? null
                      : 'This template engine does not support anonymous templates nested within its templates'
                  );
                }),
                (o.h.ea.template = !0);
            })(),
            o.b('setTemplateEngine', o.gc),
            o.b('renderTemplate', o.dc),
            (o.a.Kc = function (a, b, c) {
              if (a.length && b.length) {
                var d, e, f, g, h;
                for (d = e = 0; (!c || d < c) && (g = a[e]); ++e) {
                  for (f = 0; (h = b[f]); ++f)
                    if (g.value === h.value) {
                      (g.moved = h.index),
                        (h.moved = g.index),
                        b.splice(f, 1),
                        (d = f = 0);
                      break;
                    }
                  d += f;
                }
              }
            }),
            (o.a.Pb = (function () {
              function a(a, b, c, d, e) {
                var f,
                  g,
                  h,
                  i,
                  j,
                  k = Math.min,
                  l = Math.max,
                  m = [],
                  n = a.length,
                  p = b.length,
                  q = p - n || 1,
                  r = n + p + 1;
                for (f = 0; f <= n; f++)
                  for (
                    i = h, m.push((h = [])), j = k(p, f + q), g = l(0, f - 1);
                    g <= j;
                    g++
                  )
                    h[g] = g
                      ? f
                        ? a[f - 1] === b[g - 1]
                          ? i[g - 1]
                          : k(i[g] || r, h[g - 1] || r) + 1
                        : g + 1
                      : f + 1;
                for (k = [], l = [], q = [], f = n, g = p; f || g; )
                  (p = m[f][g] - 1),
                    g && p === m[f][g - 1]
                      ? l.push(
                          (k[k.length] = { status: c, value: b[--g], index: g })
                        )
                      : f && p === m[f - 1][g]
                        ? q.push(
                            (k[k.length] = {
                              status: d,
                              value: a[--f],
                              index: f
                            })
                          )
                        : (--g,
                          --f,
                          e.sparse ||
                            k.push({ status: 'retained', value: b[g] }));
                return o.a.Kc(q, l, !e.dontLimitMoves && 10 * n), k.reverse();
              }
              return function (b, c, d) {
                return (
                  (d = 'boolean' == typeof d ? { dontLimitMoves: d } : d || {}),
                  (b = b || []),
                  (c = c || []),
                  b.length < c.length
                    ? a(b, c, 'added', 'deleted', d)
                    : a(c, b, 'deleted', 'added', d)
                );
              };
            })()),
            o.b('utils.compareArrays', o.a.Pb),
            (function () {
              function b(b, c, d, e, f) {
                var g = [],
                  h = o.$(
                    function () {
                      var a = c(d, f, o.a.Ua(g, b)) || [];
                      0 < g.length &&
                        (o.a.Xc(g, a), e && o.u.G(e, null, [d, a, f])),
                        (g.length = 0),
                        o.a.Nb(g, a);
                    },
                    null,
                    {
                      l: b,
                      Sa: function () {
                        return !o.a.kd(g);
                      }
                    }
                  );
                return { Y: g, $: h.ja() ? h : a };
              }
              var c = o.a.g.Z(),
                d = o.a.g.Z();
              o.a.ec = function (e, f, g, h, i, j) {
                function k(a) {
                  (n = { Aa: a, pb: o.ta(t++) }), r.push(n), q || y.push(n);
                }
                function l(a) {
                  (n = p[a]),
                    t !== n.pb.v() && x.push(n),
                    n.pb(t++),
                    o.a.Ua(n.Y, e),
                    r.push(n);
                }
                function m(a, b) {
                  if (a)
                    for (var c = 0, d = b.length; c < d; c++)
                      o.a.D(b[c].Y, function (d) {
                        a(d, c, b[c].Aa);
                      });
                }
                (f = f || []), void 0 === f.length && (f = [f]), (h = h || {});
                var n,
                  p = o.a.g.get(e, c),
                  q = !p,
                  r = [],
                  s = 0,
                  t = 0,
                  u = [],
                  v = [],
                  w = [],
                  x = [],
                  y = [],
                  z = 0;
                if (q) o.a.D(f, k);
                else {
                  if (!j || (p && p._countWaitingForRemove)) {
                    var A = o.a.Mb(p, function (a) {
                      return a.Aa;
                    });
                    j = o.a.Pb(A, f, {
                      dontLimitMoves: h.dontLimitMoves,
                      sparse: !0
                    });
                  }
                  for (var B, C, D, A = 0; (B = j[A]); A++)
                    switch (((C = B.moved), (D = B.index), B.status)) {
                      case 'deleted':
                        for (; s < D; ) l(s++);
                        C === a &&
                          ((n = p[s]),
                          n.$ && (n.$.s(), (n.$ = a)),
                          o.a.Ua(n.Y, e).length &&
                            (h.beforeRemove &&
                              (r.push(n),
                              z++,
                              n.Aa === d ? (n = null) : w.push(n)),
                            n && u.push.apply(u, n.Y))),
                          s++;
                        break;
                      case 'added':
                        for (; t < D; ) l(s++);
                        C !== a ? (v.push(r.length), l(C)) : k(B.value);
                    }
                  for (; t < f.length; ) l(s++);
                  r._countWaitingForRemove = z;
                }
                o.a.g.set(e, c, r),
                  m(h.beforeMove, x),
                  o.a.D(u, h.beforeRemove ? o.oa : o.removeNode);
                var E, F, G;
                try {
                  G = e.ownerDocument.activeElement;
                } catch (a) {}
                if (v.length)
                  for (; (A = v.shift()) != a; ) {
                    for (n = r[A], E = a; A; )
                      if ((F = r[--A].Y) && F.length) {
                        E = F[F.length - 1];
                        break;
                      }
                    for (f = 0; (s = n.Y[f]); E = s, f++) o.h.Wb(e, s, E);
                  }
                for (A = 0; (n = r[A]); A++) {
                  for (
                    n.Y || o.a.extend(n, b(e, g, n.Aa, i, n.pb)), f = 0;
                    (s = n.Y[f]);
                    E = s, f++
                  )
                    o.h.Wb(e, s, E);
                  !n.Ed &&
                    i &&
                    (i(n.Aa, n.Y, n.pb),
                    (n.Ed = !0),
                    (E = n.Y[n.Y.length - 1]));
                }
                for (
                  G && e.ownerDocument.activeElement != G && G.focus(),
                    m(h.beforeRemove, w),
                    A = 0;
                  A < w.length;
                  ++A
                )
                  w[A].Aa = d;
                m(h.afterMove, x), m(h.afterAdd, y);
              };
            })(),
            o.b('utils.setDomNodeChildrenFromArrayMapping', o.a.ec),
            (o.ba = function () {
              this.allowTemplateRewriting = !1;
            }),
            (o.ba.prototype = new o.ca()),
            (o.ba.prototype.constructor = o.ba),
            (o.ba.prototype.renderTemplateSource = function (a, b, c, d) {
              return (b = (9 > o.a.W ? 0 : a.nodes) ? a.nodes() : null)
                ? o.a.la(b.cloneNode(!0).childNodes)
                : ((a = a.text()), o.a.ua(a, d));
            }),
            (o.ba.Ma = new o.ba()),
            o.gc(o.ba.Ma),
            o.b('nativeTemplateEngine', o.ba),
            (function () {
              (o.$a = function () {
                var a = (this.Hd = (function () {
                  if (!e || !e.tmpl) return 0;
                  try {
                    if (0 <= e.tmpl.tag.tmpl.open.toString().indexOf('__'))
                      return 2;
                  } catch (a) {}
                  return 1;
                })());
                (this.renderTemplateSource = function (b, d, f, g) {
                  if (((g = g || c), (f = f || {}), 2 > a))
                    throw Error(
                      'Your version of jQuery.tmpl is too old. Please upgrade to jQuery.tmpl 1.0.0pre or later.'
                    );
                  var h = b.data('precompiled');
                  return (
                    h ||
                      ((h = b.text() || ''),
                      (h = e.template(
                        null,
                        '{{ko_with $item.koBindingContext}}' +
                          h +
                          '{{/ko_with}}'
                      )),
                      b.data('precompiled', h)),
                    (b = [d.$data]),
                    (d = e.extend({ koBindingContext: d }, f.templateOptions)),
                    (d = e.tmpl(h, b, d)),
                    d.appendTo(g.createElement('div')),
                    (e.fragments = {}),
                    d
                  );
                }),
                  (this.createJavaScriptEvaluatorBlock = function (a) {
                    return '{{ko_code ((function() { return ' + a + ' })()) }}';
                  }),
                  (this.addTemplate = function (a, b) {
                    c.write(
                      "<script type='text/html' id='" +
                        a +
                        "'>" +
                        b +
                        '</script>'
                    );
                  }),
                  0 < a &&
                    ((e.tmpl.tag.ko_code = { open: "__.push($1 || '');" }),
                    (e.tmpl.tag.ko_with = { open: 'with($1) {', close: '} ' }));
              }),
                (o.$a.prototype = new o.ca()),
                (o.$a.prototype.constructor = o.$a);
              var a = new o.$a();
              0 < a.Hd && o.gc(a), o.b('jqueryTmplTemplateEngine', o.$a);
            })();
        });
    })();
  })(),
  (function (a, b) {
    'object' == typeof exports && 'undefined' != typeof module
      ? (module.exports = b())
      : 'function' == typeof define && define.amd
        ? define(b)
        : (a.moment = b());
  })(this, function () {
    'use strict';
    function a() {
      return _a.apply(null, arguments);
    }
    function b(a) {
      return (
        a instanceof Array ||
        '[object Array]' === Object.prototype.toString.call(a)
      );
    }
    function c(a) {
      return (
        null != a && '[object Object]' === Object.prototype.toString.call(a)
      );
    }
    function d(a) {
      return void 0 === a;
    }
    function e(a) {
      return (
        'number' == typeof a ||
        '[object Number]' === Object.prototype.toString.call(a)
      );
    }
    function f(a) {
      return (
        a instanceof Date ||
        '[object Date]' === Object.prototype.toString.call(a)
      );
    }
    function g(a, b) {
      var c,
        d = [];
      for (c = 0; c < a.length; ++c) d.push(b(a[c], c));
      return d;
    }
    function h(a, b) {
      return Object.prototype.hasOwnProperty.call(a, b);
    }
    function i(a, b) {
      for (var c in b) h(b, c) && (a[c] = b[c]);
      return (
        h(b, 'toString') && (a.toString = b.toString),
        h(b, 'valueOf') && (a.valueOf = b.valueOf),
        a
      );
    }
    function j(a, b, c, d) {
      return qa(a, b, c, d, !0).utc();
    }
    function k(a) {
      return (
        null == a._pf &&
          (a._pf = {
            empty: !1,
            unusedTokens: [],
            unusedInput: [],
            overflow: -2,
            charsLeftOver: 0,
            nullInput: !1,
            invalidMonth: null,
            invalidFormat: !1,
            userInvalidated: !1,
            iso: !1,
            parsedDateParts: [],
            meridiem: null,
            rfc2822: !1,
            weekdayMismatch: !1
          }),
        a._pf
      );
    }
    function l(a) {
      if (null == a._isValid) {
        var b = k(a),
          c = ab.call(b.parsedDateParts, function (a) {
            return null != a;
          }),
          d =
            !isNaN(a._d.getTime()) &&
            b.overflow < 0 &&
            !b.empty &&
            !b.invalidMonth &&
            !b.invalidWeekday &&
            !b.weekdayMismatch &&
            !b.nullInput &&
            !b.invalidFormat &&
            !b.userInvalidated &&
            (!b.meridiem || (b.meridiem && c));
        if (
          (a._strict &&
            (d =
              d &&
              0 === b.charsLeftOver &&
              0 === b.unusedTokens.length &&
              void 0 === b.bigHour),
          null != Object.isFrozen && Object.isFrozen(a))
        )
          return d;
        a._isValid = d;
      }
      return a._isValid;
    }
    function m(a) {
      var b = j(NaN);
      return null != a ? i(k(b), a) : (k(b).userInvalidated = !0), b;
    }
    function n(a, b) {
      var c, e, f;
      if (
        (d(b._isAMomentObject) || (a._isAMomentObject = b._isAMomentObject),
        d(b._i) || (a._i = b._i),
        d(b._f) || (a._f = b._f),
        d(b._l) || (a._l = b._l),
        d(b._strict) || (a._strict = b._strict),
        d(b._tzm) || (a._tzm = b._tzm),
        d(b._isUTC) || (a._isUTC = b._isUTC),
        d(b._offset) || (a._offset = b._offset),
        d(b._pf) || (a._pf = k(b)),
        d(b._locale) || (a._locale = b._locale),
        0 < cb.length)
      )
        for (c = 0; c < cb.length; c++) d((f = b[(e = cb[c])])) || (a[e] = f);
      return a;
    }
    function o(b) {
      n(this, b),
        (this._d = new Date(null != b._d ? b._d.getTime() : NaN)),
        this.isValid() || (this._d = new Date(NaN)),
        !1 === db && ((db = !0), a.updateOffset(this), (db = !1));
    }
    function p(a) {
      return a instanceof o || (null != a && null != a._isAMomentObject);
    }
    function q(a) {
      return a < 0 ? Math.ceil(a) || 0 : Math.floor(a);
    }
    function r(a) {
      var b = +a,
        c = 0;
      return 0 !== b && isFinite(b) && (c = q(b)), c;
    }
    function s(a, b, c) {
      var d,
        e = Math.min(a.length, b.length),
        f = Math.abs(a.length - b.length),
        g = 0;
      for (d = 0; d < e; d++)
        ((c && a[d] !== b[d]) || (!c && r(a[d]) !== r(b[d]))) && g++;
      return g + f;
    }
    function t(b) {
      !1 === a.suppressDeprecationWarnings &&
        'undefined' != typeof console &&
        console.warn &&
        console.warn('Deprecation warning: ' + b);
    }
    function u(b, c) {
      var d = !0;
      return i(function () {
        if (
          (null != a.deprecationHandler && a.deprecationHandler(null, b), d)
        ) {
          for (var e, f = [], g = 0; g < arguments.length; g++) {
            if (((e = ''), 'object' == typeof arguments[g])) {
              for (var h in ((e += '\n[' + g + '] '), arguments[0]))
                e += h + ': ' + arguments[0][h] + ', ';
              e = e.slice(0, -2);
            } else e = arguments[g];
            f.push(e);
          }
          t(
            b +
              '\nArguments: ' +
              Array.prototype.slice.call(f).join('') +
              '\n' +
              new Error().stack
          ),
            (d = !1);
        }
        return c.apply(this, arguments);
      }, c);
    }
    function v(b, c) {
      null != a.deprecationHandler && a.deprecationHandler(b, c),
        eb[b] || (t(c), (eb[b] = !0));
    }
    function w(a) {
      return (
        a instanceof Function ||
        '[object Function]' === Object.prototype.toString.call(a)
      );
    }
    function x(a, b) {
      var d,
        e = i({}, a);
      for (d in b)
        h(b, d) &&
          (c(a[d]) && c(b[d])
            ? ((e[d] = {}), i(e[d], a[d]), i(e[d], b[d]))
            : null != b[d]
              ? (e[d] = b[d])
              : delete e[d]);
      for (d in a) h(a, d) && !h(b, d) && c(a[d]) && (e[d] = i({}, e[d]));
      return e;
    }
    function y(a) {
      null != a && this.set(a);
    }
    function z(a, b) {
      var c = a.toLowerCase();
      fb[c] = fb[c + 's'] = fb[b] = a;
    }
    function A(a) {
      return 'string' == typeof a ? fb[a] || fb[a.toLowerCase()] : void 0;
    }
    function B(a) {
      var b,
        c,
        d = {};
      for (c in a) h(a, c) && (b = A(c)) && (d[b] = a[c]);
      return d;
    }
    function C(a, b) {
      gb[a] = b;
    }
    function D(a, b, c) {
      var d = '' + Math.abs(a),
        e = b - d.length;
      return (
        (0 <= a ? (c ? '+' : '') : '-') +
        Math.pow(10, Math.max(0, e)).toString().substr(1) +
        d
      );
    }
    function E(a, b, c, d) {
      var e = d;
      'string' == typeof d &&
        (e = function () {
          return this[d]();
        }),
        a && (kb[a] = e),
        b &&
          (kb[b[0]] = function () {
            return D(e.apply(this, arguments), b[1], b[2]);
          }),
        c &&
          (kb[c] = function () {
            return this.localeData().ordinal(e.apply(this, arguments), a);
          });
    }
    function F(a, b) {
      return a.isValid()
        ? ((b = G(b, a.localeData())),
          (jb[b] =
            jb[b] ||
            (function (a) {
              var b,
                c,
                d,
                e = a.match(hb);
              for (b = 0, c = e.length; b < c; b++)
                kb[e[b]]
                  ? (e[b] = kb[e[b]])
                  : (e[b] = (d = e[b]).match(/\[[\s\S]/)
                      ? d.replace(/^\[|\]$/g, '')
                      : d.replace(/\\/g, ''));
              return function (b) {
                var d,
                  f = '';
                for (d = 0; d < c; d++) f += w(e[d]) ? e[d].call(b, a) : e[d];
                return f;
              };
            })(b)),
          jb[b](a))
        : a.localeData().invalidDate();
    }
    function G(a, b) {
      function c(a) {
        return b.longDateFormat(a) || a;
      }
      var d = 5;
      for (ib.lastIndex = 0; 0 <= d && ib.test(a); )
        (a = a.replace(ib, c)), (ib.lastIndex = 0), (d -= 1);
      return a;
    }
    function H(a, b, c) {
      Bb[a] = w(b)
        ? b
        : function (a, d) {
            return a && c ? c : b;
          };
    }
    function I(a, b) {
      return h(Bb, a)
        ? Bb[a](b._strict, b._locale)
        : new RegExp(
            J(
              a
                .replace('\\', '')
                .replace(
                  /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
                  function (a, b, c, d, e) {
                    return b || c || d || e;
                  }
                )
            )
          );
    }
    function J(a) {
      return a.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    function K(a, b) {
      var c,
        d = b;
      for (
        'string' == typeof a && (a = [a]),
          e(b) &&
            (d = function (a, c) {
              c[b] = r(a);
            }),
          c = 0;
        c < a.length;
        c++
      )
        Cb[a[c]] = d;
    }
    function L(a, b) {
      K(a, function (a, c, d, e) {
        (d._w = d._w || {}), b(a, d._w, d, e);
      });
    }
    function M(a) {
      return N(a) ? 366 : 365;
    }
    function N(a) {
      return (a % 4 == 0 && a % 100 != 0) || a % 400 == 0;
    }
    function O(b, c) {
      return function (d) {
        return null != d
          ? (Q(this, b, d), a.updateOffset(this, c), this)
          : P(this, b);
      };
    }
    function P(a, b) {
      return a.isValid() ? a._d['get' + (a._isUTC ? 'UTC' : '') + b]() : NaN;
    }
    function Q(a, b, c) {
      a.isValid() &&
        !isNaN(c) &&
        ('FullYear' === b && N(a.year()) && 1 === a.month() && 29 === a.date()
          ? a._d['set' + (a._isUTC ? 'UTC' : '') + b](
              c,
              a.month(),
              R(c, a.month())
            )
          : a._d['set' + (a._isUTC ? 'UTC' : '') + b](c));
    }
    function R(a, b) {
      if (isNaN(a) || isNaN(b)) return NaN;
      var c,
        d = ((b % (c = 12)) + c) % c;
      return (
        (a += (b - d) / 12), 1 === d ? (N(a) ? 29 : 28) : 31 - ((d % 7) % 2)
      );
    }
    function S(a, b) {
      var c;
      if (!a.isValid()) return a;
      if ('string' == typeof b)
        if (/^\d+$/.test(b)) b = r(b);
        else if (!e((b = a.localeData().monthsParse(b)))) return a;
      return (
        (c = Math.min(a.date(), R(a.year(), b))),
        a._d['set' + (a._isUTC ? 'UTC' : '') + 'Month'](b, c),
        a
      );
    }
    function T(b) {
      return null != b
        ? (S(this, b), a.updateOffset(this, !0), this)
        : P(this, 'Month');
    }
    function U() {
      function a(a, b) {
        return b.length - a.length;
      }
      var b,
        c,
        d = [],
        e = [],
        f = [];
      for (b = 0; b < 12; b++)
        (c = j([2e3, b])),
          d.push(this.monthsShort(c, '')),
          e.push(this.months(c, '')),
          f.push(this.months(c, '')),
          f.push(this.monthsShort(c, ''));
      for (d.sort(a), e.sort(a), f.sort(a), b = 0; b < 12; b++)
        (d[b] = J(d[b])), (e[b] = J(e[b]));
      for (b = 0; b < 24; b++) f[b] = J(f[b]);
      (this._monthsRegex = new RegExp('^(' + f.join('|') + ')', 'i')),
        (this._monthsShortRegex = this._monthsRegex),
        (this._monthsStrictRegex = new RegExp('^(' + e.join('|') + ')', 'i')),
        (this._monthsShortStrictRegex = new RegExp(
          '^(' + d.join('|') + ')',
          'i'
        ));
    }
    function V(a) {
      var b;
      if (a < 100 && 0 <= a) {
        var c = Array.prototype.slice.call(arguments);
        (c[0] = a + 400),
          (b = new Date(Date.UTC.apply(null, c))),
          isFinite(b.getUTCFullYear()) && b.setUTCFullYear(a);
      } else b = new Date(Date.UTC.apply(null, arguments));
      return b;
    }
    function W(a, b, c) {
      var d = 7 + b - c;
      return (-(7 + V(a, 0, d).getUTCDay() - b) % 7) + d - 1;
    }
    function X(a, b, c, d, e) {
      var f,
        g,
        h = 1 + 7 * (b - 1) + ((7 + c - d) % 7) + W(a, d, e);
      return (
        (g =
          h <= 0
            ? M((f = a - 1)) + h
            : h > M(a)
              ? ((f = a + 1), h - M(a))
              : ((f = a), h)),
        { year: f, dayOfYear: g }
      );
    }
    function Y(a, b, c) {
      var d,
        e,
        f = W(a.year(), b, c),
        g = Math.floor((a.dayOfYear() - f - 1) / 7) + 1;
      return (
        g < 1
          ? (d = g + Z((e = a.year() - 1), b, c))
          : g > Z(a.year(), b, c)
            ? ((d = g - Z(a.year(), b, c)), (e = a.year() + 1))
            : ((e = a.year()), (d = g)),
        { week: d, year: e }
      );
    }
    function Z(a, b, c) {
      var d = W(a, b, c),
        e = W(a + 1, b, c);
      return (M(a) - d + e) / 7;
    }
    function $(a, b) {
      return a.slice(b, 7).concat(a.slice(0, b));
    }
    function _() {
      function a(a, b) {
        return b.length - a.length;
      }
      var b,
        c,
        d,
        e,
        f,
        g = [],
        h = [],
        i = [],
        k = [];
      for (b = 0; b < 7; b++)
        (c = j([2e3, 1]).day(b)),
          (d = this.weekdaysMin(c, '')),
          (e = this.weekdaysShort(c, '')),
          (f = this.weekdays(c, '')),
          g.push(d),
          h.push(e),
          i.push(f),
          k.push(d),
          k.push(e),
          k.push(f);
      for (g.sort(a), h.sort(a), i.sort(a), k.sort(a), b = 0; b < 7; b++)
        (h[b] = J(h[b])), (i[b] = J(i[b])), (k[b] = J(k[b]));
      (this._weekdaysRegex = new RegExp('^(' + k.join('|') + ')', 'i')),
        (this._weekdaysShortRegex = this._weekdaysRegex),
        (this._weekdaysMinRegex = this._weekdaysRegex),
        (this._weekdaysStrictRegex = new RegExp('^(' + i.join('|') + ')', 'i')),
        (this._weekdaysShortStrictRegex = new RegExp(
          '^(' + h.join('|') + ')',
          'i'
        )),
        (this._weekdaysMinStrictRegex = new RegExp(
          '^(' + g.join('|') + ')',
          'i'
        ));
    }
    function aa() {
      return this.hours() % 12 || 12;
    }
    function ba(a, b) {
      E(a, 0, 0, function () {
        return this.localeData().meridiem(this.hours(), this.minutes(), b);
      });
    }
    function ca(a, b) {
      return b._meridiemParse;
    }
    function da(a) {
      return a ? a.toLowerCase().replace('_', '-') : a;
    }
    function ea(a) {
      var b = null;
      if (!ac[a] && 'undefined' != typeof module && module && module.exports)
        try {
          (b = Zb._abbr), require('./locale/' + a), fa(b);
        } catch (a) {}
      return ac[a];
    }
    function fa(a, b) {
      var c;
      return (
        a &&
          ((c = d(b) ? ha(a) : ga(a, b))
            ? (Zb = c)
            : 'undefined' != typeof console &&
              console.warn &&
              console.warn(
                'Locale ' + a + ' not found. Did you forget to load it?'
              )),
        Zb._abbr
      );
    }
    function ga(a, b) {
      if (null === b) return delete ac[a], null;
      var c,
        d = _b;
      if (((b.abbr = a), null != ac[a]))
        v(
          'defineLocaleOverride',
          'use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info.'
        ),
          (d = ac[a]._config);
      else if (null != b.parentLocale)
        if (null != ac[b.parentLocale]) d = ac[b.parentLocale]._config;
        else {
          if (null == (c = ea(b.parentLocale)))
            return (
              bc[b.parentLocale] || (bc[b.parentLocale] = []),
              bc[b.parentLocale].push({ name: a, config: b }),
              null
            );
          d = c._config;
        }
      return (
        (ac[a] = new y(x(d, b))),
        bc[a] &&
          bc[a].forEach(function (a) {
            ga(a.name, a.config);
          }),
        fa(a),
        ac[a]
      );
    }
    function ha(a) {
      var c;
      if ((a && a._locale && a._locale._abbr && (a = a._locale._abbr), !a))
        return Zb;
      if (!b(a)) {
        if ((c = ea(a))) return c;
        a = [a];
      }
      return (function (a) {
        for (var b, c, d, e, f = 0; f < a.length; ) {
          for (
            b = (e = da(a[f]).split('-')).length,
              c = (c = da(a[f + 1])) ? c.split('-') : null;
            0 < b;

          ) {
            if ((d = ea(e.slice(0, b).join('-')))) return d;
            if (c && c.length >= b && s(e, c, !0) >= b - 1) break;
            b--;
          }
          f++;
        }
        return Zb;
      })(a);
    }
    function ia(a) {
      var b,
        c = a._a;
      return (
        c &&
          -2 === k(a).overflow &&
          ((b =
            c[Eb] < 0 || 11 < c[Eb]
              ? Eb
              : c[Fb] < 1 || c[Fb] > R(c[Db], c[Eb])
                ? Fb
                : c[Gb] < 0 ||
                    24 < c[Gb] ||
                    (24 === c[Gb] &&
                      (0 !== c[Hb] || 0 !== c[Ib] || 0 !== c[Jb]))
                  ? Gb
                  : c[Hb] < 0 || 59 < c[Hb]
                    ? Hb
                    : c[Ib] < 0 || 59 < c[Ib]
                      ? Ib
                      : c[Jb] < 0 || 999 < c[Jb]
                        ? Jb
                        : -1),
          k(a)._overflowDayOfYear && (b < Db || Fb < b) && (b = Fb),
          k(a)._overflowWeeks && -1 === b && (b = Kb),
          k(a)._overflowWeekday && -1 === b && (b = Lb),
          (k(a).overflow = b)),
        a
      );
    }
    function ja(a, b, c) {
      return null != a ? a : null != b ? b : c;
    }
    function ka(b) {
      var c,
        d,
        e,
        f,
        g,
        h = [];
      if (!b._d) {
        var i, j;
        for (
          i = b,
            j = new Date(a.now()),
            e = i._useUTC
              ? [j.getUTCFullYear(), j.getUTCMonth(), j.getUTCDate()]
              : [j.getFullYear(), j.getMonth(), j.getDate()],
            b._w &&
              null == b._a[Fb] &&
              null == b._a[Eb] &&
              (function (a) {
                var b, c, d, e, f, g, h, i;
                if (null != (b = a._w).GG || null != b.W || null != b.E)
                  (f = 1),
                    (g = 4),
                    (c = ja(b.GG, a._a[Db], Y(ra(), 1, 4).year)),
                    (d = ja(b.W, 1)),
                    ((e = ja(b.E, 1)) < 1 || 7 < e) && (i = !0);
                else {
                  (f = a._locale._week.dow), (g = a._locale._week.doy);
                  var j = Y(ra(), f, g);
                  (c = ja(b.gg, a._a[Db], j.year)),
                    (d = ja(b.w, j.week)),
                    null != b.d
                      ? ((e = b.d) < 0 || 6 < e) && (i = !0)
                      : null != b.e
                        ? ((e = b.e + f), (b.e < 0 || 6 < b.e) && (i = !0))
                        : (e = f);
                }
                d < 1 || d > Z(c, f, g)
                  ? (k(a)._overflowWeeks = !0)
                  : null != i
                    ? (k(a)._overflowWeekday = !0)
                    : ((h = X(c, d, e, f, g)),
                      (a._a[Db] = h.year),
                      (a._dayOfYear = h.dayOfYear));
              })(b),
            null != b._dayOfYear &&
              ((g = ja(b._a[Db], e[Db])),
              (b._dayOfYear > M(g) || 0 === b._dayOfYear) &&
                (k(b)._overflowDayOfYear = !0),
              (d = V(g, 0, b._dayOfYear)),
              (b._a[Eb] = d.getUTCMonth()),
              (b._a[Fb] = d.getUTCDate())),
            c = 0;
          c < 3 && null == b._a[c];
          ++c
        )
          b._a[c] = h[c] = e[c];
        for (; c < 7; c++)
          b._a[c] = h[c] = null == b._a[c] ? (2 === c ? 1 : 0) : b._a[c];
        24 === b._a[Gb] &&
          0 === b._a[Hb] &&
          0 === b._a[Ib] &&
          0 === b._a[Jb] &&
          ((b._nextDay = !0), (b._a[Gb] = 0)),
          (b._d = (
            b._useUTC
              ? V
              : function (a, b, c, d, e, f, g) {
                  var h;
                  return (
                    a < 100 && 0 <= a
                      ? ((h = new Date(a + 400, b, c, d, e, f, g)),
                        isFinite(h.getFullYear()) && h.setFullYear(a))
                      : (h = new Date(a, b, c, d, e, f, g)),
                    h
                  );
                }
          ).apply(null, h)),
          (f = b._useUTC ? b._d.getUTCDay() : b._d.getDay()),
          null != b._tzm && b._d.setUTCMinutes(b._d.getUTCMinutes() - b._tzm),
          b._nextDay && (b._a[Gb] = 24),
          b._w &&
            void 0 !== b._w.d &&
            b._w.d !== f &&
            (k(b).weekdayMismatch = !0);
      }
    }
    function la(a) {
      var b,
        c,
        d,
        e,
        f,
        g,
        h = a._i,
        i = cc.exec(h) || dc.exec(h);
      if (i) {
        for (k(a).iso = !0, b = 0, c = fc.length; b < c; b++)
          if (fc[b][1].exec(i[1])) {
            (e = fc[b][0]), (d = !1 !== fc[b][2]);
            break;
          }
        if (null == e) return void (a._isValid = !1);
        if (i[3]) {
          for (b = 0, c = gc.length; b < c; b++)
            if (gc[b][1].exec(i[3])) {
              f = (i[2] || ' ') + gc[b][0];
              break;
            }
          if (null == f) return void (a._isValid = !1);
        }
        if (!d && null != f) return void (a._isValid = !1);
        if (i[4]) {
          if (!ec.exec(i[4])) return void (a._isValid = !1);
          g = 'Z';
        }
        (a._f = e + (f || '') + (g || '')), oa(a);
      } else a._isValid = !1;
    }
    function ma(a, b, c, d, e, f) {
      var g = [
        (function (a) {
          var b = parseInt(a, 10);
          return b <= 49 ? 2e3 + b : b <= 999 ? 1900 + b : b;
        })(a),
        Qb.indexOf(b),
        parseInt(c, 10),
        parseInt(d, 10),
        parseInt(e, 10)
      ];
      return f && g.push(parseInt(f, 10)), g;
    }
    function na(a) {
      var b,
        c,
        d,
        e = ic.exec(
          a._i
            .replace(/\([^)]*\)|[\n\t]/g, ' ')
            .replace(/(\s\s+)/g, ' ')
            .replace(/^\s\s*/, '')
            .replace(/\s\s*$/, '')
        );
      if (e) {
        var f = ma(e[4], e[3], e[2], e[5], e[6], e[7]);
        if (
          ((b = e[1]),
          (c = f),
          (d = a),
          b &&
            Ub.indexOf(b) !== new Date(c[0], c[1], c[2]).getDay() &&
            ((k(d).weekdayMismatch = !0), !(d._isValid = !1)))
        )
          return;
        (a._a = f),
          (a._tzm = (function (a, b, c) {
            if (a) return jc[a];
            if (b) return 0;
            var d = parseInt(c, 10),
              e = d % 100;
            return ((d - e) / 100) * 60 + e;
          })(e[8], e[9], e[10])),
          (a._d = V.apply(null, a._a)),
          a._d.setUTCMinutes(a._d.getUTCMinutes() - a._tzm),
          (k(a).rfc2822 = !0);
      } else a._isValid = !1;
    }
    function oa(b) {
      if (b._f !== a.ISO_8601)
        if (b._f !== a.RFC_2822) {
          (b._a = []), (k(b).empty = !0);
          var c,
            d,
            e,
            f,
            g,
            i,
            j,
            l,
            m = '' + b._i,
            n = m.length,
            o = 0;
          for (e = G(b._f, b._locale).match(hb) || [], c = 0; c < e.length; c++)
            (f = e[c]),
              (d = (m.match(I(f, b)) || [])[0]) &&
                (0 < (g = m.substr(0, m.indexOf(d))).length &&
                  k(b).unusedInput.push(g),
                (m = m.slice(m.indexOf(d) + d.length)),
                (o += d.length)),
              kb[f]
                ? (d ? (k(b).empty = !1) : k(b).unusedTokens.push(f),
                  (i = f),
                  (l = b),
                  null != (j = d) && h(Cb, i) && Cb[i](j, l._a, l, i))
                : b._strict && !d && k(b).unusedTokens.push(f);
          (k(b).charsLeftOver = n - o),
            0 < m.length && k(b).unusedInput.push(m),
            b._a[Gb] <= 12 &&
              !0 === k(b).bigHour &&
              0 < b._a[Gb] &&
              (k(b).bigHour = void 0),
            (k(b).parsedDateParts = b._a.slice(0)),
            (k(b).meridiem = b._meridiem),
            (b._a[Gb] = (function (a, b, c) {
              var d;
              return null == c
                ? b
                : null != a.meridiemHour
                  ? a.meridiemHour(b, c)
                  : (null != a.isPM &&
                      ((d = a.isPM(c)) && b < 12 && (b += 12),
                      d || 12 !== b || (b = 0)),
                    b);
            })(b._locale, b._a[Gb], b._meridiem)),
            ka(b),
            ia(b);
        } else na(b);
      else la(b);
    }
    function pa(h) {
      var j,
        q,
        r,
        s,
        t = h._i,
        u = h._f;
      return (
        (h._locale = h._locale || ha(h._l)),
        null === t || (void 0 === u && '' === t)
          ? m({ nullInput: !0 })
          : ('string' == typeof t && (h._i = t = h._locale.preparse(t)),
            p(t)
              ? new o(ia(t))
              : (f(t)
                  ? (h._d = t)
                  : b(u)
                    ? (function (a) {
                        var b, c, d, e, f;
                        if (0 === a._f.length)
                          return (
                            (k(a).invalidFormat = !0), (a._d = new Date(NaN))
                          );
                        for (e = 0; e < a._f.length; e++)
                          (f = 0),
                            (b = n({}, a)),
                            null != a._useUTC && (b._useUTC = a._useUTC),
                            (b._f = a._f[e]),
                            oa(b),
                            l(b) &&
                              ((f += k(b).charsLeftOver),
                              (f += 10 * k(b).unusedTokens.length),
                              (k(b).score = f),
                              (null == d || f < d) && ((d = f), (c = b)));
                        i(a, c || b);
                      })(h)
                    : u
                      ? oa(h)
                      : d((q = (j = h)._i))
                        ? (j._d = new Date(a.now()))
                        : f(q)
                          ? (j._d = new Date(q.valueOf()))
                          : 'string' == typeof q
                            ? ((r = j),
                              null === (s = hc.exec(r._i))
                                ? (la(r),
                                  !1 === r._isValid &&
                                    (delete r._isValid,
                                    na(r),
                                    !1 === r._isValid &&
                                      (delete r._isValid,
                                      a.createFromInputFallback(r))))
                                : (r._d = new Date(+s[1])))
                            : b(q)
                              ? ((j._a = g(q.slice(0), function (a) {
                                  return parseInt(a, 10);
                                })),
                                ka(j))
                              : c(q)
                                ? (function (a) {
                                    if (!a._d) {
                                      var b = B(a._i);
                                      (a._a = g(
                                        [
                                          b.year,
                                          b.month,
                                          b.day || b.date,
                                          b.hour,
                                          b.minute,
                                          b.second,
                                          b.millisecond
                                        ],
                                        function (a) {
                                          return a && parseInt(a, 10);
                                        }
                                      )),
                                        ka(a);
                                    }
                                  })(j)
                                : e(q)
                                  ? (j._d = new Date(q))
                                  : a.createFromInputFallback(j),
                l(h) || (h._d = null),
                h))
      );
    }
    function qa(a, d, e, f, g) {
      var h,
        i = {};
      return (
        (!0 !== e && !1 !== e) || ((f = e), (e = void 0)),
        ((c(a) &&
          (function (a) {
            if (Object.getOwnPropertyNames)
              return 0 === Object.getOwnPropertyNames(a).length;
            var b;
            for (b in a) if (a.hasOwnProperty(b)) return !1;
            return !0;
          })(a)) ||
          (b(a) && 0 === a.length)) &&
          (a = void 0),
        (i._isAMomentObject = !0),
        (i._useUTC = i._isUTC = g),
        (i._l = e),
        (i._i = a),
        (i._f = d),
        (i._strict = f),
        (h = new o(ia(pa(i))))._nextDay &&
          (h.add(1, 'd'), (h._nextDay = void 0)),
        h
      );
    }
    function ra(a, b, c, d) {
      return qa(a, b, c, d, !1);
    }
    function sa(a, c) {
      var d, e;
      if ((1 === c.length && b(c[0]) && (c = c[0]), !c.length)) return ra();
      for (d = c[0], e = 1; e < c.length; ++e)
        (c[e].isValid() && !c[e][a](d)) || (d = c[e]);
      return d;
    }
    function ta(a) {
      var b = B(a),
        c = b.year || 0,
        d = b.quarter || 0,
        e = b.month || 0,
        f = b.week || b.isoWeek || 0,
        g = b.day || 0,
        h = b.hour || 0,
        i = b.minute || 0,
        j = b.second || 0,
        k = b.millisecond || 0;
      (this._isValid = (function (a) {
        for (var b in a)
          if (-1 === Mb.call(mc, b) || (null != a[b] && isNaN(a[b]))) return !1;
        for (var c = !1, d = 0; d < mc.length; ++d)
          if (a[mc[d]]) {
            if (c) return !1;
            parseFloat(a[mc[d]]) !== r(a[mc[d]]) && (c = !0);
          }
        return !0;
      })(b)),
        (this._milliseconds = +k + 1e3 * j + 6e4 * i + 1e3 * h * 60 * 60),
        (this._days = +g + 7 * f),
        (this._months = +e + 3 * d + 12 * c),
        (this._data = {}),
        (this._locale = ha()),
        this._bubble();
    }
    function ua(a) {
      return a instanceof ta;
    }
    function va(a) {
      return a < 0 ? -1 * Math.round(-1 * a) : Math.round(a);
    }
    function wa(a, b) {
      E(a, 0, 0, function () {
        var a = this.utcOffset(),
          c = '+';
        return (
          a < 0 && ((a = -a), (c = '-')),
          c + D(~~(a / 60), 2) + b + D(~~a % 60, 2)
        );
      });
    }
    function xa(a, b) {
      var c = (b || '').match(a);
      if (null === c) return null;
      var d = ((c[c.length - 1] || []) + '').match(nc) || ['-', 0, 0],
        e = 60 * d[1] + r(d[2]);
      return 0 === e ? 0 : '+' === d[0] ? e : -e;
    }
    function ya(b, c) {
      var d, e;
      return c._isUTC
        ? ((d = c.clone()),
          (e = (p(b) || f(b) ? b.valueOf() : ra(b).valueOf()) - d.valueOf()),
          d._d.setTime(d._d.valueOf() + e),
          a.updateOffset(d, !1),
          d)
        : ra(b).local();
    }
    function za(a) {
      return 15 * -Math.round(a._d.getTimezoneOffset() / 15);
    }
    function Aa() {
      return !!this.isValid() && this._isUTC && 0 === this._offset;
    }
    function Ba(a, b) {
      var c,
        d,
        f,
        g = a,
        i = null;
      return (
        ua(a)
          ? (g = { ms: a._milliseconds, d: a._days, M: a._months })
          : e(a)
            ? ((g = {}), b ? (g[b] = a) : (g.milliseconds = a))
            : (i = oc.exec(a))
              ? ((c = '-' === i[1] ? -1 : 1),
                (g = {
                  y: 0,
                  d: r(i[Fb]) * c,
                  h: r(i[Gb]) * c,
                  m: r(i[Hb]) * c,
                  s: r(i[Ib]) * c,
                  ms: r(va(1e3 * i[Jb])) * c
                }))
              : (i = pc.exec(a))
                ? ((c = '-' === i[1] ? -1 : 1),
                  (g = {
                    y: Ca(i[2], c),
                    M: Ca(i[3], c),
                    w: Ca(i[4], c),
                    d: Ca(i[5], c),
                    h: Ca(i[6], c),
                    m: Ca(i[7], c),
                    s: Ca(i[8], c)
                  }))
                : null == g
                  ? (g = {})
                  : 'object' == typeof g &&
                    ('from' in g || 'to' in g) &&
                    ((f = (function (a, b) {
                      var c;
                      return a.isValid() && b.isValid()
                        ? ((b = ya(b, a)),
                          a.isBefore(b)
                            ? (c = Da(a, b))
                            : (((c = Da(b, a)).milliseconds = -c.milliseconds),
                              (c.months = -c.months)),
                          c)
                        : { milliseconds: 0, months: 0 };
                    })(ra(g.from), ra(g.to))),
                    ((g = {}).ms = f.milliseconds),
                    (g.M = f.months)),
        (d = new ta(g)),
        ua(a) && h(a, '_locale') && (d._locale = a._locale),
        d
      );
    }
    function Ca(a, b) {
      var c = a && parseFloat(a.replace(',', '.'));
      return (isNaN(c) ? 0 : c) * b;
    }
    function Da(a, b) {
      var c = {};
      return (
        (c.months = b.month() - a.month() + 12 * (b.year() - a.year())),
        a.clone().add(c.months, 'M').isAfter(b) && --c.months,
        (c.milliseconds = +b - +a.clone().add(c.months, 'M')),
        c
      );
    }
    function Ea(a, b) {
      return function (c, d) {
        var e;
        return (
          null === d ||
            isNaN(+d) ||
            (v(
              b,
              'moment().' +
                b +
                '(period, number) is deprecated. Please use moment().' +
                b +
                '(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info.'
            ),
            (e = c),
            (c = d),
            (d = e)),
          Fa(this, Ba((c = 'string' == typeof c ? +c : c), d), a),
          this
        );
      };
    }
    function Fa(b, c, d, e) {
      var f = c._milliseconds,
        g = va(c._days),
        h = va(c._months);
      b.isValid() &&
        ((e = null == e || e),
        h && S(b, P(b, 'Month') + h * d),
        g && Q(b, 'Date', P(b, 'Date') + g * d),
        f && b._d.setTime(b._d.valueOf() + f * d),
        e && a.updateOffset(b, g || h));
    }
    function Ga(a, b) {
      var c = 12 * (b.year() - a.year()) + (b.month() - a.month()),
        d = a.clone().add(c, 'months');
      return (
        -(
          c +
          (b - d < 0
            ? (b - d) / (d - a.clone().add(c - 1, 'months'))
            : (b - d) / (a.clone().add(c + 1, 'months') - d))
        ) || 0
      );
    }
    function Ha(a) {
      var b;
      return void 0 === a
        ? this._locale._abbr
        : (null != (b = ha(a)) && (this._locale = b), this);
    }
    function Ia() {
      return this._locale;
    }
    function Ja(a, b) {
      return ((a % b) + b) % b;
    }
    function Ka(a, b, c) {
      return a < 100 && 0 <= a
        ? new Date(a + 400, b, c) - tc
        : new Date(a, b, c).valueOf();
    }
    function La(a, b, c) {
      return a < 100 && 0 <= a
        ? Date.UTC(a + 400, b, c) - tc
        : Date.UTC(a, b, c);
    }
    function Ma(a, b) {
      E(0, [a, a.length], 0, b);
    }
    function Na(a, b, c, d, e) {
      var f;
      return null == a
        ? Y(this, d, e).year
        : ((f = Z(a, d, e)) < b && (b = f),
          function (a, b, c, d, e) {
            var f = X(a, b, c, d, e),
              g = V(f.year, 0, f.dayOfYear);
            return (
              this.year(g.getUTCFullYear()),
              this.month(g.getUTCMonth()),
              this.date(g.getUTCDate()),
              this
            );
          }.call(this, a, b, c, d, e));
    }
    function Oa(a, b) {
      b[Jb] = r(1e3 * ('0.' + a));
    }
    function Pa(a) {
      return a;
    }
    function Qa(a, b, c, d) {
      var e = ha(),
        f = j().set(d, b);
      return e[c](f, a);
    }
    function Ra(a, b, c) {
      if ((e(a) && ((b = a), (a = void 0)), (a = a || ''), null != b))
        return Qa(a, b, c, 'month');
      var d,
        f = [];
      for (d = 0; d < 12; d++) f[d] = Qa(a, d, c, 'month');
      return f;
    }
    function Sa(a, b, c, d) {
      'boolean' == typeof a
        ? e(b) && ((c = b), (b = void 0))
        : ((b = a), (a = !1), e((c = b)) && ((c = b), (b = void 0))),
        (b = b || '');
      var f,
        g = ha(),
        h = a ? g._week.dow : 0;
      if (null != c) return Qa(b, (c + h) % 7, d, 'day');
      var i = [];
      for (f = 0; f < 7; f++) i[f] = Qa(b, (f + h) % 7, d, 'day');
      return i;
    }
    function Ta(a, b, c, d) {
      var e = Ba(b, c);
      return (
        (a._milliseconds += d * e._milliseconds),
        (a._days += d * e._days),
        (a._months += d * e._months),
        a._bubble()
      );
    }
    function Ua(a) {
      return a < 0 ? Math.floor(a) : Math.ceil(a);
    }
    function Va(a) {
      return (4800 * a) / 146097;
    }
    function Wa(a) {
      return (146097 * a) / 4800;
    }
    function Xa(a) {
      return function () {
        return this.as(a);
      };
    }
    function Ya(a) {
      return function () {
        return this.isValid() ? this._data[a] : NaN;
      };
    }
    function Za(a) {
      return (0 < a) - (a < 0) || +a;
    }
    function $a() {
      if (!this.isValid()) return this.localeData().invalidDate();
      var a,
        b,
        c = Uc(this._milliseconds) / 1e3,
        d = Uc(this._days),
        e = Uc(this._months);
      (b = q((a = q(c / 60)) / 60)), (c %= 60), (a %= 60);
      var f = q(e / 12),
        g = (e %= 12),
        h = d,
        i = b,
        j = a,
        k = c ? c.toFixed(3).replace(/\.?0+$/, '') : '',
        l = this.asSeconds();
      if (!l) return 'P0D';
      var m = l < 0 ? '-' : '',
        n = Za(this._months) !== Za(l) ? '-' : '',
        o = Za(this._days) !== Za(l) ? '-' : '',
        p = Za(this._milliseconds) !== Za(l) ? '-' : '';
      return (
        m +
        'P' +
        (f ? n + f + 'Y' : '') +
        (g ? n + g + 'M' : '') +
        (h ? o + h + 'D' : '') +
        (i || j || k ? 'T' : '') +
        (i ? p + i + 'H' : '') +
        (j ? p + j + 'M' : '') +
        (k ? p + k + 'S' : '')
      );
    }
    var _a, ab;
    ab = Array.prototype.some
      ? Array.prototype.some
      : function (a) {
          for (var b = Object(this), c = b.length >>> 0, d = 0; d < c; d++)
            if (d in b && a.call(this, b[d], d, b)) return !0;
          return !1;
        };
    var bb,
      cb = (a.momentProperties = []),
      db = !1,
      eb = {};
    (a.suppressDeprecationWarnings = !1),
      (a.deprecationHandler = null),
      (bb = Object.keys
        ? Object.keys
        : function (a) {
            var b,
              c = [];
            for (b in a) h(a, b) && c.push(b);
            return c;
          });
    var fb = {},
      gb = {},
      hb =
        /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|YYYYYY|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g,
      ib = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g,
      jb = {},
      kb = {},
      lb = /\d/,
      mb = /\d\d/,
      nb = /\d{3}/,
      ob = /\d{4}/,
      pb = /[+-]?\d{6}/,
      qb = /\d\d?/,
      rb = /\d\d\d\d?/,
      sb = /\d\d\d\d\d\d?/,
      tb = /\d{1,3}/,
      ub = /\d{1,4}/,
      vb = /[+-]?\d{1,6}/,
      wb = /\d+/,
      xb = /[+-]?\d+/,
      yb = /Z|[+-]\d\d:?\d\d/gi,
      zb = /Z|[+-]\d\d(?::?\d\d)?/gi,
      Ab =
        /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i,
      Bb = {},
      Cb = {},
      Db = 0,
      Eb = 1,
      Fb = 2,
      Gb = 3,
      Hb = 4,
      Ib = 5,
      Jb = 6,
      Kb = 7,
      Lb = 8;
    E('Y', 0, 0, function () {
      var a = this.year();
      return a <= 9999 ? '' + a : '+' + a;
    }),
      E(0, ['YY', 2], 0, function () {
        return this.year() % 100;
      }),
      E(0, ['YYYY', 4], 0, 'year'),
      E(0, ['YYYYY', 5], 0, 'year'),
      E(0, ['YYYYYY', 6, !0], 0, 'year'),
      z('year', 'y'),
      C('year', 1),
      H('Y', xb),
      H('YY', qb, mb),
      H('YYYY', ub, ob),
      H('YYYYY', vb, pb),
      H('YYYYYY', vb, pb),
      K(['YYYYY', 'YYYYYY'], Db),
      K('YYYY', function (b, c) {
        c[Db] = 2 === b.length ? a.parseTwoDigitYear(b) : r(b);
      }),
      K('YY', function (b, c) {
        c[Db] = a.parseTwoDigitYear(b);
      }),
      K('Y', function (a, b) {
        b[Db] = parseInt(a, 10);
      }),
      (a.parseTwoDigitYear = function (a) {
        return r(a) + (68 < r(a) ? 1900 : 2e3);
      });
    var Mb,
      Nb = O('FullYear', !0);
    (Mb = Array.prototype.indexOf
      ? Array.prototype.indexOf
      : function (a) {
          var b;
          for (b = 0; b < this.length; ++b) if (this[b] === a) return b;
          return -1;
        }),
      E('M', ['MM', 2], 'Mo', function () {
        return this.month() + 1;
      }),
      E('MMM', 0, 0, function (a) {
        return this.localeData().monthsShort(this, a);
      }),
      E('MMMM', 0, 0, function (a) {
        return this.localeData().months(this, a);
      }),
      z('month', 'M'),
      C('month', 8),
      H('M', qb),
      H('MM', qb, mb),
      H('MMM', function (a, b) {
        return b.monthsShortRegex(a);
      }),
      H('MMMM', function (a, b) {
        return b.monthsRegex(a);
      }),
      K(['M', 'MM'], function (a, b) {
        b[Eb] = r(a) - 1;
      }),
      K(['MMM', 'MMMM'], function (a, b, c, d) {
        var e = c._locale.monthsParse(a, d, c._strict);
        null != e ? (b[Eb] = e) : (k(c).invalidMonth = a);
      });
    var Ob = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/,
      Pb =
        'January_February_March_April_May_June_July_August_September_October_November_December'.split(
          '_'
        ),
      Qb = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
      Rb = Ab,
      Sb = Ab;
    E('w', ['ww', 2], 'wo', 'week'),
      E('W', ['WW', 2], 'Wo', 'isoWeek'),
      z('week', 'w'),
      z('isoWeek', 'W'),
      C('week', 5),
      C('isoWeek', 5),
      H('w', qb),
      H('ww', qb, mb),
      H('W', qb),
      H('WW', qb, mb),
      L(['w', 'ww', 'W', 'WW'], function (a, b, c, d) {
        b[d.substr(0, 1)] = r(a);
      }),
      E('d', 0, 'do', 'day'),
      E('dd', 0, 0, function (a) {
        return this.localeData().weekdaysMin(this, a);
      }),
      E('ddd', 0, 0, function (a) {
        return this.localeData().weekdaysShort(this, a);
      }),
      E('dddd', 0, 0, function (a) {
        return this.localeData().weekdays(this, a);
      }),
      E('e', 0, 0, 'weekday'),
      E('E', 0, 0, 'isoWeekday'),
      z('day', 'd'),
      z('weekday', 'e'),
      z('isoWeekday', 'E'),
      C('day', 11),
      C('weekday', 11),
      C('isoWeekday', 11),
      H('d', qb),
      H('e', qb),
      H('E', qb),
      H('dd', function (a, b) {
        return b.weekdaysMinRegex(a);
      }),
      H('ddd', function (a, b) {
        return b.weekdaysShortRegex(a);
      }),
      H('dddd', function (a, b) {
        return b.weekdaysRegex(a);
      }),
      L(['dd', 'ddd', 'dddd'], function (a, b, c, d) {
        var e = c._locale.weekdaysParse(a, d, c._strict);
        null != e ? (b.d = e) : (k(c).invalidWeekday = a);
      }),
      L(['d', 'e', 'E'], function (a, b, c, d) {
        b[d] = r(a);
      });
    var Tb = 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
        '_'
      ),
      Ub = 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
      Vb = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
      Wb = Ab,
      Xb = Ab,
      Yb = Ab;
    E('H', ['HH', 2], 0, 'hour'),
      E('h', ['hh', 2], 0, aa),
      E('k', ['kk', 2], 0, function () {
        return this.hours() || 24;
      }),
      E('hmm', 0, 0, function () {
        return '' + aa.apply(this) + D(this.minutes(), 2);
      }),
      E('hmmss', 0, 0, function () {
        return (
          '' + aa.apply(this) + D(this.minutes(), 2) + D(this.seconds(), 2)
        );
      }),
      E('Hmm', 0, 0, function () {
        return '' + this.hours() + D(this.minutes(), 2);
      }),
      E('Hmmss', 0, 0, function () {
        return '' + this.hours() + D(this.minutes(), 2) + D(this.seconds(), 2);
      }),
      ba('a', !0),
      ba('A', !1),
      z('hour', 'h'),
      C('hour', 13),
      H('a', ca),
      H('A', ca),
      H('H', qb),
      H('h', qb),
      H('k', qb),
      H('HH', qb, mb),
      H('hh', qb, mb),
      H('kk', qb, mb),
      H('hmm', rb),
      H('hmmss', sb),
      H('Hmm', rb),
      H('Hmmss', sb),
      K(['H', 'HH'], Gb),
      K(['k', 'kk'], function (a, b, c) {
        var d = r(a);
        b[Gb] = 24 === d ? 0 : d;
      }),
      K(['a', 'A'], function (a, b, c) {
        (c._isPm = c._locale.isPM(a)), (c._meridiem = a);
      }),
      K(['h', 'hh'], function (a, b, c) {
        (b[Gb] = r(a)), (k(c).bigHour = !0);
      }),
      K('hmm', function (a, b, c) {
        var d = a.length - 2;
        (b[Gb] = r(a.substr(0, d))),
          (b[Hb] = r(a.substr(d))),
          (k(c).bigHour = !0);
      }),
      K('hmmss', function (a, b, c) {
        var d = a.length - 4,
          e = a.length - 2;
        (b[Gb] = r(a.substr(0, d))),
          (b[Hb] = r(a.substr(d, 2))),
          (b[Ib] = r(a.substr(e))),
          (k(c).bigHour = !0);
      }),
      K('Hmm', function (a, b, c) {
        var d = a.length - 2;
        (b[Gb] = r(a.substr(0, d))), (b[Hb] = r(a.substr(d)));
      }),
      K('Hmmss', function (a, b, c) {
        var d = a.length - 4,
          e = a.length - 2;
        (b[Gb] = r(a.substr(0, d))),
          (b[Hb] = r(a.substr(d, 2))),
          (b[Ib] = r(a.substr(e)));
      });
    var Zb,
      $b = O('Hours', !0),
      _b = {
        calendar: {
          sameDay: '[Today at] LT',
          nextDay: '[Tomorrow at] LT',
          nextWeek: 'dddd [at] LT',
          lastDay: '[Yesterday at] LT',
          lastWeek: '[Last] dddd [at] LT',
          sameElse: 'L'
        },
        longDateFormat: {
          LTS: 'h:mm:ss A',
          LT: 'h:mm A',
          L: 'MM/DD/YYYY',
          LL: 'MMMM D, YYYY',
          LLL: 'MMMM D, YYYY h:mm A',
          LLLL: 'dddd, MMMM D, YYYY h:mm A'
        },
        invalidDate: 'Invalid date',
        ordinal: '%d',
        dayOfMonthOrdinalParse: /\d{1,2}/,
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'a few seconds',
          ss: '%d seconds',
          m: 'a minute',
          mm: '%d minutes',
          h: 'an hour',
          hh: '%d hours',
          d: 'a day',
          dd: '%d days',
          M: 'a month',
          MM: '%d months',
          y: 'a year',
          yy: '%d years'
        },
        months: Pb,
        monthsShort: Qb,
        week: { dow: 0, doy: 6 },
        weekdays: Tb,
        weekdaysMin: Vb,
        weekdaysShort: Ub,
        meridiemParse: /[ap]\.?m?\.?/i
      },
      ac = {},
      bc = {},
      cc =
        /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([\+\-]\d\d(?::?\d\d)?|\s*Z)?)?$/,
      dc =
        /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([\+\-]\d\d(?::?\d\d)?|\s*Z)?)?$/,
      ec = /Z|[+-]\d\d(?::?\d\d)?/,
      fc = [
        ['YYYYYY-MM-DD', /[+-]\d{6}-\d\d-\d\d/],
        ['YYYY-MM-DD', /\d{4}-\d\d-\d\d/],
        ['GGGG-[W]WW-E', /\d{4}-W\d\d-\d/],
        ['GGGG-[W]WW', /\d{4}-W\d\d/, !1],
        ['YYYY-DDD', /\d{4}-\d{3}/],
        ['YYYY-MM', /\d{4}-\d\d/, !1],
        ['YYYYYYMMDD', /[+-]\d{10}/],
        ['YYYYMMDD', /\d{8}/],
        ['GGGG[W]WWE', /\d{4}W\d{3}/],
        ['GGGG[W]WW', /\d{4}W\d{2}/, !1],
        ['YYYYDDD', /\d{7}/]
      ],
      gc = [
        ['HH:mm:ss.SSSS', /\d\d:\d\d:\d\d\.\d+/],
        ['HH:mm:ss,SSSS', /\d\d:\d\d:\d\d,\d+/],
        ['HH:mm:ss', /\d\d:\d\d:\d\d/],
        ['HH:mm', /\d\d:\d\d/],
        ['HHmmss.SSSS', /\d\d\d\d\d\d\.\d+/],
        ['HHmmss,SSSS', /\d\d\d\d\d\d,\d+/],
        ['HHmmss', /\d\d\d\d\d\d/],
        ['HHmm', /\d\d\d\d/],
        ['HH', /\d\d/]
      ],
      hc = /^\/?Date\((\-?\d+)/i,
      ic =
        /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/,
      jc = {
        UT: 0,
        GMT: 0,
        EDT: -240,
        EST: -300,
        CDT: -300,
        CST: -360,
        MDT: -360,
        MST: -420,
        PDT: -420,
        PST: -480
      };
    (a.createFromInputFallback = u(
      'value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged and will be removed in an upcoming major release. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.',
      function (a) {
        a._d = new Date(a._i + (a._useUTC ? ' UTC' : ''));
      }
    )),
      (a.ISO_8601 = function () {}),
      (a.RFC_2822 = function () {});
    var kc = u(
        'moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/',
        function () {
          var a = ra.apply(null, arguments);
          return this.isValid() && a.isValid() ? (a < this ? this : a) : m();
        }
      ),
      lc = u(
        'moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/',
        function () {
          var a = ra.apply(null, arguments);
          return this.isValid() && a.isValid() ? (this < a ? this : a) : m();
        }
      ),
      mc = [
        'year',
        'quarter',
        'month',
        'week',
        'day',
        'hour',
        'minute',
        'second',
        'millisecond'
      ];
    wa('Z', ':'),
      wa('ZZ', ''),
      H('Z', zb),
      H('ZZ', zb),
      K(['Z', 'ZZ'], function (a, b, c) {
        (c._useUTC = !0), (c._tzm = xa(zb, a));
      });
    var nc = /([\+\-]|\d\d)/gi;
    a.updateOffset = function () {};
    var oc = /^(\-|\+)?(?:(\d*)[. ])?(\d+)\:(\d+)(?:\:(\d+)(\.\d*)?)?$/,
      pc =
        /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
    (Ba.fn = ta.prototype),
      (Ba.invalid = function () {
        return Ba(NaN);
      });
    var qc = Ea(1, 'add'),
      rc = Ea(-1, 'subtract');
    (a.defaultFormat = 'YYYY-MM-DDTHH:mm:ssZ'),
      (a.defaultFormatUtc = 'YYYY-MM-DDTHH:mm:ss[Z]');
    var sc = u(
        'moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.',
        function (a) {
          return void 0 === a ? this.localeData() : this.locale(a);
        }
      ),
      tc = 126227808e5;
    E(0, ['gg', 2], 0, function () {
      return this.weekYear() % 100;
    }),
      E(0, ['GG', 2], 0, function () {
        return this.isoWeekYear() % 100;
      }),
      Ma('gggg', 'weekYear'),
      Ma('ggggg', 'weekYear'),
      Ma('GGGG', 'isoWeekYear'),
      Ma('GGGGG', 'isoWeekYear'),
      z('weekYear', 'gg'),
      z('isoWeekYear', 'GG'),
      C('weekYear', 1),
      C('isoWeekYear', 1),
      H('G', xb),
      H('g', xb),
      H('GG', qb, mb),
      H('gg', qb, mb),
      H('GGGG', ub, ob),
      H('gggg', ub, ob),
      H('GGGGG', vb, pb),
      H('ggggg', vb, pb),
      L(['gggg', 'ggggg', 'GGGG', 'GGGGG'], function (a, b, c, d) {
        b[d.substr(0, 2)] = r(a);
      }),
      L(['gg', 'GG'], function (b, c, d, e) {
        c[e] = a.parseTwoDigitYear(b);
      }),
      E('Q', 0, 'Qo', 'quarter'),
      z('quarter', 'Q'),
      C('quarter', 7),
      H('Q', lb),
      K('Q', function (a, b) {
        b[Eb] = 3 * (r(a) - 1);
      }),
      E('D', ['DD', 2], 'Do', 'date'),
      z('date', 'D'),
      C('date', 9),
      H('D', qb),
      H('DD', qb, mb),
      H('Do', function (a, b) {
        return a
          ? b._dayOfMonthOrdinalParse || b._ordinalParse
          : b._dayOfMonthOrdinalParseLenient;
      }),
      K(['D', 'DD'], Fb),
      K('Do', function (a, b) {
        b[Fb] = r(a.match(qb)[0]);
      });
    var uc = O('Date', !0);
    E('DDD', ['DDDD', 3], 'DDDo', 'dayOfYear'),
      z('dayOfYear', 'DDD'),
      C('dayOfYear', 4),
      H('DDD', tb),
      H('DDDD', nb),
      K(['DDD', 'DDDD'], function (a, b, c) {
        c._dayOfYear = r(a);
      }),
      E('m', ['mm', 2], 0, 'minute'),
      z('minute', 'm'),
      C('minute', 14),
      H('m', qb),
      H('mm', qb, mb),
      K(['m', 'mm'], Hb);
    var vc = O('Minutes', !1);
    E('s', ['ss', 2], 0, 'second'),
      z('second', 's'),
      C('second', 15),
      H('s', qb),
      H('ss', qb, mb),
      K(['s', 'ss'], Ib);
    var wc,
      xc = O('Seconds', !1);
    for (
      E('S', 0, 0, function () {
        return ~~(this.millisecond() / 100);
      }),
        E(0, ['SS', 2], 0, function () {
          return ~~(this.millisecond() / 10);
        }),
        E(0, ['SSS', 3], 0, 'millisecond'),
        E(0, ['SSSS', 4], 0, function () {
          return 10 * this.millisecond();
        }),
        E(0, ['SSSSS', 5], 0, function () {
          return 100 * this.millisecond();
        }),
        E(0, ['SSSSSS', 6], 0, function () {
          return 1e3 * this.millisecond();
        }),
        E(0, ['SSSSSSS', 7], 0, function () {
          return 1e4 * this.millisecond();
        }),
        E(0, ['SSSSSSSS', 8], 0, function () {
          return 1e5 * this.millisecond();
        }),
        E(0, ['SSSSSSSSS', 9], 0, function () {
          return 1e6 * this.millisecond();
        }),
        z('millisecond', 'ms'),
        C('millisecond', 16),
        H('S', tb, lb),
        H('SS', tb, mb),
        H('SSS', tb, nb),
        wc = 'SSSS';
      wc.length <= 9;
      wc += 'S'
    )
      H(wc, wb);
    for (wc = 'S'; wc.length <= 9; wc += 'S') K(wc, Oa);
    var yc = O('Milliseconds', !1);
    E('z', 0, 0, 'zoneAbbr'), E('zz', 0, 0, 'zoneName');
    var zc = o.prototype;
    (zc.add = qc),
      (zc.calendar = function (b, c) {
        var d = b || ra(),
          e = ya(d, this).startOf('day'),
          f = a.calendarFormat(this, e) || 'sameElse',
          g = c && (w(c[f]) ? c[f].call(this, d) : c[f]);
        return this.format(g || this.localeData().calendar(f, this, ra(d)));
      }),
      (zc.clone = function () {
        return new o(this);
      }),
      (zc.diff = function (a, b, c) {
        var d, e, f;
        if (!this.isValid()) return NaN;
        if (!(d = ya(a, this)).isValid()) return NaN;
        switch (((e = 6e4 * (d.utcOffset() - this.utcOffset())), (b = A(b)))) {
          case 'year':
            f = Ga(this, d) / 12;
            break;
          case 'month':
            f = Ga(this, d);
            break;
          case 'quarter':
            f = Ga(this, d) / 3;
            break;
          case 'second':
            f = (this - d) / 1e3;
            break;
          case 'minute':
            f = (this - d) / 6e4;
            break;
          case 'hour':
            f = (this - d) / 36e5;
            break;
          case 'day':
            f = (this - d - e) / 864e5;
            break;
          case 'week':
            f = (this - d - e) / 6048e5;
            break;
          default:
            f = this - d;
        }
        return c ? f : q(f);
      }),
      (zc.endOf = function (b) {
        var c;
        if (void 0 === (b = A(b)) || 'millisecond' === b || !this.isValid())
          return this;
        var d = this._isUTC ? La : Ka;
        switch (b) {
          case 'year':
            c = d(this.year() + 1, 0, 1) - 1;
            break;
          case 'quarter':
            c = d(this.year(), this.month() - (this.month() % 3) + 3, 1) - 1;
            break;
          case 'month':
            c = d(this.year(), this.month() + 1, 1) - 1;
            break;
          case 'week':
            c =
              d(this.year(), this.month(), this.date() - this.weekday() + 7) -
              1;
            break;
          case 'isoWeek':
            c =
              d(
                this.year(),
                this.month(),
                this.date() - (this.isoWeekday() - 1) + 7
              ) - 1;
            break;
          case 'day':
          case 'date':
            c = d(this.year(), this.month(), this.date() + 1) - 1;
            break;
          case 'hour':
            (c = this._d.valueOf()),
              (c +=
                36e5 -
                Ja(c + (this._isUTC ? 0 : 6e4 * this.utcOffset()), 36e5) -
                1);
            break;
          case 'minute':
            (c = this._d.valueOf()), (c += 6e4 - Ja(c, 6e4) - 1);
            break;
          case 'second':
            (c = this._d.valueOf()), (c += 1e3 - Ja(c, 1e3) - 1);
        }
        return this._d.setTime(c), a.updateOffset(this, !0), this;
      }),
      (zc.format = function (b) {
        b || (b = this.isUtc() ? a.defaultFormatUtc : a.defaultFormat);
        var c = F(this, b);
        return this.localeData().postformat(c);
      }),
      (zc.from = function (a, b) {
        return this.isValid() && ((p(a) && a.isValid()) || ra(a).isValid())
          ? Ba({ to: this, from: a }).locale(this.locale()).humanize(!b)
          : this.localeData().invalidDate();
      }),
      (zc.fromNow = function (a) {
        return this.from(ra(), a);
      }),
      (zc.to = function (a, b) {
        return this.isValid() && ((p(a) && a.isValid()) || ra(a).isValid())
          ? Ba({ from: this, to: a }).locale(this.locale()).humanize(!b)
          : this.localeData().invalidDate();
      }),
      (zc.toNow = function (a) {
        return this.to(ra(), a);
      }),
      (zc.get = function (a) {
        return w(this[(a = A(a))]) ? this[a]() : this;
      }),
      (zc.invalidAt = function () {
        return k(this).overflow;
      }),
      (zc.isAfter = function (a, b) {
        var c = p(a) ? a : ra(a);
        return (
          !(!this.isValid() || !c.isValid()) &&
          ('millisecond' === (b = A(b) || 'millisecond')
            ? this.valueOf() > c.valueOf()
            : c.valueOf() < this.clone().startOf(b).valueOf())
        );
      }),
      (zc.isBefore = function (a, b) {
        var c = p(a) ? a : ra(a);
        return (
          !(!this.isValid() || !c.isValid()) &&
          ('millisecond' === (b = A(b) || 'millisecond')
            ? this.valueOf() < c.valueOf()
            : this.clone().endOf(b).valueOf() < c.valueOf())
        );
      }),
      (zc.isBetween = function (a, b, c, d) {
        var e = p(a) ? a : ra(a),
          f = p(b) ? b : ra(b);
        return (
          !!(this.isValid() && e.isValid() && f.isValid()) &&
          ('(' === (d = d || '()')[0]
            ? this.isAfter(e, c)
            : !this.isBefore(e, c)) &&
          (')' === d[1] ? this.isBefore(f, c) : !this.isAfter(f, c))
        );
      }),
      (zc.isSame = function (a, b) {
        var c,
          d = p(a) ? a : ra(a);
        return (
          !(!this.isValid() || !d.isValid()) &&
          ('millisecond' === (b = A(b) || 'millisecond')
            ? this.valueOf() === d.valueOf()
            : ((c = d.valueOf()),
              this.clone().startOf(b).valueOf() <= c &&
                c <= this.clone().endOf(b).valueOf()))
        );
      }),
      (zc.isSameOrAfter = function (a, b) {
        return this.isSame(a, b) || this.isAfter(a, b);
      }),
      (zc.isSameOrBefore = function (a, b) {
        return this.isSame(a, b) || this.isBefore(a, b);
      }),
      (zc.isValid = function () {
        return l(this);
      }),
      (zc.lang = sc),
      (zc.locale = Ha),
      (zc.localeData = Ia),
      (zc.max = lc),
      (zc.min = kc),
      (zc.parsingFlags = function () {
        return i({}, k(this));
      }),
      (zc.set = function (a, b) {
        if ('object' == typeof a)
          for (
            var c = (function (a) {
                var b = [];
                for (var c in a) b.push({ unit: c, priority: gb[c] });
                return (
                  b.sort(function (a, b) {
                    return a.priority - b.priority;
                  }),
                  b
                );
              })((a = B(a))),
              d = 0;
            d < c.length;
            d++
          )
            this[c[d].unit](a[c[d].unit]);
        else if (w(this[(a = A(a))])) return this[a](b);
        return this;
      }),
      (zc.startOf = function (b) {
        var c;
        if (void 0 === (b = A(b)) || 'millisecond' === b || !this.isValid())
          return this;
        var d = this._isUTC ? La : Ka;
        switch (b) {
          case 'year':
            c = d(this.year(), 0, 1);
            break;
          case 'quarter':
            c = d(this.year(), this.month() - (this.month() % 3), 1);
            break;
          case 'month':
            c = d(this.year(), this.month(), 1);
            break;
          case 'week':
            c = d(this.year(), this.month(), this.date() - this.weekday());
            break;
          case 'isoWeek':
            c = d(
              this.year(),
              this.month(),
              this.date() - (this.isoWeekday() - 1)
            );
            break;
          case 'day':
          case 'date':
            c = d(this.year(), this.month(), this.date());
            break;
          case 'hour':
            (c = this._d.valueOf()),
              (c -= Ja(c + (this._isUTC ? 0 : 6e4 * this.utcOffset()), 36e5));
            break;
          case 'minute':
            (c = this._d.valueOf()), (c -= Ja(c, 6e4));
            break;
          case 'second':
            (c = this._d.valueOf()), (c -= Ja(c, 1e3));
        }
        return this._d.setTime(c), a.updateOffset(this, !0), this;
      }),
      (zc.subtract = rc),
      (zc.toArray = function () {
        var a = this;
        return [
          a.year(),
          a.month(),
          a.date(),
          a.hour(),
          a.minute(),
          a.second(),
          a.millisecond()
        ];
      }),
      (zc.toObject = function () {
        var a = this;
        return {
          years: a.year(),
          months: a.month(),
          date: a.date(),
          hours: a.hours(),
          minutes: a.minutes(),
          seconds: a.seconds(),
          milliseconds: a.milliseconds()
        };
      }),
      (zc.toDate = function () {
        return new Date(this.valueOf());
      }),
      (zc.toISOString = function (a) {
        if (!this.isValid()) return null;
        var b = !0 !== a,
          c = b ? this.clone().utc() : this;
        return c.year() < 0 || 9999 < c.year()
          ? F(
              c,
              b
                ? 'YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
                : 'YYYYYY-MM-DD[T]HH:mm:ss.SSSZ'
            )
          : w(Date.prototype.toISOString)
            ? b
              ? this.toDate().toISOString()
              : new Date(this.valueOf() + 60 * this.utcOffset() * 1e3)
                  .toISOString()
                  .replace('Z', F(c, 'Z'))
            : F(
                c,
                b
                  ? 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
                  : 'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
              );
      }),
      (zc.inspect = function () {
        if (!this.isValid()) return 'moment.invalid(/* ' + this._i + ' */)';
        var a = 'moment',
          b = '';
        this.isLocal() ||
          ((a = 0 === this.utcOffset() ? 'moment.utc' : 'moment.parseZone'),
          (b = 'Z'));
        var c = '[' + a + '("]',
          d = 0 <= this.year() && this.year() <= 9999 ? 'YYYY' : 'YYYYYY',
          e = b + '[")]';
        return this.format(c + d + '-MM-DD[T]HH:mm:ss.SSS' + e);
      }),
      (zc.toJSON = function () {
        return this.isValid() ? this.toISOString() : null;
      }),
      (zc.toString = function () {
        return this.clone()
          .locale('en')
          .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
      }),
      (zc.unix = function () {
        return Math.floor(this.valueOf() / 1e3);
      }),
      (zc.valueOf = function () {
        return this._d.valueOf() - 6e4 * (this._offset || 0);
      }),
      (zc.creationData = function () {
        return {
          input: this._i,
          format: this._f,
          locale: this._locale,
          isUTC: this._isUTC,
          strict: this._strict
        };
      }),
      (zc.year = Nb),
      (zc.isLeapYear = function () {
        return N(this.year());
      }),
      (zc.weekYear = function (a) {
        return Na.call(
          this,
          a,
          this.week(),
          this.weekday(),
          this.localeData()._week.dow,
          this.localeData()._week.doy
        );
      }),
      (zc.isoWeekYear = function (a) {
        return Na.call(this, a, this.isoWeek(), this.isoWeekday(), 1, 4);
      }),
      (zc.quarter = zc.quarters =
        function (a) {
          return null == a
            ? Math.ceil((this.month() + 1) / 3)
            : this.month(3 * (a - 1) + (this.month() % 3));
        }),
      (zc.month = T),
      (zc.daysInMonth = function () {
        return R(this.year(), this.month());
      }),
      (zc.week = zc.weeks =
        function (a) {
          var b = this.localeData().week(this);
          return null == a ? b : this.add(7 * (a - b), 'd');
        }),
      (zc.isoWeek = zc.isoWeeks =
        function (a) {
          var b = Y(this, 1, 4).week;
          return null == a ? b : this.add(7 * (a - b), 'd');
        }),
      (zc.weeksInYear = function () {
        var a = this.localeData()._week;
        return Z(this.year(), a.dow, a.doy);
      }),
      (zc.isoWeeksInYear = function () {
        return Z(this.year(), 1, 4);
      }),
      (zc.date = uc),
      (zc.day = zc.days =
        function (a) {
          if (!this.isValid()) return null != a ? this : NaN;
          var b,
            c,
            d = this._isUTC ? this._d.getUTCDay() : this._d.getDay();
          return null != a
            ? ((b = a),
              (c = this.localeData()),
              (a =
                'string' != typeof b
                  ? b
                  : isNaN(b)
                    ? 'number' == typeof (b = c.weekdaysParse(b))
                      ? b
                      : null
                    : parseInt(b, 10)),
              this.add(a - d, 'd'))
            : d;
        }),
      (zc.weekday = function (a) {
        if (!this.isValid()) return null != a ? this : NaN;
        var b = (this.day() + 7 - this.localeData()._week.dow) % 7;
        return null == a ? b : this.add(a - b, 'd');
      }),
      (zc.isoWeekday = function (a) {
        if (!this.isValid()) return null != a ? this : NaN;
        if (null == a) return this.day() || 7;
        var b,
          c,
          d =
            ((b = a),
            (c = this.localeData()),
            'string' == typeof b
              ? c.weekdaysParse(b) % 7 || 7
              : isNaN(b)
                ? null
                : b);
        return this.day(this.day() % 7 ? d : d - 7);
      }),
      (zc.dayOfYear = function (a) {
        var b =
          Math.round(
            (this.clone().startOf('day') - this.clone().startOf('year')) / 864e5
          ) + 1;
        return null == a ? b : this.add(a - b, 'd');
      }),
      (zc.hour = zc.hours = $b),
      (zc.minute = zc.minutes = vc),
      (zc.second = zc.seconds = xc),
      (zc.millisecond = zc.milliseconds = yc),
      (zc.utcOffset = function (b, c, d) {
        var e,
          f = this._offset || 0;
        if (!this.isValid()) return null != b ? this : NaN;
        if (null == b) return this._isUTC ? f : za(this);
        if ('string' == typeof b) {
          if (null === (b = xa(zb, b))) return this;
        } else Math.abs(b) < 16 && !d && (b *= 60);
        return (
          !this._isUTC && c && (e = za(this)),
          (this._offset = b),
          (this._isUTC = !0),
          null != e && this.add(e, 'm'),
          f !== b &&
            (!c || this._changeInProgress
              ? Fa(this, Ba(b - f, 'm'), 1, !1)
              : this._changeInProgress ||
                ((this._changeInProgress = !0),
                a.updateOffset(this, !0),
                (this._changeInProgress = null))),
          this
        );
      }),
      (zc.utc = function (a) {
        return this.utcOffset(0, a);
      }),
      (zc.local = function (a) {
        return (
          this._isUTC &&
            (this.utcOffset(0, a),
            (this._isUTC = !1),
            a && this.subtract(za(this), 'm')),
          this
        );
      }),
      (zc.parseZone = function () {
        if (null != this._tzm) this.utcOffset(this._tzm, !1, !0);
        else if ('string' == typeof this._i) {
          var a = xa(yb, this._i);
          null != a ? this.utcOffset(a) : this.utcOffset(0, !0);
        }
        return this;
      }),
      (zc.hasAlignedHourOffset = function (a) {
        return (
          !!this.isValid() &&
          ((a = a ? ra(a).utcOffset() : 0), (this.utcOffset() - a) % 60 == 0)
        );
      }),
      (zc.isDST = function () {
        return (
          this.utcOffset() > this.clone().month(0).utcOffset() ||
          this.utcOffset() > this.clone().month(5).utcOffset()
        );
      }),
      (zc.isLocal = function () {
        return !!this.isValid() && !this._isUTC;
      }),
      (zc.isUtcOffset = function () {
        return !!this.isValid() && this._isUTC;
      }),
      (zc.isUtc = Aa),
      (zc.isUTC = Aa),
      (zc.zoneAbbr = function () {
        return this._isUTC ? 'UTC' : '';
      }),
      (zc.zoneName = function () {
        return this._isUTC ? 'Coordinated Universal Time' : '';
      }),
      (zc.dates = u('dates accessor is deprecated. Use date instead.', uc)),
      (zc.months = u('months accessor is deprecated. Use month instead', T)),
      (zc.years = u('years accessor is deprecated. Use year instead', Nb)),
      (zc.zone = u(
        'moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/',
        function (a, b) {
          return null != a
            ? ('string' != typeof a && (a = -a), this.utcOffset(a, b), this)
            : -this.utcOffset();
        }
      )),
      (zc.isDSTShifted = u(
        'isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information',
        function () {
          if (!d(this._isDSTShifted)) return this._isDSTShifted;
          var a = {};
          if ((n(a, this), (a = pa(a))._a)) {
            var b = a._isUTC ? j(a._a) : ra(a._a);
            this._isDSTShifted = this.isValid() && 0 < s(a._a, b.toArray());
          } else this._isDSTShifted = !1;
          return this._isDSTShifted;
        }
      ));
    var Ac = y.prototype;
    (Ac.calendar = function (a, b, c) {
      var d = this._calendar[a] || this._calendar.sameElse;
      return w(d) ? d.call(b, c) : d;
    }),
      (Ac.longDateFormat = function (a) {
        var b = this._longDateFormat[a],
          c = this._longDateFormat[a.toUpperCase()];
        return b || !c
          ? b
          : ((this._longDateFormat[a] = c.replace(
              /MMMM|MM|DD|dddd/g,
              function (a) {
                return a.slice(1);
              }
            )),
            this._longDateFormat[a]);
      }),
      (Ac.invalidDate = function () {
        return this._invalidDate;
      }),
      (Ac.ordinal = function (a) {
        return this._ordinal.replace('%d', a);
      }),
      (Ac.preparse = Pa),
      (Ac.postformat = Pa),
      (Ac.relativeTime = function (a, b, c, d) {
        var e = this._relativeTime[c];
        return w(e) ? e(a, b, c, d) : e.replace(/%d/i, a);
      }),
      (Ac.pastFuture = function (a, b) {
        var c = this._relativeTime[0 < a ? 'future' : 'past'];
        return w(c) ? c(b) : c.replace(/%s/i, b);
      }),
      (Ac.set = function (a) {
        var b, c;
        for (c in a) w((b = a[c])) ? (this[c] = b) : (this['_' + c] = b);
        (this._config = a),
          (this._dayOfMonthOrdinalParseLenient = new RegExp(
            (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) +
              '|' +
              /\d{1,2}/.source
          ));
      }),
      (Ac.months = function (a, c) {
        return a
          ? b(this._months)
            ? this._months[a.month()]
            : this._months[
                (this._months.isFormat || Ob).test(c) ? 'format' : 'standalone'
              ][a.month()]
          : b(this._months)
            ? this._months
            : this._months.standalone;
      }),
      (Ac.monthsShort = function (a, c) {
        return a
          ? b(this._monthsShort)
            ? this._monthsShort[a.month()]
            : this._monthsShort[Ob.test(c) ? 'format' : 'standalone'][a.month()]
          : b(this._monthsShort)
            ? this._monthsShort
            : this._monthsShort.standalone;
      }),
      (Ac.monthsParse = function (a, b, c) {
        var d, e, f;
        if (this._monthsParseExact)
          return function (a, b, c) {
            var d,
              e,
              f,
              g = a.toLocaleLowerCase();
            if (!this._monthsParse)
              for (
                this._monthsParse = [],
                  this._longMonthsParse = [],
                  this._shortMonthsParse = [],
                  d = 0;
                d < 12;
                ++d
              )
                (f = j([2e3, d])),
                  (this._shortMonthsParse[d] = this.monthsShort(
                    f,
                    ''
                  ).toLocaleLowerCase()),
                  (this._longMonthsParse[d] = this.months(
                    f,
                    ''
                  ).toLocaleLowerCase());
            return c
              ? 'MMM' === b
                ? -1 !== (e = Mb.call(this._shortMonthsParse, g))
                  ? e
                  : null
                : -1 !== (e = Mb.call(this._longMonthsParse, g))
                  ? e
                  : null
              : 'MMM' === b
                ? -1 !== (e = Mb.call(this._shortMonthsParse, g))
                  ? e
                  : -1 !== (e = Mb.call(this._longMonthsParse, g))
                    ? e
                    : null
                : -1 !== (e = Mb.call(this._longMonthsParse, g))
                  ? e
                  : -1 !== (e = Mb.call(this._shortMonthsParse, g))
                    ? e
                    : null;
          }.call(this, a, b, c);
        for (
          this._monthsParse ||
            ((this._monthsParse = []),
            (this._longMonthsParse = []),
            (this._shortMonthsParse = [])),
            d = 0;
          d < 12;
          d++
        ) {
          if (
            ((e = j([2e3, d])),
            c &&
              !this._longMonthsParse[d] &&
              ((this._longMonthsParse[d] = new RegExp(
                '^' + this.months(e, '').replace('.', '') + '$',
                'i'
              )),
              (this._shortMonthsParse[d] = new RegExp(
                '^' + this.monthsShort(e, '').replace('.', '') + '$',
                'i'
              ))),
            c ||
              this._monthsParse[d] ||
              ((f = '^' + this.months(e, '') + '|^' + this.monthsShort(e, '')),
              (this._monthsParse[d] = new RegExp(f.replace('.', ''), 'i'))),
            c && 'MMMM' === b && this._longMonthsParse[d].test(a))
          )
            return d;
          if (c && 'MMM' === b && this._shortMonthsParse[d].test(a)) return d;
          if (!c && this._monthsParse[d].test(a)) return d;
        }
      }),
      (Ac.monthsRegex = function (a) {
        return this._monthsParseExact
          ? (h(this, '_monthsRegex') || U.call(this),
            a ? this._monthsStrictRegex : this._monthsRegex)
          : (h(this, '_monthsRegex') || (this._monthsRegex = Sb),
            this._monthsStrictRegex && a
              ? this._monthsStrictRegex
              : this._monthsRegex);
      }),
      (Ac.monthsShortRegex = function (a) {
        return this._monthsParseExact
          ? (h(this, '_monthsRegex') || U.call(this),
            a ? this._monthsShortStrictRegex : this._monthsShortRegex)
          : (h(this, '_monthsShortRegex') || (this._monthsShortRegex = Rb),
            this._monthsShortStrictRegex && a
              ? this._monthsShortStrictRegex
              : this._monthsShortRegex);
      }),
      (Ac.week = function (a) {
        return Y(a, this._week.dow, this._week.doy).week;
      }),
      (Ac.firstDayOfYear = function () {
        return this._week.doy;
      }),
      (Ac.firstDayOfWeek = function () {
        return this._week.dow;
      }),
      (Ac.weekdays = function (a, c) {
        var d = b(this._weekdays)
          ? this._weekdays
          : this._weekdays[
              a && !0 !== a && this._weekdays.isFormat.test(c)
                ? 'format'
                : 'standalone'
            ];
        return !0 === a ? $(d, this._week.dow) : a ? d[a.day()] : d;
      }),
      (Ac.weekdaysMin = function (a) {
        return !0 === a
          ? $(this._weekdaysMin, this._week.dow)
          : a
            ? this._weekdaysMin[a.day()]
            : this._weekdaysMin;
      }),
      (Ac.weekdaysShort = function (a) {
        return !0 === a
          ? $(this._weekdaysShort, this._week.dow)
          : a
            ? this._weekdaysShort[a.day()]
            : this._weekdaysShort;
      }),
      (Ac.weekdaysParse = function (a, b, c) {
        var d, e, f;
        if (this._weekdaysParseExact)
          return function (a, b, c) {
            var d,
              e,
              f,
              g = a.toLocaleLowerCase();
            if (!this._weekdaysParse)
              for (
                this._weekdaysParse = [],
                  this._shortWeekdaysParse = [],
                  this._minWeekdaysParse = [],
                  d = 0;
                d < 7;
                ++d
              )
                (f = j([2e3, 1]).day(d)),
                  (this._minWeekdaysParse[d] = this.weekdaysMin(
                    f,
                    ''
                  ).toLocaleLowerCase()),
                  (this._shortWeekdaysParse[d] = this.weekdaysShort(
                    f,
                    ''
                  ).toLocaleLowerCase()),
                  (this._weekdaysParse[d] = this.weekdays(
                    f,
                    ''
                  ).toLocaleLowerCase());
            return c
              ? 'dddd' === b
                ? -1 !== (e = Mb.call(this._weekdaysParse, g))
                  ? e
                  : null
                : 'ddd' === b
                  ? -1 !== (e = Mb.call(this._shortWeekdaysParse, g))
                    ? e
                    : null
                  : -1 !== (e = Mb.call(this._minWeekdaysParse, g))
                    ? e
                    : null
              : 'dddd' === b
                ? -1 !== (e = Mb.call(this._weekdaysParse, g))
                  ? e
                  : -1 !== (e = Mb.call(this._shortWeekdaysParse, g))
                    ? e
                    : -1 !== (e = Mb.call(this._minWeekdaysParse, g))
                      ? e
                      : null
                : 'ddd' === b
                  ? -1 !== (e = Mb.call(this._shortWeekdaysParse, g))
                    ? e
                    : -1 !== (e = Mb.call(this._weekdaysParse, g))
                      ? e
                      : -1 !== (e = Mb.call(this._minWeekdaysParse, g))
                        ? e
                        : null
                  : -1 !== (e = Mb.call(this._minWeekdaysParse, g))
                    ? e
                    : -1 !== (e = Mb.call(this._weekdaysParse, g))
                      ? e
                      : -1 !== (e = Mb.call(this._shortWeekdaysParse, g))
                        ? e
                        : null;
          }.call(this, a, b, c);
        for (
          this._weekdaysParse ||
            ((this._weekdaysParse = []),
            (this._minWeekdaysParse = []),
            (this._shortWeekdaysParse = []),
            (this._fullWeekdaysParse = [])),
            d = 0;
          d < 7;
          d++
        ) {
          if (
            ((e = j([2e3, 1]).day(d)),
            c &&
              !this._fullWeekdaysParse[d] &&
              ((this._fullWeekdaysParse[d] = new RegExp(
                '^' + this.weekdays(e, '').replace('.', '\\.?') + '$',
                'i'
              )),
              (this._shortWeekdaysParse[d] = new RegExp(
                '^' + this.weekdaysShort(e, '').replace('.', '\\.?') + '$',
                'i'
              )),
              (this._minWeekdaysParse[d] = new RegExp(
                '^' + this.weekdaysMin(e, '').replace('.', '\\.?') + '$',
                'i'
              ))),
            this._weekdaysParse[d] ||
              ((f =
                '^' +
                this.weekdays(e, '') +
                '|^' +
                this.weekdaysShort(e, '') +
                '|^' +
                this.weekdaysMin(e, '')),
              (this._weekdaysParse[d] = new RegExp(f.replace('.', ''), 'i'))),
            c && 'dddd' === b && this._fullWeekdaysParse[d].test(a))
          )
            return d;
          if (c && 'ddd' === b && this._shortWeekdaysParse[d].test(a)) return d;
          if (c && 'dd' === b && this._minWeekdaysParse[d].test(a)) return d;
          if (!c && this._weekdaysParse[d].test(a)) return d;
        }
      }),
      (Ac.weekdaysRegex = function (a) {
        return this._weekdaysParseExact
          ? (h(this, '_weekdaysRegex') || _.call(this),
            a ? this._weekdaysStrictRegex : this._weekdaysRegex)
          : (h(this, '_weekdaysRegex') || (this._weekdaysRegex = Wb),
            this._weekdaysStrictRegex && a
              ? this._weekdaysStrictRegex
              : this._weekdaysRegex);
      }),
      (Ac.weekdaysShortRegex = function (a) {
        return this._weekdaysParseExact
          ? (h(this, '_weekdaysRegex') || _.call(this),
            a ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex)
          : (h(this, '_weekdaysShortRegex') || (this._weekdaysShortRegex = Xb),
            this._weekdaysShortStrictRegex && a
              ? this._weekdaysShortStrictRegex
              : this._weekdaysShortRegex);
      }),
      (Ac.weekdaysMinRegex = function (a) {
        return this._weekdaysParseExact
          ? (h(this, '_weekdaysRegex') || _.call(this),
            a ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex)
          : (h(this, '_weekdaysMinRegex') || (this._weekdaysMinRegex = Yb),
            this._weekdaysMinStrictRegex && a
              ? this._weekdaysMinStrictRegex
              : this._weekdaysMinRegex);
      }),
      (Ac.isPM = function (a) {
        return 'p' === (a + '').toLowerCase().charAt(0);
      }),
      (Ac.meridiem = function (a, b, c) {
        return 11 < a ? (c ? 'pm' : 'PM') : c ? 'am' : 'AM';
      }),
      fa('en', {
        dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
        ordinal: function (a) {
          var b = a % 10;
          return (
            a +
            (1 === r((a % 100) / 10)
              ? 'th'
              : 1 === b
                ? 'st'
                : 2 === b
                  ? 'nd'
                  : 3 === b
                    ? 'rd'
                    : 'th')
          );
        }
      }),
      (a.lang = u('moment.lang is deprecated. Use moment.locale instead.', fa)),
      (a.langData = u(
        'moment.langData is deprecated. Use moment.localeData instead.',
        ha
      ));
    var Bc = Math.abs,
      Cc = Xa('ms'),
      Dc = Xa('s'),
      Ec = Xa('m'),
      Fc = Xa('h'),
      Gc = Xa('d'),
      Hc = Xa('w'),
      Ic = Xa('M'),
      Jc = Xa('Q'),
      Kc = Xa('y'),
      Lc = Ya('milliseconds'),
      Mc = Ya('seconds'),
      Nc = Ya('minutes'),
      Oc = Ya('hours'),
      Pc = Ya('days'),
      Qc = Ya('months'),
      Rc = Ya('years'),
      Sc = Math.round,
      Tc = { ss: 44, s: 45, m: 45, h: 22, d: 26, M: 11 },
      Uc = Math.abs,
      Vc = ta.prototype;
    return (
      (Vc.isValid = function () {
        return this._isValid;
      }),
      (Vc.abs = function () {
        var a = this._data;
        return (
          (this._milliseconds = Bc(this._milliseconds)),
          (this._days = Bc(this._days)),
          (this._months = Bc(this._months)),
          (a.milliseconds = Bc(a.milliseconds)),
          (a.seconds = Bc(a.seconds)),
          (a.minutes = Bc(a.minutes)),
          (a.hours = Bc(a.hours)),
          (a.months = Bc(a.months)),
          (a.years = Bc(a.years)),
          this
        );
      }),
      (Vc.add = function (a, b) {
        return Ta(this, a, b, 1);
      }),
      (Vc.subtract = function (a, b) {
        return Ta(this, a, b, -1);
      }),
      (Vc.as = function (a) {
        if (!this.isValid()) return NaN;
        var b,
          c,
          d = this._milliseconds;
        if ('month' === (a = A(a)) || 'quarter' === a || 'year' === a)
          switch (
            ((b = this._days + d / 864e5), (c = this._months + Va(b)), a)
          ) {
            case 'month':
              return c;
            case 'quarter':
              return c / 3;
            case 'year':
              return c / 12;
          }
        else
          switch (((b = this._days + Math.round(Wa(this._months))), a)) {
            case 'week':
              return b / 7 + d / 6048e5;
            case 'day':
              return b + d / 864e5;
            case 'hour':
              return 24 * b + d / 36e5;
            case 'minute':
              return 1440 * b + d / 6e4;
            case 'second':
              return 86400 * b + d / 1e3;
            case 'millisecond':
              return Math.floor(864e5 * b) + d;
            default:
              throw new Error('Unknown unit ' + a);
          }
      }),
      (Vc.asMilliseconds = Cc),
      (Vc.asSeconds = Dc),
      (Vc.asMinutes = Ec),
      (Vc.asHours = Fc),
      (Vc.asDays = Gc),
      (Vc.asWeeks = Hc),
      (Vc.asMonths = Ic),
      (Vc.asQuarters = Jc),
      (Vc.asYears = Kc),
      (Vc.valueOf = function () {
        return this.isValid()
          ? this._milliseconds +
              864e5 * this._days +
              (this._months % 12) * 2592e6 +
              31536e6 * r(this._months / 12)
          : NaN;
      }),
      (Vc._bubble = function () {
        var a,
          b,
          c,
          d,
          e,
          f = this._milliseconds,
          g = this._days,
          h = this._months,
          i = this._data;
        return (
          (0 <= f && 0 <= g && 0 <= h) ||
            (f <= 0 && g <= 0 && h <= 0) ||
            ((f += 864e5 * Ua(Wa(h) + g)), (h = g = 0)),
          (i.milliseconds = f % 1e3),
          (a = q(f / 1e3)),
          (i.seconds = a % 60),
          (b = q(a / 60)),
          (i.minutes = b % 60),
          (c = q(b / 60)),
          (i.hours = c % 24),
          (h += e = q(Va((g += q(c / 24))))),
          (g -= Ua(Wa(e))),
          (d = q(h / 12)),
          (h %= 12),
          (i.days = g),
          (i.months = h),
          (i.years = d),
          this
        );
      }),
      (Vc.clone = function () {
        return Ba(this);
      }),
      (Vc.get = function (a) {
        return (a = A(a)), this.isValid() ? this[a + 's']() : NaN;
      }),
      (Vc.milliseconds = Lc),
      (Vc.seconds = Mc),
      (Vc.minutes = Nc),
      (Vc.hours = Oc),
      (Vc.days = Pc),
      (Vc.weeks = function () {
        return q(this.days() / 7);
      }),
      (Vc.months = Qc),
      (Vc.years = Rc),
      (Vc.humanize = function (a) {
        if (!this.isValid()) return this.localeData().invalidDate();
        var b,
          c,
          d,
          e,
          f,
          g,
          h,
          i,
          j,
          k,
          l,
          m = this.localeData(),
          n =
            ((c = !a),
            (d = m),
            (e = Ba((b = this)).abs()),
            (f = Sc(e.as('s'))),
            (g = Sc(e.as('m'))),
            (h = Sc(e.as('h'))),
            (i = Sc(e.as('d'))),
            (j = Sc(e.as('M'))),
            (k = Sc(e.as('y'))),
            ((l = (f <= Tc.ss && ['s', f]) ||
              (f < Tc.s && ['ss', f]) ||
              (g <= 1 && ['m']) ||
              (g < Tc.m && ['mm', g]) ||
              (h <= 1 && ['h']) ||
              (h < Tc.h && ['hh', h]) ||
              (i <= 1 && ['d']) ||
              (i < Tc.d && ['dd', i]) ||
              (j <= 1 && ['M']) ||
              (j < Tc.M && ['MM', j]) ||
              (k <= 1 && ['y']) || ['yy', k])[2] = c),
            (l[3] = 0 < +b),
            (l[4] = d),
            function (a, b, c, d, e) {
              return e.relativeTime(b || 1, !!c, a, d);
            }.apply(null, l));
        return a && (n = m.pastFuture(+this, n)), m.postformat(n);
      }),
      (Vc.toISOString = $a),
      (Vc.toString = $a),
      (Vc.toJSON = $a),
      (Vc.locale = Ha),
      (Vc.localeData = Ia),
      (Vc.toIsoString = u(
        'toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)',
        $a
      )),
      (Vc.lang = sc),
      E('X', 0, 0, 'unix'),
      E('x', 0, 0, 'valueOf'),
      H('x', xb),
      H('X', /[+-]?\d+(\.\d{1,3})?/),
      K('X', function (a, b, c) {
        c._d = new Date(1e3 * parseFloat(a, 10));
      }),
      K('x', function (a, b, c) {
        c._d = new Date(r(a));
      }),
      (a.version = '2.24.0'),
      (_a = ra),
      (a.fn = zc),
      (a.min = function () {
        return sa('isBefore', [].slice.call(arguments, 0));
      }),
      (a.max = function () {
        return sa('isAfter', [].slice.call(arguments, 0));
      }),
      (a.now = function () {
        return Date.now ? Date.now() : +new Date();
      }),
      (a.utc = j),
      (a.unix = function (a) {
        return ra(1e3 * a);
      }),
      (a.months = function (a, b) {
        return Ra(a, b, 'months');
      }),
      (a.isDate = f),
      (a.locale = fa),
      (a.invalid = m),
      (a.duration = Ba),
      (a.isMoment = p),
      (a.weekdays = function (a, b, c) {
        return Sa(a, b, c, 'weekdays');
      }),
      (a.parseZone = function () {
        return ra.apply(null, arguments).parseZone();
      }),
      (a.localeData = ha),
      (a.isDuration = ua),
      (a.monthsShort = function (a, b) {
        return Ra(a, b, 'monthsShort');
      }),
      (a.weekdaysMin = function (a, b, c) {
        return Sa(a, b, c, 'weekdaysMin');
      }),
      (a.defineLocale = ga),
      (a.updateLocale = function (a, b) {
        if (null != b) {
          var c,
            d,
            e = _b;
          null != (d = ea(a)) && (e = d._config),
            ((c = new y((b = x(e, b)))).parentLocale = ac[a]),
            (ac[a] = c),
            fa(a);
        } else
          null != ac[a] &&
            (null != ac[a].parentLocale
              ? (ac[a] = ac[a].parentLocale)
              : null != ac[a] && delete ac[a]);
        return ac[a];
      }),
      (a.locales = function () {
        return bb(ac);
      }),
      (a.weekdaysShort = function (a, b, c) {
        return Sa(a, b, c, 'weekdaysShort');
      }),
      (a.normalizeUnits = A),
      (a.relativeTimeRounding = function (a) {
        return void 0 === a ? Sc : 'function' == typeof a && ((Sc = a), !0);
      }),
      (a.relativeTimeThreshold = function (a, b) {
        return (
          void 0 !== Tc[a] &&
          (void 0 === b
            ? Tc[a]
            : ((Tc[a] = b), 's' === a && (Tc.ss = b - 1), !0))
        );
      }),
      (a.calendarFormat = function (a, b) {
        var c = a.diff(b, 'days', !0);
        return c < -6
          ? 'sameElse'
          : c < -1
            ? 'lastWeek'
            : c < 0
              ? 'lastDay'
              : c < 1
                ? 'sameDay'
                : c < 2
                  ? 'nextDay'
                  : c < 7
                    ? 'nextWeek'
                    : 'sameElse';
      }),
      (a.prototype = zc),
      (a.HTML5_FMT = {
        DATETIME_LOCAL: 'YYYY-MM-DDTHH:mm',
        DATETIME_LOCAL_SECONDS: 'YYYY-MM-DDTHH:mm:ss',
        DATETIME_LOCAL_MS: 'YYYY-MM-DDTHH:mm:ss.SSS',
        DATE: 'YYYY-MM-DD',
        TIME: 'HH:mm',
        TIME_SECONDS: 'HH:mm:ss',
        TIME_MS: 'HH:mm:ss.SSS',
        WEEK: 'GGGG-[W]WW',
        MONTH: 'YYYY-MM'
      }),
      a
    );
  }),
  (function (a) {
    'function' == typeof require &&
    'object' == typeof exports &&
    'object' == typeof module
      ? a(require('knockout'), exports)
      : 'function' == typeof define && define.amd
        ? define(['knockout', 'exports'], a)
        : a(ko, ko);
  })(function (a, b) {
    function c(a) {
      return function () {
        return a;
      };
    }
    if (void 0 === typeof a)
      throw 'Knockout is required, please ensure it is loaded before loading the commanding plug-in';
    (b.command = function (b) {
      var c = function () {
          return c.execute.apply(this, arguments);
        },
        d = b.canExecute,
        e = b.execute;
      return (
        (c.canExecute = a.computed(function () {
          return !d || d();
        })),
        (c.execute = function (a, b) {
          if (c.canExecute()) return e.apply(this, [a, b]);
        }),
        c
      );
    }),
      (b.asyncCommand = function (b) {
        var c = function () {
            return c.execute.apply(this, arguments);
          },
          d = b.canExecute,
          e = b.execute,
          f = function () {
            c.isExecuting(!1);
          };
        return (
          (c.isExecuting = a.observable()),
          (c.canExecute = a.computed(function () {
            return d ? d(c.isExecuting()) : !c.isExecuting();
          })),
          (c.execute = function (a, b) {
            if (c.canExecute()) {
              var d = [];
              return (
                e.length >= 2 && d.push(a),
                e.length >= 3 && d.push(b),
                d.push(f),
                c.isExecuting(!0),
                e.apply(this, d)
              );
            }
          }),
          c
        );
      }),
      (a.bindingHandlers.command = {
        init: function (b, d, e, f, g) {
          var h = d(),
            i = h.execute ? { click: h } : h,
            j = function (b) {
              return void 0 !== a.bindingHandlers[b];
            },
            k = function () {
              for (var d in i)
                j(d) && a.bindingHandlers[d].init(b, c(i[d].execute), e, f, g);
            },
            l = function () {
              var d = {};
              for (var h in i) j(h) || (d[h] = i[h].execute);
              a.bindingHandlers.event.init(b, c(d), e, f, g);
            };
          k(), l();
        },
        update: function (b, c, d, e, f) {
          var g = c(),
            h = g.canExecute;
          if (!h)
            for (var i in g)
              if (g[i].canExecute) {
                h = g[i].canExecute;
                break;
              }
          h && a.bindingHandlers.enable.update(b, h, d, e, f);
        }
      });
  }),
  (function (a) {
    'function' == typeof require &&
    'object' == typeof exports &&
    'object' == typeof module
      ? a(require('knockout'), exports)
      : 'function' == typeof define && define.amd
        ? define(['knockout', 'exports'], a)
        : a(ko, (ko.mapping = {}));
  })(function (a, b) {
    function c(a, d) {
      var e, f;
      for (f in d)
        if (d.hasOwnProperty(f) && d[f])
          if (
            ((e = b.getType(a[f])),
            f && a[f] && 'array' !== e && 'string' !== e)
          )
            c(a[f], d[f]);
          else if ('array' === b.getType(a[f]) && 'array' === b.getType(d[f])) {
            e = a;
            for (
              var g = f, h = a[f], i = d[f], j = {}, k = h.length - 1;
              0 <= k;
              --k
            )
              j[h[k]] = h[k];
            for (k = i.length - 1; 0 <= k; --k) j[i[k]] = i[k];
            (h = []), (i = void 0);
            for (i in j) h.push(j[i]);
            e[g] = h;
          } else a[f] = d[f];
    }
    function d(a, b) {
      var d = {};
      return c(d, a), c(d, b), d;
    }
    function e(a, b) {
      for (var c = d({}, a), e = u.length - 1; 0 <= e; e--) {
        var g = u[e];
        c[g] &&
          (c[''] instanceof Object || (c[''] = {}),
          (c[''][g] = c[g]),
          delete c[g]);
      }
      return (
        b &&
          ((c.ignore = f(b.ignore, c.ignore)),
          (c.include = f(b.include, c.include)),
          (c.copy = f(b.copy, c.copy)),
          (c.observe = f(b.observe, c.observe))),
        (c.ignore = f(c.ignore, x.ignore)),
        (c.include = f(c.include, x.include)),
        (c.copy = f(c.copy, x.copy)),
        (c.observe = f(c.observe, x.observe)),
        (c.mappedProperties = c.mappedProperties || {}),
        (c.copiedProperties = c.copiedProperties || {}),
        c
      );
    }
    function f(c, d) {
      return (
        'array' !== b.getType(c) &&
          (c = 'undefined' === b.getType(c) ? [] : [c]),
        'array' !== b.getType(d) &&
          (d = 'undefined' === b.getType(d) ? [] : [d]),
        a.utils.arrayGetDistinctValues(c.concat(d))
      );
    }
    function g(c, e, f, i, n, o, t) {
      var u = 'array' === b.getType(a.utils.unwrapObservable(e));
      if (((o = o || ''), b.isMapped(c))) {
        var w = a.utils.unwrapObservable(c)[r];
        f = d(w, f);
      }
      var x = t || n,
        y = function () {
          return f[i] && f[i].create instanceof Function;
        },
        z = function (b) {
          var c = p,
            d = a.dependentObservable;
          return (
            (a.dependentObservable = function (b, d, e) {
              (e = e || {}), b && 'object' == typeof b && (e = b);
              var f = e.deferEvaluation,
                g = !1;
              if (((e.deferEvaluation = !0), (b = new s(b, d, e)), !f)) {
                var h = b,
                  f = a.dependentObservable;
                (a.dependentObservable = s),
                  (b = a.isWriteableObservable(h)),
                  (a.dependentObservable = f),
                  (b = s({
                    read: function () {
                      return (
                        g || (a.utils.arrayRemoveItem(c, h), (g = !0)),
                        h.apply(h, arguments)
                      );
                    },
                    write:
                      b &&
                      function (a) {
                        return h(a);
                      },
                    deferEvaluation: !0
                  })),
                  c.push(b);
              }
              return b;
            }),
            (a.dependentObservable.fn = s.fn),
            (a.computed = a.dependentObservable),
            (b =
              a.utils.unwrapObservable(n) instanceof Array
                ? f[i].create({ data: b || e, parent: x, skip: v })
                : f[i].create({ data: b || e, parent: x })),
            (a.dependentObservable = d),
            (a.computed = a.dependentObservable),
            b
          );
        },
        A = function () {
          return f[i] && f[i].update instanceof Function;
        },
        B = function (b, c) {
          var d = {
            data: c || e,
            parent: x,
            target: a.utils.unwrapObservable(b)
          };
          return (
            a.isWriteableObservable(b) && (d.observable = b), f[i].update(d)
          );
        };
      if ((t = q.get(e))) return t;
      if (((i = i || ''), u)) {
        var u = [],
          C = !1,
          D = function (a) {
            return a;
          };
        f[i] && f[i].key && ((D = f[i].key), (C = !0)),
          a.isObservable(c) ||
            ((c = a.observableArray([])),
            (c.mappedRemove = function (a) {
              var b =
                'function' == typeof a
                  ? a
                  : function (b) {
                      return b === D(a);
                    };
              return c.remove(function (a) {
                return b(D(a));
              });
            }),
            (c.mappedRemoveAll = function (b) {
              var d = k(b, D);
              return c.remove(function (b) {
                return -1 != a.utils.arrayIndexOf(d, D(b));
              });
            }),
            (c.mappedDestroy = function (a) {
              var b =
                'function' == typeof a
                  ? a
                  : function (b) {
                      return b === D(a);
                    };
              return c.destroy(function (a) {
                return b(D(a));
              });
            }),
            (c.mappedDestroyAll = function (b) {
              var d = k(b, D);
              return c.destroy(function (b) {
                return -1 != a.utils.arrayIndexOf(d, D(b));
              });
            }),
            (c.mappedIndexOf = function (b) {
              var d = k(c(), D);
              return (b = D(b)), a.utils.arrayIndexOf(d, b);
            }),
            (c.mappedCreate = function (b) {
              if (-1 !== c.mappedIndexOf(b))
                throw Error(
                  'There already is an object with the key that you specified.'
                );
              var d = y() ? z(b) : b;
              return (
                A() &&
                  ((b = B(d, b)), a.isWriteableObservable(d) ? d(b) : (d = b)),
                c.push(d),
                d
              );
            })),
          (t = k(a.utils.unwrapObservable(c), D).sort()),
          (w = k(e, D)),
          C && w.sort(),
          (C = a.utils.compareArrays(t, w)),
          (t = {});
        var E,
          F = a.utils.unwrapObservable(e),
          G = {},
          H = !0,
          w = 0;
        for (E = F.length; w < E; w++) {
          var I = D(F[w]);
          if (void 0 === I || I instanceof Object) {
            H = !1;
            break;
          }
          G[I] = F[w];
        }
        var F = [],
          J = 0,
          w = 0;
        for (E = C.length; w < E; w++) {
          var K,
            I = C[w],
            L = o + '[' + w + ']';
          switch (I.status) {
            case 'added':
              var M = H
                ? G[I.value]
                : j(a.utils.unwrapObservable(e), I.value, D);
              (K = g(void 0, M, f, i, c, L, n)),
                y() || (K = a.utils.unwrapObservable(K)),
                (L = h(a.utils.unwrapObservable(e), M, t)),
                K === v ? J++ : (F[L - J] = K),
                (t[L] = !0);
              break;
            case 'retained':
              (M = H ? G[I.value] : j(a.utils.unwrapObservable(e), I.value, D)),
                (K = j(c, I.value, D)),
                g(K, M, f, i, c, L, n),
                (L = h(a.utils.unwrapObservable(e), M, t)),
                (F[L] = K),
                (t[L] = !0);
              break;
            case 'deleted':
              K = j(c, I.value, D);
          }
          u.push({ event: I.status, item: K });
        }
        c(F),
          f[i] &&
            f[i].arrayChanged &&
            a.utils.arrayForEach(u, function (a) {
              f[i].arrayChanged(a.event, a.item);
            });
      } else if (m(e)) {
        if (!(c = a.utils.unwrapObservable(c))) {
          if (y()) return (C = z()), A() && (C = B(C)), C;
          if (A()) return B(C);
          c = {};
        }
        if ((A() && (c = B(c)), q.save(e, c), A())) return c;
        l(e, function (b) {
          var d = o.length ? o + '.' + b : b;
          if (-1 == a.utils.arrayIndexOf(f.ignore, d))
            if (-1 != a.utils.arrayIndexOf(f.copy, d)) c[b] = e[b];
            else if (
              'object' != typeof e[b] &&
              'array' != typeof e[b] &&
              0 < f.observe.length &&
              -1 == a.utils.arrayIndexOf(f.observe, d)
            )
              (c[b] = e[b]), (f.copiedProperties[d] = !0);
            else {
              var h = q.get(e[b]),
                i = g(c[b], e[b], f, b, c, d, c),
                h = h || i;
              0 < f.observe.length && -1 == a.utils.arrayIndexOf(f.observe, d)
                ? ((c[b] = h()), (f.copiedProperties[d] = !0))
                : (a.isWriteableObservable(c[b])
                    ? c[b](a.utils.unwrapObservable(h))
                    : ((h = void 0 === c[b] ? h : a.utils.unwrapObservable(h)),
                      (c[b] = h)),
                  (f.mappedProperties[d] = !0));
            }
        });
      } else
        switch (b.getType(e)) {
          case 'function':
            A()
              ? a.isWriteableObservable(e)
                ? (e(B(e)), (c = e))
                : (c = B(e))
              : (c = e);
            break;
          default:
            if (a.isWriteableObservable(c))
              return (K = A() ? B(c) : a.utils.unwrapObservable(e)), c(K), K;
            y() || A(),
              (c = y() ? z() : a.observable(a.utils.unwrapObservable(e))),
              A() && c(B(c));
        }
      return c;
    }
    function h(a, b, c) {
      for (var d = 0, e = a.length; d < e; d++)
        if (!0 !== c[d] && a[d] === b) return d;
      return null;
    }
    function i(c, d) {
      var e;
      return (
        d && (e = d(c)),
        'undefined' === b.getType(e) && (e = c),
        a.utils.unwrapObservable(e)
      );
    }
    function j(b, c, d) {
      b = a.utils.unwrapObservable(b);
      for (var e = 0, f = b.length; e < f; e++) {
        var g = b[e];
        if (i(g, d) === c) return g;
      }
      throw Error(
        "When calling ko.update*, the key '" + c + "' was not found!"
      );
    }
    function k(b, c) {
      return a.utils.arrayMap(a.utils.unwrapObservable(b), function (a) {
        return c ? i(a, c) : a;
      });
    }
    function l(a, c) {
      if ('array' === b.getType(a)) for (var d = 0; d < a.length; d++) c(d);
      else for (d in a) c(d);
    }
    function m(a) {
      var c = b.getType(a);
      return ('object' === c || 'array' === c) && null !== a;
    }
    function n() {
      var b = [],
        c = [];
      (this.save = function (d, e) {
        var f = a.utils.arrayIndexOf(b, d);
        0 <= f ? (c[f] = e) : (b.push(d), c.push(e));
      }),
        (this.get = function (d) {
          return (d = a.utils.arrayIndexOf(b, d)), 0 <= d ? c[d] : void 0;
        });
    }
    function o() {
      var a = {},
        b = function (b) {
          var c;
          try {
            c = b;
          } catch (a) {
            c = '$$$';
          }
          return (b = a[c]), void 0 === b && ((b = new n()), (a[c] = b)), b;
        };
      (this.save = function (a, c) {
        b(a).save(a, c);
      }),
        (this.get = function (a) {
          return b(a).get(a);
        });
    }
    var p,
      q,
      r = '__ko_mapping__',
      s = a.dependentObservable,
      t = 0,
      u = ['create', 'update', 'key', 'arrayChanged'],
      v = {},
      w = { include: ['_destroy'], ignore: [], copy: [], observe: [] },
      x = w;
    (b.isMapped = function (b) {
      return (b = a.utils.unwrapObservable(b)) && b[r];
    }),
      (b.fromJS = function (a) {
        if (0 == arguments.length)
          throw Error(
            'When calling ko.fromJS, pass the object you want to convert.'
          );
        try {
          t++ || ((p = []), (q = new o()));
          var b, c;
          2 == arguments.length &&
            (arguments[1][r] ? (c = arguments[1]) : (b = arguments[1])),
            3 == arguments.length && ((b = arguments[1]), (c = arguments[2])),
            c && (b = d(b, c[r])),
            (b = e(b));
          var f = g(c, a, b);
          if ((c && (f = c), !--t))
            for (; p.length; ) {
              var h = p.pop();
              h && h();
            }
          return (f[r] = d(f[r], b)), f;
        } catch (a) {
          throw ((t = 0), a);
        }
      }),
      (b.fromJSON = function (c) {
        var d = a.utils.parseJson(c);
        return (arguments[0] = d), b.fromJS.apply(this, arguments);
      }),
      (b.updateFromJS = function () {
        throw Error(
          'ko.mapping.updateFromJS, use ko.mapping.fromJS instead. Please note that the order of parameters is different!'
        );
      }),
      (b.updateFromJSON = function () {
        throw Error(
          'ko.mapping.updateFromJSON, use ko.mapping.fromJSON instead. Please note that the order of parameters is different!'
        );
      }),
      (b.toJS = function (c, d) {
        if ((x || b.resetDefaultOptions(), 0 == arguments.length))
          throw Error(
            'When calling ko.mapping.toJS, pass the object you want to convert.'
          );
        if ('array' !== b.getType(x.ignore))
          throw Error('ko.mapping.defaultOptions().ignore should be an array.');
        if ('array' !== b.getType(x.include))
          throw Error(
            'ko.mapping.defaultOptions().include should be an array.'
          );
        if ('array' !== b.getType(x.copy))
          throw Error('ko.mapping.defaultOptions().copy should be an array.');
        return (
          (d = e(d, c[r])),
          b.visitModel(
            c,
            function (b) {
              return a.utils.unwrapObservable(b);
            },
            d
          )
        );
      }),
      (b.toJSON = function (c, d) {
        var e = b.toJS(c, d);
        return a.utils.stringifyJson(e);
      }),
      (b.defaultOptions = function () {
        if (!(0 < arguments.length)) return x;
        x = arguments[0];
      }),
      (b.resetDefaultOptions = function () {
        x = {
          include: w.include.slice(0),
          ignore: w.ignore.slice(0),
          copy: w.copy.slice(0)
        };
      }),
      (b.getType = function (a) {
        if (a && 'object' == typeof a) {
          if (a.constructor === Date) return 'date';
          if (a.constructor === Array) return 'array';
        }
        return typeof a;
      }),
      (b.visitModel = function (c, d, f) {
        (f = f || {}), (f.visitedObjects = f.visitedObjects || new o());
        var g,
          h = a.utils.unwrapObservable(c);
        if (!m(h)) return d(c, f.parentName);
        (f = e(f, h[r])),
          d(c, f.parentName),
          (g = 'array' === b.getType(h) ? [] : {}),
          f.visitedObjects.save(c, g);
        var i = f.parentName;
        return (
          l(h, function (c) {
            if (!f.ignore || -1 == a.utils.arrayIndexOf(f.ignore, c)) {
              var e = h[c],
                j = f,
                k = i || '';
              if (
                ('array' === b.getType(h)
                  ? i && (k += '[' + c + ']')
                  : (i && (k += '.'), (k += c)),
                (j.parentName = k),
                -1 !== a.utils.arrayIndexOf(f.copy, c) ||
                  -1 !== a.utils.arrayIndexOf(f.include, c) ||
                  !h[r] ||
                  !h[r].mappedProperties ||
                  h[r].mappedProperties[c] ||
                  !h[r].copiedProperties ||
                  h[r].copiedProperties[c] ||
                  'array' === b.getType(h))
              )
                switch (b.getType(a.utils.unwrapObservable(e))) {
                  case 'object':
                  case 'array':
                  case 'undefined':
                    (j = f.visitedObjects.get(e)),
                      (g[c] =
                        'undefined' !== b.getType(j)
                          ? j
                          : b.visitModel(e, d, f));
                    break;
                  default:
                    g[c] = d(e, f.parentName);
                }
            }
          }),
          g
        );
      });
  }),
  (function (a, b) {
    'object' == typeof exports && 'undefined' != typeof module
      ? (module.exports = b())
      : 'function' == typeof define && define.amd
        ? define('underscore', b)
        : ((a = 'undefined' != typeof globalThis ? globalThis : a || self),
          (function () {
            var c = a._,
              d = (a._ = b());
            d.noConflict = function () {
              return (a._ = c), d;
            };
          })());
  })(this, function () {
    function a(a, b) {
      return (
        (b = null == b ? a.length - 1 : +b),
        function () {
          for (
            var c = Math.max(arguments.length - b, 0), d = Array(c), e = 0;
            e < c;
            e++
          )
            d[e] = arguments[e + b];
          switch (b) {
            case 0:
              return a.call(this, d);
            case 1:
              return a.call(this, arguments[0], d);
            case 2:
              return a.call(this, arguments[0], arguments[1], d);
          }
          var f = Array(b + 1);
          for (e = 0; e < b; e++) f[e] = arguments[e];
          return (f[b] = d), a.apply(this, f);
        }
      );
    }
    function b(a) {
      var b = typeof a;
      return 'function' === b || ('object' === b && !!a);
    }
    function c(a) {
      return void 0 === a;
    }
    function d(a) {
      return !0 === a || !1 === a || '[object Boolean]' === ua.call(a);
    }
    function e(a) {
      var b = '[object ' + a + ']';
      return function (a) {
        return ua.call(a) === b;
      };
    }
    function f(a, b) {
      return null != a && va.call(a, b);
    }
    function g(a) {
      return Ia(a) && Ca(a);
    }
    function h(a) {
      return function () {
        return a;
      };
    }
    function i(a) {
      return function (b) {
        var c = a(b);
        return 'number' == typeof c && c >= 0 && c <= Ga;
      };
    }
    function j(a) {
      return function (b) {
        return null == b ? void 0 : b[a];
      };
    }
    function k(a, b) {
      b = (function (a) {
        for (var b = {}, c = a.length, d = 0; d < c; ++d) b[a[d]] = !0;
        return {
          contains: function (a) {
            return b[a];
          },
          push: function (c) {
            return (b[c] = !0), a.push(c);
          }
        };
      })(b);
      var c = Fa.length,
        d = a.constructor,
        e = (Qa(d) && d.prototype) || qa,
        g = 'constructor';
      for (f(a, g) && !b.contains(g) && b.push(g); c--; )
        (g = Fa[c]) in a && a[g] !== e[g] && !b.contains(g) && b.push(g);
    }
    function l(a) {
      if (!b(a)) return [];
      if (za) return za(a);
      var c = [];
      for (var d in a) f(a, d) && c.push(d);
      return Ea && k(a, c), c;
    }
    function m(a, b) {
      var c = l(b),
        d = c.length;
      if (null == a) return !d;
      for (var e = Object(a), f = 0; f < d; f++) {
        var g = c[f];
        if (b[g] !== e[g] || !(g in e)) return !1;
      }
      return !0;
    }
    function n(a) {
      return a instanceof n
        ? a
        : this instanceof n
          ? void (this._wrapped = a)
          : new n(a);
    }
    function o(a) {
      return new Uint8Array(a.buffer || a, a.byteOffset || 0, Za(a));
    }
    function p(a, b, c, d) {
      if (a === b) return 0 !== a || 1 / a == 1 / b;
      if (null == a || null == b) return !1;
      if (a != a) return b != b;
      var e = typeof a;
      return (
        ('function' === e || 'object' === e || 'object' == typeof b) &&
        (function a(b, c, d, e) {
          b instanceof n && (b = b._wrapped),
            c instanceof n && (c = c._wrapped);
          var g = ua.call(b);
          if (g !== ua.call(c)) return !1;
          if (Sa && '[object Object]' == g && Va(b)) {
            if (!Va(c)) return !1;
            g = cb;
          }
          switch (g) {
            case '[object RegExp]':
            case '[object String]':
              return '' + b == '' + c;
            case '[object Number]':
              return +b != +b ? +c != +c : 0 == +b ? 1 / +b == 1 / c : +b == +c;
            case '[object Date]':
            case '[object Boolean]':
              return +b == +c;
            case '[object Symbol]':
              return ra.valueOf.call(b) === ra.valueOf.call(c);
            case '[object ArrayBuffer]':
            case cb:
              return a(o(b), o(c), d, e);
          }
          var h = '[object Array]' === g;
          if (!h && ab(b)) {
            if (Za(b) !== Za(c)) return !1;
            if (b.buffer === c.buffer && b.byteOffset === c.byteOffset)
              return !0;
            h = !0;
          }
          if (!h) {
            if ('object' != typeof b || 'object' != typeof c) return !1;
            var i = b.constructor,
              j = c.constructor;
            if (
              i !== j &&
              !(Qa(i) && i instanceof i && Qa(j) && j instanceof j) &&
              'constructor' in b &&
              'constructor' in c
            )
              return !1;
          }
          e = e || [];
          for (var k = (d = d || []).length; k--; )
            if (d[k] === b) return e[k] === c;
          if ((d.push(b), e.push(c), h)) {
            if ((k = b.length) !== c.length) return !1;
            for (; k--; ) if (!p(b[k], c[k], d, e)) return !1;
          } else {
            var m,
              q = l(b);
            if (((k = q.length), l(c).length !== k)) return !1;
            for (; k--; )
              if (((m = q[k]), !f(c, m) || !p(b[m], c[m], d, e))) return !1;
          }
          return d.pop(), e.pop(), !0;
        })(a, b, c, d)
      );
    }
    function q(a) {
      if (!b(a)) return [];
      var c = [];
      for (var d in a) c.push(d);
      return Ea && k(a, c), c;
    }
    function r(a) {
      var b = bb(a);
      return function (c) {
        if (null == c) return !1;
        var d = q(c);
        if (bb(d)) return !1;
        for (var e = 0; e < b; e++) if (!Qa(c[a[e]])) return !1;
        return a !== ib || !Qa(c[db]);
      };
    }
    function s(a) {
      for (var b = l(a), c = b.length, d = Array(c), e = 0; e < c; e++)
        d[e] = a[b[e]];
      return d;
    }
    function t(a) {
      for (var b = {}, c = l(a), d = 0, e = c.length; d < e; d++)
        b[a[c[d]]] = c[d];
      return b;
    }
    function u(a) {
      var b = [];
      for (var c in a) Qa(a[c]) && b.push(c);
      return b.sort();
    }
    function v(a, b) {
      return function (c) {
        var d = arguments.length;
        if ((b && (c = Object(c)), d < 2 || null == c)) return c;
        for (var e = 1; e < d; e++)
          for (
            var f = arguments[e], g = a(f), h = g.length, i = 0;
            i < h;
            i++
          ) {
            var j = g[i];
            (b && void 0 !== c[j]) || (c[j] = f[j]);
          }
        return c;
      };
    }
    function w(a) {
      if (!b(a)) return {};
      if (Aa) return Aa(a);
      var c = function () {};
      c.prototype = a;
      var d = new c();
      return (c.prototype = null), d;
    }
    function x(a) {
      return b(a) ? (Wa(a) ? a.slice() : ob({}, a)) : a;
    }
    function y(a) {
      return Wa(a) ? a : [a];
    }
    function z(a) {
      return n.toPath(a);
    }
    function A(a, b) {
      for (var c = b.length, d = 0; d < c; d++) {
        if (null == a) return;
        a = a[b[d]];
      }
      return c ? a : void 0;
    }
    function B(a, b, d) {
      var e = A(a, z(b));
      return c(e) ? d : e;
    }
    function C(a) {
      return a;
    }
    function D(a) {
      return (
        (a = pb({}, a)),
        function (b) {
          return m(b, a);
        }
      );
    }
    function E(a) {
      return (
        (a = z(a)),
        function (b) {
          return A(b, a);
        }
      );
    }
    function F(a, b, c) {
      if (void 0 === b) return a;
      switch (null == c ? 3 : c) {
        case 1:
          return function (c) {
            return a.call(b, c);
          };
        case 3:
          return function (c, d, e) {
            return a.call(b, c, d, e);
          };
        case 4:
          return function (c, d, e, f) {
            return a.call(b, c, d, e, f);
          };
      }
      return function () {
        return a.apply(b, arguments);
      };
    }
    function G(a, c, d) {
      return null == a ? C : Qa(a) ? F(a, c, d) : b(a) && !Wa(a) ? D(a) : E(a);
    }
    function H(a, b) {
      return G(a, b, 1 / 0);
    }
    function I(a, b, c) {
      return n.iteratee !== H ? n.iteratee(a, b) : G(a, b, c);
    }
    function J() {}
    function K(a, b) {
      return (
        null == b && ((b = a), (a = 0)),
        a + Math.floor(Math.random() * (b - a + 1))
      );
    }
    function L(a) {
      var b = function (b) {
          return a[b];
        },
        c = '(?:' + l(a).join('|') + ')',
        d = RegExp(c),
        e = RegExp(c, 'g');
      return function (a) {
        return (a = null == a ? '' : '' + a), d.test(a) ? a.replace(e, b) : a;
      };
    }
    function M(a) {
      return '\\' + xb[a];
    }
    function N(a, c, d, e, f) {
      if (!(e instanceof c)) return a.apply(d, f);
      var g = w(a.prototype),
        h = a.apply(g, f);
      return b(h) ? h : g;
    }
    function O(a, b, c, d) {
      if (((d = d || []), b || 0 === b)) {
        if (b <= 0) return d.concat(a);
      } else b = 1 / 0;
      for (var e = d.length, f = 0, g = bb(a); f < g; f++) {
        var h = a[f];
        if (Db(h) && (Wa(h) || Ya(h)))
          if (b > 1) O(h, b - 1, c, d), (e = d.length);
          else for (var i = 0, j = h.length; i < j; ) d[e++] = h[i++];
        else c || (d[e++] = h);
      }
      return d;
    }
    function P(a) {
      return function () {
        return !a.apply(this, arguments);
      };
    }
    function Q(a, b) {
      var c;
      return function () {
        return (
          --a > 0 && (c = b.apply(this, arguments)), a <= 1 && (b = null), c
        );
      };
    }
    function R(a, b, c) {
      b = I(b, c);
      for (var d, e = l(a), f = 0, g = e.length; f < g; f++)
        if (b(a[(d = e[f])], d, a)) return d;
    }
    function S(a) {
      return function (b, c, d) {
        c = I(c, d);
        for (var e = bb(b), f = a > 0 ? 0 : e - 1; f >= 0 && f < e; f += a)
          if (c(b[f], f, b)) return f;
        return -1;
      };
    }
    function T(a, b, c, d) {
      for (var e = (c = I(c, d, 1))(b), f = 0, g = bb(a); f < g; ) {
        var h = Math.floor((f + g) / 2);
        c(a[h]) < e ? (f = h + 1) : (g = h);
      }
      return f;
    }
    function U(a, b, c) {
      return function (d, e, f) {
        var h = 0,
          i = bb(d);
        if ('number' == typeof f)
          a > 0
            ? (h = f >= 0 ? f : Math.max(f + i, h))
            : (i = f >= 0 ? Math.min(f + 1, i) : f + i + 1);
        else if (c && f && i) return d[(f = c(d, e))] === e ? f : -1;
        if (e != e) return (f = b(ta.call(d, h, i), g)) >= 0 ? f + h : -1;
        for (f = a > 0 ? h : i - 1; f >= 0 && f < i; f += a)
          if (d[f] === e) return f;
        return -1;
      };
    }
    function V(a, b, c) {
      var d = (Db(a) ? Ib : R)(a, b, c);
      if (void 0 !== d && -1 !== d) return a[d];
    }
    function W(a, b, c) {
      var d, e;
      if (((b = F(b, c)), Db(a)))
        for (d = 0, e = a.length; d < e; d++) b(a[d], d, a);
      else {
        var f = l(a);
        for (d = 0, e = f.length; d < e; d++) b(a[f[d]], f[d], a);
      }
      return a;
    }
    function X(a, b, c) {
      b = I(b, c);
      for (
        var d = !Db(a) && l(a), e = (d || a).length, f = Array(e), g = 0;
        g < e;
        g++
      ) {
        var h = d ? d[g] : g;
        f[g] = b(a[h], h, a);
      }
      return f;
    }
    function Y(a) {
      var b = function (b, c, d, e) {
        var f = !Db(b) && l(b),
          g = (f || b).length,
          h = a > 0 ? 0 : g - 1;
        for (e || ((d = b[f ? f[h] : h]), (h += a)); h >= 0 && h < g; h += a) {
          var i = f ? f[h] : h;
          d = c(d, b[i], i, b);
        }
        return d;
      };
      return function (a, c, d, e) {
        var f = arguments.length >= 3;
        return b(a, F(c, e, 4), d, f);
      };
    }
    function Z(a, b, c) {
      var d = [];
      return (
        (b = I(b, c)),
        W(a, function (a, c, e) {
          b(a, c, e) && d.push(a);
        }),
        d
      );
    }
    function $(a, b, c) {
      b = I(b, c);
      for (var d = !Db(a) && l(a), e = (d || a).length, f = 0; f < e; f++) {
        var g = d ? d[f] : f;
        if (!b(a[g], g, a)) return !1;
      }
      return !0;
    }
    function _(a, b, c) {
      b = I(b, c);
      for (var d = !Db(a) && l(a), e = (d || a).length, f = 0; f < e; f++) {
        var g = d ? d[f] : f;
        if (b(a[g], g, a)) return !0;
      }
      return !1;
    }
    function aa(a, b, c, d) {
      return (
        Db(a) || (a = s(a)),
        ('number' != typeof c || d) && (c = 0),
        Kb(a, b, c) >= 0
      );
    }
    function ba(a, b) {
      return X(a, E(b));
    }
    function ca(a, b, c) {
      var d,
        e,
        f = -1 / 0,
        g = -1 / 0;
      if (
        null == b ||
        ('number' == typeof b && 'object' != typeof a[0] && null != a)
      )
        for (var h = 0, i = (a = Db(a) ? a : s(a)).length; h < i; h++)
          null != (d = a[h]) && d > f && (f = d);
      else
        (b = I(b, c)),
          W(a, function (a, c, d) {
            ((e = b(a, c, d)) > g || (e === -1 / 0 && f === -1 / 0)) &&
              ((f = a), (g = e));
          });
      return f;
    }
    function da(a, b, c) {
      if (null == b || c) return Db(a) || (a = s(a)), a[K(a.length - 1)];
      var d = Db(a) ? x(a) : s(a),
        e = bb(d);
      b = Math.max(Math.min(b, e), 0);
      for (var f = e - 1, g = 0; g < b; g++) {
        var h = K(g, f),
          i = d[g];
        (d[g] = d[h]), (d[h] = i);
      }
      return d.slice(0, b);
    }
    function ea(a, b) {
      return function (c, d, e) {
        var f = b ? [[], []] : {};
        return (
          (d = I(d, e)),
          W(c, function (b, e) {
            var g = d(b, e, c);
            a(f, b, g);
          }),
          f
        );
      };
    }
    function fa(a, b, c) {
      return b in c;
    }
    function ga(a, b, c) {
      return ta.call(a, 0, Math.max(0, a.length - (null == b || c ? 1 : b)));
    }
    function ha(a, b, c) {
      return null == a || a.length < 1
        ? null == b || c
          ? void 0
          : []
        : null == b || c
          ? a[0]
          : ga(a, a.length - b);
    }
    function ia(a, b, c) {
      return ta.call(a, null == b || c ? 1 : b);
    }
    function ja(a, b, c, e) {
      d(b) || ((e = c), (c = b), (b = !1)), null != c && (c = I(c, e));
      for (var f = [], g = [], h = 0, i = bb(a); h < i; h++) {
        var j = a[h],
          k = c ? c(j, h, a) : j;
        b && !c
          ? ((h && g === k) || f.push(j), (g = k))
          : c
            ? aa(g, k) || (g.push(k), f.push(j))
            : aa(f, j) || f.push(j);
      }
      return f;
    }
    function ka(a) {
      for (
        var b = (a && ca(a, bb).length) || 0, c = Array(b), d = 0;
        d < b;
        d++
      )
        c[d] = ba(a, d);
      return c;
    }
    function la(a, b) {
      return a._chain ? n(b).chain() : b;
    }
    function ma(a) {
      return (
        W(u(a), function (b) {
          var c = (n[b] = a[b]);
          n.prototype[b] = function () {
            var a = [this._wrapped];
            return sa.apply(a, arguments), la(this, c.apply(n, a));
          };
        }),
        n
      );
    }
    var na = '1.13.1',
      oa =
        ('object' == typeof self && self.self === self && self) ||
        ('object' == typeof global && global.global === global && global) ||
        Function('return this')() ||
        {},
      pa = Array.prototype,
      qa = Object.prototype,
      ra = 'undefined' != typeof Symbol ? Symbol.prototype : null,
      sa = pa.push,
      ta = pa.slice,
      ua = qa.toString,
      va = qa.hasOwnProperty,
      wa = 'undefined' != typeof ArrayBuffer,
      xa = 'undefined' != typeof DataView,
      ya = Array.isArray,
      za = Object.keys,
      Aa = Object.create,
      Ba = wa && ArrayBuffer.isView,
      Ca = isNaN,
      Da = isFinite,
      Ea = !{ toString: null }.propertyIsEnumerable('toString'),
      Fa = [
        'valueOf',
        'isPrototypeOf',
        'toString',
        'propertyIsEnumerable',
        'hasOwnProperty',
        'toLocaleString'
      ],
      Ga = Math.pow(2, 53) - 1,
      Ha = e('String'),
      Ia = e('Number'),
      Ja = e('Date'),
      Ka = e('RegExp'),
      La = e('Error'),
      Ma = e('Symbol'),
      Na = e('ArrayBuffer'),
      Oa = e('Function'),
      Pa = oa.document && oa.document.childNodes;
    'function' != typeof /./ &&
      'object' != typeof Int8Array &&
      'function' != typeof Pa &&
      (Oa = function (a) {
        return 'function' == typeof a || !1;
      });
    var Qa = Oa,
      Ra = e('Object'),
      Sa = xa && Ra(new DataView(new ArrayBuffer(8))),
      Ta = 'undefined' != typeof Map && Ra(new Map()),
      Ua = e('DataView'),
      Va = Sa
        ? function (a) {
            return null != a && Qa(a.getInt8) && Na(a.buffer);
          }
        : Ua,
      Wa = ya || e('Array'),
      Xa = e('Arguments');
    !(function () {
      Xa(arguments) ||
        (Xa = function (a) {
          return f(a, 'callee');
        });
    })();
    var Ya = Xa,
      Za = j('byteLength'),
      $a = i(Za),
      _a =
        /\[object ((I|Ui)nt(8|16|32)|Float(32|64)|Uint8Clamped|Big(I|Ui)nt64)Array\]/,
      ab = wa
        ? function (a) {
            return Ba ? Ba(a) && !Va(a) : $a(a) && _a.test(ua.call(a));
          }
        : h(!1),
      bb = j('length');
    (n.VERSION = na),
      (n.prototype.value = function () {
        return this._wrapped;
      }),
      (n.prototype.valueOf = n.prototype.toJSON = n.prototype.value),
      (n.prototype.toString = function () {
        return String(this._wrapped);
      });
    var cb = '[object DataView]',
      db = 'forEach',
      eb = 'has',
      fb = ['clear', 'delete'],
      gb = ['get', eb, 'set'],
      hb = fb.concat(db, gb),
      ib = fb.concat(gb),
      jb = ['add'].concat(fb, db, eb),
      kb = Ta ? r(hb) : e('Map'),
      lb = Ta ? r(ib) : e('WeakMap'),
      mb = Ta ? r(jb) : e('Set'),
      nb = e('WeakSet'),
      ob = v(q),
      pb = v(l),
      qb = v(q, !0);
    (n.toPath = y), (n.iteratee = H);
    var rb =
        Date.now ||
        function () {
          return new Date().getTime();
        },
      sb = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '`': '&#x60;'
      },
      tb = L(sb),
      ub = L(t(sb)),
      vb = (n.templateSettings = {
        evaluate: /<%([\s\S]+?)%>/g,
        interpolate: /<%=([\s\S]+?)%>/g,
        escape: /<%-([\s\S]+?)%>/g
      }),
      wb = /(.)^/,
      xb = {
        "'": "'",
        '\\': '\\',
        '\r': 'r',
        '\n': 'n',
        '\u2028': 'u2028',
        '\u2029': 'u2029'
      },
      yb = /\\|'|\r|\n|\u2028|\u2029/g,
      zb = /^\s*(\w|\$)+\s*$/,
      Ab = 0,
      Bb = a(function (a, b) {
        var c = Bb.placeholder,
          d = function () {
            for (var e = 0, f = b.length, g = Array(f), h = 0; h < f; h++)
              g[h] = b[h] === c ? arguments[e++] : b[h];
            for (; e < arguments.length; ) g.push(arguments[e++]);
            return N(a, d, this, this, g);
          };
        return d;
      });
    Bb.placeholder = n;
    var Cb = a(function (b, c, d) {
        if (!Qa(b)) throw new TypeError('Bind must be called on a function');
        var e = a(function (a) {
          return N(b, e, c, this, d.concat(a));
        });
        return e;
      }),
      Db = i(bb),
      Eb = a(function (a, b) {
        var c = (b = O(b, !1, !1)).length;
        if (c < 1) throw new Error('bindAll must be passed function names');
        for (; c--; ) {
          var d = b[c];
          a[d] = Cb(a[d], a);
        }
        return a;
      }),
      Fb = a(function (a, b, c) {
        return setTimeout(function () {
          return a.apply(null, c);
        }, b);
      }),
      Gb = Bb(Fb, n, 1),
      Hb = Bb(Q, 2),
      Ib = S(1),
      Jb = S(-1),
      Kb = U(1, Ib, T),
      Lb = U(-1, Jb),
      Mb = Y(1),
      Nb = Y(-1),
      Ob = a(function (a, b, c) {
        var d, e;
        return (
          Qa(b)
            ? (e = b)
            : ((b = z(b)), (d = b.slice(0, -1)), (b = b[b.length - 1])),
          X(a, function (a) {
            var f = e;
            if (!f) {
              if ((d && d.length && (a = A(a, d)), null == a)) return;
              f = a[b];
            }
            return null == f ? f : f.apply(a, c);
          })
        );
      }),
      Pb = ea(function (a, b, c) {
        f(a, c) ? a[c].push(b) : (a[c] = [b]);
      }),
      Qb = ea(function (a, b, c) {
        a[c] = b;
      }),
      Rb = ea(function (a, b, c) {
        f(a, c) ? a[c]++ : (a[c] = 1);
      }),
      Sb = ea(function (a, b, c) {
        a[c ? 0 : 1].push(b);
      }, !0),
      Tb = /[^\ud800-\udfff]|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff]/g,
      Ub = a(function (a, b) {
        var c = {},
          d = b[0];
        if (null == a) return c;
        Qa(d)
          ? (b.length > 1 && (d = F(d, b[1])), (b = q(a)))
          : ((d = fa), (b = O(b, !1, !1)), (a = Object(a)));
        for (var e = 0, f = b.length; e < f; e++) {
          var g = b[e],
            h = a[g];
          d(h, g, a) && (c[g] = h);
        }
        return c;
      }),
      Vb = a(function (a, b) {
        var c,
          d = b[0];
        return (
          Qa(d)
            ? ((d = P(d)), b.length > 1 && (c = b[1]))
            : ((b = X(O(b, !1, !1), String)),
              (d = function (a, c) {
                return !aa(b, c);
              })),
          Ub(a, d, c)
        );
      }),
      Wb = a(function (a, b) {
        return (
          (b = O(b, !0, !0)),
          Z(a, function (a) {
            return !aa(b, a);
          })
        );
      }),
      Xb = a(function (a, b) {
        return Wb(a, b);
      }),
      Yb = a(function (a) {
        return ja(O(a, !0, !0));
      }),
      Zb = a(ka);
    W(
      ['pop', 'push', 'reverse', 'shift', 'sort', 'splice', 'unshift'],
      function (a) {
        var b = pa[a];
        n.prototype[a] = function () {
          var c = this._wrapped;
          return (
            null != c &&
              (b.apply(c, arguments),
              ('shift' !== a && 'splice' !== a) ||
                0 !== c.length ||
                delete c[0]),
            la(this, c)
          );
        };
      }
    ),
      W(['concat', 'join', 'slice'], function (a) {
        var b = pa[a];
        n.prototype[a] = function () {
          var a = this._wrapped;
          return null != a && (a = b.apply(a, arguments)), la(this, a);
        };
      });
    var $b = ma({
      __proto__: null,
      VERSION: na,
      restArguments: a,
      isObject: b,
      isNull: function (a) {
        return null === a;
      },
      isUndefined: c,
      isBoolean: d,
      isElement: function (a) {
        return !(!a || 1 !== a.nodeType);
      },
      isString: Ha,
      isNumber: Ia,
      isDate: Ja,
      isRegExp: Ka,
      isError: La,
      isSymbol: Ma,
      isArrayBuffer: Na,
      isDataView: Va,
      isArray: Wa,
      isFunction: Qa,
      isArguments: Ya,
      isFinite: function (a) {
        return !Ma(a) && Da(a) && !isNaN(parseFloat(a));
      },
      isNaN: g,
      isTypedArray: ab,
      isEmpty: function (a) {
        if (null == a) return !0;
        var b = bb(a);
        return 'number' == typeof b && (Wa(a) || Ha(a) || Ya(a))
          ? 0 === b
          : 0 === bb(l(a));
      },
      isMatch: m,
      isEqual: function (a, b) {
        return p(a, b);
      },
      isMap: kb,
      isWeakMap: lb,
      isSet: mb,
      isWeakSet: nb,
      keys: l,
      allKeys: q,
      values: s,
      pairs: function (a) {
        for (var b = l(a), c = b.length, d = Array(c), e = 0; e < c; e++)
          d[e] = [b[e], a[b[e]]];
        return d;
      },
      invert: t,
      functions: u,
      methods: u,
      extend: ob,
      extendOwn: pb,
      assign: pb,
      defaults: qb,
      create: function (a, b) {
        var c = w(a);
        return b && pb(c, b), c;
      },
      clone: x,
      tap: function (a, b) {
        return b(a), a;
      },
      get: B,
      has: function (a, b) {
        for (var c = (b = z(b)).length, d = 0; d < c; d++) {
          var e = b[d];
          if (!f(a, e)) return !1;
          a = a[e];
        }
        return !!c;
      },
      mapObject: function (a, b, c) {
        b = I(b, c);
        for (var d = l(a), e = d.length, f = {}, g = 0; g < e; g++) {
          var h = d[g];
          f[h] = b(a[h], h, a);
        }
        return f;
      },
      identity: C,
      constant: h,
      noop: J,
      toPath: y,
      property: E,
      propertyOf: function (a) {
        return null == a
          ? J
          : function (b) {
              return B(a, b);
            };
      },
      matcher: D,
      matches: D,
      times: function (a, b, c) {
        var d = Array(Math.max(0, a));
        b = F(b, c, 1);
        for (var e = 0; e < a; e++) d[e] = b(e);
        return d;
      },
      random: K,
      now: rb,
      escape: tb,
      unescape: ub,
      templateSettings: vb,
      template: function (a, b, c) {
        !b && c && (b = c), (b = qb({}, b, n.templateSettings));
        var d = RegExp(
            [
              (b.escape || wb).source,
              (b.interpolate || wb).source,
              (b.evaluate || wb).source
            ].join('|') + '|$',
            'g'
          ),
          e = 0,
          f = "__p+='";
        a.replace(d, function (b, c, d, g, h) {
          return (
            (f += a.slice(e, h).replace(yb, M)),
            (e = h + b.length),
            c
              ? (f += "'+\n((__t=(" + c + "))==null?'':_.escape(__t))+\n'")
              : d
                ? (f += "'+\n((__t=(" + d + "))==null?'':__t)+\n'")
                : g && (f += "';\n" + g + "\n__p+='"),
            b
          );
        }),
          (f += "';\n");
        var g,
          h = b.variable;
        if (h) {
          if (!zb.test(h))
            throw new Error('variable is not a bare identifier: ' + h);
        } else (f = 'with(obj||{}){\n' + f + '}\n'), (h = 'obj');
        f =
          "var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};\n" +
          f +
          'return __p;\n';
        try {
          g = new Function(h, '_', f);
        } catch (a) {
          throw ((a.source = f), a);
        }
        var i = function (a) {
          return g.call(this, a, n);
        };
        return (i.source = 'function(' + h + '){\n' + f + '}'), i;
      },
      result: function (a, b, c) {
        var d = (b = z(b)).length;
        if (!d) return Qa(c) ? c.call(a) : c;
        for (var e = 0; e < d; e++) {
          var f = null == a ? void 0 : a[b[e]];
          void 0 === f && ((f = c), (e = d)), (a = Qa(f) ? f.call(a) : f);
        }
        return a;
      },
      uniqueId: function (a) {
        var b = ++Ab + '';
        return a ? a + b : b;
      },
      chain: function (a) {
        var b = n(a);
        return (b._chain = !0), b;
      },
      iteratee: H,
      partial: Bb,
      bind: Cb,
      bindAll: Eb,
      memoize: function (a, b) {
        var c = function (d) {
          var e = c.cache,
            g = '' + (b ? b.apply(this, arguments) : d);
          return f(e, g) || (e[g] = a.apply(this, arguments)), e[g];
        };
        return (c.cache = {}), c;
      },
      delay: Fb,
      defer: Gb,
      throttle: function (a, b, c) {
        var d,
          e,
          f,
          g,
          h = 0;
        c || (c = {});
        var i = function () {
            (h = !1 === c.leading ? 0 : rb()),
              (d = null),
              (g = a.apply(e, f)),
              d || (e = f = null);
          },
          j = function () {
            var j = rb();
            h || !1 !== c.leading || (h = j);
            var k = b - (j - h);
            return (
              (e = this),
              (f = arguments),
              k <= 0 || k > b
                ? (d && (clearTimeout(d), (d = null)),
                  (h = j),
                  (g = a.apply(e, f)),
                  d || (e = f = null))
                : d || !1 === c.trailing || (d = setTimeout(i, k)),
              g
            );
          };
        return (
          (j.cancel = function () {
            clearTimeout(d), (h = 0), (d = e = f = null);
          }),
          j
        );
      },
      debounce: function (b, c, d) {
        var e,
          f,
          g,
          h,
          i,
          j = function () {
            var a = rb() - f;
            c > a
              ? (e = setTimeout(j, c - a))
              : ((e = null), d || (h = b.apply(i, g)), e || (g = i = null));
          },
          k = a(function (a) {
            return (
              (i = this),
              (g = a),
              (f = rb()),
              e || ((e = setTimeout(j, c)), d && (h = b.apply(i, g))),
              h
            );
          });
        return (
          (k.cancel = function () {
            clearTimeout(e), (e = g = i = null);
          }),
          k
        );
      },
      wrap: function (a, b) {
        return Bb(b, a);
      },
      negate: P,
      compose: function () {
        var a = arguments,
          b = a.length - 1;
        return function () {
          for (var c = b, d = a[b].apply(this, arguments); c--; )
            d = a[c].call(this, d);
          return d;
        };
      },
      after: function (a, b) {
        return function () {
          if (--a < 1) return b.apply(this, arguments);
        };
      },
      before: Q,
      once: Hb,
      findKey: R,
      findIndex: Ib,
      findLastIndex: Jb,
      sortedIndex: T,
      indexOf: Kb,
      lastIndexOf: Lb,
      find: V,
      detect: V,
      findWhere: function (a, b) {
        return V(a, D(b));
      },
      each: W,
      forEach: W,
      map: X,
      collect: X,
      reduce: Mb,
      foldl: Mb,
      inject: Mb,
      reduceRight: Nb,
      foldr: Nb,
      filter: Z,
      select: Z,
      reject: function (a, b, c) {
        return Z(a, P(I(b)), c);
      },
      every: $,
      all: $,
      some: _,
      any: _,
      contains: aa,
      includes: aa,
      include: aa,
      invoke: Ob,
      pluck: ba,
      where: function (a, b) {
        return Z(a, D(b));
      },
      max: ca,
      min: function (a, b, c) {
        var d,
          e,
          f = 1 / 0,
          g = 1 / 0;
        if (
          null == b ||
          ('number' == typeof b && 'object' != typeof a[0] && null != a)
        )
          for (var h = 0, i = (a = Db(a) ? a : s(a)).length; h < i; h++)
            null != (d = a[h]) && d < f && (f = d);
        else
          (b = I(b, c)),
            W(a, function (a, c, d) {
              ((e = b(a, c, d)) < g || (e === 1 / 0 && f === 1 / 0)) &&
                ((f = a), (g = e));
            });
        return f;
      },
      shuffle: function (a) {
        return da(a, 1 / 0);
      },
      sample: da,
      sortBy: function (a, b, c) {
        var d = 0;
        return (
          (b = I(b, c)),
          ba(
            X(a, function (a, c, e) {
              return { value: a, index: d++, criteria: b(a, c, e) };
            }).sort(function (a, b) {
              var c = a.criteria,
                d = b.criteria;
              if (c !== d) {
                if (c > d || void 0 === c) return 1;
                if (c < d || void 0 === d) return -1;
              }
              return a.index - b.index;
            }),
            'value'
          )
        );
      },
      groupBy: Pb,
      indexBy: Qb,
      countBy: Rb,
      partition: Sb,
      toArray: function (a) {
        return a
          ? Wa(a)
            ? ta.call(a)
            : Ha(a)
              ? a.match(Tb)
              : Db(a)
                ? X(a, C)
                : s(a)
          : [];
      },
      size: function (a) {
        return null == a ? 0 : Db(a) ? a.length : l(a).length;
      },
      pick: Ub,
      omit: Vb,
      first: ha,
      head: ha,
      take: ha,
      initial: ga,
      last: function (a, b, c) {
        return null == a || a.length < 1
          ? null == b || c
            ? void 0
            : []
          : null == b || c
            ? a[a.length - 1]
            : ia(a, Math.max(0, a.length - b));
      },
      rest: ia,
      tail: ia,
      drop: ia,
      compact: function (a) {
        return Z(a, Boolean);
      },
      flatten: function (a, b) {
        return O(a, b, !1);
      },
      without: Xb,
      uniq: ja,
      unique: ja,
      union: Yb,
      intersection: function (a) {
        for (var b = [], c = arguments.length, d = 0, e = bb(a); d < e; d++) {
          var f = a[d];
          if (!aa(b, f)) {
            var g;
            for (g = 1; g < c && aa(arguments[g], f); g++);
            g === c && b.push(f);
          }
        }
        return b;
      },
      difference: Wb,
      unzip: ka,
      transpose: ka,
      zip: Zb,
      object: function (a, b) {
        for (var c = {}, d = 0, e = bb(a); d < e; d++)
          b ? (c[a[d]] = b[d]) : (c[a[d][0]] = a[d][1]);
        return c;
      },
      range: function (a, b, c) {
        null == b && ((b = a || 0), (a = 0)), c || (c = b < a ? -1 : 1);
        for (
          var d = Math.max(Math.ceil((b - a) / c), 0), e = Array(d), f = 0;
          f < d;
          f++, a += c
        )
          e[f] = a;
        return e;
      },
      chunk: function (a, b) {
        if (null == b || b < 1) return [];
        for (var c = [], d = 0, e = a.length; d < e; )
          c.push(ta.call(a, d, (d += b)));
        return c;
      },
      mixin: ma,
      default: n
    });
    return ($b._ = $b), $b;
  }),
  (function (a) {
    if ('object' == typeof exports && 'undefined' != typeof module)
      module.exports = a();
    else if ('function' == typeof define && define.amd) define([], a);
    else {
      var b;
      (b =
        'undefined' != typeof window
          ? window
          : 'undefined' != typeof global
            ? global
            : 'undefined' != typeof self
              ? self
              : this),
        (b.s = a());
    }
  })(function () {
    var a;
    return (function a(b, c, d) {
      function e(g, h) {
        if (!c[g]) {
          if (!b[g]) {
            var i = 'function' == typeof require && require;
            if (!h && i) return i(g, !0);
            if (f) return f(g, !0);
            var j = new Error("Cannot find module '" + g + "'");
            throw ((j.code = 'MODULE_NOT_FOUND'), j);
          }
          var k = (c[g] = { exports: {} });
          b[g][0].call(
            k.exports,
            function (a) {
              var c = b[g][1][a];
              return e(c || a);
            },
            k,
            k.exports,
            a,
            b,
            c,
            d
          );
        }
        return c[g].exports;
      }
      for (
        var f = 'function' == typeof require && require, g = 0;
        g < d.length;
        g++
      )
        e(d[g]);
      return e;
    })(
      {
        1: [
          function (a, b, c) {
            var d = a('./trim'),
              e = a('./decapitalize');
            b.exports = function (a, b) {
              return (
                (a = d(a).replace(/[-_\s]+(.)?/g, function (a, b) {
                  return b ? b.toUpperCase() : '';
                })),
                !0 === b ? e(a) : a
              );
            };
          },
          { './decapitalize': 10, './trim': 65 }
        ],
        2: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              a = d(a);
              var c = b ? a.slice(1).toLowerCase() : a.slice(1);
              return a.charAt(0).toUpperCase() + c;
            };
          },
          { './helper/makeString': 20 }
        ],
        3: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return d(a).split('');
            };
          },
          { './helper/makeString': 20 }
        ],
        4: [
          function (a, b, c) {
            b.exports = function (a, b) {
              return null == a
                ? []
                : ((a = String(a)),
                  (b = ~~b),
                  b > 0 ? a.match(new RegExp('.{1,' + b + '}', 'g')) : [a]);
            };
          },
          {}
        ],
        5: [
          function (a, b, c) {
            var d = a('./capitalize'),
              e = a('./camelize'),
              f = a('./helper/makeString');
            b.exports = function (a) {
              return (
                (a = f(a)), d(e(a.replace(/[\W_]/g, ' ')).replace(/\s/g, ''))
              );
            };
          },
          { './camelize': 1, './capitalize': 2, './helper/makeString': 20 }
        ],
        6: [
          function (a, b, c) {
            var d = a('./trim');
            b.exports = function (a) {
              return d(a).replace(/\s\s+/g, ' ');
            };
          },
          { './trim': 65 }
        ],
        7: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = 'ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźž',
              f = 'aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzz';
            (e += e.toUpperCase()),
              (f += f.toUpperCase()),
              (f = f.split('')),
              (e += 'ß'),
              f.push('ss'),
              (b.exports = function (a) {
                return d(a).replace(/.{1}/g, function (a) {
                  var b = e.indexOf(a);
                  return -1 === b ? a : f[b];
                });
              });
          },
          { './helper/makeString': 20 }
        ],
        8: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                (b = d(b)),
                0 === a.length || 0 === b.length ? 0 : a.split(b).length - 1
              );
            };
          },
          { './helper/makeString': 20 }
        ],
        9: [
          function (a, b, c) {
            var d = a('./trim');
            b.exports = function (a) {
              return d(a)
                .replace(/([A-Z])/g, '-$1')
                .replace(/[-_\s]+/g, '-')
                .toLowerCase();
            };
          },
          { './trim': 65 }
        ],
        10: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return (a = d(a)), a.charAt(0).toLowerCase() + a.slice(1);
            };
          },
          { './helper/makeString': 20 }
        ],
        11: [
          function (a, b, c) {
            function d(a) {
              for (
                var b = a.match(/^[\s\\t]*/gm), c = b[0].length, d = 1;
                d < b.length;
                d++
              )
                c = Math.min(b[d].length, c);
              return c;
            }
            var e = a('./helper/makeString');
            b.exports = function (a, b) {
              a = e(a);
              var c,
                f = d(a);
              return 0 === f
                ? a
                : ((c =
                    'string' == typeof b
                      ? new RegExp('^' + b, 'gm')
                      : new RegExp('^[ \\t]{' + f + '}', 'gm')),
                  a.replace(c, ''));
            };
          },
          { './helper/makeString': 20 }
        ],
        12: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/toPositive');
            b.exports = function (a, b, c) {
              return (
                (a = d(a)),
                (b = '' + b),
                (c =
                  void 0 === c
                    ? a.length - b.length
                    : Math.min(e(c), a.length) - b.length) >= 0 &&
                  a.indexOf(b, c) === c
              );
            };
          },
          { './helper/makeString': 20, './helper/toPositive': 22 }
        ],
        13: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/escapeChars'),
              f = '[';
            for (var g in e) f += g;
            f += ']';
            var h = new RegExp(f, 'g');
            b.exports = function (a) {
              return d(a).replace(h, function (a) {
                return '&' + e[a] + ';';
              });
            };
          },
          { './helper/escapeChars': 17, './helper/makeString': 20 }
        ],
        14: [
          function (a, b, c) {
            b.exports = function () {
              var a = {};
              for (var b in this)
                this.hasOwnProperty(b) &&
                  !b.match(/^(?:include|contains|reverse|join|map|wrap)$/) &&
                  (a[b] = this[b]);
              return a;
            };
          },
          {}
        ],
        15: [
          function (a, b, c) {
            var d = a('./makeString');
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                0 === a.length
                  ? ''
                  : a.slice(0, -1) +
                    String.fromCharCode(a.charCodeAt(a.length - 1) + b)
              );
            };
          },
          { './makeString': 20 }
        ],
        16: [
          function (a, b, c) {
            var d = a('./escapeRegExp');
            b.exports = function (a) {
              return null == a ? '\\s' : a.source ? a.source : '[' + d(a) + ']';
            };
          },
          { './escapeRegExp': 18 }
        ],
        17: [
          function (a, b, c) {
            var d = {
              '¢': 'cent',
              '£': 'pound',
              '¥': 'yen',
              '€': 'euro',
              '©': 'copy',
              '®': 'reg',
              '<': 'lt',
              '>': 'gt',
              '"': 'quot',
              '&': 'amp',
              "'": '#39'
            };
            b.exports = d;
          },
          {}
        ],
        18: [
          function (a, b, c) {
            var d = a('./makeString');
            b.exports = function (a) {
              return d(a).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
            };
          },
          { './makeString': 20 }
        ],
        19: [
          function (a, b, c) {
            var d = {
              nbsp: ' ',
              cent: '¢',
              pound: '£',
              yen: '¥',
              euro: '€',
              copy: '©',
              reg: '®',
              lt: '<',
              gt: '>',
              quot: '"',
              amp: '&',
              apos: "'"
            };
            b.exports = d;
          },
          {}
        ],
        20: [
          function (a, b, c) {
            b.exports = function (a) {
              return null == a ? '' : '' + a;
            };
          },
          {}
        ],
        21: [
          function (a, b, c) {
            b.exports = function (a, b) {
              if (b < 1) return '';
              for (var c = ''; b > 0; ) 1 & b && (c += a), (b >>= 1), (a += a);
              return c;
            };
          },
          {}
        ],
        22: [
          function (a, b, c) {
            b.exports = function (a) {
              return a < 0 ? 0 : +a || 0;
            };
          },
          {}
        ],
        23: [
          function (a, b, c) {
            var d = a('./capitalize'),
              e = a('./underscored'),
              f = a('./trim');
            b.exports = function (a) {
              return d(f(e(a).replace(/_id$/, '').replace(/_/g, ' ')));
            };
          },
          { './capitalize': 2, './trim': 65, './underscored': 67 }
        ],
        24: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              return '' === b || -1 !== d(a).indexOf(b);
            };
          },
          { './helper/makeString': 20 }
        ],
        25: [
          function (a, b, c) {
            'use strict';
            function d(a) {
              if (!(this instanceof d)) return new d(a);
              this._wrapped = a;
            }
            function e(a, b) {
              'function' == typeof b &&
                (d.prototype[a] = function () {
                  var a = [this._wrapped].concat(
                      Array.prototype.slice.call(arguments)
                    ),
                    c = b.apply(null, a);
                  return 'string' == typeof c ? new d(c) : c;
                });
            }
            function f(a) {
              e(a, function (b) {
                var c = Array.prototype.slice.call(arguments, 1);
                return String.prototype[a].apply(b, c);
              });
            }
            (d.VERSION = '3.3.4'),
              (d.isBlank = a('./isBlank')),
              (d.stripTags = a('./stripTags')),
              (d.capitalize = a('./capitalize')),
              (d.decapitalize = a('./decapitalize')),
              (d.chop = a('./chop')),
              (d.trim = a('./trim')),
              (d.clean = a('./clean')),
              (d.cleanDiacritics = a('./cleanDiacritics')),
              (d.count = a('./count')),
              (d.chars = a('./chars')),
              (d.swapCase = a('./swapCase')),
              (d.escapeHTML = a('./escapeHTML')),
              (d.unescapeHTML = a('./unescapeHTML')),
              (d.splice = a('./splice')),
              (d.insert = a('./insert')),
              (d.replaceAll = a('./replaceAll')),
              (d.include = a('./include')),
              (d.join = a('./join')),
              (d.lines = a('./lines')),
              (d.dedent = a('./dedent')),
              (d.reverse = a('./reverse')),
              (d.startsWith = a('./startsWith')),
              (d.endsWith = a('./endsWith')),
              (d.pred = a('./pred')),
              (d.succ = a('./succ')),
              (d.titleize = a('./titleize')),
              (d.camelize = a('./camelize')),
              (d.underscored = a('./underscored')),
              (d.dasherize = a('./dasherize')),
              (d.classify = a('./classify')),
              (d.humanize = a('./humanize')),
              (d.ltrim = a('./ltrim')),
              (d.rtrim = a('./rtrim')),
              (d.truncate = a('./truncate')),
              (d.prune = a('./prune')),
              (d.words = a('./words')),
              (d.pad = a('./pad')),
              (d.lpad = a('./lpad')),
              (d.rpad = a('./rpad')),
              (d.lrpad = a('./lrpad')),
              (d.sprintf = a('./sprintf')),
              (d.vsprintf = a('./vsprintf')),
              (d.toNumber = a('./toNumber')),
              (d.numberFormat = a('./numberFormat')),
              (d.strRight = a('./strRight')),
              (d.strRightBack = a('./strRightBack')),
              (d.strLeft = a('./strLeft')),
              (d.strLeftBack = a('./strLeftBack')),
              (d.toSentence = a('./toSentence')),
              (d.toSentenceSerial = a('./toSentenceSerial')),
              (d.slugify = a('./slugify')),
              (d.surround = a('./surround')),
              (d.quote = a('./quote')),
              (d.unquote = a('./unquote')),
              (d.repeat = a('./repeat')),
              (d.naturalCmp = a('./naturalCmp')),
              (d.levenshtein = a('./levenshtein')),
              (d.toBoolean = a('./toBoolean')),
              (d.exports = a('./exports')),
              (d.escapeRegExp = a('./helper/escapeRegExp')),
              (d.wrap = a('./wrap')),
              (d.map = a('./map')),
              (d.strip = d.trim),
              (d.lstrip = d.ltrim),
              (d.rstrip = d.rtrim),
              (d.center = d.lrpad),
              (d.rjust = d.lpad),
              (d.ljust = d.rpad),
              (d.contains = d.include),
              (d.q = d.quote),
              (d.toBool = d.toBoolean),
              (d.camelcase = d.camelize),
              (d.mapChars = d.map),
              (d.prototype = {
                value: function () {
                  return this._wrapped;
                }
              });
            for (var g in d) e(g, d[g]);
            e('tap', function (a, b) {
              return b(a);
            });
            var h = [
              'toUpperCase',
              'toLowerCase',
              'split',
              'replace',
              'slice',
              'substring',
              'substr',
              'concat'
            ];
            for (var i in h) f(h[i]);
            b.exports = d;
          },
          {
            './camelize': 1,
            './capitalize': 2,
            './chars': 3,
            './chop': 4,
            './classify': 5,
            './clean': 6,
            './cleanDiacritics': 7,
            './count': 8,
            './dasherize': 9,
            './decapitalize': 10,
            './dedent': 11,
            './endsWith': 12,
            './escapeHTML': 13,
            './exports': 14,
            './helper/escapeRegExp': 18,
            './humanize': 23,
            './include': 24,
            './insert': 26,
            './isBlank': 27,
            './join': 28,
            './levenshtein': 29,
            './lines': 30,
            './lpad': 31,
            './lrpad': 32,
            './ltrim': 33,
            './map': 34,
            './naturalCmp': 35,
            './numberFormat': 38,
            './pad': 39,
            './pred': 40,
            './prune': 41,
            './quote': 42,
            './repeat': 43,
            './replaceAll': 44,
            './reverse': 45,
            './rpad': 46,
            './rtrim': 47,
            './slugify': 48,
            './splice': 49,
            './sprintf': 50,
            './startsWith': 51,
            './strLeft': 52,
            './strLeftBack': 53,
            './strRight': 54,
            './strRightBack': 55,
            './stripTags': 56,
            './succ': 57,
            './surround': 58,
            './swapCase': 59,
            './titleize': 60,
            './toBoolean': 61,
            './toNumber': 62,
            './toSentence': 63,
            './toSentenceSerial': 64,
            './trim': 65,
            './truncate': 66,
            './underscored': 67,
            './unescapeHTML': 68,
            './unquote': 69,
            './vsprintf': 70,
            './words': 71,
            './wrap': 72
          }
        ],
        26: [
          function (a, b, c) {
            var d = a('./splice');
            b.exports = function (a, b, c) {
              return d(a, b, 0, c);
            };
          },
          { './splice': 49 }
        ],
        27: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return /^\s*$/.test(d(a));
            };
          },
          { './helper/makeString': 20 }
        ],
        28: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = [].slice;
            b.exports = function () {
              var a = e.call(arguments),
                b = a.shift();
              return a.join(d(b));
            };
          },
          { './helper/makeString': 20 }
        ],
        29: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              'use strict';
              if (((a = d(a)), (b = d(b)), a === b)) return 0;
              if (!a || !b) return Math.max(a.length, b.length);
              for (var c = new Array(b.length + 1), e = 0; e < c.length; ++e)
                c[e] = e;
              for (e = 0; e < a.length; ++e) {
                for (var f = e + 1, g = 0; g < b.length; ++g) {
                  var h = f;
                  f = c[g] + (a.charAt(e) === b.charAt(g) ? 0 : 1);
                  var i = h + 1;
                  f > i && (f = i),
                    (i = c[g + 1] + 1),
                    f > i && (f = i),
                    (c[g] = h);
                }
                c[g] = f;
              }
              return f;
            };
          },
          { './helper/makeString': 20 }
        ],
        30: [
          function (a, b, c) {
            b.exports = function (a) {
              return null == a ? [] : String(a).split(/\r\n?|\n/);
            };
          },
          {}
        ],
        31: [
          function (a, b, c) {
            var d = a('./pad');
            b.exports = function (a, b, c) {
              return d(a, b, c);
            };
          },
          { './pad': 39 }
        ],
        32: [
          function (a, b, c) {
            var d = a('./pad');
            b.exports = function (a, b, c) {
              return d(a, b, c, 'both');
            };
          },
          { './pad': 39 }
        ],
        33: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/defaultToWhiteSpace'),
              f = String.prototype.trimLeft;
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                !b && f
                  ? f.call(a)
                  : ((b = e(b)), a.replace(new RegExp('^' + b + '+'), ''))
              );
            };
          },
          { './helper/defaultToWhiteSpace': 16, './helper/makeString': 20 }
        ],
        34: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                0 === a.length || 'function' != typeof b
                  ? a
                  : a.replace(/./g, b)
              );
            };
          },
          { './helper/makeString': 20 }
        ],
        35: [
          function (a, b, c) {
            b.exports = function (a, b) {
              if (a == b) return 0;
              if (!a) return -1;
              if (!b) return 1;
              for (
                var c = /(\.\d+|\d+|\D+)/g,
                  d = String(a).match(c),
                  e = String(b).match(c),
                  f = Math.min(d.length, e.length),
                  g = 0;
                g < f;
                g++
              ) {
                var h = d[g],
                  i = e[g];
                if (h !== i) {
                  var j = +h,
                    k = +i;
                  return j === j && k === k ? (j > k ? 1 : -1) : h < i ? -1 : 1;
                }
              }
              return d.length != e.length
                ? d.length - e.length
                : a < b
                  ? -1
                  : 1;
            };
          },
          {}
        ],
        36: [
          function (b, c, d) {
            !(function (b) {
              function c() {
                var a = arguments[0],
                  b = c.cache;
                return (
                  (b[a] && b.hasOwnProperty(a)) || (b[a] = c.parse(a)),
                  c.format.call(null, b[a], arguments)
                );
              }
              function e(a) {
                return Object.prototype.toString
                  .call(a)
                  .slice(8, -1)
                  .toLowerCase();
              }
              function f(a, b) {
                return Array(b + 1).join(a);
              }
              var g = {
                not_string: /[^s]/,
                number: /[diefg]/,
                json: /[j]/,
                not_json: /[^j]/,
                text: /^[^\x25]+/,
                modulo: /^\x25{2}/,
                placeholder:
                  /^\x25(?:([1-9]\d*)\$|\(([^\)]+)\))?(\+)?(0|'[^$])?(-)?(\d+)?(?:\.(\d+))?([b-gijosuxX])/,
                key: /^([a-z_][a-z_\d]*)/i,
                key_access: /^\.([a-z_][a-z_\d]*)/i,
                index_access: /^\[(\d+)\]/,
                sign: /^[\+\-]/
              };
              (c.format = function (a, b) {
                var d,
                  h,
                  i,
                  j,
                  k,
                  l,
                  m,
                  n = 1,
                  o = a.length,
                  p = '',
                  q = [],
                  r = !0,
                  s = '';
                for (h = 0; h < o; h++)
                  if ('string' === (p = e(a[h]))) q[q.length] = a[h];
                  else if ('array' === p) {
                    if (((j = a[h]), j[2]))
                      for (d = b[n], i = 0; i < j[2].length; i++) {
                        if (!d.hasOwnProperty(j[2][i]))
                          throw new Error(
                            c("[sprintf] property '%s' does not exist", j[2][i])
                          );
                        d = d[j[2][i]];
                      }
                    else d = j[1] ? b[j[1]] : b[n++];
                    if (
                      ('function' == e(d) && (d = d()),
                      g.not_string.test(j[8]) &&
                        g.not_json.test(j[8]) &&
                        'number' != e(d) &&
                        isNaN(d))
                    )
                      throw new TypeError(
                        c('[sprintf] expecting number but found %s', e(d))
                      );
                    switch ((g.number.test(j[8]) && (r = d >= 0), j[8])) {
                      case 'b':
                        d = d.toString(2);
                        break;
                      case 'c':
                        d = String.fromCharCode(d);
                        break;
                      case 'd':
                      case 'i':
                        d = parseInt(d, 10);
                        break;
                      case 'j':
                        d = JSON.stringify(d, null, j[6] ? parseInt(j[6]) : 0);
                        break;
                      case 'e':
                        d = j[7] ? d.toExponential(j[7]) : d.toExponential();
                        break;
                      case 'f':
                        d = j[7] ? parseFloat(d).toFixed(j[7]) : parseFloat(d);
                        break;
                      case 'g':
                        d = j[7]
                          ? parseFloat(d).toPrecision(j[7])
                          : parseFloat(d);
                        break;
                      case 'o':
                        d = d.toString(8);
                        break;
                      case 's':
                        d = (d = String(d)) && j[7] ? d.substring(0, j[7]) : d;
                        break;
                      case 'u':
                        d >>>= 0;
                        break;
                      case 'x':
                        d = d.toString(16);
                        break;
                      case 'X':
                        d = d.toString(16).toUpperCase();
                    }
                    g.json.test(j[8])
                      ? (q[q.length] = d)
                      : (!g.number.test(j[8]) || (r && !j[3])
                          ? (s = '')
                          : ((s = r ? '+' : '-'),
                            (d = d.toString().replace(g.sign, ''))),
                        (l = j[4]
                          ? '0' === j[4]
                            ? '0'
                            : j[4].charAt(1)
                          : ' '),
                        (m = j[6] - (s + d).length),
                        (k = j[6] && m > 0 ? f(l, m) : ''),
                        (q[q.length] = j[5]
                          ? s + d + k
                          : '0' === l
                            ? s + k + d
                            : k + s + d));
                  }
                return q.join('');
              }),
                (c.cache = {}),
                (c.parse = function (a) {
                  for (var b = a, c = [], d = [], e = 0; b; ) {
                    if (null !== (c = g.text.exec(b))) d[d.length] = c[0];
                    else if (null !== (c = g.modulo.exec(b))) d[d.length] = '%';
                    else {
                      if (null === (c = g.placeholder.exec(b)))
                        throw new SyntaxError(
                          '[sprintf] unexpected placeholder'
                        );
                      if (c[2]) {
                        e |= 1;
                        var f = [],
                          h = c[2],
                          i = [];
                        if (null === (i = g.key.exec(h)))
                          throw new SyntaxError(
                            '[sprintf] failed to parse named argument key'
                          );
                        for (
                          f[f.length] = i[1];
                          '' !== (h = h.substring(i[0].length));

                        )
                          if (null !== (i = g.key_access.exec(h)))
                            f[f.length] = i[1];
                          else {
                            if (null === (i = g.index_access.exec(h)))
                              throw new SyntaxError(
                                '[sprintf] failed to parse named argument key'
                              );
                            f[f.length] = i[1];
                          }
                        c[2] = f;
                      } else e |= 2;
                      if (3 === e)
                        throw new Error(
                          '[sprintf] mixing positional and named placeholders is not (yet) supported'
                        );
                      d[d.length] = c;
                    }
                    b = b.substring(c[0].length);
                  }
                  return d;
                });
              var h = function (a, b, d) {
                return (
                  (d = (b || []).slice(0)), d.splice(0, 0, a), c.apply(null, d)
                );
              };
              void 0 !== d
                ? ((d.sprintf = c), (d.vsprintf = h))
                : ((b.sprintf = c),
                  (b.vsprintf = h),
                  'function' == typeof a &&
                    a.amd &&
                    a(function () {
                      return { sprintf: c, vsprintf: h };
                    }));
            })('undefined' == typeof window ? this : window);
          },
          {}
        ],
        37: [
          function (a, b, c) {
            (function (a) {
              function c(a, b) {
                function c() {
                  if (!e) {
                    if (d('throwDeprecation')) throw new Error(b);
                    d('traceDeprecation') ? console.trace(b) : console.warn(b),
                      (e = !0);
                  }
                  return a.apply(this, arguments);
                }
                if (d('noDeprecation')) return a;
                var e = !1;
                return c;
              }
              function d(b) {
                try {
                  if (!a.localStorage) return !1;
                } catch (a) {
                  return !1;
                }
                var c = a.localStorage[b];
                return null != c && 'true' === String(c).toLowerCase();
              }
              b.exports = c;
            }).call(
              this,
              'undefined' != typeof global
                ? global
                : 'undefined' != typeof self
                  ? self
                  : 'undefined' != typeof window
                    ? window
                    : {}
            );
          },
          {}
        ],
        38: [
          function (a, b, c) {
            b.exports = function (a, b, c, d) {
              if (isNaN(a) || null == a) return '';
              (a = a.toFixed(~~b)), (d = 'string' == typeof d ? d : ',');
              var e = a.split('.'),
                f = e[0],
                g = e[1] ? (c || '.') + e[1] : '';
              return f.replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + d) + g;
            };
          },
          {}
        ],
        39: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/strRepeat');
            b.exports = function (a, b, c, f) {
              (a = d(a)), (b = ~~b);
              var g = 0;
              switch ((c ? c.length > 1 && (c = c.charAt(0)) : (c = ' '), f)) {
                case 'right':
                  return (g = b - a.length), a + e(c, g);
                case 'both':
                  return (
                    (g = b - a.length),
                    e(c, Math.ceil(g / 2)) + a + e(c, Math.floor(g / 2))
                  );
                default:
                  return (g = b - a.length), e(c, g) + a;
              }
            };
          },
          { './helper/makeString': 20, './helper/strRepeat': 21 }
        ],
        40: [
          function (a, b, c) {
            var d = a('./helper/adjacent');
            b.exports = function (a) {
              return d(a, -1);
            };
          },
          { './helper/adjacent': 15 }
        ],
        41: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./rtrim');
            b.exports = function (a, b, c) {
              if (
                ((a = d(a)),
                (b = ~~b),
                (c = null != c ? String(c) : '...'),
                a.length <= b)
              )
                return a;
              var f = function (a) {
                  return a.toUpperCase() !== a.toLowerCase() ? 'A' : ' ';
                },
                g = a.slice(0, b + 1).replace(/.(?=\W*\w*$)/g, f);
              return (
                (g = g.slice(g.length - 2).match(/\w\w/)
                  ? g.replace(/\s*\S+$/, '')
                  : e(g.slice(0, g.length - 1))),
                (g + c).length > a.length ? a : a.slice(0, g.length) + c
              );
            };
          },
          { './helper/makeString': 20, './rtrim': 47 }
        ],
        42: [
          function (a, b, c) {
            var d = a('./surround');
            b.exports = function (a, b) {
              return d(a, b || '"');
            };
          },
          { './surround': 58 }
        ],
        43: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/strRepeat');
            b.exports = function (a, b, c) {
              if (((a = d(a)), (b = ~~b), null == c)) return e(a, b);
              for (var f = []; b > 0; f[--b] = a);
              return f.join(c);
            };
          },
          { './helper/makeString': 20, './helper/strRepeat': 21 }
        ],
        44: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b, c, e) {
              var f = !0 === e ? 'gi' : 'g',
                g = new RegExp(b, f);
              return d(a).replace(g, c);
            };
          },
          { './helper/makeString': 20 }
        ],
        45: [
          function (a, b, c) {
            var d = a('./chars');
            b.exports = function (a) {
              return d(a).reverse().join('');
            };
          },
          { './chars': 3 }
        ],
        46: [
          function (a, b, c) {
            var d = a('./pad');
            b.exports = function (a, b, c) {
              return d(a, b, c, 'right');
            };
          },
          { './pad': 39 }
        ],
        47: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/defaultToWhiteSpace'),
              f = String.prototype.trimRight;
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                !b && f
                  ? f.call(a)
                  : ((b = e(b)), a.replace(new RegExp(b + '+$'), ''))
              );
            };
          },
          { './helper/defaultToWhiteSpace': 16, './helper/makeString': 20 }
        ],
        48: [
          function (a, b, c) {
            var d = a('./trim'),
              e = a('./dasherize'),
              f = a('./cleanDiacritics');
            b.exports = function (a) {
              return d(
                e(
                  f(a)
                    .replace(/[^\w\s-]/g, '-')
                    .toLowerCase()
                ),
                '-'
              );
            };
          },
          { './cleanDiacritics': 7, './dasherize': 9, './trim': 65 }
        ],
        49: [
          function (a, b, c) {
            var d = a('./chars');
            b.exports = function (a, b, c, e) {
              var f = d(a);
              return f.splice(~~b, ~~c, e), f.join('');
            };
          },
          { './chars': 3 }
        ],
        50: [
          function (a, b, c) {
            var d = a('util-deprecate');
            b.exports = d(
              a('sprintf-js').sprintf,
              'sprintf() will be removed in the next major release, use the sprintf-js package instead.'
            );
          },
          { 'sprintf-js': 36, 'util-deprecate': 37 }
        ],
        51: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/toPositive');
            b.exports = function (a, b, c) {
              return (
                (a = d(a)),
                (b = '' + b),
                (c = null == c ? 0 : Math.min(e(c), a.length)),
                a.lastIndexOf(b, c) === c
              );
            };
          },
          { './helper/makeString': 20, './helper/toPositive': 22 }
        ],
        52: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              (a = d(a)), (b = d(b));
              var c = b ? a.indexOf(b) : -1;
              return ~c ? a.slice(0, c) : a;
            };
          },
          { './helper/makeString': 20 }
        ],
        53: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              (a = d(a)), (b = d(b));
              var c = a.lastIndexOf(b);
              return ~c ? a.slice(0, c) : a;
            };
          },
          { './helper/makeString': 20 }
        ],
        54: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              (a = d(a)), (b = d(b));
              var c = b ? a.indexOf(b) : -1;
              return ~c ? a.slice(c + b.length, a.length) : a;
            };
          },
          { './helper/makeString': 20 }
        ],
        55: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              (a = d(a)), (b = d(b));
              var c = b ? a.lastIndexOf(b) : -1;
              return ~c ? a.slice(c + b.length, a.length) : a;
            };
          },
          { './helper/makeString': 20 }
        ],
        56: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return d(a).replace(/<\/?[^>]+>/g, '');
            };
          },
          { './helper/makeString': 20 }
        ],
        57: [
          function (a, b, c) {
            var d = a('./helper/adjacent');
            b.exports = function (a) {
              return d(a, 1);
            };
          },
          { './helper/adjacent': 15 }
        ],
        58: [
          function (a, b, c) {
            b.exports = function (a, b) {
              return [b, a, b].join('');
            };
          },
          {}
        ],
        59: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return d(a).replace(/\S/g, function (a) {
                return a === a.toUpperCase()
                  ? a.toLowerCase()
                  : a.toUpperCase();
              });
            };
          },
          { './helper/makeString': 20 }
        ],
        60: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a) {
              return d(a)
                .toLowerCase()
                .replace(/(?:^|\s|-)\S/g, function (a) {
                  return a.toUpperCase();
                });
            };
          },
          { './helper/makeString': 20 }
        ],
        61: [
          function (a, b, c) {
            function d(a, b) {
              var c,
                d,
                e = a.toLowerCase();
              for (b = [].concat(b), c = 0; c < b.length; c += 1)
                if ((d = b[c])) {
                  if (d.test && d.test(a)) return !0;
                  if (d.toLowerCase() === e) return !0;
                }
            }
            var e = a('./trim');
            b.exports = function (a, b, c) {
              return (
                'number' == typeof a && (a = '' + a),
                'string' != typeof a
                  ? !!a
                  : ((a = e(a)),
                    !!d(a, b || ['true', '1']) ||
                      (!d(a, c || ['false', '0']) && void 0))
              );
            };
          },
          { './trim': 65 }
        ],
        62: [
          function (a, b, c) {
            b.exports = function (a, b) {
              if (null == a) return 0;
              var c = Math.pow(10, isFinite(b) ? b : 0);
              return Math.round(a * c) / c;
            };
          },
          {}
        ],
        63: [
          function (a, b, c) {
            var d = a('./rtrim');
            b.exports = function (a, b, c, e) {
              (b = b || ', '), (c = c || ' and ');
              var f = a.slice(),
                g = f.pop();
              return (
                a.length > 2 && e && (c = d(b) + c),
                f.length ? f.join(b) + c + g : g
              );
            };
          },
          { './rtrim': 47 }
        ],
        64: [
          function (a, b, c) {
            var d = a('./toSentence');
            b.exports = function (a, b, c) {
              return d(a, b, c, !0);
            };
          },
          { './toSentence': 63 }
        ],
        65: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/defaultToWhiteSpace'),
              f = String.prototype.trim;
            b.exports = function (a, b) {
              return (
                (a = d(a)),
                !b && f
                  ? f.call(a)
                  : ((b = e(b)),
                    a.replace(new RegExp('^' + b + '+|' + b + '+$', 'g'), ''))
              );
            };
          },
          { './helper/defaultToWhiteSpace': 16, './helper/makeString': 20 }
        ],
        66: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b, c) {
              return (
                (a = d(a)),
                (c = c || '...'),
                (b = ~~b),
                a.length > b ? a.slice(0, b) + c : a
              );
            };
          },
          { './helper/makeString': 20 }
        ],
        67: [
          function (a, b, c) {
            var d = a('./trim');
            b.exports = function (a) {
              return d(a)
                .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
                .replace(/[-\s]+/g, '_')
                .toLowerCase();
            };
          },
          { './trim': 65 }
        ],
        68: [
          function (a, b, c) {
            var d = a('./helper/makeString'),
              e = a('./helper/htmlEntities');
            b.exports = function (a) {
              return d(a).replace(/\&([^;]+);/g, function (a, b) {
                var c;
                return b in e
                  ? e[b]
                  : (c = b.match(/^#x([\da-fA-F]+)$/))
                    ? String.fromCharCode(parseInt(c[1], 16))
                    : (c = b.match(/^#(\d+)$/))
                      ? String.fromCharCode(~~c[1])
                      : a;
              });
            };
          },
          { './helper/htmlEntities': 19, './helper/makeString': 20 }
        ],
        69: [
          function (a, b, c) {
            b.exports = function (a, b) {
              return (
                (b = b || '"'),
                a[0] === b && a[a.length - 1] === b
                  ? a.slice(1, a.length - 1)
                  : a
              );
            };
          },
          {}
        ],
        70: [
          function (a, b, c) {
            var d = a('util-deprecate');
            b.exports = d(
              a('sprintf-js').vsprintf,
              'vsprintf() will be removed in the next major release, use the sprintf-js package instead.'
            );
          },
          { 'sprintf-js': 36, 'util-deprecate': 37 }
        ],
        71: [
          function (a, b, c) {
            var d = a('./isBlank'),
              e = a('./trim');
            b.exports = function (a, b) {
              return d(a) ? [] : e(a, b).split(b || /\s+/);
            };
          },
          { './isBlank': 27, './trim': 65 }
        ],
        72: [
          function (a, b, c) {
            var d = a('./helper/makeString');
            b.exports = function (a, b) {
              (a = d(a)), (b = b || {});
              var c,
                e = b.width || 75,
                f = b.seperator || '\n',
                g = b.cut || !1,
                h = b.preserveSpaces || !1,
                i = b.trailingSpaces || !1;
              if (e <= 0) return a;
              if (g) {
                var j = 0;
                for (c = ''; j < a.length; )
                  j % e == 0 && j > 0 && (c += f), (c += a.charAt(j)), j++;
                if (i) for (; j % e > 0; ) (c += ' '), j++;
                return c;
              }
              var k = a.split(' '),
                l = 0;
              for (c = ''; k.length > 0; ) {
                if (1 + k[0].length + l > e && l > 0) {
                  if (h) (c += ' '), l++;
                  else if (i) for (; l < e; ) (c += ' '), l++;
                  (c += f), (l = 0);
                }
                l > 0 && ((c += ' '), l++),
                  (c += k[0]),
                  (l += k[0].length),
                  k.shift();
              }
              if (i) for (; l < e; ) (c += ' '), l++;
              return c;
            };
          },
          { './helper/makeString': 20 }
        ]
      },
      {},
      [25]
    )(25);
  }),
  Function.prototype.bind &&
    /^object$|^function$/.test(typeof console) &&
    'object' == typeof console.log &&
    'function' == typeof window.addEventListener &&
    ([
      'assert',
      'clear',
      'dir',
      'error',
      'info',
      'log',
      'profile',
      'profileEnd',
      'warn'
    ].forEach(function (a) {
      console[a] = this.call(console[a], console);
    }, Function.prototype.bind),
    [
      '_exception',
      'count',
      'debug',
      'dirxml',
      'group',
      'groupCollapsed',
      'groupEnd',
      'table',
      'time',
      'timeEnd',
      'timeline',
      'timelineEnd',
      'timeStamp',
      'trace'
    ].forEach(function (a) {
      console[a] = console.log;
    })),
  (function (a, b) {
    'undefined' != typeof module
      ? (module.exports = b())
      : 'function' == typeof define && define.amd
        ? define(b)
        : (window[a] = b());
  })('log', function () {
    var a,
      b = navigator.userAgent,
      c = (function () {
        var a = /Windows\sNT\s(\d+\.\d+)/;
        return !!(
          'undefined' != typeof console &&
          console.log &&
          /MSIE\s(\d+)/.test(b) &&
          a.test(b) &&
          parseFloat(a.exec(b)[1]) >= 6.1
        );
      })(),
      d = (function () {
        var a = Function.prototype.bind;
        return (
          (!a || (a && void 0 === window.addEventListener)) &&
          'object' == typeof console &&
          'object' == typeof console.log
        );
      })(),
      e = !c && !d && /Trident\//.test(b),
      f = c || (window.console && 'function' == typeof console.log),
      g = 0,
      h = { label: 'Log:', collapsed: !0 },
      i = function () {
        !(function (a, b, c, d, e, f, g, h, i, j, k) {
          a.getElementById(e) ||
            ((k = a[b + 'NS'] && a.documentElement.namespaceURI),
            (k = k ? a[b + 'NS'](k, 'script') : a[b]('script')),
            k[c]('id', e),
            k[c]('src', i + g + j),
            k[c](e, f),
            (a[d]('head')[0] || a[d]('body')[0]).appendChild(k),
            (k = new Image()),
            k[c]('src', i + h));
        })(
          document,
          'createElement',
          'setAttribute',
          'getElementsByTagName',
          'FirebugLite',
          '4',
          'firebug-lite.js',
          'releases/lite/latest/skin/xp/sprite.png',
          'https://getfirebug.com/',
          '#startOpened'
        );
      },
      j = function (a) {
        var b = a.split('\n').pop(),
          c = document.location.pathname.substr(
            0,
            document.location.pathname.lastIndexOf('/') + 1
          );
        return (
          b.indexOf(c) > -1 &&
            (b = b
              .replace(c, '')
              .replace(document.location.protocol + '//', '')),
          /[^\(\@]+\:\d+\:\d+\)?$/.test(b)
            ? (b = '@' + /([^\(\@]+\:\d+\:\d+)\)?$/.exec(b)[1])
            : (b.indexOf(' (') > -1
                ? (b = b.split(' (')[1].substring(0, b.length - 1))
                : b.indexOf('at ') > -1
                  ? (b = b.split('at ')[1])
                  : /([^\/]+\:\d+\:\d+)/.test(b) &&
                    (b = /([^\/]+\:\d+\:\d+)/.exec(b)[1]),
              (b = '@' + b.substring(b.lastIndexOf('/') + 1))),
          b
        );
      },
      k = function (a) {
        var b, c, d;
        if (null === a) return 'null';
        if (/function|undefined|string|boolean|number/.test(typeof a))
          return typeof a;
        if ('object' == typeof a)
          for (
            b = Object.prototype.toString.call(a),
              c = [
                'Math',
                'ErrorEvent',
                'Error',
                'Date',
                'RegExp',
                'Event',
                'Array'
              ],
              d = c.length;
            d--;

          )
            return b === '[object ' + c[d] + ']'
              ? c[d].toLowerCase()
              : 'object' == typeof HTMLElement && a instanceof HTMLElement
                ? 'element'
                : 'string' == typeof a.nodeName && 1 === a.nodeType
                  ? 'element'
                  : 'object' == typeof Node && a instanceof Node
                    ? 'node'
                    : 'number' == typeof a.nodeType &&
                        'string' == typeof a.nodeName
                      ? 'node'
                      : /^\[object (HTMLCollection|NodeList|Object)\]$/.test(
                            b
                          ) &&
                          'number' == typeof a.length &&
                          void 0 !== a.item &&
                          (0 === a.length ||
                            ('object' == typeof a[0] && a[0].nodeType > 0))
                        ? 'nodelist'
                        : 'object';
        return 'unknown';
      };
    return (
      (a = function () {
        var b,
          f,
          h = arguments,
          l = Array.prototype.slice.call(h),
          m = c || (window.console && 'function' == typeof console.log);
        if ((a.history.push(arguments), m)) {
          if (
            (a.options.group &&
              (a.options.group.collapsed
                ? console.groupCollapsed(a.options.group.label)
                : console.group(a.options.group.label)),
            a.options.lineNumber &&
              ((b = new Error()),
              b.fileName && b.lineNumber
                ? l.push(
                    '@' +
                      b.fileName.substr(b.fileName.lastIndexOf('/') + 1) +
                      ':' +
                      b.lineNumber +
                      ':1'
                  )
                : b.stack && l.push(j(b.stack))),
            a.detailPrint && a.needsDetailPrint)
          )
            for (
              console.log('-----------------'), h = a.detailPrint(h), f = 0;
              f < h.length;

            )
              console.log(h[f]), f++;
          else if (1 === l.length && 'string' == typeof l[0])
            console.log(l.toString());
          else if (e)
            for (f = 0; f < h.length; )
              e && 'object' === k(h[f]) ? console.dir(h[f]) : console.log(h[f]),
                f++;
          else console.log(l);
          a.options.group && console.groupEnd();
        } else if (d)
          if (a.detailPrint)
            for (
              h = a.detailPrint(h), h.unshift('-----------------'), f = 0;
              f < h.length;

            )
              Function.prototype.call.call(
                console.log,
                console,
                Array.prototype.slice.call([h[f]])
              ),
                f++;
          else
            Function.prototype.call.call(
              console.log,
              console,
              Array.prototype.slice.call(h)
            );
        else
          document.getElementById('FirebugLite')
            ? 20 > g
              ? (setTimeout(function () {
                  window.log.apply(window, h);
                }, 500),
                g++)
              : (g = 0)
            : (i(),
              setTimeout(function () {
                window.log.apply(window, h);
              }, 3e3),
              (a.needsDetailPrint = !1));
      }),
      (a.history = []),
      (a.options = { lineNumber: !0, group: !1 }),
      e && !a.options.group && (a.options.group = h),
      (a.settings = function (b) {
        b &&
          'object' === k(b) &&
          (f &&
            console.group &&
            ('boolean' == typeof b.group
              ? (a.options.group = !!b.group && h)
              : 'object' === k(b.group) &&
                ((a.options.group = h),
                void 0 !== b.group.collapsed &&
                  (a.options.group.collapsed = !!b.group.collapsed),
                'string' == typeof b.group.label &&
                  (a.options.group.label = b.group.label))),
          void 0 !== b.lineNumber && (a.options.lineNumber = !!b.lineNumber));
      }),
      a
    );
  }),
  (function (a) {
    'function' == typeof define && define.amd
      ? define(['jquery'], a)
      : a(jQuery);
  })(function (a) {
    function b(a) {
      for (var b = a.css('visibility'); 'inherit' === b; )
        (a = a.parent()), (b = a.css('visibility'));
      return 'hidden' !== b;
    }
    function c(a) {
      for (var b, c; a.length && a[0] !== document; ) {
        if (
          ('absolute' === (b = a.css('position')) ||
            'relative' === b ||
            'fixed' === b) &&
          ((c = parseInt(a.css('zIndex'), 10)), !isNaN(c) && 0 !== c)
        )
          return c;
        a = a.parent();
      }
      return 0;
    }
    function d() {
      (this._curInst = null),
        (this._keyEvent = !1),
        (this._disabledInputs = []),
        (this._datepickerShowing = !1),
        (this._inDialog = !1),
        (this._mainDivId = 'ui-datepicker-div'),
        (this._inlineClass = 'ui-datepicker-inline'),
        (this._appendClass = 'ui-datepicker-append'),
        (this._triggerClass = 'ui-datepicker-trigger'),
        (this._dialogClass = 'ui-datepicker-dialog'),
        (this._disableClass = 'ui-datepicker-disabled'),
        (this._unselectableClass = 'ui-datepicker-unselectable'),
        (this._currentClass = 'ui-datepicker-current-day'),
        (this._dayOverClass = 'ui-datepicker-days-cell-over'),
        (this.regional = []),
        (this.regional[''] = {
          closeText: 'Done',
          prevText: 'Prev',
          nextText: 'Next',
          currentText: 'Today',
          monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          monthNamesShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          dayNames: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          weekHeader: 'Wk',
          dateFormat: 'mm/dd/yy',
          firstDay: 0,
          isRTL: !1,
          showMonthAfterYear: !1,
          yearSuffix: ''
        }),
        (this._defaults = {
          showOn: 'focus',
          showAnim: 'fadeIn',
          showOptions: {},
          defaultDate: null,
          appendText: '',
          buttonText: '...',
          buttonImage: '',
          buttonImageOnly: !1,
          hideIfNoPrevNext: !1,
          navigationAsDateFormat: !1,
          gotoCurrent: !1,
          changeMonth: !1,
          changeYear: !1,
          yearRange: 'c-10:c+10',
          showOtherMonths: !1,
          selectOtherMonths: !1,
          showWeek: !1,
          calculateWeek: this.iso8601Week,
          shortYearCutoff: '+10',
          minDate: null,
          maxDate: null,
          duration: 'fast',
          beforeShowDay: null,
          beforeShow: null,
          onSelect: null,
          onChangeMonthYear: null,
          onClose: null,
          numberOfMonths: 1,
          showCurrentAtPos: 0,
          stepMonths: 1,
          stepBigMonths: 12,
          altField: '',
          altFormat: '',
          constrainInput: !0,
          showButtonPanel: !1,
          autoSize: !1,
          disabled: !1
        }),
        a.extend(this._defaults, this.regional['']),
        (this.regional.en = a.extend(!0, {}, this.regional[''])),
        (this.regional['en-US'] = a.extend(!0, {}, this.regional.en)),
        (this.dpDiv = e(
          a(
            "<div id='" +
              this._mainDivId +
              "' class='ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>"
          )
        ));
    }
    function e(b) {
      var c =
        'button, .ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-calendar td a';
      return b
        .on('mouseout', c, function () {
          a(this).removeClass('ui-state-hover'),
            -1 !== this.className.indexOf('ui-datepicker-prev') &&
              a(this).removeClass('ui-datepicker-prev-hover'),
            -1 !== this.className.indexOf('ui-datepicker-next') &&
              a(this).removeClass('ui-datepicker-next-hover');
        })
        .on('mouseover', c, f);
    }
    function f() {
      a.datepicker._isDisabledDatepicker(
        p.inline ? p.dpDiv.parent()[0] : p.input[0]
      ) ||
        (a(this)
          .parents('.ui-datepicker-calendar')
          .find('a')
          .removeClass('ui-state-hover'),
        a(this).addClass('ui-state-hover'),
        -1 !== this.className.indexOf('ui-datepicker-prev') &&
          a(this).addClass('ui-datepicker-prev-hover'),
        -1 !== this.className.indexOf('ui-datepicker-next') &&
          a(this).addClass('ui-datepicker-next-hover'));
    }
    function g(b, c) {
      a.extend(b, c);
      for (var d in c) null == c[d] && (b[d] = c[d]);
      return b;
    }
    function h(a) {
      return function () {
        var b = this.element.val();
        a.apply(this, arguments),
          this._refresh(),
          b !== this.element.val() && this._trigger('change');
      };
    }
    (a.ui = a.ui || {}), (a.ui.version = '1.12.1');
    var i = 0,
      j = Array.prototype.slice;
    (a.cleanData = (function (b) {
      return function (c) {
        var d, e, f;
        for (f = 0; null != (e = c[f]); f++)
          try {
            (d = a._data(e, 'events')) &&
              d.remove &&
              a(e).triggerHandler('remove');
          } catch (a) {}
        b(c);
      };
    })(a.cleanData)),
      (a.widget = function (b, c, d) {
        var e,
          f,
          g,
          h = {},
          i = b.split('.')[0];
        b = b.split('.')[1];
        var j = i + '-' + b;
        return (
          d || ((d = c), (c = a.Widget)),
          a.isArray(d) && (d = a.extend.apply(null, [{}].concat(d))),
          (a.expr[':'][j.toLowerCase()] = function (b) {
            return !!a.data(b, j);
          }),
          (a[i] = a[i] || {}),
          (e = a[i][b]),
          (f = a[i][b] =
            function (a, b) {
              return this._createWidget
                ? void (arguments.length && this._createWidget(a, b))
                : new f(a, b);
            }),
          a.extend(f, e, {
            version: d.version,
            _proto: a.extend({}, d),
            _childConstructors: []
          }),
          (g = new c()),
          (g.options = a.widget.extend({}, g.options)),
          a.each(d, function (b, d) {
            return a.isFunction(d)
              ? void (h[b] = (function () {
                  function a() {
                    return c.prototype[b].apply(this, arguments);
                  }
                  function e(a) {
                    return c.prototype[b].apply(this, a);
                  }
                  return function () {
                    var b,
                      c = this._super,
                      f = this._superApply;
                    return (
                      (this._super = a),
                      (this._superApply = e),
                      (b = d.apply(this, arguments)),
                      (this._super = c),
                      (this._superApply = f),
                      b
                    );
                  };
                })())
              : void (h[b] = d);
          }),
          (f.prototype = a.widget.extend(
            g,
            { widgetEventPrefix: e ? g.widgetEventPrefix || b : b },
            h,
            { constructor: f, namespace: i, widgetName: b, widgetFullName: j }
          )),
          e
            ? (a.each(e._childConstructors, function (b, c) {
                var d = c.prototype;
                a.widget(d.namespace + '.' + d.widgetName, f, c._proto);
              }),
              delete e._childConstructors)
            : c._childConstructors.push(f),
          a.widget.bridge(b, f),
          f
        );
      }),
      (a.widget.extend = function (b) {
        for (
          var c, d, e = j.call(arguments, 1), f = 0, g = e.length;
          g > f;
          f++
        )
          for (c in e[f])
            (d = e[f][c]),
              e[f].hasOwnProperty(c) &&
                void 0 !== d &&
                (b[c] = a.isPlainObject(d)
                  ? a.isPlainObject(b[c])
                    ? a.widget.extend({}, b[c], d)
                    : a.widget.extend({}, d)
                  : d);
        return b;
      }),
      (a.widget.bridge = function (b, c) {
        var d = c.prototype.widgetFullName || b;
        a.fn[b] = function (e) {
          var f = 'string' == typeof e,
            g = j.call(arguments, 1),
            h = this;
          return (
            f
              ? this.length || 'instance' !== e
                ? this.each(function () {
                    var c,
                      f = a.data(this, d);
                    return 'instance' === e
                      ? ((h = f), !1)
                      : f
                        ? a.isFunction(f[e]) && '_' !== e.charAt(0)
                          ? ((c = f[e].apply(f, g)),
                            c !== f && void 0 !== c
                              ? ((h = c && c.jquery ? h.pushStack(c.get()) : c),
                                !1)
                              : void 0)
                          : a.error(
                              "no such method '" +
                                e +
                                "' for " +
                                b +
                                ' widget instance'
                            )
                        : a.error(
                            'cannot call methods on ' +
                              b +
                              " prior to initialization; attempted to call method '" +
                              e +
                              "'"
                          );
                  })
                : (h = void 0)
              : (g.length && (e = a.widget.extend.apply(null, [e].concat(g))),
                this.each(function () {
                  var b = a.data(this, d);
                  b
                    ? (b.option(e || {}), b._init && b._init())
                    : a.data(this, d, new c(e, this));
                })),
            h
          );
        };
      }),
      (a.Widget = function () {}),
      (a.Widget._childConstructors = []),
      (a.Widget.prototype = {
        widgetName: 'widget',
        widgetEventPrefix: '',
        defaultElement: '<div>',
        options: { classes: {}, disabled: !1, create: null },
        _createWidget: function (b, c) {
          (c = a(c || this.defaultElement || this)[0]),
            (this.element = a(c)),
            (this.uuid = i++),
            (this.eventNamespace = '.' + this.widgetName + this.uuid),
            (this.bindings = a()),
            (this.hoverable = a()),
            (this.focusable = a()),
            (this.classesElementLookup = {}),
            c !== this &&
              (a.data(c, this.widgetFullName, this),
              this._on(!0, this.element, {
                remove: function (a) {
                  a.target === c && this.destroy();
                }
              }),
              (this.document = a(c.style ? c.ownerDocument : c.document || c)),
              (this.window = a(
                this.document[0].defaultView || this.document[0].parentWindow
              ))),
            (this.options = a.widget.extend(
              {},
              this.options,
              this._getCreateOptions(),
              b
            )),
            this._create(),
            this.options.disabled &&
              this._setOptionDisabled(this.options.disabled),
            this._trigger('create', null, this._getCreateEventData()),
            this._init();
        },
        _getCreateOptions: function () {
          return {};
        },
        _getCreateEventData: a.noop,
        _create: a.noop,
        _init: a.noop,
        destroy: function () {
          var b = this;
          this._destroy(),
            a.each(this.classesElementLookup, function (a, c) {
              b._removeClass(c, a);
            }),
            this.element
              .off(this.eventNamespace)
              .removeData(this.widgetFullName),
            this.widget().off(this.eventNamespace).removeAttr('aria-disabled'),
            this.bindings.off(this.eventNamespace);
        },
        _destroy: a.noop,
        widget: function () {
          return this.element;
        },
        option: function (b, c) {
          var d,
            e,
            f,
            g = b;
          if (0 === arguments.length) return a.widget.extend({}, this.options);
          if ('string' == typeof b)
            if (((g = {}), (d = b.split('.')), (b = d.shift()), d.length)) {
              for (
                e = g[b] = a.widget.extend({}, this.options[b]), f = 0;
                d.length - 1 > f;
                f++
              )
                (e[d[f]] = e[d[f]] || {}), (e = e[d[f]]);
              if (((b = d.pop()), 1 === arguments.length))
                return void 0 === e[b] ? null : e[b];
              e[b] = c;
            } else {
              if (1 === arguments.length)
                return void 0 === this.options[b] ? null : this.options[b];
              g[b] = c;
            }
          return this._setOptions(g), this;
        },
        _setOptions: function (a) {
          var b;
          for (b in a) this._setOption(b, a[b]);
          return this;
        },
        _setOption: function (a, b) {
          return (
            'classes' === a && this._setOptionClasses(b),
            (this.options[a] = b),
            'disabled' === a && this._setOptionDisabled(b),
            this
          );
        },
        _setOptionClasses: function (b) {
          var c, d, e;
          for (c in b)
            (e = this.classesElementLookup[c]),
              b[c] !== this.options.classes[c] &&
                e &&
                e.length &&
                ((d = a(e.get())),
                this._removeClass(e, c),
                d.addClass(
                  this._classes({ element: d, keys: c, classes: b, add: !0 })
                ));
        },
        _setOptionDisabled: function (a) {
          this._toggleClass(
            this.widget(),
            this.widgetFullName + '-disabled',
            null,
            !!a
          ),
            a &&
              (this._removeClass(this.hoverable, null, 'ui-state-hover'),
              this._removeClass(this.focusable, null, 'ui-state-focus'));
        },
        enable: function () {
          return this._setOptions({ disabled: !1 });
        },
        disable: function () {
          return this._setOptions({ disabled: !0 });
        },
        _classes: function (b) {
          function c(c, f) {
            var g, h;
            for (h = 0; c.length > h; h++)
              (g = e.classesElementLookup[c[h]] || a()),
                (g = a(
                  b.add
                    ? a.unique(g.get().concat(b.element.get()))
                    : g.not(b.element).get()
                )),
                (e.classesElementLookup[c[h]] = g),
                d.push(c[h]),
                f && b.classes[c[h]] && d.push(b.classes[c[h]]);
          }
          var d = [],
            e = this;
          return (
            (b = a.extend(
              { element: this.element, classes: this.options.classes || {} },
              b
            )),
            this._on(b.element, { remove: '_untrackClassesElement' }),
            b.keys && c(b.keys.match(/\S+/g) || [], !0),
            b.extra && c(b.extra.match(/\S+/g) || []),
            d.join(' ')
          );
        },
        _untrackClassesElement: function (b) {
          var c = this;
          a.each(c.classesElementLookup, function (d, e) {
            -1 !== a.inArray(b.target, e) &&
              (c.classesElementLookup[d] = a(e.not(b.target).get()));
          });
        },
        _removeClass: function (a, b, c) {
          return this._toggleClass(a, b, c, !1);
        },
        _addClass: function (a, b, c) {
          return this._toggleClass(a, b, c, !0);
        },
        _toggleClass: function (a, b, c, d) {
          d = 'boolean' == typeof d ? d : c;
          var e = 'string' == typeof a || null === a,
            f = {
              extra: e ? b : c,
              keys: e ? a : b,
              element: e ? this.element : a,
              add: d
            };
          return f.element.toggleClass(this._classes(f), d), this;
        },
        _on: function (b, c, d) {
          var e,
            f = this;
          'boolean' != typeof b && ((d = c), (c = b), (b = !1)),
            d
              ? ((c = e = a(c)), (this.bindings = this.bindings.add(c)))
              : ((d = c), (c = this.element), (e = this.widget())),
            a.each(d, function (d, g) {
              function h() {
                return b ||
                  (!0 !== f.options.disabled &&
                    !a(this).hasClass('ui-state-disabled'))
                  ? ('string' == typeof g ? f[g] : g).apply(f, arguments)
                  : void 0;
              }
              'string' != typeof g &&
                (h.guid = g.guid = g.guid || h.guid || a.guid++);
              var i = d.match(/^([\w:-]*)\s*(.*)$/),
                j = i[1] + f.eventNamespace,
                k = i[2];
              k ? e.on(j, k, h) : c.on(j, h);
            });
        },
        _off: function (b, c) {
          (c =
            (c || '').split(' ').join(this.eventNamespace + ' ') +
            this.eventNamespace),
            b.off(c).off(c),
            (this.bindings = a(this.bindings.not(b).get())),
            (this.focusable = a(this.focusable.not(b).get())),
            (this.hoverable = a(this.hoverable.not(b).get()));
        },
        _delay: function (a, b) {
          function c() {
            return ('string' == typeof a ? d[a] : a).apply(d, arguments);
          }
          var d = this;
          return setTimeout(c, b || 0);
        },
        _hoverable: function (b) {
          (this.hoverable = this.hoverable.add(b)),
            this._on(b, {
              mouseenter: function (b) {
                this._addClass(a(b.currentTarget), null, 'ui-state-hover');
              },
              mouseleave: function (b) {
                this._removeClass(a(b.currentTarget), null, 'ui-state-hover');
              }
            });
        },
        _focusable: function (b) {
          (this.focusable = this.focusable.add(b)),
            this._on(b, {
              focusin: function (b) {
                this._addClass(a(b.currentTarget), null, 'ui-state-focus');
              },
              focusout: function (b) {
                this._removeClass(a(b.currentTarget), null, 'ui-state-focus');
              }
            });
        },
        _trigger: function (b, c, d) {
          var e,
            f,
            g = this.options[b];
          if (
            ((d = d || {}),
            (c = a.Event(c)),
            (c.type = (
              b === this.widgetEventPrefix ? b : this.widgetEventPrefix + b
            ).toLowerCase()),
            (c.target = this.element[0]),
            (f = c.originalEvent))
          )
            for (e in f) e in c || (c[e] = f[e]);
          return (
            this.element.trigger(c, d),
            !(
              (a.isFunction(g) &&
                !1 === g.apply(this.element[0], [c].concat(d))) ||
              c.isDefaultPrevented()
            )
          );
        }
      }),
      a.each({ show: 'fadeIn', hide: 'fadeOut' }, function (b, c) {
        a.Widget.prototype['_' + b] = function (d, e, f) {
          'string' == typeof e && (e = { effect: e });
          var g,
            h = e ? (!0 === e || 'number' == typeof e ? c : e.effect || c) : b;
          (e = e || {}),
            'number' == typeof e && (e = { duration: e }),
            (g = !a.isEmptyObject(e)),
            (e.complete = f),
            e.delay && d.delay(e.delay),
            g && a.effects && a.effects.effect[h]
              ? d[b](e)
              : h !== b && d[h]
                ? d[h](e.duration, e.easing, f)
                : d.queue(function (c) {
                    a(this)[b](), f && f.call(d[0]), c();
                  });
        };
      }),
      a.widget,
      (function () {
        function b(a, b, c) {
          return [
            parseFloat(a[0]) * (l.test(a[0]) ? b / 100 : 1),
            parseFloat(a[1]) * (l.test(a[1]) ? c / 100 : 1)
          ];
        }
        function c(b, c) {
          return parseInt(a.css(b, c), 10) || 0;
        }
        function d(b) {
          var c = b[0];
          return 9 === c.nodeType
            ? {
                width: b.width(),
                height: b.height(),
                offset: { top: 0, left: 0 }
              }
            : a.isWindow(c)
              ? {
                  width: b.width(),
                  height: b.height(),
                  offset: { top: b.scrollTop(), left: b.scrollLeft() }
                }
              : c.preventDefault
                ? {
                    width: 0,
                    height: 0,
                    offset: { top: c.pageY, left: c.pageX }
                  }
                : {
                    width: b.outerWidth(),
                    height: b.outerHeight(),
                    offset: b.offset()
                  };
        }
        var e,
          f = Math.max,
          g = Math.abs,
          h = /left|center|right/,
          i = /top|center|bottom/,
          j = /[\+\-]\d+(\.[\d]+)?%?/,
          k = /^\w+/,
          l = /%$/,
          m = a.fn.position;
        (a.position = {
          scrollbarWidth: function () {
            if (void 0 !== e) return e;
            var b,
              c,
              d = a(
                "<div style='display:block;position:absolute;width:50px;height:50px;overflow:hidden;'><div style='height:100px;width:auto;'></div></div>"
              ),
              f = d.children()[0];
            return (
              a('body').append(d),
              (b = f.offsetWidth),
              d.css('overflow', 'scroll'),
              (c = f.offsetWidth),
              b === c && (c = d[0].clientWidth),
              d.remove(),
              (e = b - c)
            );
          },
          getScrollInfo: function (b) {
            var c =
                b.isWindow || b.isDocument ? '' : b.element.css('overflow-x'),
              d = b.isWindow || b.isDocument ? '' : b.element.css('overflow-y'),
              e =
                'scroll' === c ||
                ('auto' === c && b.width < b.element[0].scrollWidth);
            return {
              width:
                'scroll' === d ||
                ('auto' === d && b.height < b.element[0].scrollHeight)
                  ? a.position.scrollbarWidth()
                  : 0,
              height: e ? a.position.scrollbarWidth() : 0
            };
          },
          getWithinInfo: function (b) {
            var c = a(b || window),
              d = a.isWindow(c[0]),
              e = !!c[0] && 9 === c[0].nodeType;
            return {
              element: c,
              isWindow: d,
              isDocument: e,
              offset: d || e ? { left: 0, top: 0 } : a(b).offset(),
              scrollLeft: c.scrollLeft(),
              scrollTop: c.scrollTop(),
              width: c.outerWidth(),
              height: c.outerHeight()
            };
          }
        }),
          (a.fn.position = function (e) {
            if (!e || !e.of) return m.apply(this, arguments);
            e = a.extend({}, e);
            var l,
              n,
              o,
              p,
              q,
              r,
              s = a(e.of),
              t = a.position.getWithinInfo(e.within),
              u = a.position.getScrollInfo(t),
              v = (e.collision || 'flip').split(' '),
              w = {};
            return (
              (r = d(s)),
              s[0].preventDefault && (e.at = 'left top'),
              (n = r.width),
              (o = r.height),
              (p = r.offset),
              (q = a.extend({}, p)),
              a.each(['my', 'at'], function () {
                var a,
                  b,
                  c = (e[this] || '').split(' ');
                1 === c.length &&
                  (c = h.test(c[0])
                    ? c.concat(['center'])
                    : i.test(c[0])
                      ? ['center'].concat(c)
                      : ['center', 'center']),
                  (c[0] = h.test(c[0]) ? c[0] : 'center'),
                  (c[1] = i.test(c[1]) ? c[1] : 'center'),
                  (a = j.exec(c[0])),
                  (b = j.exec(c[1])),
                  (w[this] = [a ? a[0] : 0, b ? b[0] : 0]),
                  (e[this] = [k.exec(c[0])[0], k.exec(c[1])[0]]);
              }),
              1 === v.length && (v[1] = v[0]),
              'right' === e.at[0]
                ? (q.left += n)
                : 'center' === e.at[0] && (q.left += n / 2),
              'bottom' === e.at[1]
                ? (q.top += o)
                : 'center' === e.at[1] && (q.top += o / 2),
              (l = b(w.at, n, o)),
              (q.left += l[0]),
              (q.top += l[1]),
              this.each(function () {
                var d,
                  h,
                  i = a(this),
                  j = i.outerWidth(),
                  k = i.outerHeight(),
                  m = c(this, 'marginLeft'),
                  r = c(this, 'marginTop'),
                  x = j + m + c(this, 'marginRight') + u.width,
                  y = k + r + c(this, 'marginBottom') + u.height,
                  z = a.extend({}, q),
                  A = b(w.my, i.outerWidth(), i.outerHeight());
                'right' === e.my[0]
                  ? (z.left -= j)
                  : 'center' === e.my[0] && (z.left -= j / 2),
                  'bottom' === e.my[1]
                    ? (z.top -= k)
                    : 'center' === e.my[1] && (z.top -= k / 2),
                  (z.left += A[0]),
                  (z.top += A[1]),
                  (d = { marginLeft: m, marginTop: r }),
                  a.each(['left', 'top'], function (b, c) {
                    a.ui.position[v[b]] &&
                      a.ui.position[v[b]][c](z, {
                        targetWidth: n,
                        targetHeight: o,
                        elemWidth: j,
                        elemHeight: k,
                        collisionPosition: d,
                        collisionWidth: x,
                        collisionHeight: y,
                        offset: [l[0] + A[0], l[1] + A[1]],
                        my: e.my,
                        at: e.at,
                        within: t,
                        elem: i
                      });
                  }),
                  e.using &&
                    (h = function (a) {
                      var b = p.left - z.left,
                        c = b + n - j,
                        d = p.top - z.top,
                        h = d + o - k,
                        l = {
                          target: {
                            element: s,
                            left: p.left,
                            top: p.top,
                            width: n,
                            height: o
                          },
                          element: {
                            element: i,
                            left: z.left,
                            top: z.top,
                            width: j,
                            height: k
                          },
                          horizontal:
                            0 > c ? 'left' : b > 0 ? 'right' : 'center',
                          vertical: 0 > h ? 'top' : d > 0 ? 'bottom' : 'middle'
                        };
                      j > n && n > g(b + c) && (l.horizontal = 'center'),
                        k > o && o > g(d + h) && (l.vertical = 'middle'),
                        (l.important =
                          f(g(b), g(c)) > f(g(d), g(h))
                            ? 'horizontal'
                            : 'vertical'),
                        e.using.call(this, a, l);
                    }),
                  i.offset(a.extend(z, { using: h }));
              })
            );
          }),
          (a.ui.position = {
            fit: {
              left: function (a, b) {
                var c,
                  d = b.within,
                  e = d.isWindow ? d.scrollLeft : d.offset.left,
                  g = d.width,
                  h = a.left - b.collisionPosition.marginLeft,
                  i = e - h,
                  j = h + b.collisionWidth - g - e;
                b.collisionWidth > g
                  ? i > 0 && 0 >= j
                    ? ((c = a.left + i + b.collisionWidth - g - e),
                      (a.left += i - c))
                    : (a.left =
                        j > 0 && 0 >= i
                          ? e
                          : i > j
                            ? e + g - b.collisionWidth
                            : e)
                  : i > 0
                    ? (a.left += i)
                    : j > 0
                      ? (a.left -= j)
                      : (a.left = f(a.left - h, a.left));
              },
              top: function (a, b) {
                var c,
                  d = b.within,
                  e = d.isWindow ? d.scrollTop : d.offset.top,
                  g = b.within.height,
                  h = a.top - b.collisionPosition.marginTop,
                  i = e - h,
                  j = h + b.collisionHeight - g - e;
                b.collisionHeight > g
                  ? i > 0 && 0 >= j
                    ? ((c = a.top + i + b.collisionHeight - g - e),
                      (a.top += i - c))
                    : (a.top =
                        j > 0 && 0 >= i
                          ? e
                          : i > j
                            ? e + g - b.collisionHeight
                            : e)
                  : i > 0
                    ? (a.top += i)
                    : j > 0
                      ? (a.top -= j)
                      : (a.top = f(a.top - h, a.top));
              }
            },
            flip: {
              left: function (a, b) {
                var c,
                  d,
                  e = b.within,
                  f = e.offset.left + e.scrollLeft,
                  h = e.width,
                  i = e.isWindow ? e.scrollLeft : e.offset.left,
                  j = a.left - b.collisionPosition.marginLeft,
                  k = j - i,
                  l = j + b.collisionWidth - h - i,
                  m =
                    'left' === b.my[0]
                      ? -b.elemWidth
                      : 'right' === b.my[0]
                        ? b.elemWidth
                        : 0,
                  n =
                    'left' === b.at[0]
                      ? b.targetWidth
                      : 'right' === b.at[0]
                        ? -b.targetWidth
                        : 0,
                  o = -2 * b.offset[0];
                0 > k
                  ? (0 > (c = a.left + m + n + o + b.collisionWidth - h - f) ||
                      g(k) > c) &&
                    (a.left += m + n + o)
                  : l > 0 &&
                    ((d =
                      a.left - b.collisionPosition.marginLeft + m + n + o - i) >
                      0 ||
                      l > g(d)) &&
                    (a.left += m + n + o);
              },
              top: function (a, b) {
                var c,
                  d,
                  e = b.within,
                  f = e.offset.top + e.scrollTop,
                  h = e.height,
                  i = e.isWindow ? e.scrollTop : e.offset.top,
                  j = a.top - b.collisionPosition.marginTop,
                  k = j - i,
                  l = j + b.collisionHeight - h - i,
                  m = 'top' === b.my[1],
                  n = m
                    ? -b.elemHeight
                    : 'bottom' === b.my[1]
                      ? b.elemHeight
                      : 0,
                  o =
                    'top' === b.at[1]
                      ? b.targetHeight
                      : 'bottom' === b.at[1]
                        ? -b.targetHeight
                        : 0,
                  p = -2 * b.offset[1];
                0 > k
                  ? (0 > (d = a.top + n + o + p + b.collisionHeight - h - f) ||
                      g(k) > d) &&
                    (a.top += n + o + p)
                  : l > 0 &&
                    ((c =
                      a.top - b.collisionPosition.marginTop + n + o + p - i) >
                      0 ||
                      l > g(c)) &&
                    (a.top += n + o + p);
              }
            },
            flipfit: {
              left: function () {
                a.ui.position.flip.left.apply(this, arguments),
                  a.ui.position.fit.left.apply(this, arguments);
              },
              top: function () {
                a.ui.position.flip.top.apply(this, arguments),
                  a.ui.position.fit.top.apply(this, arguments);
              }
            }
          });
      })(),
      a.ui.position,
      a.extend(a.expr[':'], {
        data: a.expr.createPseudo
          ? a.expr.createPseudo(function (b) {
              return function (c) {
                return !!a.data(c, b);
              };
            })
          : function (b, c, d) {
              return !!a.data(b, d[3]);
            }
      }),
      a.fn.extend({
        disableSelection: (function () {
          var a =
            'onselectstart' in document.createElement('div')
              ? 'selectstart'
              : 'mousedown';
          return function () {
            return this.on(a + '.ui-disableSelection', function (a) {
              a.preventDefault();
            });
          };
        })(),
        enableSelection: function () {
          return this.off('.ui-disableSelection');
        }
      });
    var k = 'ui-effects-',
      l = 'ui-effects-style',
      m = 'ui-effects-animated',
      n = a;
    (a.effects = { effect: {} }),
      (function (a, b) {
        function c(a, b, c) {
          var d = k[b.type] || {};
          return null == a
            ? c || !b.def
              ? null
              : b.def
            : ((a = d.floor ? ~~a : parseFloat(a)),
              isNaN(a)
                ? b.def
                : d.mod
                  ? (a + d.mod) % d.mod
                  : 0 > a
                    ? 0
                    : a > d.max
                      ? d.max
                      : a);
        }
        function d(c) {
          var d = i(),
            e = (d._rgba = []);
          return (
            (c = c.toLowerCase()),
            n(h, function (a, f) {
              var g,
                h = f.re.exec(c),
                i = h && f.parse(h),
                k = f.space || 'rgba';
              return i
                ? ((g = d[k](i)),
                  (d[j[k].cache] = g[j[k].cache]),
                  (e = d._rgba = g._rgba),
                  !1)
                : b;
            }),
            e.length
              ? ('0,0,0,0' === e.join() && a.extend(e, f.transparent), d)
              : f[c]
          );
        }
        function e(a, b, c) {
          return (
            (c = (c + 1) % 1),
            1 > 6 * c
              ? a + 6 * (b - a) * c
              : 1 > 2 * c
                ? b
                : 2 > 3 * c
                  ? a + 6 * (b - a) * (2 / 3 - c)
                  : a
          );
        }
        var f,
          g = /^([\-+])=\s*(\d+\.?\d*)/,
          h = [
            {
              re: /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
              parse: function (a) {
                return [a[1], a[2], a[3], a[4]];
              }
            },
            {
              re: /rgba?\(\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
              parse: function (a) {
                return [2.55 * a[1], 2.55 * a[2], 2.55 * a[3], a[4]];
              }
            },
            {
              re: /#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/,
              parse: function (a) {
                return [
                  parseInt(a[1], 16),
                  parseInt(a[2], 16),
                  parseInt(a[3], 16)
                ];
              }
            },
            {
              re: /#([a-f0-9])([a-f0-9])([a-f0-9])/,
              parse: function (a) {
                return [
                  parseInt(a[1] + a[1], 16),
                  parseInt(a[2] + a[2], 16),
                  parseInt(a[3] + a[3], 16)
                ];
              }
            },
            {
              re: /hsla?\(\s*(\d+(?:\.\d+)?)\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
              space: 'hsla',
              parse: function (a) {
                return [a[1], a[2] / 100, a[3] / 100, a[4]];
              }
            }
          ],
          i = (a.Color = function (b, c, d, e) {
            return new a.Color.fn.parse(b, c, d, e);
          }),
          j = {
            rgba: {
              props: {
                red: { idx: 0, type: 'byte' },
                green: { idx: 1, type: 'byte' },
                blue: { idx: 2, type: 'byte' }
              }
            },
            hsla: {
              props: {
                hue: { idx: 0, type: 'degrees' },
                saturation: { idx: 1, type: 'percent' },
                lightness: { idx: 2, type: 'percent' }
              }
            }
          },
          k = {
            byte: { floor: !0, max: 255 },
            percent: { max: 1 },
            degrees: { mod: 360, floor: !0 }
          },
          l = (i.support = {}),
          m = a('<p>')[0],
          n = a.each;
        (m.style.cssText = 'background-color:rgba(1,1,1,.5)'),
          (l.rgba = m.style.backgroundColor.indexOf('rgba') > -1),
          n(j, function (a, b) {
            (b.cache = '_' + a),
              (b.props.alpha = { idx: 3, type: 'percent', def: 1 });
          }),
          (i.fn = a.extend(i.prototype, {
            parse: function (e, g, h, k) {
              if (e === b) return (this._rgba = [null, null, null, null]), this;
              (e.jquery || e.nodeType) && ((e = a(e).css(g)), (g = b));
              var l = this,
                m = a.type(e),
                o = (this._rgba = []);
              return (
                g !== b && ((e = [e, g, h, k]), (m = 'array')),
                'string' === m
                  ? this.parse(d(e) || f._default)
                  : 'array' === m
                    ? (n(j.rgba.props, function (a, b) {
                        o[b.idx] = c(e[b.idx], b);
                      }),
                      this)
                    : 'object' === m
                      ? (e instanceof i
                          ? n(j, function (a, b) {
                              e[b.cache] && (l[b.cache] = e[b.cache].slice());
                            })
                          : n(j, function (b, d) {
                              var f = d.cache;
                              n(d.props, function (a, b) {
                                if (!l[f] && d.to) {
                                  if ('alpha' === a || null == e[a]) return;
                                  l[f] = d.to(l._rgba);
                                }
                                l[f][b.idx] = c(e[a], b, !0);
                              }),
                                l[f] &&
                                  0 > a.inArray(null, l[f].slice(0, 3)) &&
                                  ((l[f][3] = 1),
                                  d.from && (l._rgba = d.from(l[f])));
                            }),
                        this)
                      : b
              );
            },
            is: function (a) {
              var c = i(a),
                d = !0,
                e = this;
              return (
                n(j, function (a, f) {
                  var g,
                    h = c[f.cache];
                  return (
                    h &&
                      ((g = e[f.cache] || (f.to && f.to(e._rgba)) || []),
                      n(f.props, function (a, c) {
                        return null != h[c.idx]
                          ? (d = h[c.idx] === g[c.idx])
                          : b;
                      })),
                    d
                  );
                }),
                d
              );
            },
            _space: function () {
              var a = [],
                b = this;
              return (
                n(j, function (c, d) {
                  b[d.cache] && a.push(c);
                }),
                a.pop()
              );
            },
            transition: function (a, b) {
              var d = i(a),
                e = d._space(),
                f = j[e],
                g = 0 === this.alpha() ? i('transparent') : this,
                h = g[f.cache] || f.to(g._rgba),
                l = h.slice();
              return (
                (d = d[f.cache]),
                n(f.props, function (a, e) {
                  var f = e.idx,
                    g = h[f],
                    i = d[f],
                    j = k[e.type] || {};
                  null !== i &&
                    (null === g
                      ? (l[f] = i)
                      : (j.mod &&
                          (i - g > j.mod / 2
                            ? (g += j.mod)
                            : g - i > j.mod / 2 && (g -= j.mod)),
                        (l[f] = c((i - g) * b + g, e))));
                }),
                this[e](l)
              );
            },
            blend: function (b) {
              if (1 === this._rgba[3]) return this;
              var c = this._rgba.slice(),
                d = c.pop(),
                e = i(b)._rgba;
              return i(
                a.map(c, function (a, b) {
                  return (1 - d) * e[b] + d * a;
                })
              );
            },
            toRgbaString: function () {
              var b = 'rgba(',
                c = a.map(this._rgba, function (a, b) {
                  return null == a ? (b > 2 ? 1 : 0) : a;
                });
              return 1 === c[3] && (c.pop(), (b = 'rgb(')), b + c.join() + ')';
            },
            toHslaString: function () {
              var b = 'hsla(',
                c = a.map(this.hsla(), function (a, b) {
                  return (
                    null == a && (a = b > 2 ? 1 : 0),
                    b && 3 > b && (a = Math.round(100 * a) + '%'),
                    a
                  );
                });
              return 1 === c[3] && (c.pop(), (b = 'hsl(')), b + c.join() + ')';
            },
            toHexString: function (b) {
              var c = this._rgba.slice(),
                d = c.pop();
              return (
                b && c.push(~~(255 * d)),
                '#' +
                  a
                    .map(c, function (a) {
                      return (
                        (a = (a || 0).toString(16)),
                        1 === a.length ? '0' + a : a
                      );
                    })
                    .join('')
              );
            },
            toString: function () {
              return 0 === this._rgba[3] ? 'transparent' : this.toRgbaString();
            }
          })),
          (i.fn.parse.prototype = i.fn),
          (j.hsla.to = function (a) {
            if (null == a[0] || null == a[1] || null == a[2])
              return [null, null, null, a[3]];
            var b,
              c,
              d = a[0] / 255,
              e = a[1] / 255,
              f = a[2] / 255,
              g = a[3],
              h = Math.max(d, e, f),
              i = Math.min(d, e, f),
              j = h - i,
              k = h + i,
              l = 0.5 * k;
            return (
              (b =
                i === h
                  ? 0
                  : d === h
                    ? (60 * (e - f)) / j + 360
                    : e === h
                      ? (60 * (f - d)) / j + 120
                      : (60 * (d - e)) / j + 240),
              (c = 0 === j ? 0 : 0.5 >= l ? j / k : j / (2 - k)),
              [Math.round(b) % 360, c, l, null == g ? 1 : g]
            );
          }),
          (j.hsla.from = function (a) {
            if (null == a[0] || null == a[1] || null == a[2])
              return [null, null, null, a[3]];
            var b = a[0] / 360,
              c = a[1],
              d = a[2],
              f = a[3],
              g = 0.5 >= d ? d * (1 + c) : d + c - d * c,
              h = 2 * d - g;
            return [
              Math.round(255 * e(h, g, b + 1 / 3)),
              Math.round(255 * e(h, g, b)),
              Math.round(255 * e(h, g, b - 1 / 3)),
              f
            ];
          }),
          n(j, function (d, e) {
            var f = e.props,
              h = e.cache,
              j = e.to,
              k = e.from;
            (i.fn[d] = function (d) {
              if ((j && !this[h] && (this[h] = j(this._rgba)), d === b))
                return this[h].slice();
              var e,
                g = a.type(d),
                l = 'array' === g || 'object' === g ? d : arguments,
                m = this[h].slice();
              return (
                n(f, function (a, b) {
                  var d = l['object' === g ? a : b.idx];
                  null == d && (d = m[b.idx]), (m[b.idx] = c(d, b));
                }),
                k ? ((e = i(k(m))), (e[h] = m), e) : i(m)
              );
            }),
              n(f, function (b, c) {
                i.fn[b] ||
                  (i.fn[b] = function (e) {
                    var f,
                      h = a.type(e),
                      i = 'alpha' === b ? (this._hsla ? 'hsla' : 'rgba') : d,
                      j = this[i](),
                      k = j[c.idx];
                    return 'undefined' === h
                      ? k
                      : ('function' === h &&
                          ((e = e.call(this, k)), (h = a.type(e))),
                        null == e && c.empty
                          ? this
                          : ('string' === h &&
                              (f = g.exec(e)) &&
                              (e =
                                k + parseFloat(f[2]) * ('+' === f[1] ? 1 : -1)),
                            (j[c.idx] = e),
                            this[i](j)));
                  });
              });
          }),
          (i.hook = function (b) {
            var c = b.split(' ');
            n(c, function (b, c) {
              (a.cssHooks[c] = {
                set: function (b, e) {
                  var f,
                    g,
                    h = '';
                  if (
                    'transparent' !== e &&
                    ('string' !== a.type(e) || (f = d(e)))
                  ) {
                    if (((e = i(f || e)), !l.rgba && 1 !== e._rgba[3])) {
                      for (
                        g = 'backgroundColor' === c ? b.parentNode : b;
                        ('' === h || 'transparent' === h) && g && g.style;

                      )
                        try {
                          (h = a.css(g, 'backgroundColor')), (g = g.parentNode);
                        } catch (a) {}
                      e = e.blend(h && 'transparent' !== h ? h : '_default');
                    }
                    e = e.toRgbaString();
                  }
                  try {
                    b.style[c] = e;
                  } catch (a) {}
                }
              }),
                (a.fx.step[c] = function (b) {
                  b.colorInit ||
                    ((b.start = i(b.elem, c)),
                    (b.end = i(b.end)),
                    (b.colorInit = !0)),
                    a.cssHooks[c].set(b.elem, b.start.transition(b.end, b.pos));
                });
            });
          }),
          i.hook(
            'backgroundColor borderBottomColor borderLeftColor borderRightColor borderTopColor color columnRuleColor outlineColor textDecorationColor textEmphasisColor'
          ),
          (a.cssHooks.borderColor = {
            expand: function (a) {
              var b = {};
              return (
                n(['Top', 'Right', 'Bottom', 'Left'], function (c, d) {
                  b['border' + d + 'Color'] = a;
                }),
                b
              );
            }
          }),
          (f = a.Color.names =
            {
              aqua: '#00ffff',
              black: '#000000',
              blue: '#0000ff',
              fuchsia: '#ff00ff',
              gray: '#808080',
              green: '#008000',
              lime: '#00ff00',
              maroon: '#800000',
              navy: '#000080',
              olive: '#808000',
              purple: '#800080',
              red: '#ff0000',
              silver: '#c0c0c0',
              teal: '#008080',
              white: '#ffffff',
              yellow: '#ffff00',
              transparent: [null, null, null, 0],
              _default: '#ffffff'
            });
      })(n),
      (function () {
        function b(b) {
          var c,
            d,
            e = b.ownerDocument.defaultView
              ? b.ownerDocument.defaultView.getComputedStyle(b, null)
              : b.currentStyle,
            f = {};
          if (e && e.length && e[0] && e[e[0]])
            for (d = e.length; d--; )
              (c = e[d]), 'string' == typeof e[c] && (f[a.camelCase(c)] = e[c]);
          else for (c in e) 'string' == typeof e[c] && (f[c] = e[c]);
          return f;
        }
        function c(b, c) {
          var d,
            f,
            g = {};
          for (d in c)
            (f = c[d]),
              b[d] !== f &&
                (e[d] ||
                  ((a.fx.step[d] || !isNaN(parseFloat(f))) && (g[d] = f)));
          return g;
        }
        var d = ['add', 'remove', 'toggle'],
          e = {
            border: 1,
            borderBottom: 1,
            borderColor: 1,
            borderLeft: 1,
            borderRight: 1,
            borderTop: 1,
            borderWidth: 1,
            margin: 1,
            padding: 1
          };
        a.each(
          [
            'borderLeftStyle',
            'borderRightStyle',
            'borderBottomStyle',
            'borderTopStyle'
          ],
          function (b, c) {
            a.fx.step[c] = function (a) {
              (('none' !== a.end && !a.setAttr) ||
                (1 === a.pos && !a.setAttr)) &&
                (n.style(a.elem, c, a.end), (a.setAttr = !0));
            };
          }
        ),
          a.fn.addBack ||
            (a.fn.addBack = function (a) {
              return this.add(
                null == a ? this.prevObject : this.prevObject.filter(a)
              );
            }),
          (a.effects.animateClass = function (e, f, g, h) {
            var i = a.speed(f, g, h);
            return this.queue(function () {
              var f,
                g = a(this),
                h = g.attr('class') || '',
                j = i.children ? g.find('*').addBack() : g;
              (j = j.map(function () {
                return { el: a(this), start: b(this) };
              })),
                (f = function () {
                  a.each(d, function (a, b) {
                    e[b] && g[b + 'Class'](e[b]);
                  });
                }),
                f(),
                (j = j.map(function () {
                  return (
                    (this.end = b(this.el[0])),
                    (this.diff = c(this.start, this.end)),
                    this
                  );
                })),
                g.attr('class', h),
                (j = j.map(function () {
                  var b = this,
                    c = a.Deferred(),
                    d = a.extend({}, i, {
                      queue: !1,
                      complete: function () {
                        c.resolve(b);
                      }
                    });
                  return this.el.animate(this.diff, d), c.promise();
                })),
                a.when.apply(a, j.get()).done(function () {
                  f(),
                    a.each(arguments, function () {
                      var b = this.el;
                      a.each(this.diff, function (a) {
                        b.css(a, '');
                      });
                    }),
                    i.complete.call(g[0]);
                });
            });
          }),
          a.fn.extend({
            addClass: (function (b) {
              return function (c, d, e, f) {
                return d
                  ? a.effects.animateClass.call(this, { add: c }, d, e, f)
                  : b.apply(this, arguments);
              };
            })(a.fn.addClass),
            removeClass: (function (b) {
              return function (c, d, e, f) {
                return arguments.length > 1
                  ? a.effects.animateClass.call(this, { remove: c }, d, e, f)
                  : b.apply(this, arguments);
              };
            })(a.fn.removeClass),
            toggleClass: (function (b) {
              return function (c, d, e, f, g) {
                return 'boolean' == typeof d || void 0 === d
                  ? e
                    ? a.effects.animateClass.call(
                        this,
                        d ? { add: c } : { remove: c },
                        e,
                        f,
                        g
                      )
                    : b.apply(this, arguments)
                  : a.effects.animateClass.call(this, { toggle: c }, d, e, f);
              };
            })(a.fn.toggleClass),
            switchClass: function (b, c, d, e, f) {
              return a.effects.animateClass.call(
                this,
                { add: c, remove: b },
                d,
                e,
                f
              );
            }
          });
      })(),
      (function () {
        function b(b, c, d, e) {
          return (
            a.isPlainObject(b) && ((c = b), (b = b.effect)),
            (b = { effect: b }),
            null == c && (c = {}),
            a.isFunction(c) && ((e = c), (d = null), (c = {})),
            ('number' == typeof c || a.fx.speeds[c]) &&
              ((e = d), (d = c), (c = {})),
            a.isFunction(d) && ((e = d), (d = null)),
            c && a.extend(b, c),
            (d = d || c.duration),
            (b.duration = a.fx.off
              ? 0
              : 'number' == typeof d
                ? d
                : d in a.fx.speeds
                  ? a.fx.speeds[d]
                  : a.fx.speeds._default),
            (b.complete = e || c.complete),
            b
          );
        }
        function c(b) {
          return (
            !(b && 'number' != typeof b && !a.fx.speeds[b]) ||
            ('string' == typeof b && !a.effects.effect[b]) ||
            !!a.isFunction(b) ||
            ('object' == typeof b && !b.effect)
          );
        }
        function d(a, b) {
          var c = b.outerWidth(),
            d = b.outerHeight(),
            e =
              /^rect\((-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto)\)$/,
            f = e.exec(a) || ['', 0, c, d, 0];
          return {
            top: parseFloat(f[1]) || 0,
            right: 'auto' === f[2] ? c : parseFloat(f[2]),
            bottom: 'auto' === f[3] ? d : parseFloat(f[3]),
            left: parseFloat(f[4]) || 0
          };
        }
        a.expr &&
          a.expr.filters &&
          a.expr.filters.animated &&
          (a.expr.filters.animated = (function (b) {
            return function (c) {
              return !!a(c).data(m) || b(c);
            };
          })(a.expr.filters.animated)),
          !1 !== a.uiBackCompat &&
            a.extend(a.effects, {
              save: function (a, b) {
                for (var c = 0, d = b.length; d > c; c++)
                  null !== b[c] && a.data(k + b[c], a[0].style[b[c]]);
              },
              restore: function (a, b) {
                for (var c, d = 0, e = b.length; e > d; d++)
                  null !== b[d] && ((c = a.data(k + b[d])), a.css(b[d], c));
              },
              setMode: function (a, b) {
                return (
                  'toggle' === b && (b = a.is(':hidden') ? 'show' : 'hide'), b
                );
              },
              createWrapper: function (b) {
                if (b.parent().is('.ui-effects-wrapper')) return b.parent();
                var c = {
                    width: b.outerWidth(!0),
                    height: b.outerHeight(!0),
                    float: b.css('float')
                  },
                  d = a('<div></div>').addClass('ui-effects-wrapper').css({
                    fontSize: '100%',
                    background: 'transparent',
                    border: 'none',
                    margin: 0,
                    padding: 0
                  }),
                  e = { width: b.width(), height: b.height() },
                  f = document.activeElement;
                try {
                  f.id;
                } catch (a) {
                  f = document.body;
                }
                return (
                  b.wrap(d),
                  (b[0] === f || a.contains(b[0], f)) && a(f).trigger('focus'),
                  (d = b.parent()),
                  'static' === b.css('position')
                    ? (d.css({ position: 'relative' }),
                      b.css({ position: 'relative' }))
                    : (a.extend(c, {
                        position: b.css('position'),
                        zIndex: b.css('z-index')
                      }),
                      a.each(
                        ['top', 'left', 'bottom', 'right'],
                        function (a, d) {
                          (c[d] = b.css(d)),
                            isNaN(parseInt(c[d], 10)) && (c[d] = 'auto');
                        }
                      ),
                      b.css({
                        position: 'relative',
                        top: 0,
                        left: 0,
                        right: 'auto',
                        bottom: 'auto'
                      })),
                  b.css(e),
                  d.css(c).show()
                );
              },
              removeWrapper: function (b) {
                var c = document.activeElement;
                return (
                  b.parent().is('.ui-effects-wrapper') &&
                    (b.parent().replaceWith(b),
                    (b[0] === c || a.contains(b[0], c)) &&
                      a(c).trigger('focus')),
                  b
                );
              }
            }),
          a.extend(a.effects, {
            version: '1.12.1',
            define: function (b, c, d) {
              return (
                d || ((d = c), (c = 'effect')),
                (a.effects.effect[b] = d),
                (a.effects.effect[b].mode = c),
                d
              );
            },
            scaledDimensions: function (a, b, c) {
              if (0 === b)
                return { height: 0, width: 0, outerHeight: 0, outerWidth: 0 };
              var d = 'horizontal' !== c ? (b || 100) / 100 : 1,
                e = 'vertical' !== c ? (b || 100) / 100 : 1;
              return {
                height: a.height() * e,
                width: a.width() * d,
                outerHeight: a.outerHeight() * e,
                outerWidth: a.outerWidth() * d
              };
            },
            clipToBox: function (a) {
              return {
                width: a.clip.right - a.clip.left,
                height: a.clip.bottom - a.clip.top,
                left: a.clip.left,
                top: a.clip.top
              };
            },
            unshift: function (a, b, c) {
              var d = a.queue();
              b > 1 && d.splice.apply(d, [1, 0].concat(d.splice(b, c))),
                a.dequeue();
            },
            saveStyle: function (a) {
              a.data(l, a[0].style.cssText);
            },
            restoreStyle: function (a) {
              (a[0].style.cssText = a.data(l) || ''), a.removeData(l);
            },
            mode: function (a, b) {
              var c = a.is(':hidden');
              return (
                'toggle' === b && (b = c ? 'show' : 'hide'),
                (c ? 'hide' === b : 'show' === b) && (b = 'none'),
                b
              );
            },
            getBaseline: function (a, b) {
              var c, d;
              switch (a[0]) {
                case 'top':
                  c = 0;
                  break;
                case 'middle':
                  c = 0.5;
                  break;
                case 'bottom':
                  c = 1;
                  break;
                default:
                  c = a[0] / b.height;
              }
              switch (a[1]) {
                case 'left':
                  d = 0;
                  break;
                case 'center':
                  d = 0.5;
                  break;
                case 'right':
                  d = 1;
                  break;
                default:
                  d = a[1] / b.width;
              }
              return { x: d, y: c };
            },
            createPlaceholder: function (b) {
              var c,
                d = b.css('position'),
                e = b.position();
              return (
                b
                  .css({
                    marginTop: b.css('marginTop'),
                    marginBottom: b.css('marginBottom'),
                    marginLeft: b.css('marginLeft'),
                    marginRight: b.css('marginRight')
                  })
                  .outerWidth(b.outerWidth())
                  .outerHeight(b.outerHeight()),
                /^(static|relative)/.test(d) &&
                  ((d = 'absolute'),
                  (c = a('<' + b[0].nodeName + '>')
                    .insertAfter(b)
                    .css({
                      display: /^(inline|ruby)/.test(b.css('display'))
                        ? 'inline-block'
                        : 'block',
                      visibility: 'hidden',
                      marginTop: b.css('marginTop'),
                      marginBottom: b.css('marginBottom'),
                      marginLeft: b.css('marginLeft'),
                      marginRight: b.css('marginRight'),
                      float: b.css('float')
                    })
                    .outerWidth(b.outerWidth())
                    .outerHeight(b.outerHeight())
                    .addClass('ui-effects-placeholder')),
                  b.data(k + 'placeholder', c)),
                b.css({ position: d, left: e.left, top: e.top }),
                c
              );
            },
            removePlaceholder: function (a) {
              var b = k + 'placeholder',
                c = a.data(b);
              c && (c.remove(), a.removeData(b));
            },
            cleanUp: function (b) {
              a.effects.restoreStyle(b), a.effects.removePlaceholder(b);
            },
            setTransition: function (b, c, d, e) {
              return (
                (e = e || {}),
                a.each(c, function (a, c) {
                  var f = b.cssUnit(c);
                  f[0] > 0 && (e[c] = f[0] * d + f[1]);
                }),
                e
              );
            }
          }),
          a.fn.extend({
            effect: function () {
              function c(b) {
                function c() {
                  h.removeData(m),
                    a.effects.cleanUp(h),
                    'hide' === d.mode && h.hide(),
                    g();
                }
                function g() {
                  a.isFunction(i) && i.call(h[0]), a.isFunction(b) && b();
                }
                var h = a(this);
                (d.mode = k.shift()),
                  !1 === a.uiBackCompat || f
                    ? 'none' === d.mode
                      ? (h[j](), g())
                      : e.call(h[0], d, c)
                    : (h.is(':hidden') ? 'hide' === j : 'show' === j)
                      ? (h[j](), g())
                      : e.call(h[0], d, g);
              }
              var d = b.apply(this, arguments),
                e = a.effects.effect[d.effect],
                f = e.mode,
                g = d.queue,
                h = g || 'fx',
                i = d.complete,
                j = d.mode,
                k = [],
                l = function (b) {
                  var c = a(this),
                    d = a.effects.mode(c, j) || f;
                  c.data(m, !0),
                    k.push(d),
                    f &&
                      ('show' === d || (d === f && 'hide' === d)) &&
                      c.show(),
                    (f && 'none' === d) || a.effects.saveStyle(c),
                    a.isFunction(b) && b();
                };
              return a.fx.off || !e
                ? j
                  ? this[j](d.duration, i)
                  : this.each(function () {
                      i && i.call(this);
                    })
                : !1 === g
                  ? this.each(l).each(c)
                  : this.queue(h, l).queue(h, c);
            },
            show: (function (a) {
              return function (d) {
                if (c(d)) return a.apply(this, arguments);
                var e = b.apply(this, arguments);
                return (e.mode = 'show'), this.effect.call(this, e);
              };
            })(a.fn.show),
            hide: (function (a) {
              return function (d) {
                if (c(d)) return a.apply(this, arguments);
                var e = b.apply(this, arguments);
                return (e.mode = 'hide'), this.effect.call(this, e);
              };
            })(a.fn.hide),
            toggle: (function (a) {
              return function (d) {
                if (c(d) || 'boolean' == typeof d)
                  return a.apply(this, arguments);
                var e = b.apply(this, arguments);
                return (e.mode = 'toggle'), this.effect.call(this, e);
              };
            })(a.fn.toggle),
            cssUnit: function (b) {
              var c = this.css(b),
                d = [];
              return (
                a.each(['em', 'px', '%', 'pt'], function (a, b) {
                  c.indexOf(b) > 0 && (d = [parseFloat(c), b]);
                }),
                d
              );
            },
            cssClip: function (a) {
              return a
                ? this.css(
                    'clip',
                    'rect(' +
                      a.top +
                      'px ' +
                      a.right +
                      'px ' +
                      a.bottom +
                      'px ' +
                      a.left +
                      'px)'
                  )
                : d(this.css('clip'), this);
            },
            transfer: function (b, c) {
              var d = a(this),
                e = a(b.to),
                f = 'fixed' === e.css('position'),
                g = a('body'),
                h = f ? g.scrollTop() : 0,
                i = f ? g.scrollLeft() : 0,
                j = e.offset(),
                k = {
                  top: j.top - h,
                  left: j.left - i,
                  height: e.innerHeight(),
                  width: e.innerWidth()
                },
                l = d.offset(),
                m = a("<div class='ui-effects-transfer'></div>")
                  .appendTo('body')
                  .addClass(b.className)
                  .css({
                    top: l.top - h,
                    left: l.left - i,
                    height: d.innerHeight(),
                    width: d.innerWidth(),
                    position: f ? 'fixed' : 'absolute'
                  })
                  .animate(k, b.duration, b.easing, function () {
                    m.remove(), a.isFunction(c) && c();
                  });
            }
          }),
          (a.fx.step.clip = function (b) {
            b.clipInit ||
              ((b.start = a(b.elem).cssClip()),
              'string' == typeof b.end && (b.end = d(b.end, b.elem)),
              (b.clipInit = !0)),
              a(b.elem).cssClip({
                top: b.pos * (b.end.top - b.start.top) + b.start.top,
                right: b.pos * (b.end.right - b.start.right) + b.start.right,
                bottom:
                  b.pos * (b.end.bottom - b.start.bottom) + b.start.bottom,
                left: b.pos * (b.end.left - b.start.left) + b.start.left
              });
          });
      })(),
      (function () {
        var b = {};
        a.each(['Quad', 'Cubic', 'Quart', 'Quint', 'Expo'], function (a, c) {
          b[c] = function (b) {
            return Math.pow(b, a + 2);
          };
        }),
          a.extend(b, {
            Sine: function (a) {
              return 1 - Math.cos((a * Math.PI) / 2);
            },
            Circ: function (a) {
              return 1 - Math.sqrt(1 - a * a);
            },
            Elastic: function (a) {
              return 0 === a || 1 === a
                ? a
                : -Math.pow(2, 8 * (a - 1)) *
                    Math.sin(((80 * (a - 1) - 7.5) * Math.PI) / 15);
            },
            Back: function (a) {
              return a * a * (3 * a - 2);
            },
            Bounce: function (a) {
              for (var b, c = 4; ((b = Math.pow(2, --c)) - 1) / 11 > a; );
              return (
                1 / Math.pow(4, 3 - c) -
                7.5625 * Math.pow((3 * b - 2) / 22 - a, 2)
              );
            }
          }),
          a.each(b, function (b, c) {
            (a.easing['easeIn' + b] = c),
              (a.easing['easeOut' + b] = function (a) {
                return 1 - c(1 - a);
              }),
              (a.easing['easeInOut' + b] = function (a) {
                return 0.5 > a ? c(2 * a) / 2 : 1 - c(-2 * a + 2) / 2;
              });
          });
      })();
    a.effects;
    a.effects.define('blind', 'hide', function (b, c) {
      var d = {
          up: ['bottom', 'top'],
          vertical: ['bottom', 'top'],
          down: ['top', 'bottom'],
          left: ['right', 'left'],
          horizontal: ['right', 'left'],
          right: ['left', 'right']
        },
        e = a(this),
        f = b.direction || 'up',
        g = e.cssClip(),
        h = { clip: a.extend({}, g) },
        i = a.effects.createPlaceholder(e);
      (h.clip[d[f][0]] = h.clip[d[f][1]]),
        'show' === b.mode &&
          (e.cssClip(h.clip), i && i.css(a.effects.clipToBox(h)), (h.clip = g)),
        i && i.animate(a.effects.clipToBox(h), b.duration, b.easing),
        e.animate(h, {
          queue: !1,
          duration: b.duration,
          easing: b.easing,
          complete: c
        });
    }),
      a.effects.define('bounce', function (b, c) {
        var d,
          e,
          f,
          g = a(this),
          h = b.mode,
          i = 'hide' === h,
          j = 'show' === h,
          k = b.direction || 'up',
          l = b.distance,
          m = b.times || 5,
          n = 2 * m + (j || i ? 1 : 0),
          o = b.duration / n,
          p = b.easing,
          q = 'up' === k || 'down' === k ? 'top' : 'left',
          r = 'up' === k || 'left' === k,
          s = 0,
          t = g.queue().length;
        for (
          a.effects.createPlaceholder(g),
            f = g.css(q),
            l || (l = g['top' === q ? 'outerHeight' : 'outerWidth']() / 3),
            j &&
              ((e = { opacity: 1 }),
              (e[q] = f),
              g
                .css('opacity', 0)
                .css(q, r ? 2 * -l : 2 * l)
                .animate(e, o, p)),
            i && (l /= Math.pow(2, m - 1)),
            e = {},
            e[q] = f;
          m > s;
          s++
        )
          (d = {}),
            (d[q] = (r ? '-=' : '+=') + l),
            g.animate(d, o, p).animate(e, o, p),
            (l = i ? 2 * l : l / 2);
        i &&
          ((d = { opacity: 0 }),
          (d[q] = (r ? '-=' : '+=') + l),
          g.animate(d, o, p)),
          g.queue(c),
          a.effects.unshift(g, t, n + 1);
      }),
      a.effects.define('clip', 'hide', function (b, c) {
        var d,
          e = {},
          f = a(this),
          g = b.direction || 'vertical',
          h = 'both' === g,
          i = h || 'horizontal' === g,
          j = h || 'vertical' === g;
        (d = f.cssClip()),
          (e.clip = {
            top: j ? (d.bottom - d.top) / 2 : d.top,
            right: i ? (d.right - d.left) / 2 : d.right,
            bottom: j ? (d.bottom - d.top) / 2 : d.bottom,
            left: i ? (d.right - d.left) / 2 : d.left
          }),
          a.effects.createPlaceholder(f),
          'show' === b.mode && (f.cssClip(e.clip), (e.clip = d)),
          f.animate(e, {
            queue: !1,
            duration: b.duration,
            easing: b.easing,
            complete: c
          });
      }),
      a.effects.define('drop', 'hide', function (b, c) {
        var d,
          e = a(this),
          f = b.mode,
          g = 'show' === f,
          h = b.direction || 'left',
          i = 'up' === h || 'down' === h ? 'top' : 'left',
          j = 'up' === h || 'left' === h ? '-=' : '+=',
          k = '+=' === j ? '-=' : '+=',
          l = { opacity: 0 };
        a.effects.createPlaceholder(e),
          (d =
            b.distance ||
            e['top' === i ? 'outerHeight' : 'outerWidth'](!0) / 2),
          (l[i] = j + d),
          g && (e.css(l), (l[i] = k + d), (l.opacity = 1)),
          e.animate(l, {
            queue: !1,
            duration: b.duration,
            easing: b.easing,
            complete: c
          });
      }),
      a.effects.define('explode', 'hide', function (b, c) {
        function d() {
          t.push(this), t.length === l * m && e();
        }
        function e() {
          n.css({ visibility: 'visible' }), a(t).remove(), c();
        }
        var f,
          g,
          h,
          i,
          j,
          k,
          l = b.pieces ? Math.round(Math.sqrt(b.pieces)) : 3,
          m = l,
          n = a(this),
          o = b.mode,
          p = 'show' === o,
          q = n.show().css('visibility', 'hidden').offset(),
          r = Math.ceil(n.outerWidth() / m),
          s = Math.ceil(n.outerHeight() / l),
          t = [];
        for (f = 0; l > f; f++)
          for (i = q.top + f * s, k = f - (l - 1) / 2, g = 0; m > g; g++)
            (h = q.left + g * r),
              (j = g - (m - 1) / 2),
              n
                .clone()
                .appendTo('body')
                .wrap('<div></div>')
                .css({
                  position: 'absolute',
                  visibility: 'visible',
                  left: -g * r,
                  top: -f * s
                })
                .parent()
                .addClass('ui-effects-explode')
                .css({
                  position: 'absolute',
                  overflow: 'hidden',
                  width: r,
                  height: s,
                  left: h + (p ? j * r : 0),
                  top: i + (p ? k * s : 0),
                  opacity: p ? 0 : 1
                })
                .animate(
                  {
                    left: h + (p ? 0 : j * r),
                    top: i + (p ? 0 : k * s),
                    opacity: p ? 1 : 0
                  },
                  b.duration || 500,
                  b.easing,
                  d
                );
      }),
      a.effects.define('fade', 'toggle', function (b, c) {
        var d = 'show' === b.mode;
        a(this)
          .css('opacity', d ? 0 : 1)
          .animate(
            { opacity: d ? 1 : 0 },
            { queue: !1, duration: b.duration, easing: b.easing, complete: c }
          );
      }),
      a.effects.define('fold', 'hide', function (b, c) {
        var d = a(this),
          e = b.mode,
          f = 'show' === e,
          g = 'hide' === e,
          h = b.size || 15,
          i = /([0-9]+)%/.exec(h),
          j = !!b.horizFirst,
          k = j ? ['right', 'bottom'] : ['bottom', 'right'],
          l = b.duration / 2,
          m = a.effects.createPlaceholder(d),
          n = d.cssClip(),
          o = { clip: a.extend({}, n) },
          p = { clip: a.extend({}, n) },
          q = [n[k[0]], n[k[1]]],
          r = d.queue().length;
        i && (h = (parseInt(i[1], 10) / 100) * q[g ? 0 : 1]),
          (o.clip[k[0]] = h),
          (p.clip[k[0]] = h),
          (p.clip[k[1]] = 0),
          f &&
            (d.cssClip(p.clip),
            m && m.css(a.effects.clipToBox(p)),
            (p.clip = n)),
          d
            .queue(function (c) {
              m &&
                m
                  .animate(a.effects.clipToBox(o), l, b.easing)
                  .animate(a.effects.clipToBox(p), l, b.easing),
                c();
            })
            .animate(o, l, b.easing)
            .animate(p, l, b.easing)
            .queue(c),
          a.effects.unshift(d, r, 4);
      }),
      a.effects.define('highlight', 'show', function (b, c) {
        var d = a(this),
          e = { backgroundColor: d.css('backgroundColor') };
        'hide' === b.mode && (e.opacity = 0),
          a.effects.saveStyle(d),
          d
            .css({
              backgroundImage: 'none',
              backgroundColor: b.color || '#ffff99'
            })
            .animate(e, {
              queue: !1,
              duration: b.duration,
              easing: b.easing,
              complete: c
            });
      }),
      a.effects.define('size', function (b, c) {
        var d,
          e,
          f,
          g = a(this),
          h = ['fontSize'],
          i = [
            'borderTopWidth',
            'borderBottomWidth',
            'paddingTop',
            'paddingBottom'
          ],
          j = [
            'borderLeftWidth',
            'borderRightWidth',
            'paddingLeft',
            'paddingRight'
          ],
          k = b.mode,
          l = 'effect' !== k,
          m = b.scale || 'both',
          n = b.origin || ['middle', 'center'],
          o = g.css('position'),
          p = g.position(),
          q = a.effects.scaledDimensions(g),
          r = b.from || q,
          s = b.to || a.effects.scaledDimensions(g, 0);
        a.effects.createPlaceholder(g),
          'show' === k && ((f = r), (r = s), (s = f)),
          (e = {
            from: { y: r.height / q.height, x: r.width / q.width },
            to: { y: s.height / q.height, x: s.width / q.width }
          }),
          ('box' === m || 'both' === m) &&
            (e.from.y !== e.to.y &&
              ((r = a.effects.setTransition(g, i, e.from.y, r)),
              (s = a.effects.setTransition(g, i, e.to.y, s))),
            e.from.x !== e.to.x &&
              ((r = a.effects.setTransition(g, j, e.from.x, r)),
              (s = a.effects.setTransition(g, j, e.to.x, s)))),
          ('content' === m || 'both' === m) &&
            e.from.y !== e.to.y &&
            ((r = a.effects.setTransition(g, h, e.from.y, r)),
            (s = a.effects.setTransition(g, h, e.to.y, s))),
          n &&
            ((d = a.effects.getBaseline(n, q)),
            (r.top = (q.outerHeight - r.outerHeight) * d.y + p.top),
            (r.left = (q.outerWidth - r.outerWidth) * d.x + p.left),
            (s.top = (q.outerHeight - s.outerHeight) * d.y + p.top),
            (s.left = (q.outerWidth - s.outerWidth) * d.x + p.left)),
          g.css(r),
          ('content' === m || 'both' === m) &&
            ((i = i.concat(['marginTop', 'marginBottom']).concat(h)),
            (j = j.concat(['marginLeft', 'marginRight'])),
            g.find('*[width]').each(function () {
              var c = a(this),
                d = a.effects.scaledDimensions(c),
                f = {
                  height: d.height * e.from.y,
                  width: d.width * e.from.x,
                  outerHeight: d.outerHeight * e.from.y,
                  outerWidth: d.outerWidth * e.from.x
                },
                g = {
                  height: d.height * e.to.y,
                  width: d.width * e.to.x,
                  outerHeight: d.height * e.to.y,
                  outerWidth: d.width * e.to.x
                };
              e.from.y !== e.to.y &&
                ((f = a.effects.setTransition(c, i, e.from.y, f)),
                (g = a.effects.setTransition(c, i, e.to.y, g))),
                e.from.x !== e.to.x &&
                  ((f = a.effects.setTransition(c, j, e.from.x, f)),
                  (g = a.effects.setTransition(c, j, e.to.x, g))),
                l && a.effects.saveStyle(c),
                c.css(f),
                c.animate(g, b.duration, b.easing, function () {
                  l && a.effects.restoreStyle(c);
                });
            })),
          g.animate(s, {
            queue: !1,
            duration: b.duration,
            easing: b.easing,
            complete: function () {
              var b = g.offset();
              0 === s.opacity && g.css('opacity', r.opacity),
                l ||
                  (g.css('position', 'static' === o ? 'relative' : o).offset(b),
                  a.effects.saveStyle(g)),
                c();
            }
          });
      }),
      a.effects.define('scale', function (b, c) {
        var d = a(this),
          e = b.mode,
          f =
            parseInt(b.percent, 10) ||
            (0 === parseInt(b.percent, 10) ? 0 : 'effect' !== e ? 0 : 100),
          g = a.extend(
            !0,
            {
              from: a.effects.scaledDimensions(d),
              to: a.effects.scaledDimensions(d, f, b.direction || 'both'),
              origin: b.origin || ['middle', 'center']
            },
            b
          );
        b.fade && ((g.from.opacity = 1), (g.to.opacity = 0)),
          a.effects.effect.size.call(this, g, c);
      }),
      a.effects.define('puff', 'hide', function (b, c) {
        var d = a.extend(!0, {}, b, {
          fade: !0,
          percent: parseInt(b.percent, 10) || 150
        });
        a.effects.effect.scale.call(this, d, c);
      }),
      a.effects.define('pulsate', 'show', function (b, c) {
        var d = a(this),
          e = b.mode,
          f = 'show' === e,
          g = 'hide' === e,
          h = f || g,
          i = 2 * (b.times || 5) + (h ? 1 : 0),
          j = b.duration / i,
          k = 0,
          l = 1,
          m = d.queue().length;
        for (
          (f || !d.is(':visible')) && (d.css('opacity', 0).show(), (k = 1));
          i > l;
          l++
        )
          d.animate({ opacity: k }, j, b.easing), (k = 1 - k);
        d.animate({ opacity: k }, j, b.easing),
          d.queue(c),
          a.effects.unshift(d, m, i + 1);
      }),
      a.effects.define('shake', function (b, c) {
        var d = 1,
          e = a(this),
          f = b.direction || 'left',
          g = b.distance || 20,
          h = b.times || 3,
          i = 2 * h + 1,
          j = Math.round(b.duration / i),
          k = 'up' === f || 'down' === f ? 'top' : 'left',
          l = 'up' === f || 'left' === f,
          m = {},
          n = {},
          o = {},
          p = e.queue().length;
        for (
          a.effects.createPlaceholder(e),
            m[k] = (l ? '-=' : '+=') + g,
            n[k] = (l ? '+=' : '-=') + 2 * g,
            o[k] = (l ? '-=' : '+=') + 2 * g,
            e.animate(m, j, b.easing);
          h > d;
          d++
        )
          e.animate(n, j, b.easing).animate(o, j, b.easing);
        e
          .animate(n, j, b.easing)
          .animate(m, j / 2, b.easing)
          .queue(c),
          a.effects.unshift(e, p, i + 1);
      }),
      a.effects.define('slide', 'show', function (b, c) {
        var d,
          e,
          f = a(this),
          g = {
            up: ['bottom', 'top'],
            down: ['top', 'bottom'],
            left: ['right', 'left'],
            right: ['left', 'right']
          },
          h = b.mode,
          i = b.direction || 'left',
          j = 'up' === i || 'down' === i ? 'top' : 'left',
          k = 'up' === i || 'left' === i,
          l = b.distance || f['top' === j ? 'outerHeight' : 'outerWidth'](!0),
          m = {};
        a.effects.createPlaceholder(f),
          (d = f.cssClip()),
          (e = f.position()[j]),
          (m[j] = (k ? -1 : 1) * l + e),
          (m.clip = f.cssClip()),
          (m.clip[g[i][1]] = m.clip[g[i][0]]),
          'show' === h &&
            (f.cssClip(m.clip), f.css(j, m[j]), (m.clip = d), (m[j] = e)),
          f.animate(m, {
            queue: !1,
            duration: b.duration,
            easing: b.easing,
            complete: c
          });
      });
    !1 !== a.uiBackCompat &&
      a.effects.define('transfer', function (b, c) {
        a(this).transfer(b, c);
      }),
      (a.ui.focusable = function (c, d) {
        var e,
          f,
          g,
          h,
          i,
          j = c.nodeName.toLowerCase();
        return 'area' === j
          ? ((e = c.parentNode),
            (f = e.name),
            !(!c.href || !f || 'map' !== e.nodeName.toLowerCase()) &&
              ((g = a("img[usemap='#" + f + "']")),
              g.length > 0 && g.is(':visible')))
          : (/^(input|select|textarea|button|object)$/.test(j)
              ? (h = !c.disabled) &&
                (i = a(c).closest('fieldset')[0]) &&
                (h = !i.disabled)
              : (h = 'a' === j ? c.href || d : d),
            h && a(c).is(':visible') && b(a(c)));
      }),
      a.extend(a.expr[':'], {
        focusable: function (b) {
          return a.ui.focusable(b, null != a.attr(b, 'tabindex'));
        }
      }),
      a.ui.focusable,
      (a.fn.form = function () {
        return 'string' == typeof this[0].form
          ? this.closest('form')
          : a(this[0].form);
      }),
      (a.ui.formResetMixin = {
        _formResetHandler: function () {
          var b = a(this);
          setTimeout(function () {
            var c = b.data('ui-form-reset-instances');
            a.each(c, function () {
              this.refresh();
            });
          });
        },
        _bindFormResetHandler: function () {
          if (((this.form = this.element.form()), this.form.length)) {
            var a = this.form.data('ui-form-reset-instances') || [];
            a.length ||
              this.form.on('reset.ui-form-reset', this._formResetHandler),
              a.push(this),
              this.form.data('ui-form-reset-instances', a);
          }
        },
        _unbindFormResetHandler: function () {
          if (this.form.length) {
            var b = this.form.data('ui-form-reset-instances');
            b.splice(a.inArray(this, b), 1),
              b.length
                ? this.form.data('ui-form-reset-instances', b)
                : this.form
                    .removeData('ui-form-reset-instances')
                    .off('reset.ui-form-reset');
          }
        }
      }),
      '1.7' === a.fn.jquery.substring(0, 3) &&
        (a.each(['Width', 'Height'], function (b, c) {
          function d(b, c, d, f) {
            return (
              a.each(e, function () {
                (c -= parseFloat(a.css(b, 'padding' + this)) || 0),
                  d &&
                    (c -= parseFloat(a.css(b, 'border' + this + 'Width')) || 0),
                  f && (c -= parseFloat(a.css(b, 'margin' + this)) || 0);
              }),
              c
            );
          }
          var e = 'Width' === c ? ['Left', 'Right'] : ['Top', 'Bottom'],
            f = c.toLowerCase(),
            g = {
              innerWidth: a.fn.innerWidth,
              innerHeight: a.fn.innerHeight,
              outerWidth: a.fn.outerWidth,
              outerHeight: a.fn.outerHeight
            };
          (a.fn['inner' + c] = function (b) {
            return void 0 === b
              ? g['inner' + c].call(this)
              : this.each(function () {
                  a(this).css(f, d(this, b) + 'px');
                });
          }),
            (a.fn['outer' + c] = function (b, e) {
              return 'number' != typeof b
                ? g['outer' + c].call(this, b)
                : this.each(function () {
                    a(this).css(f, d(this, b, !0, e) + 'px');
                  });
            });
        }),
        (a.fn.addBack = function (a) {
          return this.add(
            null == a ? this.prevObject : this.prevObject.filter(a)
          );
        })),
      (a.ui.keyCode = {
        BACKSPACE: 8,
        COMMA: 188,
        DELETE: 46,
        DOWN: 40,
        END: 35,
        ENTER: 13,
        ESCAPE: 27,
        HOME: 36,
        LEFT: 37,
        PAGE_DOWN: 34,
        PAGE_UP: 33,
        PERIOD: 190,
        RIGHT: 39,
        SPACE: 32,
        TAB: 9,
        UP: 38
      }),
      (a.ui.escapeSelector = (function () {
        var a = /([!"#$%&'()*+,.\/:;<=>?@[\]^`{|}~])/g;
        return function (b) {
          return b.replace(a, '\\$1');
        };
      })()),
      (a.fn.labels = function () {
        var b, c, d, e, f;
        return this[0].labels && this[0].labels.length
          ? this.pushStack(this[0].labels)
          : ((e = this.eq(0).parents('label')),
            (d = this.attr('id')),
            d &&
              ((b = this.eq(0).parents().last()),
              (f = b.add(b.length ? b.siblings() : this.siblings())),
              (c = "label[for='" + a.ui.escapeSelector(d) + "']"),
              (e = e.add(f.find(c).addBack(c)))),
            this.pushStack(e));
      }),
      (a.fn.scrollParent = function (b) {
        var c = this.css('position'),
          d = 'absolute' === c,
          e = b ? /(auto|scroll|hidden)/ : /(auto|scroll)/,
          f = this.parents()
            .filter(function () {
              var b = a(this);
              return (
                (!d || 'static' !== b.css('position')) &&
                e.test(
                  b.css('overflow') + b.css('overflow-y') + b.css('overflow-x')
                )
              );
            })
            .eq(0);
        return 'fixed' !== c && f.length
          ? f
          : a(this[0].ownerDocument || document);
      }),
      a.extend(a.expr[':'], {
        tabbable: function (b) {
          var c = a.attr(b, 'tabindex'),
            d = null != c;
          return (!d || c >= 0) && a.ui.focusable(b, d);
        }
      }),
      a.fn.extend({
        uniqueId: (function () {
          var a = 0;
          return function () {
            return this.each(function () {
              this.id || (this.id = 'ui-id-' + ++a);
            });
          };
        })(),
        removeUniqueId: function () {
          return this.each(function () {
            /^ui-id-\d+$/.test(this.id) && a(this).removeAttr('id');
          });
        }
      }),
      a.widget('ui.accordion', {
        version: '1.12.1',
        options: {
          active: 0,
          animate: {},
          classes: {
            'ui-accordion-header': 'ui-corner-top',
            'ui-accordion-header-collapsed': 'ui-corner-all',
            'ui-accordion-content': 'ui-corner-bottom'
          },
          collapsible: !1,
          event: 'click',
          header: '> li > :first-child, > :not(li):even',
          heightStyle: 'auto',
          icons: {
            activeHeader: 'ui-icon-triangle-1-s',
            header: 'ui-icon-triangle-1-e'
          },
          activate: null,
          beforeActivate: null
        },
        hideProps: {
          borderTopWidth: 'hide',
          borderBottomWidth: 'hide',
          paddingTop: 'hide',
          paddingBottom: 'hide',
          height: 'hide'
        },
        showProps: {
          borderTopWidth: 'show',
          borderBottomWidth: 'show',
          paddingTop: 'show',
          paddingBottom: 'show',
          height: 'show'
        },
        _create: function () {
          var b = this.options;
          (this.prevShow = this.prevHide = a()),
            this._addClass('ui-accordion', 'ui-widget ui-helper-reset'),
            this.element.attr('role', 'tablist'),
            b.collapsible ||
              (!1 !== b.active && null != b.active) ||
              (b.active = 0),
            this._processPanels(),
            0 > b.active && (b.active += this.headers.length),
            this._refresh();
        },
        _getCreateEventData: function () {
          return {
            header: this.active,
            panel: this.active.length ? this.active.next() : a()
          };
        },
        _createIcons: function () {
          var b,
            c,
            d = this.options.icons;
          d &&
            ((b = a('<span>')),
            this._addClass(
              b,
              'ui-accordion-header-icon',
              'ui-icon ' + d.header
            ),
            b.prependTo(this.headers),
            (c = this.active.children('.ui-accordion-header-icon')),
            this._removeClass(c, d.header)
              ._addClass(c, null, d.activeHeader)
              ._addClass(this.headers, 'ui-accordion-icons'));
        },
        _destroyIcons: function () {
          this._removeClass(this.headers, 'ui-accordion-icons'),
            this.headers.children('.ui-accordion-header-icon').remove();
        },
        _destroy: function () {
          var a;
          this.element.removeAttr('role'),
            this.headers
              .removeAttr(
                'role aria-expanded aria-selected aria-controls tabIndex'
              )
              .removeUniqueId(),
            this._destroyIcons(),
            (a = this.headers
              .next()
              .css('display', '')
              .removeAttr('role aria-hidden aria-labelledby')
              .removeUniqueId()),
            'content' !== this.options.heightStyle && a.css('height', '');
        },
        _setOption: function (a, b) {
          return 'active' === a
            ? void this._activate(b)
            : ('event' === a &&
                (this.options.event &&
                  this._off(this.headers, this.options.event),
                this._setupEvents(b)),
              this._super(a, b),
              'collapsible' !== a ||
                b ||
                !1 !== this.options.active ||
                this._activate(0),
              void (
                'icons' === a &&
                (this._destroyIcons(), b && this._createIcons())
              ));
        },
        _setOptionDisabled: function (a) {
          this._super(a),
            this.element.attr('aria-disabled', a),
            this._toggleClass(null, 'ui-state-disabled', !!a),
            this._toggleClass(
              this.headers.add(this.headers.next()),
              null,
              'ui-state-disabled',
              !!a
            );
        },
        _keydown: function (b) {
          if (!b.altKey && !b.ctrlKey) {
            var c = a.ui.keyCode,
              d = this.headers.length,
              e = this.headers.index(b.target),
              f = !1;
            switch (b.keyCode) {
              case c.RIGHT:
              case c.DOWN:
                f = this.headers[(e + 1) % d];
                break;
              case c.LEFT:
              case c.UP:
                f = this.headers[(e - 1 + d) % d];
                break;
              case c.SPACE:
              case c.ENTER:
                this._eventHandler(b);
                break;
              case c.HOME:
                f = this.headers[0];
                break;
              case c.END:
                f = this.headers[d - 1];
            }
            f &&
              (a(b.target).attr('tabIndex', -1),
              a(f).attr('tabIndex', 0),
              a(f).trigger('focus'),
              b.preventDefault());
          }
        },
        _panelKeyDown: function (b) {
          b.keyCode === a.ui.keyCode.UP &&
            b.ctrlKey &&
            a(b.currentTarget).prev().trigger('focus');
        },
        refresh: function () {
          var b = this.options;
          this._processPanels(),
            (!1 === b.active && !0 === b.collapsible) || !this.headers.length
              ? ((b.active = !1), (this.active = a()))
              : !1 === b.active
                ? this._activate(0)
                : this.active.length &&
                    !a.contains(this.element[0], this.active[0])
                  ? this.headers.length ===
                    this.headers.find('.ui-state-disabled').length
                    ? ((b.active = !1), (this.active = a()))
                    : this._activate(Math.max(0, b.active - 1))
                  : (b.active = this.headers.index(this.active)),
            this._destroyIcons(),
            this._refresh();
        },
        _processPanels: function () {
          var a = this.headers,
            b = this.panels;
          (this.headers = this.element.find(this.options.header)),
            this._addClass(
              this.headers,
              'ui-accordion-header ui-accordion-header-collapsed',
              'ui-state-default'
            ),
            (this.panels = this.headers
              .next()
              .filter(':not(.ui-accordion-content-active)')
              .hide()),
            this._addClass(
              this.panels,
              'ui-accordion-content',
              'ui-helper-reset ui-widget-content'
            ),
            b &&
              (this._off(a.not(this.headers)), this._off(b.not(this.panels)));
        },
        _refresh: function () {
          var b,
            c = this.options,
            d = c.heightStyle,
            e = this.element.parent();
          (this.active = this._findActive(c.active)),
            this._addClass(
              this.active,
              'ui-accordion-header-active',
              'ui-state-active'
            )._removeClass(this.active, 'ui-accordion-header-collapsed'),
            this._addClass(this.active.next(), 'ui-accordion-content-active'),
            this.active.next().show(),
            this.headers
              .attr('role', 'tab')
              .each(function () {
                var b = a(this),
                  c = b.uniqueId().attr('id'),
                  d = b.next(),
                  e = d.uniqueId().attr('id');
                b.attr('aria-controls', e), d.attr('aria-labelledby', c);
              })
              .next()
              .attr('role', 'tabpanel'),
            this.headers
              .not(this.active)
              .attr({
                'aria-selected': 'false',
                'aria-expanded': 'false',
                tabIndex: -1
              })
              .next()
              .attr({ 'aria-hidden': 'true' })
              .hide(),
            this.active.length
              ? this.active
                  .attr({
                    'aria-selected': 'true',
                    'aria-expanded': 'true',
                    tabIndex: 0
                  })
                  .next()
                  .attr({ 'aria-hidden': 'false' })
              : this.headers.eq(0).attr('tabIndex', 0),
            this._createIcons(),
            this._setupEvents(c.event),
            'fill' === d
              ? ((b = e.height()),
                this.element.siblings(':visible').each(function () {
                  var c = a(this),
                    d = c.css('position');
                  'absolute' !== d && 'fixed' !== d && (b -= c.outerHeight(!0));
                }),
                this.headers.each(function () {
                  b -= a(this).outerHeight(!0);
                }),
                this.headers
                  .next()
                  .each(function () {
                    a(this).height(
                      Math.max(0, b - a(this).innerHeight() + a(this).height())
                    );
                  })
                  .css('overflow', 'auto'))
              : 'auto' === d &&
                ((b = 0),
                this.headers
                  .next()
                  .each(function () {
                    var c = a(this).is(':visible');
                    c || a(this).show(),
                      (b = Math.max(b, a(this).css('height', '').height())),
                      c || a(this).hide();
                  })
                  .height(b));
        },
        _activate: function (b) {
          var c = this._findActive(b)[0];
          c !== this.active[0] &&
            ((c = c || this.active[0]),
            this._eventHandler({
              target: c,
              currentTarget: c,
              preventDefault: a.noop
            }));
        },
        _findActive: function (b) {
          return 'number' == typeof b ? this.headers.eq(b) : a();
        },
        _setupEvents: function (b) {
          var c = { keydown: '_keydown' };
          b &&
            a.each(b.split(' '), function (a, b) {
              c[b] = '_eventHandler';
            }),
            this._off(this.headers.add(this.headers.next())),
            this._on(this.headers, c),
            this._on(this.headers.next(), { keydown: '_panelKeyDown' }),
            this._hoverable(this.headers),
            this._focusable(this.headers);
        },
        _eventHandler: function (b) {
          var c,
            d,
            e = this.options,
            f = this.active,
            g = a(b.currentTarget),
            h = g[0] === f[0],
            i = h && e.collapsible,
            j = i ? a() : g.next(),
            k = f.next(),
            l = {
              oldHeader: f,
              oldPanel: k,
              newHeader: i ? a() : g,
              newPanel: j
            };
          b.preventDefault(),
            (h && !e.collapsible) ||
              !1 === this._trigger('beforeActivate', b, l) ||
              ((e.active = !i && this.headers.index(g)),
              (this.active = h ? a() : g),
              this._toggle(l),
              this._removeClass(
                f,
                'ui-accordion-header-active',
                'ui-state-active'
              ),
              e.icons &&
                ((c = f.children('.ui-accordion-header-icon')),
                this._removeClass(c, null, e.icons.activeHeader)._addClass(
                  c,
                  null,
                  e.icons.header
                )),
              h ||
                (this._removeClass(
                  g,
                  'ui-accordion-header-collapsed'
                )._addClass(g, 'ui-accordion-header-active', 'ui-state-active'),
                e.icons &&
                  ((d = g.children('.ui-accordion-header-icon')),
                  this._removeClass(d, null, e.icons.header)._addClass(
                    d,
                    null,
                    e.icons.activeHeader
                  )),
                this._addClass(g.next(), 'ui-accordion-content-active')));
        },
        _toggle: function (b) {
          var c = b.newPanel,
            d = this.prevShow.length ? this.prevShow : b.oldPanel;
          this.prevShow.add(this.prevHide).stop(!0, !0),
            (this.prevShow = c),
            (this.prevHide = d),
            this.options.animate
              ? this._animate(c, d, b)
              : (d.hide(), c.show(), this._toggleComplete(b)),
            d.attr({ 'aria-hidden': 'true' }),
            d
              .prev()
              .attr({ 'aria-selected': 'false', 'aria-expanded': 'false' }),
            c.length && d.length
              ? d.prev().attr({ tabIndex: -1, 'aria-expanded': 'false' })
              : c.length &&
                this.headers
                  .filter(function () {
                    return 0 === parseInt(a(this).attr('tabIndex'), 10);
                  })
                  .attr('tabIndex', -1),
            c.attr('aria-hidden', 'false').prev().attr({
              'aria-selected': 'true',
              'aria-expanded': 'true',
              tabIndex: 0
            });
        },
        _animate: function (a, b, c) {
          var d,
            e,
            f,
            g = this,
            h = 0,
            i = a.css('box-sizing'),
            j = a.length && (!b.length || a.index() < b.index()),
            k = this.options.animate || {},
            l = (j && k.down) || k,
            m = function () {
              g._toggleComplete(c);
            };
          return (
            'number' == typeof l && (f = l),
            'string' == typeof l && (e = l),
            (e = e || l.easing || k.easing),
            (f = f || l.duration || k.duration),
            b.length
              ? a.length
                ? ((d = a.show().outerHeight()),
                  b.animate(this.hideProps, {
                    duration: f,
                    easing: e,
                    step: function (a, b) {
                      b.now = Math.round(a);
                    }
                  }),
                  void a.hide().animate(this.showProps, {
                    duration: f,
                    easing: e,
                    complete: m,
                    step: function (a, c) {
                      (c.now = Math.round(a)),
                        'height' !== c.prop
                          ? 'content-box' === i && (h += c.now)
                          : 'content' !== g.options.heightStyle &&
                            ((c.now = Math.round(d - b.outerHeight() - h)),
                            (h = 0));
                    }
                  }))
                : b.animate(this.hideProps, f, e, m)
              : a.animate(this.showProps, f, e, m)
          );
        },
        _toggleComplete: function (a) {
          var b = a.oldPanel,
            c = b.prev();
          this._removeClass(b, 'ui-accordion-content-active'),
            this._removeClass(c, 'ui-accordion-header-active')._addClass(
              c,
              'ui-accordion-header-collapsed'
            ),
            b.length && (b.parent()[0].className = b.parent()[0].className),
            this._trigger('activate', null, a);
        }
      }),
      (a.ui.safeActiveElement = function (a) {
        var b;
        try {
          b = a.activeElement;
        } catch (c) {
          b = a.body;
        }
        return b || (b = a.body), b.nodeName || (b = a.body), b;
      }),
      a.widget('ui.menu', {
        version: '1.12.1',
        defaultElement: '<ul>',
        delay: 300,
        options: {
          icons: { submenu: 'ui-icon-caret-1-e' },
          items: '> *',
          menus: 'ul',
          position: { my: 'left top', at: 'right top' },
          role: 'menu',
          blur: null,
          focus: null,
          select: null
        },
        _create: function () {
          (this.activeMenu = this.element),
            (this.mouseHandled = !1),
            this.element
              .uniqueId()
              .attr({ role: this.options.role, tabIndex: 0 }),
            this._addClass('ui-menu', 'ui-widget ui-widget-content'),
            this._on({
              'mousedown .ui-menu-item': function (a) {
                a.preventDefault();
              },
              'click .ui-menu-item': function (b) {
                var c = a(b.target),
                  d = a(a.ui.safeActiveElement(this.document[0]));
                !this.mouseHandled &&
                  c.not('.ui-state-disabled').length &&
                  (this.select(b),
                  b.isPropagationStopped() || (this.mouseHandled = !0),
                  c.has('.ui-menu').length
                    ? this.expand(b)
                    : !this.element.is(':focus') &&
                      d.closest('.ui-menu').length &&
                      (this.element.trigger('focus', [!0]),
                      this.active &&
                        1 === this.active.parents('.ui-menu').length &&
                        clearTimeout(this.timer)));
              },
              'mouseenter .ui-menu-item': function (b) {
                if (!this.previousFilter) {
                  var c = a(b.target).closest('.ui-menu-item'),
                    d = a(b.currentTarget);
                  c[0] === d[0] &&
                    (this._removeClass(
                      d.siblings().children('.ui-state-active'),
                      null,
                      'ui-state-active'
                    ),
                    this.focus(b, d));
                }
              },
              mouseleave: 'collapseAll',
              'mouseleave .ui-menu': 'collapseAll',
              focus: function (a, b) {
                var c =
                  this.active || this.element.find(this.options.items).eq(0);
                b || this.focus(a, c);
              },
              blur: function (b) {
                this._delay(function () {
                  !a.contains(
                    this.element[0],
                    a.ui.safeActiveElement(this.document[0])
                  ) && this.collapseAll(b);
                });
              },
              keydown: '_keydown'
            }),
            this.refresh(),
            this._on(this.document, {
              click: function (a) {
                this._closeOnDocumentClick(a) && this.collapseAll(a),
                  (this.mouseHandled = !1);
              }
            });
        },
        _destroy: function () {
          var b = this.element
              .find('.ui-menu-item')
              .removeAttr('role aria-disabled'),
            c = b
              .children('.ui-menu-item-wrapper')
              .removeUniqueId()
              .removeAttr('tabIndex role aria-haspopup');
          this.element
            .removeAttr('aria-activedescendant')
            .find('.ui-menu')
            .addBack()
            .removeAttr(
              'role aria-labelledby aria-expanded aria-hidden aria-disabled tabIndex'
            )
            .removeUniqueId()
            .show(),
            c.children().each(function () {
              var b = a(this);
              b.data('ui-menu-submenu-caret') && b.remove();
            });
        },
        _keydown: function (b) {
          var c,
            d,
            e,
            f,
            g = !0;
          switch (b.keyCode) {
            case a.ui.keyCode.PAGE_UP:
              this.previousPage(b);
              break;
            case a.ui.keyCode.PAGE_DOWN:
              this.nextPage(b);
              break;
            case a.ui.keyCode.HOME:
              this._move('first', 'first', b);
              break;
            case a.ui.keyCode.END:
              this._move('last', 'last', b);
              break;
            case a.ui.keyCode.UP:
              this.previous(b);
              break;
            case a.ui.keyCode.DOWN:
              this.next(b);
              break;
            case a.ui.keyCode.LEFT:
              this.collapse(b);
              break;
            case a.ui.keyCode.RIGHT:
              this.active &&
                !this.active.is('.ui-state-disabled') &&
                this.expand(b);
              break;
            case a.ui.keyCode.ENTER:
            case a.ui.keyCode.SPACE:
              this._activate(b);
              break;
            case a.ui.keyCode.ESCAPE:
              this.collapse(b);
              break;
            default:
              (g = !1),
                (d = this.previousFilter || ''),
                (f = !1),
                (e =
                  b.keyCode >= 96 && 105 >= b.keyCode
                    ? '' + (b.keyCode - 96)
                    : String.fromCharCode(b.keyCode)),
                clearTimeout(this.filterTimer),
                e === d ? (f = !0) : (e = d + e),
                (c = this._filterMenuItems(e)),
                (c =
                  f && -1 !== c.index(this.active.next())
                    ? this.active.nextAll('.ui-menu-item')
                    : c),
                c.length ||
                  ((e = String.fromCharCode(b.keyCode)),
                  (c = this._filterMenuItems(e))),
                c.length
                  ? (this.focus(b, c),
                    (this.previousFilter = e),
                    (this.filterTimer = this._delay(function () {
                      delete this.previousFilter;
                    }, 1e3)))
                  : delete this.previousFilter;
          }
          g && b.preventDefault();
        },
        _activate: function (a) {
          this.active &&
            !this.active.is('.ui-state-disabled') &&
            (this.active.children("[aria-haspopup='true']").length
              ? this.expand(a)
              : this.select(a));
        },
        refresh: function () {
          var b,
            c,
            d,
            e,
            f,
            g = this,
            h = this.options.icons.submenu,
            i = this.element.find(this.options.menus);
          this._toggleClass(
            'ui-menu-icons',
            null,
            !!this.element.find('.ui-icon').length
          ),
            (d = i
              .filter(':not(.ui-menu)')
              .hide()
              .attr({
                role: this.options.role,
                'aria-hidden': 'true',
                'aria-expanded': 'false'
              })
              .each(function () {
                var b = a(this),
                  c = b.prev(),
                  d = a('<span>').data('ui-menu-submenu-caret', !0);
                g._addClass(d, 'ui-menu-icon', 'ui-icon ' + h),
                  c.attr('aria-haspopup', 'true').prepend(d),
                  b.attr('aria-labelledby', c.attr('id'));
              })),
            this._addClass(
              d,
              'ui-menu',
              'ui-widget ui-widget-content ui-front'
            ),
            (b = i.add(this.element)),
            (c = b.find(this.options.items)),
            c.not('.ui-menu-item').each(function () {
              var b = a(this);
              g._isDivider(b) &&
                g._addClass(b, 'ui-menu-divider', 'ui-widget-content');
            }),
            (e = c.not('.ui-menu-item, .ui-menu-divider')),
            (f = e
              .children()
              .not('.ui-menu')
              .uniqueId()
              .attr({ tabIndex: -1, role: this._itemRole() })),
            this._addClass(e, 'ui-menu-item')._addClass(
              f,
              'ui-menu-item-wrapper'
            ),
            c.filter('.ui-state-disabled').attr('aria-disabled', 'true'),
            this.active &&
              !a.contains(this.element[0], this.active[0]) &&
              this.blur();
        },
        _itemRole: function () {
          return { menu: 'menuitem', listbox: 'option' }[this.options.role];
        },
        _setOption: function (a, b) {
          if ('icons' === a) {
            var c = this.element.find('.ui-menu-icon');
            this._removeClass(c, null, this.options.icons.submenu)._addClass(
              c,
              null,
              b.submenu
            );
          }
          this._super(a, b);
        },
        _setOptionDisabled: function (a) {
          this._super(a),
            this.element.attr('aria-disabled', a + ''),
            this._toggleClass(null, 'ui-state-disabled', !!a);
        },
        focus: function (a, b) {
          var c, d, e;
          this.blur(a, a && 'focus' === a.type),
            this._scrollIntoView(b),
            (this.active = b.first()),
            (d = this.active.children('.ui-menu-item-wrapper')),
            this._addClass(d, null, 'ui-state-active'),
            this.options.role &&
              this.element.attr('aria-activedescendant', d.attr('id')),
            (e = this.active
              .parent()
              .closest('.ui-menu-item')
              .children('.ui-menu-item-wrapper')),
            this._addClass(e, null, 'ui-state-active'),
            a && 'keydown' === a.type
              ? this._close()
              : (this.timer = this._delay(function () {
                  this._close();
                }, this.delay)),
            (c = b.children('.ui-menu')),
            c.length && a && /^mouse/.test(a.type) && this._startOpening(c),
            (this.activeMenu = b.parent()),
            this._trigger('focus', a, { item: b });
        },
        _scrollIntoView: function (b) {
          var c, d, e, f, g, h;
          this._hasScroll() &&
            ((c = parseFloat(a.css(this.activeMenu[0], 'borderTopWidth')) || 0),
            (d = parseFloat(a.css(this.activeMenu[0], 'paddingTop')) || 0),
            (e = b.offset().top - this.activeMenu.offset().top - c - d),
            (f = this.activeMenu.scrollTop()),
            (g = this.activeMenu.height()),
            (h = b.outerHeight()),
            0 > e
              ? this.activeMenu.scrollTop(f + e)
              : e + h > g && this.activeMenu.scrollTop(f + e - g + h));
        },
        blur: function (a, b) {
          b || clearTimeout(this.timer),
            this.active &&
              (this._removeClass(
                this.active.children('.ui-menu-item-wrapper'),
                null,
                'ui-state-active'
              ),
              this._trigger('blur', a, { item: this.active }),
              (this.active = null));
        },
        _startOpening: function (a) {
          clearTimeout(this.timer),
            'true' === a.attr('aria-hidden') &&
              (this.timer = this._delay(function () {
                this._close(), this._open(a);
              }, this.delay));
        },
        _open: function (b) {
          var c = a.extend({ of: this.active }, this.options.position);
          clearTimeout(this.timer),
            this.element
              .find('.ui-menu')
              .not(b.parents('.ui-menu'))
              .hide()
              .attr('aria-hidden', 'true'),
            b
              .show()
              .removeAttr('aria-hidden')
              .attr('aria-expanded', 'true')
              .position(c);
        },
        collapseAll: function (b, c) {
          clearTimeout(this.timer),
            (this.timer = this._delay(function () {
              var d = c
                ? this.element
                : a(b && b.target).closest(this.element.find('.ui-menu'));
              d.length || (d = this.element),
                this._close(d),
                this.blur(b),
                this._removeClass(
                  d.find('.ui-state-active'),
                  null,
                  'ui-state-active'
                ),
                (this.activeMenu = d);
            }, this.delay));
        },
        _close: function (a) {
          a || (a = this.active ? this.active.parent() : this.element),
            a
              .find('.ui-menu')
              .hide()
              .attr('aria-hidden', 'true')
              .attr('aria-expanded', 'false');
        },
        _closeOnDocumentClick: function (b) {
          return !a(b.target).closest('.ui-menu').length;
        },
        _isDivider: function (a) {
          return !/[^\-\u2014\u2013\s]/.test(a.text());
        },
        collapse: function (a) {
          var b =
            this.active &&
            this.active.parent().closest('.ui-menu-item', this.element);
          b && b.length && (this._close(), this.focus(a, b));
        },
        expand: function (a) {
          var b =
            this.active &&
            this.active.children('.ui-menu ').find(this.options.items).first();
          b &&
            b.length &&
            (this._open(b.parent()),
            this._delay(function () {
              this.focus(a, b);
            }));
        },
        next: function (a) {
          this._move('next', 'first', a);
        },
        previous: function (a) {
          this._move('prev', 'last', a);
        },
        isFirstItem: function () {
          return this.active && !this.active.prevAll('.ui-menu-item').length;
        },
        isLastItem: function () {
          return this.active && !this.active.nextAll('.ui-menu-item').length;
        },
        _move: function (a, b, c) {
          var d;
          this.active &&
            (d =
              'first' === a || 'last' === a
                ? this.active['first' === a ? 'prevAll' : 'nextAll'](
                    '.ui-menu-item'
                  ).eq(-1)
                : this.active[a + 'All']('.ui-menu-item').eq(0)),
            (d && d.length && this.active) ||
              (d = this.activeMenu.find(this.options.items)[b]()),
            this.focus(c, d);
        },
        nextPage: function (b) {
          var c, d, e;
          return this.active
            ? void (
                this.isLastItem() ||
                (this._hasScroll()
                  ? ((d = this.active.offset().top),
                    (e = this.element.height()),
                    this.active.nextAll('.ui-menu-item').each(function () {
                      return (c = a(this)), 0 > c.offset().top - d - e;
                    }),
                    this.focus(b, c))
                  : this.focus(
                      b,
                      this.activeMenu
                        .find(this.options.items)
                        [this.active ? 'last' : 'first']()
                    ))
              )
            : void this.next(b);
        },
        previousPage: function (b) {
          var c, d, e;
          return this.active
            ? void (
                this.isFirstItem() ||
                (this._hasScroll()
                  ? ((d = this.active.offset().top),
                    (e = this.element.height()),
                    this.active.prevAll('.ui-menu-item').each(function () {
                      return (c = a(this)), c.offset().top - d + e > 0;
                    }),
                    this.focus(b, c))
                  : this.focus(
                      b,
                      this.activeMenu.find(this.options.items).first()
                    ))
              )
            : void this.next(b);
        },
        _hasScroll: function () {
          return this.element.outerHeight() < this.element.prop('scrollHeight');
        },
        select: function (b) {
          this.active = this.active || a(b.target).closest('.ui-menu-item');
          var c = { item: this.active };
          this.active.has('.ui-menu').length || this.collapseAll(b, !0),
            this._trigger('select', b, c);
        },
        _filterMenuItems: function (b) {
          var c = b.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&'),
            d = RegExp('^' + c, 'i');
          return this.activeMenu
            .find(this.options.items)
            .filter('.ui-menu-item')
            .filter(function () {
              return d.test(
                a.trim(a(this).children('.ui-menu-item-wrapper').text())
              );
            });
        }
      }),
      a.widget('ui.autocomplete', {
        version: '1.12.1',
        defaultElement: '<input>',
        options: {
          appendTo: null,
          autoFocus: !1,
          delay: 300,
          minLength: 1,
          position: { my: 'left top', at: 'left bottom', collision: 'none' },
          source: null,
          change: null,
          close: null,
          focus: null,
          open: null,
          response: null,
          search: null,
          select: null
        },
        requestIndex: 0,
        pending: 0,
        _create: function () {
          var b,
            c,
            d,
            e = this.element[0].nodeName.toLowerCase(),
            f = 'textarea' === e,
            g = 'input' === e;
          (this.isMultiLine =
            f || (!g && this._isContentEditable(this.element))),
            (this.valueMethod = this.element[f || g ? 'val' : 'text']),
            (this.isNewMenu = !0),
            this._addClass('ui-autocomplete-input'),
            this.element.attr('autocomplete', 'off'),
            this._on(this.element, {
              keydown: function (e) {
                if (this.element.prop('readOnly'))
                  return (b = !0), (d = !0), void (c = !0);
                (b = !1), (d = !1), (c = !1);
                var f = a.ui.keyCode;
                switch (e.keyCode) {
                  case f.PAGE_UP:
                    (b = !0), this._move('previousPage', e);
                    break;
                  case f.PAGE_DOWN:
                    (b = !0), this._move('nextPage', e);
                    break;
                  case f.UP:
                    (b = !0), this._keyEvent('previous', e);
                    break;
                  case f.DOWN:
                    (b = !0), this._keyEvent('next', e);
                    break;
                  case f.ENTER:
                    this.menu.active &&
                      ((b = !0), e.preventDefault(), this.menu.select(e));
                    break;
                  case f.TAB:
                    this.menu.active && this.menu.select(e);
                    break;
                  case f.ESCAPE:
                    this.menu.element.is(':visible') &&
                      (this.isMultiLine || this._value(this.term),
                      this.close(e),
                      e.preventDefault());
                    break;
                  default:
                    (c = !0), this._searchTimeout(e);
                }
              },
              keypress: function (d) {
                if (b)
                  return (
                    (b = !1),
                    void (
                      (!this.isMultiLine || this.menu.element.is(':visible')) &&
                      d.preventDefault()
                    )
                  );
                if (!c) {
                  var e = a.ui.keyCode;
                  switch (d.keyCode) {
                    case e.PAGE_UP:
                      this._move('previousPage', d);
                      break;
                    case e.PAGE_DOWN:
                      this._move('nextPage', d);
                      break;
                    case e.UP:
                      this._keyEvent('previous', d);
                      break;
                    case e.DOWN:
                      this._keyEvent('next', d);
                  }
                }
              },
              input: function (a) {
                return d
                  ? ((d = !1), void a.preventDefault())
                  : void this._searchTimeout(a);
              },
              focus: function () {
                (this.selectedItem = null), (this.previous = this._value());
              },
              blur: function (a) {
                return this.cancelBlur
                  ? void delete this.cancelBlur
                  : (clearTimeout(this.searching),
                    this.close(a),
                    void this._change(a));
              }
            }),
            this._initSource(),
            (this.menu = a('<ul>')
              .appendTo(this._appendTo())
              .menu({ role: null })
              .hide()
              .menu('instance')),
            this._addClass(this.menu.element, 'ui-autocomplete', 'ui-front'),
            this._on(this.menu.element, {
              mousedown: function (b) {
                b.preventDefault(),
                  (this.cancelBlur = !0),
                  this._delay(function () {
                    delete this.cancelBlur,
                      this.element[0] !==
                        a.ui.safeActiveElement(this.document[0]) &&
                        this.element.trigger('focus');
                  });
              },
              menufocus: function (b, c) {
                var d, e;
                return this.isNewMenu &&
                  ((this.isNewMenu = !1),
                  b.originalEvent && /^mouse/.test(b.originalEvent.type))
                  ? (this.menu.blur(),
                    void this.document.one('mousemove', function () {
                      a(b.target).trigger(b.originalEvent);
                    }))
                  : ((e = c.item.data('ui-autocomplete-item')),
                    !1 !== this._trigger('focus', b, { item: e }) &&
                      b.originalEvent &&
                      /^key/.test(b.originalEvent.type) &&
                      this._value(e.value),
                    void (
                      (d = c.item.attr('aria-label') || e.value) &&
                      a.trim(d).length &&
                      (this.liveRegion.children().hide(),
                      a('<div>').text(d).appendTo(this.liveRegion))
                    ));
              },
              menuselect: function (b, c) {
                var d = c.item.data('ui-autocomplete-item'),
                  e = this.previous;
                this.element[0] !== a.ui.safeActiveElement(this.document[0]) &&
                  (this.element.trigger('focus'),
                  (this.previous = e),
                  this._delay(function () {
                    (this.previous = e), (this.selectedItem = d);
                  })),
                  !1 !== this._trigger('select', b, { item: d }) &&
                    this._value(d.value),
                  (this.term = this._value()),
                  this.close(b),
                  (this.selectedItem = d);
              }
            }),
            (this.liveRegion = a('<div>', {
              role: 'status',
              'aria-live': 'assertive',
              'aria-relevant': 'additions'
            }).appendTo(this.document[0].body)),
            this._addClass(
              this.liveRegion,
              null,
              'ui-helper-hidden-accessible'
            ),
            this._on(this.window, {
              beforeunload: function () {
                this.element.removeAttr('autocomplete');
              }
            });
        },
        _destroy: function () {
          clearTimeout(this.searching),
            this.element.removeAttr('autocomplete'),
            this.menu.element.remove(),
            this.liveRegion.remove();
        },
        _setOption: function (a, b) {
          this._super(a, b),
            'source' === a && this._initSource(),
            'appendTo' === a && this.menu.element.appendTo(this._appendTo()),
            'disabled' === a && b && this.xhr && this.xhr.abort();
        },
        _isEventTargetInWidget: function (b) {
          var c = this.menu.element[0];
          return (
            b.target === this.element[0] ||
            b.target === c ||
            a.contains(c, b.target)
          );
        },
        _closeOnClickOutside: function (a) {
          this._isEventTargetInWidget(a) || this.close();
        },
        _appendTo: function () {
          var b = this.options.appendTo;
          return (
            b &&
              (b = b.jquery || b.nodeType ? a(b) : this.document.find(b).eq(0)),
            (b && b[0]) || (b = this.element.closest('.ui-front, dialog')),
            b.length || (b = this.document[0].body),
            b
          );
        },
        _initSource: function () {
          var b,
            c,
            d = this;
          a.isArray(this.options.source)
            ? ((b = this.options.source),
              (this.source = function (c, d) {
                d(a.ui.autocomplete.filter(b, c.term));
              }))
            : 'string' == typeof this.options.source
              ? ((c = this.options.source),
                (this.source = function (b, e) {
                  d.xhr && d.xhr.abort(),
                    (d.xhr = a.ajax({
                      url: c,
                      data: b,
                      dataType: 'json',
                      success: function (a) {
                        e(a);
                      },
                      error: function () {
                        e([]);
                      }
                    }));
                }))
              : (this.source = this.options.source);
        },
        _searchTimeout: function (a) {
          clearTimeout(this.searching),
            (this.searching = this._delay(function () {
              var b = this.term === this._value(),
                c = this.menu.element.is(':visible'),
                d = a.altKey || a.ctrlKey || a.metaKey || a.shiftKey;
              (!b || (b && !c && !d)) &&
                ((this.selectedItem = null), this.search(null, a));
            }, this.options.delay));
        },
        search: function (a, b) {
          return (
            (a = null != a ? a : this._value()),
            (this.term = this._value()),
            a.length < this.options.minLength
              ? this.close(b)
              : !1 !== this._trigger('search', b)
                ? this._search(a)
                : void 0
          );
        },
        _search: function (a) {
          this.pending++,
            this._addClass('ui-autocomplete-loading'),
            (this.cancelSearch = !1),
            this.source({ term: a }, this._response());
        },
        _response: function () {
          var b = ++this.requestIndex;
          return a.proxy(function (a) {
            b === this.requestIndex && this.__response(a),
              --this.pending || this._removeClass('ui-autocomplete-loading');
          }, this);
        },
        __response: function (a) {
          a && (a = this._normalize(a)),
            this._trigger('response', null, { content: a }),
            !this.options.disabled && a && a.length && !this.cancelSearch
              ? (this._suggest(a), this._trigger('open'))
              : this._close();
        },
        close: function (a) {
          (this.cancelSearch = !0), this._close(a);
        },
        _close: function (a) {
          this._off(this.document, 'mousedown'),
            this.menu.element.is(':visible') &&
              (this.menu.element.hide(),
              this.menu.blur(),
              (this.isNewMenu = !0),
              this._trigger('close', a));
        },
        _change: function (a) {
          this.previous !== this._value() &&
            this._trigger('change', a, { item: this.selectedItem });
        },
        _normalize: function (b) {
          return b.length && b[0].label && b[0].value
            ? b
            : a.map(b, function (b) {
                return 'string' == typeof b
                  ? { label: b, value: b }
                  : a.extend({}, b, {
                      label: b.label || b.value,
                      value: b.value || b.label
                    });
              });
        },
        _suggest: function (b) {
          var c = this.menu.element.empty();
          this._renderMenu(c, b),
            (this.isNewMenu = !0),
            this.menu.refresh(),
            c.show(),
            this._resizeMenu(),
            c.position(a.extend({ of: this.element }, this.options.position)),
            this.options.autoFocus && this.menu.next(),
            this._on(this.document, { mousedown: '_closeOnClickOutside' });
        },
        _resizeMenu: function () {
          var a = this.menu.element;
          a.outerWidth(
            Math.max(a.width('').outerWidth() + 1, this.element.outerWidth())
          );
        },
        _renderMenu: function (b, c) {
          var d = this;
          a.each(c, function (a, c) {
            d._renderItemData(b, c);
          });
        },
        _renderItemData: function (a, b) {
          return this._renderItem(a, b).data('ui-autocomplete-item', b);
        },
        _renderItem: function (b, c) {
          return a('<li>').append(a('<div>').text(c.label)).appendTo(b);
        },
        _move: function (a, b) {
          return this.menu.element.is(':visible')
            ? (this.menu.isFirstItem() && /^previous/.test(a)) ||
              (this.menu.isLastItem() && /^next/.test(a))
              ? (this.isMultiLine || this._value(this.term),
                void this.menu.blur())
              : void this.menu[a](b)
            : void this.search(null, b);
        },
        widget: function () {
          return this.menu.element;
        },
        _value: function () {
          return this.valueMethod.apply(this.element, arguments);
        },
        _keyEvent: function (a, b) {
          (!this.isMultiLine || this.menu.element.is(':visible')) &&
            (this._move(a, b), b.preventDefault());
        },
        _isContentEditable: function (a) {
          if (!a.length) return !1;
          var b = a.prop('contentEditable');
          return 'inherit' === b
            ? this._isContentEditable(a.parent())
            : 'true' === b;
        }
      }),
      a.extend(a.ui.autocomplete, {
        escapeRegex: function (a) {
          return a.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
        },
        filter: function (b, c) {
          var d = RegExp(a.ui.autocomplete.escapeRegex(c), 'i');
          return a.grep(b, function (a) {
            return d.test(a.label || a.value || a);
          });
        }
      }),
      a.widget('ui.autocomplete', a.ui.autocomplete, {
        options: {
          messages: {
            noResults: 'No search results.',
            results: function (a) {
              return (
                a +
                (a > 1 ? ' results are' : ' result is') +
                ' available, use up and down arrow keys to navigate.'
              );
            }
          }
        },
        __response: function (b) {
          var c;
          this._superApply(arguments),
            this.options.disabled ||
              this.cancelSearch ||
              ((c =
                b && b.length
                  ? this.options.messages.results(b.length)
                  : this.options.messages.noResults),
              this.liveRegion.children().hide(),
              a('<div>').text(c).appendTo(this.liveRegion));
        }
      }),
      a.ui.autocomplete;
    var o = /ui-corner-([a-z]){2,6}/g;
    a.widget('ui.controlgroup', {
      version: '1.12.1',
      defaultElement: '<div>',
      options: {
        direction: 'horizontal',
        disabled: null,
        onlyVisible: !0,
        items: {
          button:
            'input[type=button], input[type=submit], input[type=reset], button, a',
          controlgroupLabel: '.ui-controlgroup-label',
          checkboxradio: "input[type='checkbox'], input[type='radio']",
          selectmenu: 'select',
          spinner: '.ui-spinner-input'
        }
      },
      _create: function () {
        this._enhance();
      },
      _enhance: function () {
        this.element.attr('role', 'toolbar'), this.refresh();
      },
      _destroy: function () {
        this._callChildMethod('destroy'),
          this.childWidgets.removeData('ui-controlgroup-data'),
          this.element.removeAttr('role'),
          this.options.items.controlgroupLabel &&
            this.element
              .find(this.options.items.controlgroupLabel)
              .find('.ui-controlgroup-label-contents')
              .contents()
              .unwrap();
      },
      _initWidgets: function () {
        var b = this,
          c = [];
        a.each(this.options.items, function (d, e) {
          var f,
            g = {};
          return e
            ? 'controlgroupLabel' === d
              ? ((f = b.element.find(e)),
                f.each(function () {
                  var b = a(this);
                  b.children('.ui-controlgroup-label-contents').length ||
                    b
                      .contents()
                      .wrapAll(
                        "<span class='ui-controlgroup-label-contents'></span>"
                      );
                }),
                b._addClass(
                  f,
                  null,
                  'ui-widget ui-widget-content ui-state-default'
                ),
                void (c = c.concat(f.get())))
              : void (
                  a.fn[d] &&
                  ((g = b['_' + d + 'Options']
                    ? b['_' + d + 'Options']('middle')
                    : { classes: {} }),
                  b.element.find(e).each(function () {
                    var e = a(this),
                      f = e[d]('instance'),
                      h = a.widget.extend({}, g);
                    if ('button' !== d || !e.parent('.ui-spinner').length) {
                      f || (f = e[d]()[d]('instance')),
                        f &&
                          (h.classes = b._resolveClassesValues(h.classes, f)),
                        e[d](h);
                      var i = e[d]('widget');
                      a.data(
                        i[0],
                        'ui-controlgroup-data',
                        f || e[d]('instance')
                      ),
                        c.push(i[0]);
                    }
                  }))
                )
            : void 0;
        }),
          (this.childWidgets = a(a.unique(c))),
          this._addClass(this.childWidgets, 'ui-controlgroup-item');
      },
      _callChildMethod: function (b) {
        this.childWidgets.each(function () {
          var c = a(this),
            d = c.data('ui-controlgroup-data');
          d && d[b] && d[b]();
        });
      },
      _updateCornerClass: function (a, b) {
        var c = this._buildSimpleOptions(b, 'label').classes.label;
        this._removeClass(
          a,
          null,
          'ui-corner-top ui-corner-bottom ui-corner-left ui-corner-right ui-corner-all'
        ),
          this._addClass(a, null, c);
      },
      _buildSimpleOptions: function (a, b) {
        var c = 'vertical' === this.options.direction,
          d = { classes: {} };
        return (
          (d.classes[b] = {
            middle: '',
            first: 'ui-corner-' + (c ? 'top' : 'left'),
            last: 'ui-corner-' + (c ? 'bottom' : 'right'),
            only: 'ui-corner-all'
          }[a]),
          d
        );
      },
      _spinnerOptions: function (a) {
        var b = this._buildSimpleOptions(a, 'ui-spinner');
        return (
          (b.classes['ui-spinner-up'] = ''),
          (b.classes['ui-spinner-down'] = ''),
          b
        );
      },
      _buttonOptions: function (a) {
        return this._buildSimpleOptions(a, 'ui-button');
      },
      _checkboxradioOptions: function (a) {
        return this._buildSimpleOptions(a, 'ui-checkboxradio-label');
      },
      _selectmenuOptions: function (a) {
        var b = 'vertical' === this.options.direction;
        return {
          width: !!b && 'auto',
          classes: {
            middle: {
              'ui-selectmenu-button-open': '',
              'ui-selectmenu-button-closed': ''
            },
            first: {
              'ui-selectmenu-button-open': 'ui-corner-' + (b ? 'top' : 'tl'),
              'ui-selectmenu-button-closed': 'ui-corner-' + (b ? 'top' : 'left')
            },
            last: {
              'ui-selectmenu-button-open': b ? '' : 'ui-corner-tr',
              'ui-selectmenu-button-closed':
                'ui-corner-' + (b ? 'bottom' : 'right')
            },
            only: {
              'ui-selectmenu-button-open': 'ui-corner-top',
              'ui-selectmenu-button-closed': 'ui-corner-all'
            }
          }[a]
        };
      },
      _resolveClassesValues: function (b, c) {
        var d = {};
        return (
          a.each(b, function (e) {
            var f = c.options.classes[e] || '';
            (f = a.trim(f.replace(o, ''))),
              (d[e] = (f + ' ' + b[e]).replace(/\s+/g, ' '));
          }),
          d
        );
      },
      _setOption: function (a, b) {
        return (
          'direction' === a &&
            this._removeClass('ui-controlgroup-' + this.options.direction),
          this._super(a, b),
          'disabled' === a
            ? void this._callChildMethod(b ? 'disable' : 'enable')
            : void this.refresh()
        );
      },
      refresh: function () {
        var b,
          c = this;
        this._addClass(
          'ui-controlgroup ui-controlgroup-' + this.options.direction
        ),
          'horizontal' === this.options.direction &&
            this._addClass(null, 'ui-helper-clearfix'),
          this._initWidgets(),
          (b = this.childWidgets),
          this.options.onlyVisible && (b = b.filter(':visible')),
          b.length &&
            (a.each(['first', 'last'], function (a, d) {
              var e = b[d]().data('ui-controlgroup-data');
              if (e && c['_' + e.widgetName + 'Options']) {
                var f = c['_' + e.widgetName + 'Options'](
                  1 === b.length ? 'only' : d
                );
                (f.classes = c._resolveClassesValues(f.classes, e)),
                  e.element[e.widgetName](f);
              } else c._updateCornerClass(b[d](), d);
            }),
            this._callChildMethod('refresh'));
      }
    }),
      a.widget('ui.checkboxradio', [
        a.ui.formResetMixin,
        {
          version: '1.12.1',
          options: {
            disabled: null,
            label: null,
            icon: !0,
            classes: {
              'ui-checkboxradio-label': 'ui-corner-all',
              'ui-checkboxradio-icon': 'ui-corner-all'
            }
          },
          _getCreateOptions: function () {
            var b,
              c,
              d = this,
              e = this._super() || {};
            return (
              this._readType(),
              (c = this.element.labels()),
              (this.label = a(c[c.length - 1])),
              this.label.length ||
                a.error('No label found for checkboxradio widget'),
              (this.originalLabel = ''),
              this.label
                .contents()
                .not(this.element[0])
                .each(function () {
                  d.originalLabel +=
                    3 === this.nodeType ? a(this).text() : this.outerHTML;
                }),
              this.originalLabel && (e.label = this.originalLabel),
              (b = this.element[0].disabled),
              null != b && (e.disabled = b),
              e
            );
          },
          _create: function () {
            var a = this.element[0].checked;
            this._bindFormResetHandler(),
              null == this.options.disabled &&
                (this.options.disabled = this.element[0].disabled),
              this._setOption('disabled', this.options.disabled),
              this._addClass('ui-checkboxradio', 'ui-helper-hidden-accessible'),
              this._addClass(
                this.label,
                'ui-checkboxradio-label',
                'ui-button ui-widget'
              ),
              'radio' === this.type &&
                this._addClass(this.label, 'ui-checkboxradio-radio-label'),
              this.options.label && this.options.label !== this.originalLabel
                ? this._updateLabel()
                : this.originalLabel &&
                  (this.options.label = this.originalLabel),
              this._enhance(),
              a &&
                (this._addClass(
                  this.label,
                  'ui-checkboxradio-checked',
                  'ui-state-active'
                ),
                this.icon && this._addClass(this.icon, null, 'ui-state-hover')),
              this._on({
                change: '_toggleClasses',
                focus: function () {
                  this._addClass(
                    this.label,
                    null,
                    'ui-state-focus ui-visual-focus'
                  );
                },
                blur: function () {
                  this._removeClass(
                    this.label,
                    null,
                    'ui-state-focus ui-visual-focus'
                  );
                }
              });
          },
          _readType: function () {
            var b = this.element[0].nodeName.toLowerCase();
            (this.type = this.element[0].type),
              ('input' === b && /radio|checkbox/.test(this.type)) ||
                a.error(
                  "Can't create checkboxradio on element.nodeName=" +
                    b +
                    ' and element.type=' +
                    this.type
                );
          },
          _enhance: function () {
            this._updateIcon(this.element[0].checked);
          },
          widget: function () {
            return this.label;
          },
          _getRadioGroup: function () {
            var b,
              c = this.element[0].name,
              d = "input[name='" + a.ui.escapeSelector(c) + "']";
            return c
              ? ((b = this.form.length
                  ? a(this.form[0].elements).filter(d)
                  : a(d).filter(function () {
                      return 0 === a(this).form().length;
                    })),
                b.not(this.element))
              : a([]);
          },
          _toggleClasses: function () {
            var b = this.element[0].checked;
            this._toggleClass(
              this.label,
              'ui-checkboxradio-checked',
              'ui-state-active',
              b
            ),
              this.options.icon &&
                'checkbox' === this.type &&
                this._toggleClass(
                  this.icon,
                  null,
                  'ui-icon-check ui-state-checked',
                  b
                )._toggleClass(this.icon, null, 'ui-icon-blank', !b),
              'radio' === this.type &&
                this._getRadioGroup().each(function () {
                  var b = a(this).checkboxradio('instance');
                  b &&
                    b._removeClass(
                      b.label,
                      'ui-checkboxradio-checked',
                      'ui-state-active'
                    );
                });
          },
          _destroy: function () {
            this._unbindFormResetHandler(),
              this.icon && (this.icon.remove(), this.iconSpace.remove());
          },
          _setOption: function (a, b) {
            return 'label' !== a || b
              ? (this._super(a, b),
                'disabled' === a
                  ? (this._toggleClass(
                      this.label,
                      null,
                      'ui-state-disabled',
                      b
                    ),
                    void (this.element[0].disabled = b))
                  : void this.refresh())
              : void 0;
          },
          _updateIcon: function (b) {
            var c = 'ui-icon ui-icon-background ';
            this.options.icon
              ? (this.icon ||
                  ((this.icon = a('<span>')),
                  (this.iconSpace = a('<span> </span>')),
                  this._addClass(
                    this.iconSpace,
                    'ui-checkboxradio-icon-space'
                  )),
                'checkbox' === this.type
                  ? ((c += b
                      ? 'ui-icon-check ui-state-checked'
                      : 'ui-icon-blank'),
                    this._removeClass(
                      this.icon,
                      null,
                      b ? 'ui-icon-blank' : 'ui-icon-check'
                    ))
                  : (c += 'ui-icon-blank'),
                this._addClass(this.icon, 'ui-checkboxradio-icon', c),
                b ||
                  this._removeClass(
                    this.icon,
                    null,
                    'ui-icon-check ui-state-checked'
                  ),
                this.icon.prependTo(this.label).after(this.iconSpace))
              : void 0 !== this.icon &&
                (this.icon.remove(), this.iconSpace.remove(), delete this.icon);
          },
          _updateLabel: function () {
            var a = this.label.contents().not(this.element[0]);
            this.icon && (a = a.not(this.icon[0])),
              this.iconSpace && (a = a.not(this.iconSpace[0])),
              a.remove(),
              this.label.append(this.options.label);
          },
          refresh: function () {
            var a = this.element[0].checked,
              b = this.element[0].disabled;
            this._updateIcon(a),
              this._toggleClass(
                this.label,
                'ui-checkboxradio-checked',
                'ui-state-active',
                a
              ),
              null !== this.options.label && this._updateLabel(),
              b !== this.options.disabled && this._setOptions({ disabled: b });
          }
        }
      ]),
      a.ui.checkboxradio,
      a.widget('ui.button', {
        version: '1.12.1',
        defaultElement: '<button>',
        options: {
          classes: { 'ui-button': 'ui-corner-all' },
          disabled: null,
          icon: null,
          iconPosition: 'beginning',
          label: null,
          showLabel: !0
        },
        _getCreateOptions: function () {
          var a,
            b = this._super() || {};
          return (
            (this.isInput = this.element.is('input')),
            (a = this.element[0].disabled),
            null != a && (b.disabled = a),
            (this.originalLabel = this.isInput
              ? this.element.val()
              : this.element.html()),
            this.originalLabel && (b.label = this.originalLabel),
            b
          );
        },
        _create: function () {
          !this.option.showLabel & !this.options.icon &&
            (this.options.showLabel = !0),
            null == this.options.disabled &&
              (this.options.disabled = this.element[0].disabled || !1),
            (this.hasTitle = !!this.element.attr('title')),
            this.options.label &&
              this.options.label !== this.originalLabel &&
              (this.isInput
                ? this.element.val(this.options.label)
                : this.element.html(this.options.label)),
            this._addClass('ui-button', 'ui-widget'),
            this._setOption('disabled', this.options.disabled),
            this._enhance(),
            this.element.is('a') &&
              this._on({
                keyup: function (b) {
                  b.keyCode === a.ui.keyCode.SPACE &&
                    (b.preventDefault(),
                    this.element[0].click
                      ? this.element[0].click()
                      : this.element.trigger('click'));
                }
              });
        },
        _enhance: function () {
          this.element.is('button') || this.element.attr('role', 'button'),
            this.options.icon &&
              (this._updateIcon('icon', this.options.icon),
              this._updateTooltip());
        },
        _updateTooltip: function () {
          (this.title = this.element.attr('title')),
            this.options.showLabel ||
              this.title ||
              this.element.attr('title', this.options.label);
        },
        _updateIcon: function (b, c) {
          var d = 'iconPosition' !== b,
            e = d ? this.options.iconPosition : c,
            f = 'top' === e || 'bottom' === e;
          this.icon
            ? d && this._removeClass(this.icon, null, this.options.icon)
            : ((this.icon = a('<span>')),
              this._addClass(this.icon, 'ui-button-icon', 'ui-icon'),
              this.options.showLabel || this._addClass('ui-button-icon-only')),
            d && this._addClass(this.icon, null, c),
            this._attachIcon(e),
            f
              ? (this._addClass(this.icon, null, 'ui-widget-icon-block'),
                this.iconSpace && this.iconSpace.remove())
              : (this.iconSpace ||
                  ((this.iconSpace = a('<span> </span>')),
                  this._addClass(this.iconSpace, 'ui-button-icon-space')),
                this._removeClass(this.icon, null, 'ui-wiget-icon-block'),
                this._attachIconSpace(e));
        },
        _destroy: function () {
          this.element.removeAttr('role'),
            this.icon && this.icon.remove(),
            this.iconSpace && this.iconSpace.remove(),
            this.hasTitle || this.element.removeAttr('title');
        },
        _attachIconSpace: function (a) {
          this.icon[/^(?:end|bottom)/.test(a) ? 'before' : 'after'](
            this.iconSpace
          );
        },
        _attachIcon: function (a) {
          this.element[/^(?:end|bottom)/.test(a) ? 'append' : 'prepend'](
            this.icon
          );
        },
        _setOptions: function (a) {
          var b = void 0 === a.showLabel ? this.options.showLabel : a.showLabel,
            c = void 0 === a.icon ? this.options.icon : a.icon;
          b || c || (a.showLabel = !0), this._super(a);
        },
        _setOption: function (a, b) {
          'icon' === a &&
            (b
              ? this._updateIcon(a, b)
              : this.icon &&
                (this.icon.remove(),
                this.iconSpace && this.iconSpace.remove())),
            'iconPosition' === a && this._updateIcon(a, b),
            'showLabel' === a &&
              (this._toggleClass('ui-button-icon-only', null, !b),
              this._updateTooltip()),
            'label' === a &&
              (this.isInput
                ? this.element.val(b)
                : (this.element.html(b),
                  this.icon &&
                    (this._attachIcon(this.options.iconPosition),
                    this._attachIconSpace(this.options.iconPosition)))),
            this._super(a, b),
            'disabled' === a &&
              (this._toggleClass(null, 'ui-state-disabled', b),
              (this.element[0].disabled = b),
              b && this.element.blur());
        },
        refresh: function () {
          var a = this.element.is('input, button')
            ? this.element[0].disabled
            : this.element.hasClass('ui-button-disabled');
          a !== this.options.disabled && this._setOptions({ disabled: a }),
            this._updateTooltip();
        }
      }),
      !1 !== a.uiBackCompat &&
        (a.widget('ui.button', a.ui.button, {
          options: { text: !0, icons: { primary: null, secondary: null } },
          _create: function () {
            this.options.showLabel &&
              !this.options.text &&
              (this.options.showLabel = this.options.text),
              !this.options.showLabel &&
                this.options.text &&
                (this.options.text = this.options.showLabel),
              this.options.icon ||
              (!this.options.icons.primary && !this.options.icons.secondary)
                ? this.options.icon &&
                  (this.options.icons.primary = this.options.icon)
                : this.options.icons.primary
                  ? (this.options.icon = this.options.icons.primary)
                  : ((this.options.icon = this.options.icons.secondary),
                    (this.options.iconPosition = 'end')),
              this._super();
          },
          _setOption: function (a, b) {
            return 'text' === a
              ? void this._super('showLabel', b)
              : ('showLabel' === a && (this.options.text = b),
                'icon' === a && (this.options.icons.primary = b),
                'icons' === a &&
                  (b.primary
                    ? (this._super('icon', b.primary),
                      this._super('iconPosition', 'beginning'))
                    : b.secondary &&
                      (this._super('icon', b.secondary),
                      this._super('iconPosition', 'end'))),
                void this._superApply(arguments));
          }
        }),
        (a.fn.button = (function (b) {
          return function () {
            return !this.length ||
              (this.length && 'INPUT' !== this[0].tagName) ||
              (this.length &&
                'INPUT' === this[0].tagName &&
                'checkbox' !== this.attr('type') &&
                'radio' !== this.attr('type'))
              ? b.apply(this, arguments)
              : (a.ui.checkboxradio || a.error('Checkboxradio widget missing'),
                0 === arguments.length
                  ? this.checkboxradio({ icon: !1 })
                  : this.checkboxradio.apply(this, arguments));
          };
        })(a.fn.button)),
        (a.fn.buttonset = function () {
          return (
            a.ui.controlgroup || a.error('Controlgroup widget missing'),
            'option' === arguments[0] &&
            'items' === arguments[1] &&
            arguments[2]
              ? this.controlgroup.apply(this, [
                  arguments[0],
                  'items.button',
                  arguments[2]
                ])
              : 'option' === arguments[0] && 'items' === arguments[1]
                ? this.controlgroup.apply(this, [arguments[0], 'items.button'])
                : ('object' == typeof arguments[0] &&
                    arguments[0].items &&
                    (arguments[0].items = { button: arguments[0].items }),
                  this.controlgroup.apply(this, arguments))
          );
        })),
      a.ui.button,
      a.extend(a.ui, { datepicker: { version: '1.12.1' } });
    var p;
    a.extend(d.prototype, {
      markerClassName: 'hasDatepicker',
      maxRows: 4,
      _widgetDatepicker: function () {
        return this.dpDiv;
      },
      setDefaults: function (a) {
        return g(this._defaults, a || {}), this;
      },
      _attachDatepicker: function (b, c) {
        var d, e, f;
        (d = b.nodeName.toLowerCase()),
          (e = 'div' === d || 'span' === d),
          b.id || ((this.uuid += 1), (b.id = 'dp' + this.uuid)),
          (f = this._newInst(a(b), e)),
          (f.settings = a.extend({}, c || {})),
          'input' === d
            ? this._connectDatepicker(b, f)
            : e && this._inlineDatepicker(b, f);
      },
      _newInst: function (b, c) {
        return {
          id: b[0].id.replace(/([^A-Za-z0-9_\-])/g, '\\\\$1'),
          input: b,
          selectedDay: 0,
          selectedMonth: 0,
          selectedYear: 0,
          drawMonth: 0,
          drawYear: 0,
          inline: c,
          dpDiv: c
            ? e(
                a(
                  "<div class='" +
                    this._inlineClass +
                    " ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>"
                )
              )
            : this.dpDiv
        };
      },
      _connectDatepicker: function (b, c) {
        var d = a(b);
        (c.append = a([])),
          (c.trigger = a([])),
          d.hasClass(this.markerClassName) ||
            (this._attachments(d, c),
            d
              .addClass(this.markerClassName)
              .on('keydown', this._doKeyDown)
              .on('keypress', this._doKeyPress)
              .on('keyup', this._doKeyUp),
            this._autoSize(c),
            a.data(b, 'datepicker', c),
            c.settings.disabled && this._disableDatepicker(b));
      },
      _attachments: function (b, c) {
        var d,
          e,
          f,
          g = this._get(c, 'appendText'),
          h = this._get(c, 'isRTL');
        c.append && c.append.remove(),
          g &&
            ((c.append = a(
              "<span class='" + this._appendClass + "'>" + g + '</span>'
            )),
            b[h ? 'before' : 'after'](c.append)),
          b.off('focus', this._showDatepicker),
          c.trigger && c.trigger.remove(),
          (d = this._get(c, 'showOn')),
          ('focus' === d || 'both' === d) &&
            b.on('focus', this._showDatepicker),
          ('button' === d || 'both' === d) &&
            ((e = this._get(c, 'buttonText')),
            (f = this._get(c, 'buttonImage')),
            (c.trigger = a(
              this._get(c, 'buttonImageOnly')
                ? a('<img/>')
                    .addClass(this._triggerClass)
                    .attr({ src: f, alt: e, title: e })
                : a("<button type='button'></button>")
                    .addClass(this._triggerClass)
                    .html(
                      f ? a('<img/>').attr({ src: f, alt: e, title: e }) : e
                    )
            )),
            b[h ? 'before' : 'after'](c.trigger),
            c.trigger.on('click', function () {
              return (
                a.datepicker._datepickerShowing &&
                a.datepicker._lastInput === b[0]
                  ? a.datepicker._hideDatepicker()
                  : a.datepicker._datepickerShowing &&
                      a.datepicker._lastInput !== b[0]
                    ? (a.datepicker._hideDatepicker(),
                      a.datepicker._showDatepicker(b[0]))
                    : a.datepicker._showDatepicker(b[0]),
                !1
              );
            }));
      },
      _autoSize: function (a) {
        if (this._get(a, 'autoSize') && !a.inline) {
          var b,
            c,
            d,
            e,
            f = new Date(2009, 11, 20),
            g = this._get(a, 'dateFormat');
          g.match(/[DM]/) &&
            ((b = function (a) {
              for (c = 0, d = 0, e = 0; a.length > e; e++)
                a[e].length > c && ((c = a[e].length), (d = e));
              return d;
            }),
            f.setMonth(
              b(this._get(a, g.match(/MM/) ? 'monthNames' : 'monthNamesShort'))
            ),
            f.setDate(
              b(this._get(a, g.match(/DD/) ? 'dayNames' : 'dayNamesShort')) +
                20 -
                f.getDay()
            )),
            a.input.attr('size', this._formatDate(a, f).length);
        }
      },
      _inlineDatepicker: function (b, c) {
        var d = a(b);
        d.hasClass(this.markerClassName) ||
          (d.addClass(this.markerClassName).append(c.dpDiv),
          a.data(b, 'datepicker', c),
          this._setDate(c, this._getDefaultDate(c), !0),
          this._updateDatepicker(c),
          this._updateAlternate(c),
          c.settings.disabled && this._disableDatepicker(b),
          c.dpDiv.css('display', 'block'));
      },
      _dialogDatepicker: function (b, c, d, e, f) {
        var h,
          i,
          j,
          k,
          l,
          m = this._dialogInst;
        return (
          m ||
            ((this.uuid += 1),
            (h = 'dp' + this.uuid),
            (this._dialogInput = a(
              "<input type='text' id='" +
                h +
                "' style='position: absolute; top: -100px; width: 0px;'/>"
            )),
            this._dialogInput.on('keydown', this._doKeyDown),
            a('body').append(this._dialogInput),
            (m = this._dialogInst = this._newInst(this._dialogInput, !1)),
            (m.settings = {}),
            a.data(this._dialogInput[0], 'datepicker', m)),
          g(m.settings, e || {}),
          (c = c && c.constructor === Date ? this._formatDate(m, c) : c),
          this._dialogInput.val(c),
          (this._pos = f ? (f.length ? f : [f.pageX, f.pageY]) : null),
          this._pos ||
            ((i = document.documentElement.clientWidth),
            (j = document.documentElement.clientHeight),
            (k =
              document.documentElement.scrollLeft || document.body.scrollLeft),
            (l = document.documentElement.scrollTop || document.body.scrollTop),
            (this._pos = [i / 2 - 100 + k, j / 2 - 150 + l])),
          this._dialogInput
            .css('left', this._pos[0] + 20 + 'px')
            .css('top', this._pos[1] + 'px'),
          (m.settings.onSelect = d),
          (this._inDialog = !0),
          this.dpDiv.addClass(this._dialogClass),
          this._showDatepicker(this._dialogInput[0]),
          a.blockUI && a.blockUI(this.dpDiv),
          a.data(this._dialogInput[0], 'datepicker', m),
          this
        );
      },
      _destroyDatepicker: function (b) {
        var c,
          d = a(b),
          e = a.data(b, 'datepicker');
        d.hasClass(this.markerClassName) &&
          ((c = b.nodeName.toLowerCase()),
          a.removeData(b, 'datepicker'),
          'input' === c
            ? (e.append.remove(),
              e.trigger.remove(),
              d
                .removeClass(this.markerClassName)
                .off('focus', this._showDatepicker)
                .off('keydown', this._doKeyDown)
                .off('keypress', this._doKeyPress)
                .off('keyup', this._doKeyUp))
            : ('div' === c || 'span' === c) &&
              d.removeClass(this.markerClassName).empty(),
          p === e && (p = null));
      },
      _enableDatepicker: function (b) {
        var c,
          d,
          e = a(b),
          f = a.data(b, 'datepicker');
        e.hasClass(this.markerClassName) &&
          ((c = b.nodeName.toLowerCase()),
          'input' === c
            ? ((b.disabled = !1),
              f.trigger
                .filter('button')
                .each(function () {
                  this.disabled = !1;
                })
                .end()
                .filter('img')
                .css({ opacity: '1.0', cursor: '' }))
            : ('div' === c || 'span' === c) &&
              ((d = e.children('.' + this._inlineClass)),
              d.children().removeClass('ui-state-disabled'),
              d
                .find('select.ui-datepicker-month, select.ui-datepicker-year')
                .prop('disabled', !1)),
          (this._disabledInputs = a.map(this._disabledInputs, function (a) {
            return a === b ? null : a;
          })));
      },
      _disableDatepicker: function (b) {
        var c,
          d,
          e = a(b),
          f = a.data(b, 'datepicker');
        e.hasClass(this.markerClassName) &&
          ((c = b.nodeName.toLowerCase()),
          'input' === c
            ? ((b.disabled = !0),
              f.trigger
                .filter('button')
                .each(function () {
                  this.disabled = !0;
                })
                .end()
                .filter('img')
                .css({ opacity: '0.5', cursor: 'default' }))
            : ('div' === c || 'span' === c) &&
              ((d = e.children('.' + this._inlineClass)),
              d.children().addClass('ui-state-disabled'),
              d
                .find('select.ui-datepicker-month, select.ui-datepicker-year')
                .prop('disabled', !0)),
          (this._disabledInputs = a.map(this._disabledInputs, function (a) {
            return a === b ? null : a;
          })),
          (this._disabledInputs[this._disabledInputs.length] = b));
      },
      _isDisabledDatepicker: function (a) {
        if (!a) return !1;
        for (var b = 0; this._disabledInputs.length > b; b++)
          if (this._disabledInputs[b] === a) return !0;
        return !1;
      },
      _getInst: function (b) {
        try {
          return a.data(b, 'datepicker');
        } catch (a) {
          throw 'Missing instance data for this datepicker';
        }
      },
      _optionDatepicker: function (b, c, d) {
        var e,
          f,
          h,
          i,
          j = this._getInst(b);
        return 2 === arguments.length && 'string' == typeof c
          ? 'defaults' === c
            ? a.extend({}, a.datepicker._defaults)
            : j
              ? 'all' === c
                ? a.extend({}, j.settings)
                : this._get(j, c)
              : null
          : ((e = c || {}),
            'string' == typeof c && ((e = {}), (e[c] = d)),
            void (
              j &&
              (this._curInst === j && this._hideDatepicker(),
              (f = this._getDateDatepicker(b, !0)),
              (h = this._getMinMaxDate(j, 'min')),
              (i = this._getMinMaxDate(j, 'max')),
              g(j.settings, e),
              null !== h &&
                void 0 !== e.dateFormat &&
                void 0 === e.minDate &&
                (j.settings.minDate = this._formatDate(j, h)),
              null !== i &&
                void 0 !== e.dateFormat &&
                void 0 === e.maxDate &&
                (j.settings.maxDate = this._formatDate(j, i)),
              'disabled' in e &&
                (e.disabled
                  ? this._disableDatepicker(b)
                  : this._enableDatepicker(b)),
              this._attachments(a(b), j),
              this._autoSize(j),
              this._setDate(j, f),
              this._updateAlternate(j),
              this._updateDatepicker(j))
            ));
      },
      _changeDatepicker: function (a, b, c) {
        this._optionDatepicker(a, b, c);
      },
      _refreshDatepicker: function (a) {
        var b = this._getInst(a);
        b && this._updateDatepicker(b);
      },
      _setDateDatepicker: function (a, b) {
        var c = this._getInst(a);
        c &&
          (this._setDate(c, b),
          this._updateDatepicker(c),
          this._updateAlternate(c));
      },
      _getDateDatepicker: function (a, b) {
        var c = this._getInst(a);
        return (
          c && !c.inline && this._setDateFromField(c, b),
          c ? this._getDate(c) : null
        );
      },
      _doKeyDown: function (b) {
        var c,
          d,
          e,
          f = a.datepicker._getInst(b.target),
          g = !0,
          h = f.dpDiv.is('.ui-datepicker-rtl');
        if (((f._keyEvent = !0), a.datepicker._datepickerShowing))
          switch (b.keyCode) {
            case 9:
              a.datepicker._hideDatepicker(), (g = !1);
              break;
            case 13:
              return (
                (e = a(
                  'td.' +
                    a.datepicker._dayOverClass +
                    ':not(.' +
                    a.datepicker._currentClass +
                    ')',
                  f.dpDiv
                )),
                e[0] &&
                  a.datepicker._selectDay(
                    b.target,
                    f.selectedMonth,
                    f.selectedYear,
                    e[0]
                  ),
                (c = a.datepicker._get(f, 'onSelect')),
                c
                  ? ((d = a.datepicker._formatDate(f)),
                    c.apply(f.input ? f.input[0] : null, [d, f]))
                  : a.datepicker._hideDatepicker(),
                !1
              );
            case 27:
              a.datepicker._hideDatepicker();
              break;
            case 33:
              a.datepicker._adjustDate(
                b.target,
                b.ctrlKey
                  ? -a.datepicker._get(f, 'stepBigMonths')
                  : -a.datepicker._get(f, 'stepMonths'),
                'M'
              );
              break;
            case 34:
              a.datepicker._adjustDate(
                b.target,
                b.ctrlKey
                  ? +a.datepicker._get(f, 'stepBigMonths')
                  : +a.datepicker._get(f, 'stepMonths'),
                'M'
              );
              break;
            case 35:
              (b.ctrlKey || b.metaKey) && a.datepicker._clearDate(b.target),
                (g = b.ctrlKey || b.metaKey);
              break;
            case 36:
              (b.ctrlKey || b.metaKey) && a.datepicker._gotoToday(b.target),
                (g = b.ctrlKey || b.metaKey);
              break;
            case 37:
              (b.ctrlKey || b.metaKey) &&
                a.datepicker._adjustDate(b.target, h ? 1 : -1, 'D'),
                (g = b.ctrlKey || b.metaKey),
                b.originalEvent.altKey &&
                  a.datepicker._adjustDate(
                    b.target,
                    b.ctrlKey
                      ? -a.datepicker._get(f, 'stepBigMonths')
                      : -a.datepicker._get(f, 'stepMonths'),
                    'M'
                  );
              break;
            case 38:
              (b.ctrlKey || b.metaKey) &&
                a.datepicker._adjustDate(b.target, -7, 'D'),
                (g = b.ctrlKey || b.metaKey);
              break;
            case 39:
              (b.ctrlKey || b.metaKey) &&
                a.datepicker._adjustDate(b.target, h ? -1 : 1, 'D'),
                (g = b.ctrlKey || b.metaKey),
                b.originalEvent.altKey &&
                  a.datepicker._adjustDate(
                    b.target,
                    b.ctrlKey
                      ? +a.datepicker._get(f, 'stepBigMonths')
                      : +a.datepicker._get(f, 'stepMonths'),
                    'M'
                  );
              break;
            case 40:
              (b.ctrlKey || b.metaKey) &&
                a.datepicker._adjustDate(b.target, 7, 'D'),
                (g = b.ctrlKey || b.metaKey);
              break;
            default:
              g = !1;
          }
        else
          36 === b.keyCode && b.ctrlKey
            ? a.datepicker._showDatepicker(this)
            : (g = !1);
        g && (b.preventDefault(), b.stopPropagation());
      },
      _doKeyPress: function (b) {
        var c,
          d,
          e = a.datepicker._getInst(b.target);
        return a.datepicker._get(e, 'constrainInput')
          ? ((c = a.datepicker._possibleChars(
              a.datepicker._get(e, 'dateFormat')
            )),
            (d = String.fromCharCode(
              null == b.charCode ? b.keyCode : b.charCode
            )),
            b.ctrlKey || b.metaKey || ' ' > d || !c || c.indexOf(d) > -1)
          : void 0;
      },
      _doKeyUp: function (b) {
        var c = a.datepicker._getInst(b.target);
        if (c.input.val() !== c.lastVal)
          try {
            a.datepicker.parseDate(
              a.datepicker._get(c, 'dateFormat'),
              c.input ? c.input.val() : null,
              a.datepicker._getFormatConfig(c)
            ) &&
              (a.datepicker._setDateFromField(c),
              a.datepicker._updateAlternate(c),
              a.datepicker._updateDatepicker(c));
          } catch (a) {}
        return !0;
      },
      _showDatepicker: function (b) {
        if (
          ((b = b.target || b),
          'input' !== b.nodeName.toLowerCase() &&
            (b = a('input', b.parentNode)[0]),
          !a.datepicker._isDisabledDatepicker(b) &&
            a.datepicker._lastInput !== b)
        ) {
          var d, e, f, h, i, j, k;
          (d = a.datepicker._getInst(b)),
            a.datepicker._curInst &&
              a.datepicker._curInst !== d &&
              (a.datepicker._curInst.dpDiv.stop(!0, !0),
              d &&
                a.datepicker._datepickerShowing &&
                a.datepicker._hideDatepicker(a.datepicker._curInst.input[0])),
            (e = a.datepicker._get(d, 'beforeShow')),
            !1 !== (f = e ? e.apply(b, [b, d]) : {}) &&
              (g(d.settings, f),
              (d.lastVal = null),
              (a.datepicker._lastInput = b),
              a.datepicker._setDateFromField(d),
              a.datepicker._inDialog && (b.value = ''),
              a.datepicker._pos ||
                ((a.datepicker._pos = a.datepicker._findPos(b)),
                (a.datepicker._pos[1] += b.offsetHeight)),
              (h = !1),
              a(b)
                .parents()
                .each(function () {
                  return !(h |= 'fixed' === a(this).css('position'));
                }),
              (i = { left: a.datepicker._pos[0], top: a.datepicker._pos[1] }),
              (a.datepicker._pos = null),
              d.dpDiv.empty(),
              d.dpDiv.css({
                position: 'absolute',
                display: 'block',
                top: '-1000px'
              }),
              a.datepicker._updateDatepicker(d),
              (i = a.datepicker._checkOffset(d, i, h)),
              d.dpDiv.css({
                position:
                  a.datepicker._inDialog && a.blockUI
                    ? 'static'
                    : h
                      ? 'fixed'
                      : 'absolute',
                display: 'none',
                left: i.left + 'px',
                top: i.top + 'px'
              }),
              d.inline ||
                ((j = a.datepicker._get(d, 'showAnim')),
                (k = a.datepicker._get(d, 'duration')),
                d.dpDiv.css('z-index', c(a(b)) + 1),
                (a.datepicker._datepickerShowing = !0),
                a.effects && a.effects.effect[j]
                  ? d.dpDiv.show(j, a.datepicker._get(d, 'showOptions'), k)
                  : d.dpDiv[j || 'show'](j ? k : null),
                a.datepicker._shouldFocusInput(d) && d.input.trigger('focus'),
                (a.datepicker._curInst = d)));
        }
      },
      _updateDatepicker: function (b) {
        (this.maxRows = 4),
          (p = b),
          b.dpDiv.empty().append(this._generateHTML(b)),
          this._attachHandlers(b);
        var c,
          d = this._getNumberOfMonths(b),
          e = d[1],
          g = b.dpDiv.find('.' + this._dayOverClass + ' a');
        g.length > 0 && f.apply(g.get(0)),
          b.dpDiv
            .removeClass(
              'ui-datepicker-multi-2 ui-datepicker-multi-3 ui-datepicker-multi-4'
            )
            .width(''),
          e > 1 &&
            b.dpDiv
              .addClass('ui-datepicker-multi-' + e)
              .css('width', 17 * e + 'em'),
          b.dpDiv[(1 !== d[0] || 1 !== d[1] ? 'add' : 'remove') + 'Class'](
            'ui-datepicker-multi'
          ),
          b.dpDiv[(this._get(b, 'isRTL') ? 'add' : 'remove') + 'Class'](
            'ui-datepicker-rtl'
          ),
          b === a.datepicker._curInst &&
            a.datepicker._datepickerShowing &&
            a.datepicker._shouldFocusInput(b) &&
            b.input.trigger('focus'),
          b.yearshtml &&
            ((c = b.yearshtml),
            setTimeout(function () {
              c === b.yearshtml &&
                b.yearshtml &&
                b.dpDiv
                  .find('select.ui-datepicker-year:first')
                  .replaceWith(b.yearshtml),
                (c = b.yearshtml = null);
            }, 0));
      },
      _shouldFocusInput: function (a) {
        return (
          a.input &&
          a.input.is(':visible') &&
          !a.input.is(':disabled') &&
          !a.input.is(':focus')
        );
      },
      _checkOffset: function (b, c, d) {
        var e = b.dpDiv.outerWidth(),
          f = b.dpDiv.outerHeight(),
          g = b.input ? b.input.outerWidth() : 0,
          h = b.input ? b.input.outerHeight() : 0,
          i =
            document.documentElement.clientWidth +
            (d ? 0 : a(document).scrollLeft()),
          j =
            document.documentElement.clientHeight +
            (d ? 0 : a(document).scrollTop());
        return (
          (c.left -= this._get(b, 'isRTL') ? e - g : 0),
          (c.left -=
            d && c.left === b.input.offset().left
              ? a(document).scrollLeft()
              : 0),
          (c.top -=
            d && c.top === b.input.offset().top + h
              ? a(document).scrollTop()
              : 0),
          (c.left -= Math.min(
            c.left,
            c.left + e > i && i > e ? Math.abs(c.left + e - i) : 0
          )),
          (c.top -= Math.min(
            c.top,
            c.top + f > j && j > f ? Math.abs(f + h) : 0
          )),
          c
        );
      },
      _findPos: function (b) {
        for (
          var c, d = this._getInst(b), e = this._get(d, 'isRTL');
          b &&
          ('hidden' === b.type || 1 !== b.nodeType || a.expr.filters.hidden(b));

        )
          b = b[e ? 'previousSibling' : 'nextSibling'];
        return (c = a(b).offset()), [c.left, c.top];
      },
      _hideDatepicker: function (b) {
        var c,
          d,
          e,
          f,
          g = this._curInst;
        !g ||
          (b && g !== a.data(b, 'datepicker')) ||
          (this._datepickerShowing &&
            ((c = this._get(g, 'showAnim')),
            (d = this._get(g, 'duration')),
            (e = function () {
              a.datepicker._tidyDialog(g);
            }),
            a.effects && (a.effects.effect[c] || a.effects[c])
              ? g.dpDiv.hide(c, a.datepicker._get(g, 'showOptions'), d, e)
              : g.dpDiv[
                  'slideDown' === c
                    ? 'slideUp'
                    : 'fadeIn' === c
                      ? 'fadeOut'
                      : 'hide'
                ](c ? d : null, e),
            c || e(),
            (this._datepickerShowing = !1),
            (f = this._get(g, 'onClose')),
            f &&
              f.apply(g.input ? g.input[0] : null, [
                g.input ? g.input.val() : '',
                g
              ]),
            (this._lastInput = null),
            this._inDialog &&
              (this._dialogInput.css({
                position: 'absolute',
                left: '0',
                top: '-100px'
              }),
              a.blockUI && (a.unblockUI(), a('body').append(this.dpDiv))),
            (this._inDialog = !1)));
      },
      _tidyDialog: function (a) {
        a.dpDiv.removeClass(this._dialogClass).off('.ui-datepicker-calendar');
      },
      _checkExternalClick: function (b) {
        if (a.datepicker._curInst) {
          var c = a(b.target),
            d = a.datepicker._getInst(c[0]);
          ((c[0].id !== a.datepicker._mainDivId &&
            0 === c.parents('#' + a.datepicker._mainDivId).length &&
            !c.hasClass(a.datepicker.markerClassName) &&
            !c.closest('.' + a.datepicker._triggerClass).length &&
            a.datepicker._datepickerShowing &&
            (!a.datepicker._inDialog || !a.blockUI)) ||
            (c.hasClass(a.datepicker.markerClassName) &&
              a.datepicker._curInst !== d)) &&
            a.datepicker._hideDatepicker();
        }
      },
      _adjustDate: function (b, c, d) {
        var e = a(b),
          f = this._getInst(e[0]);
        this._isDisabledDatepicker(e[0]) ||
          (this._adjustInstDate(
            f,
            c + ('M' === d ? this._get(f, 'showCurrentAtPos') : 0),
            d
          ),
          this._updateDatepicker(f));
      },
      _gotoToday: function (b) {
        var c,
          d = a(b),
          e = this._getInst(d[0]);
        this._get(e, 'gotoCurrent') && e.currentDay
          ? ((e.selectedDay = e.currentDay),
            (e.drawMonth = e.selectedMonth = e.currentMonth),
            (e.drawYear = e.selectedYear = e.currentYear))
          : ((c = new Date()),
            (e.selectedDay = c.getDate()),
            (e.drawMonth = e.selectedMonth = c.getMonth()),
            (e.drawYear = e.selectedYear = c.getFullYear())),
          this._notifyChange(e),
          this._adjustDate(d);
      },
      _selectMonthYear: function (b, c, d) {
        var e = a(b),
          f = this._getInst(e[0]);
        (f['selected' + ('M' === d ? 'Month' : 'Year')] = f[
          'draw' + ('M' === d ? 'Month' : 'Year')
        ] =
          parseInt(c.options[c.selectedIndex].value, 10)),
          this._notifyChange(f),
          this._adjustDate(e);
      },
      _selectDay: function (b, c, d, e) {
        var f,
          g = a(b);
        a(e).hasClass(this._unselectableClass) ||
          this._isDisabledDatepicker(g[0]) ||
          ((f = this._getInst(g[0])),
          (f.selectedDay = f.currentDay = a('a', e).html()),
          (f.selectedMonth = f.currentMonth = c),
          (f.selectedYear = f.currentYear = d),
          this._selectDate(
            b,
            this._formatDate(f, f.currentDay, f.currentMonth, f.currentYear)
          ));
      },
      _clearDate: function (b) {
        var c = a(b);
        this._selectDate(c, '');
      },
      _selectDate: function (b, c) {
        var d,
          e = a(b),
          f = this._getInst(e[0]);
        (c = null != c ? c : this._formatDate(f)),
          f.input && f.input.val(c),
          this._updateAlternate(f),
          (d = this._get(f, 'onSelect')),
          d
            ? d.apply(f.input ? f.input[0] : null, [c, f])
            : f.input && f.input.trigger('change'),
          f.inline
            ? this._updateDatepicker(f)
            : (this._hideDatepicker(),
              (this._lastInput = f.input[0]),
              'object' != typeof f.input[0] && f.input.trigger('focus'),
              (this._lastInput = null));
      },
      _updateAlternate: function (b) {
        var c,
          d,
          e,
          f = this._get(b, 'altField');
        f &&
          ((c = this._get(b, 'altFormat') || this._get(b, 'dateFormat')),
          (d = this._getDate(b)),
          (e = this.formatDate(c, d, this._getFormatConfig(b))),
          a(f).val(e));
      },
      noWeekends: function (a) {
        var b = a.getDay();
        return [b > 0 && 6 > b, ''];
      },
      iso8601Week: function (a) {
        var b,
          c = new Date(a.getTime());
        return (
          c.setDate(c.getDate() + 4 - (c.getDay() || 7)),
          (b = c.getTime()),
          c.setMonth(0),
          c.setDate(1),
          Math.floor(Math.round((b - c) / 864e5) / 7) + 1
        );
      },
      parseDate: function (b, c, d) {
        if (null == b || null == c) throw 'Invalid arguments';
        if ('' === (c = 'object' == typeof c ? '' + c : c + '')) return null;
        var e,
          f,
          g,
          h,
          i = 0,
          j = (d ? d.shortYearCutoff : null) || this._defaults.shortYearCutoff,
          k =
            'string' != typeof j
              ? j
              : (new Date().getFullYear() % 100) + parseInt(j, 10),
          l = (d ? d.dayNamesShort : null) || this._defaults.dayNamesShort,
          m = (d ? d.dayNames : null) || this._defaults.dayNames,
          n = (d ? d.monthNamesShort : null) || this._defaults.monthNamesShort,
          o = (d ? d.monthNames : null) || this._defaults.monthNames,
          p = -1,
          q = -1,
          r = -1,
          s = -1,
          t = !1,
          u = function (a) {
            var c = b.length > e + 1 && b.charAt(e + 1) === a;
            return c && e++, c;
          },
          v = function (a) {
            var b = u(a),
              d =
                '@' === a
                  ? 14
                  : '!' === a
                    ? 20
                    : 'y' === a && b
                      ? 4
                      : 'o' === a
                        ? 3
                        : 2,
              e = 'y' === a ? d : 1,
              f = RegExp('^\\d{' + e + ',' + d + '}'),
              g = c.substring(i).match(f);
            if (!g) throw 'Missing number at position ' + i;
            return (i += g[0].length), parseInt(g[0], 10);
          },
          w = function (b, d, e) {
            var f = -1,
              g = a
                .map(u(b) ? e : d, function (a, b) {
                  return [[b, a]];
                })
                .sort(function (a, b) {
                  return -(a[1].length - b[1].length);
                });
            if (
              (a.each(g, function (a, b) {
                var d = b[1];
                return c.substr(i, d.length).toLowerCase() === d.toLowerCase()
                  ? ((f = b[0]), (i += d.length), !1)
                  : void 0;
              }),
              -1 !== f)
            )
              return f + 1;
            throw 'Unknown name at position ' + i;
          },
          x = function () {
            if (c.charAt(i) !== b.charAt(e))
              throw 'Unexpected literal at position ' + i;
            i++;
          };
        for (e = 0; b.length > e; e++)
          if (t) "'" !== b.charAt(e) || u("'") ? x() : (t = !1);
          else
            switch (b.charAt(e)) {
              case 'd':
                r = v('d');
                break;
              case 'D':
                w('D', l, m);
                break;
              case 'o':
                s = v('o');
                break;
              case 'm':
                q = v('m');
                break;
              case 'M':
                q = w('M', n, o);
                break;
              case 'y':
                p = v('y');
                break;
              case '@':
                (h = new Date(v('@'))),
                  (p = h.getFullYear()),
                  (q = h.getMonth() + 1),
                  (r = h.getDate());
                break;
              case '!':
                (h = new Date((v('!') - this._ticksTo1970) / 1e4)),
                  (p = h.getFullYear()),
                  (q = h.getMonth() + 1),
                  (r = h.getDate());
                break;
              case "'":
                u("'") ? x() : (t = !0);
                break;
              default:
                x();
            }
        if (c.length > i && ((g = c.substr(i)), !/^\s+/.test(g)))
          throw 'Extra/unparsed characters found in date: ' + g;
        if (
          (-1 === p
            ? (p = new Date().getFullYear())
            : 100 > p &&
              (p +=
                new Date().getFullYear() -
                (new Date().getFullYear() % 100) +
                (k >= p ? 0 : -100)),
          s > -1)
        )
          for (q = 1, r = s; !((f = this._getDaysInMonth(p, q - 1)) >= r); )
            q++, (r -= f);
        if (
          ((h = this._daylightSavingAdjust(new Date(p, q - 1, r))),
          h.getFullYear() !== p || h.getMonth() + 1 !== q || h.getDate() !== r)
        )
          throw 'Invalid date';
        return h;
      },
      ATOM: 'yy-mm-dd',
      COOKIE: 'D, dd M yy',
      ISO_8601: 'yy-mm-dd',
      RFC_822: 'D, d M y',
      RFC_850: 'DD, dd-M-y',
      RFC_1036: 'D, d M y',
      RFC_1123: 'D, d M yy',
      RFC_2822: 'D, d M yy',
      RSS: 'D, d M y',
      TICKS: '!',
      TIMESTAMP: '@',
      W3C: 'yy-mm-dd',
      _ticksTo1970:
        864e9 *
        (718685 + Math.floor(492.5) - Math.floor(19.7) + Math.floor(4.925)),
      formatDate: function (a, b, c) {
        if (!b) return '';
        var d,
          e = (c ? c.dayNamesShort : null) || this._defaults.dayNamesShort,
          f = (c ? c.dayNames : null) || this._defaults.dayNames,
          g = (c ? c.monthNamesShort : null) || this._defaults.monthNamesShort,
          h = (c ? c.monthNames : null) || this._defaults.monthNames,
          i = function (b) {
            var c = a.length > d + 1 && a.charAt(d + 1) === b;
            return c && d++, c;
          },
          j = function (a, b, c) {
            var d = '' + b;
            if (i(a)) for (; c > d.length; ) d = '0' + d;
            return d;
          },
          k = function (a, b, c, d) {
            return i(a) ? d[b] : c[b];
          },
          l = '',
          m = !1;
        if (b)
          for (d = 0; a.length > d; d++)
            if (m)
              "'" !== a.charAt(d) || i("'") ? (l += a.charAt(d)) : (m = !1);
            else
              switch (a.charAt(d)) {
                case 'd':
                  l += j('d', b.getDate(), 2);
                  break;
                case 'D':
                  l += k('D', b.getDay(), e, f);
                  break;
                case 'o':
                  l += j(
                    'o',
                    Math.round(
                      (new Date(
                        b.getFullYear(),
                        b.getMonth(),
                        b.getDate()
                      ).getTime() -
                        new Date(b.getFullYear(), 0, 0).getTime()) /
                        864e5
                    ),
                    3
                  );
                  break;
                case 'm':
                  l += j('m', b.getMonth() + 1, 2);
                  break;
                case 'M':
                  l += k('M', b.getMonth(), g, h);
                  break;
                case 'y':
                  l += i('y')
                    ? b.getFullYear()
                    : (10 > b.getFullYear() % 100 ? '0' : '') +
                      (b.getFullYear() % 100);
                  break;
                case '@':
                  l += b.getTime();
                  break;
                case '!':
                  l += 1e4 * b.getTime() + this._ticksTo1970;
                  break;
                case "'":
                  i("'") ? (l += "'") : (m = !0);
                  break;
                default:
                  l += a.charAt(d);
              }
        return l;
      },
      _possibleChars: function (a) {
        var b,
          c = '',
          d = !1,
          e = function (c) {
            var d = a.length > b + 1 && a.charAt(b + 1) === c;
            return d && b++, d;
          };
        for (b = 0; a.length > b; b++)
          if (d) "'" !== a.charAt(b) || e("'") ? (c += a.charAt(b)) : (d = !1);
          else
            switch (a.charAt(b)) {
              case 'd':
              case 'm':
              case 'y':
              case '@':
                c += '0123456789';
                break;
              case 'D':
              case 'M':
                return null;
              case "'":
                e("'") ? (c += "'") : (d = !0);
                break;
              default:
                c += a.charAt(b);
            }
        return c;
      },
      _get: function (a, b) {
        return void 0 !== a.settings[b] ? a.settings[b] : this._defaults[b];
      },
      _setDateFromField: function (a, b) {
        if (a.input.val() !== a.lastVal) {
          var c = this._get(a, 'dateFormat'),
            d = (a.lastVal = a.input ? a.input.val() : null),
            e = this._getDefaultDate(a),
            f = e,
            g = this._getFormatConfig(a);
          try {
            f = this.parseDate(c, d, g) || e;
          } catch (a) {
            d = b ? '' : d;
          }
          (a.selectedDay = f.getDate()),
            (a.drawMonth = a.selectedMonth = f.getMonth()),
            (a.drawYear = a.selectedYear = f.getFullYear()),
            (a.currentDay = d ? f.getDate() : 0),
            (a.currentMonth = d ? f.getMonth() : 0),
            (a.currentYear = d ? f.getFullYear() : 0),
            this._adjustInstDate(a);
        }
      },
      _getDefaultDate: function (a) {
        return this._restrictMinMax(
          a,
          this._determineDate(a, this._get(a, 'defaultDate'), new Date())
        );
      },
      _determineDate: function (b, c, d) {
        var e = function (a) {
            var b = new Date();
            return b.setDate(b.getDate() + a), b;
          },
          f = function (c) {
            try {
              return a.datepicker.parseDate(
                a.datepicker._get(b, 'dateFormat'),
                c,
                a.datepicker._getFormatConfig(b)
              );
            } catch (a) {}
            for (
              var d =
                  (c.toLowerCase().match(/^c/)
                    ? a.datepicker._getDate(b)
                    : null) || new Date(),
                e = d.getFullYear(),
                f = d.getMonth(),
                g = d.getDate(),
                h = /([+\-]?[0-9]+)\s*(d|D|w|W|m|M|y|Y)?/g,
                i = h.exec(c);
              i;

            ) {
              switch (i[2] || 'd') {
                case 'd':
                case 'D':
                  g += parseInt(i[1], 10);
                  break;
                case 'w':
                case 'W':
                  g += 7 * parseInt(i[1], 10);
                  break;
                case 'm':
                case 'M':
                  (f += parseInt(i[1], 10)),
                    (g = Math.min(g, a.datepicker._getDaysInMonth(e, f)));
                  break;
                case 'y':
                case 'Y':
                  (e += parseInt(i[1], 10)),
                    (g = Math.min(g, a.datepicker._getDaysInMonth(e, f)));
              }
              i = h.exec(c);
            }
            return new Date(e, f, g);
          },
          g =
            null == c || '' === c
              ? d
              : 'string' == typeof c
                ? f(c)
                : 'number' == typeof c
                  ? isNaN(c)
                    ? d
                    : e(c)
                  : new Date(c.getTime());
        return (
          (g = g && 'Invalid Date' == '' + g ? d : g),
          g &&
            (g.setHours(0),
            g.setMinutes(0),
            g.setSeconds(0),
            g.setMilliseconds(0)),
          this._daylightSavingAdjust(g)
        );
      },
      _daylightSavingAdjust: function (a) {
        return a
          ? (a.setHours(a.getHours() > 12 ? a.getHours() + 2 : 0), a)
          : null;
      },
      _setDate: function (a, b, c) {
        var d = !b,
          e = a.selectedMonth,
          f = a.selectedYear,
          g = this._restrictMinMax(a, this._determineDate(a, b, new Date()));
        (a.selectedDay = a.currentDay = g.getDate()),
          (a.drawMonth = a.selectedMonth = a.currentMonth = g.getMonth()),
          (a.drawYear = a.selectedYear = a.currentYear = g.getFullYear()),
          (e === a.selectedMonth && f === a.selectedYear) ||
            c ||
            this._notifyChange(a),
          this._adjustInstDate(a),
          a.input && a.input.val(d ? '' : this._formatDate(a));
      },
      _getDate: function (a) {
        return !a.currentYear || (a.input && '' === a.input.val())
          ? null
          : this._daylightSavingAdjust(
              new Date(a.currentYear, a.currentMonth, a.currentDay)
            );
      },
      _attachHandlers: function (b) {
        var c = this._get(b, 'stepMonths'),
          d = '#' + b.id.replace(/\\\\/g, '\\');
        b.dpDiv.find('[data-handler]').map(function () {
          var b = {
            prev: function () {
              a.datepicker._adjustDate(d, -c, 'M');
            },
            next: function () {
              a.datepicker._adjustDate(d, +c, 'M');
            },
            hide: function () {
              a.datepicker._hideDatepicker();
            },
            today: function () {
              a.datepicker._gotoToday(d);
            },
            selectDay: function () {
              return (
                a.datepicker._selectDay(
                  d,
                  +this.getAttribute('data-month'),
                  +this.getAttribute('data-year'),
                  this
                ),
                !1
              );
            },
            selectMonth: function () {
              return a.datepicker._selectMonthYear(d, this, 'M'), !1;
            },
            selectYear: function () {
              return a.datepicker._selectMonthYear(d, this, 'Y'), !1;
            }
          };
          a(this).on(
            this.getAttribute('data-event'),
            b[this.getAttribute('data-handler')]
          );
        });
      },
      _generateHTML: function (a) {
        var b,
          c,
          d,
          e,
          f,
          g,
          h,
          i,
          j,
          k,
          l,
          m,
          n,
          o,
          p,
          q,
          r,
          s,
          t,
          u,
          v,
          w,
          x,
          y,
          z,
          A,
          B,
          C,
          D,
          E,
          F,
          G,
          H,
          I,
          J,
          K,
          L,
          M,
          N,
          O = new Date(),
          P = this._daylightSavingAdjust(
            new Date(O.getFullYear(), O.getMonth(), O.getDate())
          ),
          Q = this._get(a, 'isRTL'),
          R = this._get(a, 'showButtonPanel'),
          S = this._get(a, 'hideIfNoPrevNext'),
          T = this._get(a, 'navigationAsDateFormat'),
          U = this._getNumberOfMonths(a),
          V = this._get(a, 'showCurrentAtPos'),
          W = this._get(a, 'stepMonths'),
          X = 1 !== U[0] || 1 !== U[1],
          Y = this._daylightSavingAdjust(
            a.currentDay
              ? new Date(a.currentYear, a.currentMonth, a.currentDay)
              : new Date(9999, 9, 9)
          ),
          Z = this._getMinMaxDate(a, 'min'),
          $ = this._getMinMaxDate(a, 'max'),
          _ = a.drawMonth - V,
          aa = a.drawYear;
        if ((0 > _ && ((_ += 12), aa--), $))
          for (
            b = this._daylightSavingAdjust(
              new Date(
                $.getFullYear(),
                $.getMonth() - U[0] * U[1] + 1,
                $.getDate()
              )
            ),
              b = Z && Z > b ? Z : b;
            this._daylightSavingAdjust(new Date(aa, _, 1)) > b;

          )
            0 > --_ && ((_ = 11), aa--);
        for (
          a.drawMonth = _,
            a.drawYear = aa,
            c = this._get(a, 'prevText'),
            c = T
              ? this.formatDate(
                  c,
                  this._daylightSavingAdjust(new Date(aa, _ - W, 1)),
                  this._getFormatConfig(a)
                )
              : c,
            d = this._canAdjustMonth(a, -1, aa, _)
              ? "<a class='ui-datepicker-prev ui-corner-all' data-handler='prev' data-event='click' title='" +
                c +
                "'><span class='ui-icon ui-icon-circle-triangle-" +
                (Q ? 'e' : 'w') +
                "'>" +
                c +
                '</span></a>'
              : S
                ? ''
                : "<a class='ui-datepicker-prev ui-corner-all ui-state-disabled' title='" +
                  c +
                  "'><span class='ui-icon ui-icon-circle-triangle-" +
                  (Q ? 'e' : 'w') +
                  "'>" +
                  c +
                  '</span></a>',
            e = this._get(a, 'nextText'),
            e = T
              ? this.formatDate(
                  e,
                  this._daylightSavingAdjust(new Date(aa, _ + W, 1)),
                  this._getFormatConfig(a)
                )
              : e,
            f = this._canAdjustMonth(a, 1, aa, _)
              ? "<a class='ui-datepicker-next ui-corner-all' data-handler='next' data-event='click' title='" +
                e +
                "'><span class='ui-icon ui-icon-circle-triangle-" +
                (Q ? 'w' : 'e') +
                "'>" +
                e +
                '</span></a>'
              : S
                ? ''
                : "<a class='ui-datepicker-next ui-corner-all ui-state-disabled' title='" +
                  e +
                  "'><span class='ui-icon ui-icon-circle-triangle-" +
                  (Q ? 'w' : 'e') +
                  "'>" +
                  e +
                  '</span></a>',
            g = this._get(a, 'currentText'),
            h = this._get(a, 'gotoCurrent') && a.currentDay ? Y : P,
            g = T ? this.formatDate(g, h, this._getFormatConfig(a)) : g,
            i = a.inline
              ? ''
              : "<button type='button' class='ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all' data-handler='hide' data-event='click'>" +
                this._get(a, 'closeText') +
                '</button>',
            j = R
              ? "<div class='ui-datepicker-buttonpane ui-widget-content'>" +
                (Q ? i : '') +
                (this._isInRange(a, h)
                  ? "<button type='button' class='ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all' data-handler='today' data-event='click'>" +
                    g +
                    '</button>'
                  : '') +
                (Q ? '' : i) +
                '</div>'
              : '',
            k = parseInt(this._get(a, 'firstDay'), 10),
            k = isNaN(k) ? 0 : k,
            l = this._get(a, 'showWeek'),
            m = this._get(a, 'dayNames'),
            n = this._get(a, 'dayNamesMin'),
            o = this._get(a, 'monthNames'),
            p = this._get(a, 'monthNamesShort'),
            q = this._get(a, 'beforeShowDay'),
            r = this._get(a, 'showOtherMonths'),
            s = this._get(a, 'selectOtherMonths'),
            t = this._getDefaultDate(a),
            u = '',
            w = 0;
          U[0] > w;
          w++
        ) {
          for (x = '', this.maxRows = 4, y = 0; U[1] > y; y++) {
            if (
              ((z = this._daylightSavingAdjust(new Date(aa, _, a.selectedDay))),
              (A = ' ui-corner-all'),
              (B = ''),
              X)
            ) {
              if (((B += "<div class='ui-datepicker-group"), U[1] > 1))
                switch (y) {
                  case 0:
                    (B += ' ui-datepicker-group-first'),
                      (A = ' ui-corner-' + (Q ? 'right' : 'left'));
                    break;
                  case U[1] - 1:
                    (B += ' ui-datepicker-group-last'),
                      (A = ' ui-corner-' + (Q ? 'left' : 'right'));
                    break;
                  default:
                    (B += ' ui-datepicker-group-middle'), (A = '');
                }
              B += "'>";
            }
            for (
              B +=
                "<div class='ui-datepicker-header ui-widget-header ui-helper-clearfix" +
                A +
                "'>" +
                (/all|left/.test(A) && 0 === w ? (Q ? f : d) : '') +
                (/all|right/.test(A) && 0 === w ? (Q ? d : f) : '') +
                this._generateMonthYearHeader(
                  a,
                  _,
                  aa,
                  Z,
                  $,
                  w > 0 || y > 0,
                  o,
                  p
                ) +
                "</div><table class='ui-datepicker-calendar'><thead><tr>",
                C = l
                  ? "<th class='ui-datepicker-week-col'>" +
                    this._get(a, 'weekHeader') +
                    '</th>'
                  : '',
                v = 0;
              7 > v;
              v++
            )
              (D = (v + k) % 7),
                (C +=
                  "<th scope='col'" +
                  ((v + k + 6) % 7 >= 5
                    ? " class='ui-datepicker-week-end'"
                    : '') +
                  "><span title='" +
                  m[D] +
                  "'>" +
                  n[D] +
                  '</span></th>');
            for (
              B += C + '</tr></thead><tbody>',
                E = this._getDaysInMonth(aa, _),
                aa === a.selectedYear &&
                  _ === a.selectedMonth &&
                  (a.selectedDay = Math.min(a.selectedDay, E)),
                F = (this._getFirstDayOfMonth(aa, _) - k + 7) % 7,
                G = Math.ceil((F + E) / 7),
                H = X && this.maxRows > G ? this.maxRows : G,
                this.maxRows = H,
                I = this._daylightSavingAdjust(new Date(aa, _, 1 - F)),
                J = 0;
              H > J;
              J++
            ) {
              for (
                B += '<tr>',
                  K = l
                    ? "<td class='ui-datepicker-week-col'>" +
                      this._get(a, 'calculateWeek')(I) +
                      '</td>'
                    : '',
                  v = 0;
                7 > v;
                v++
              )
                (L = q ? q.apply(a.input ? a.input[0] : null, [I]) : [!0, '']),
                  (M = I.getMonth() !== _),
                  (N = (M && !s) || !L[0] || (Z && Z > I) || ($ && I > $)),
                  (K +=
                    "<td class='" +
                    ((v + k + 6) % 7 >= 5 ? ' ui-datepicker-week-end' : '') +
                    (M ? ' ui-datepicker-other-month' : '') +
                    ((I.getTime() === z.getTime() &&
                      _ === a.selectedMonth &&
                      a._keyEvent) ||
                    (t.getTime() === I.getTime() && t.getTime() === z.getTime())
                      ? ' ' + this._dayOverClass
                      : '') +
                    (N
                      ? ' ' + this._unselectableClass + ' ui-state-disabled'
                      : '') +
                    (M && !r
                      ? ''
                      : ' ' +
                        L[1] +
                        (I.getTime() === Y.getTime()
                          ? ' ' + this._currentClass
                          : '') +
                        (I.getTime() === P.getTime()
                          ? ' ui-datepicker-today'
                          : '')) +
                    "'" +
                    ((M && !r) || !L[2]
                      ? ''
                      : " title='" + L[2].replace(/'/g, '&#39;') + "'") +
                    (N
                      ? ''
                      : " data-handler='selectDay' data-event='click' data-month='" +
                        I.getMonth() +
                        "' data-year='" +
                        I.getFullYear() +
                        "'") +
                    '>' +
                    (M && !r
                      ? '&#xa0;'
                      : N
                        ? "<span class='ui-state-default'>" +
                          I.getDate() +
                          '</span>'
                        : "<a class='ui-state-default" +
                          (I.getTime() === P.getTime()
                            ? ' ui-state-highlight'
                            : '') +
                          (I.getTime() === Y.getTime()
                            ? ' ui-state-active'
                            : '') +
                          (M ? ' ui-priority-secondary' : '') +
                          "' href='#'>" +
                          I.getDate() +
                          '</a>') +
                    '</td>'),
                  I.setDate(I.getDate() + 1),
                  (I = this._daylightSavingAdjust(I));
              B += K + '</tr>';
            }
            _++,
              _ > 11 && ((_ = 0), aa++),
              (B +=
                '</tbody></table>' +
                (X
                  ? '</div>' +
                    (U[0] > 0 && y === U[1] - 1
                      ? "<div class='ui-datepicker-row-break'></div>"
                      : '')
                  : '')),
              (x += B);
          }
          u += x;
        }
        return (u += j), (a._keyEvent = !1), u;
      },
      _generateMonthYearHeader: function (a, b, c, d, e, f, g, h) {
        var i,
          j,
          k,
          l,
          m,
          n,
          o,
          p,
          q = this._get(a, 'changeMonth'),
          r = this._get(a, 'changeYear'),
          s = this._get(a, 'showMonthAfterYear'),
          t = "<div class='ui-datepicker-title'>",
          u = '';
        if (f || !q)
          u += "<span class='ui-datepicker-month'>" + g[b] + '</span>';
        else {
          for (
            i = d && d.getFullYear() === c,
              j = e && e.getFullYear() === c,
              u +=
                "<select class='ui-datepicker-month' data-handler='selectMonth' data-event='change'>",
              k = 0;
            12 > k;
            k++
          )
            (!i || k >= d.getMonth()) &&
              (!j || e.getMonth() >= k) &&
              (u +=
                "<option value='" +
                k +
                "'" +
                (k === b ? " selected='selected'" : '') +
                '>' +
                h[k] +
                '</option>');
          u += '</select>';
        }
        if ((s || (t += u + (!f && q && r ? '' : '&#xa0;')), !a.yearshtml))
          if (((a.yearshtml = ''), f || !r))
            t += "<span class='ui-datepicker-year'>" + c + '</span>';
          else {
            for (
              l = this._get(a, 'yearRange').split(':'),
                m = new Date().getFullYear(),
                n = function (a) {
                  var b = a.match(/c[+\-].*/)
                    ? c + parseInt(a.substring(1), 10)
                    : a.match(/[+\-].*/)
                      ? m + parseInt(a, 10)
                      : parseInt(a, 10);
                  return isNaN(b) ? m : b;
                },
                o = n(l[0]),
                p = Math.max(o, n(l[1] || '')),
                o = d ? Math.max(o, d.getFullYear()) : o,
                p = e ? Math.min(p, e.getFullYear()) : p,
                a.yearshtml +=
                  "<select class='ui-datepicker-year' data-handler='selectYear' data-event='change'>";
              p >= o;
              o++
            )
              a.yearshtml +=
                "<option value='" +
                o +
                "'" +
                (o === c ? " selected='selected'" : '') +
                '>' +
                o +
                '</option>';
            (a.yearshtml += '</select>'),
              (t += a.yearshtml),
              (a.yearshtml = null);
          }
        return (
          (t += this._get(a, 'yearSuffix')),
          s && (t += (!f && q && r ? '' : '&#xa0;') + u),
          (t += '</div>')
        );
      },
      _adjustInstDate: function (a, b, c) {
        var d = a.selectedYear + ('Y' === c ? b : 0),
          e = a.selectedMonth + ('M' === c ? b : 0),
          f =
            Math.min(a.selectedDay, this._getDaysInMonth(d, e)) +
            ('D' === c ? b : 0),
          g = this._restrictMinMax(
            a,
            this._daylightSavingAdjust(new Date(d, e, f))
          );
        (a.selectedDay = g.getDate()),
          (a.drawMonth = a.selectedMonth = g.getMonth()),
          (a.drawYear = a.selectedYear = g.getFullYear()),
          ('M' === c || 'Y' === c) && this._notifyChange(a);
      },
      _restrictMinMax: function (a, b) {
        var c = this._getMinMaxDate(a, 'min'),
          d = this._getMinMaxDate(a, 'max'),
          e = c && c > b ? c : b;
        return d && e > d ? d : e;
      },
      _notifyChange: function (a) {
        var b = this._get(a, 'onChangeMonthYear');
        b &&
          b.apply(a.input ? a.input[0] : null, [
            a.selectedYear,
            a.selectedMonth + 1,
            a
          ]);
      },
      _getNumberOfMonths: function (a) {
        var b = this._get(a, 'numberOfMonths');
        return null == b ? [1, 1] : 'number' == typeof b ? [1, b] : b;
      },
      _getMinMaxDate: function (a, b) {
        return this._determineDate(a, this._get(a, b + 'Date'), null);
      },
      _getDaysInMonth: function (a, b) {
        return 32 - this._daylightSavingAdjust(new Date(a, b, 32)).getDate();
      },
      _getFirstDayOfMonth: function (a, b) {
        return new Date(a, b, 1).getDay();
      },
      _canAdjustMonth: function (a, b, c, d) {
        var e = this._getNumberOfMonths(a),
          f = this._daylightSavingAdjust(
            new Date(c, d + (0 > b ? b : e[0] * e[1]), 1)
          );
        return (
          0 > b &&
            f.setDate(this._getDaysInMonth(f.getFullYear(), f.getMonth())),
          this._isInRange(a, f)
        );
      },
      _isInRange: function (a, b) {
        var c,
          d,
          e = this._getMinMaxDate(a, 'min'),
          f = this._getMinMaxDate(a, 'max'),
          g = null,
          h = null,
          i = this._get(a, 'yearRange');
        return (
          i &&
            ((c = i.split(':')),
            (d = new Date().getFullYear()),
            (g = parseInt(c[0], 10)),
            (h = parseInt(c[1], 10)),
            c[0].match(/[+\-].*/) && (g += d),
            c[1].match(/[+\-].*/) && (h += d)),
          (!e || b.getTime() >= e.getTime()) &&
            (!f || b.getTime() <= f.getTime()) &&
            (!g || b.getFullYear() >= g) &&
            (!h || h >= b.getFullYear())
        );
      },
      _getFormatConfig: function (a) {
        var b = this._get(a, 'shortYearCutoff');
        return (
          (b =
            'string' != typeof b
              ? b
              : (new Date().getFullYear() % 100) + parseInt(b, 10)),
          {
            shortYearCutoff: b,
            dayNamesShort: this._get(a, 'dayNamesShort'),
            dayNames: this._get(a, 'dayNames'),
            monthNamesShort: this._get(a, 'monthNamesShort'),
            monthNames: this._get(a, 'monthNames')
          }
        );
      },
      _formatDate: function (a, b, c, d) {
        b ||
          ((a.currentDay = a.selectedDay),
          (a.currentMonth = a.selectedMonth),
          (a.currentYear = a.selectedYear));
        var e = b
          ? 'object' == typeof b
            ? b
            : this._daylightSavingAdjust(new Date(d, c, b))
          : this._daylightSavingAdjust(
              new Date(a.currentYear, a.currentMonth, a.currentDay)
            );
        return this.formatDate(
          this._get(a, 'dateFormat'),
          e,
          this._getFormatConfig(a)
        );
      }
    }),
      (a.fn.datepicker = function (b) {
        if (!this.length) return this;
        a.datepicker.initialized ||
          (a(document).on('mousedown', a.datepicker._checkExternalClick),
          (a.datepicker.initialized = !0)),
          0 === a('#' + a.datepicker._mainDivId).length &&
            a('body').append(a.datepicker.dpDiv);
        var c = Array.prototype.slice.call(arguments, 1);
        return 'string' != typeof b ||
          ('isDisabled' !== b && 'getDate' !== b && 'widget' !== b)
          ? 'option' === b &&
            2 === arguments.length &&
            'string' == typeof arguments[1]
            ? a.datepicker['_' + b + 'Datepicker'].apply(
                a.datepicker,
                [this[0]].concat(c)
              )
            : this.each(function () {
                'string' == typeof b
                  ? a.datepicker['_' + b + 'Datepicker'].apply(
                      a.datepicker,
                      [this].concat(c)
                    )
                  : a.datepicker._attachDatepicker(this, b);
              })
          : a.datepicker['_' + b + 'Datepicker'].apply(
              a.datepicker,
              [this[0]].concat(c)
            );
      }),
      (a.datepicker = new d()),
      (a.datepicker.initialized = !1),
      (a.datepicker.uuid = new Date().getTime()),
      (a.datepicker.version = '1.12.1'),
      a.datepicker,
      (a.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase()));
    var q = !1;
    a(document).on('mouseup', function () {
      q = !1;
    }),
      a.widget('ui.mouse', {
        version: '1.12.1',
        options: {
          cancel: 'input, textarea, button, select, option',
          distance: 1,
          delay: 0
        },
        _mouseInit: function () {
          var b = this;
          this.element
            .on('mousedown.' + this.widgetName, function (a) {
              return b._mouseDown(a);
            })
            .on('click.' + this.widgetName, function (c) {
              return !0 ===
                a.data(c.target, b.widgetName + '.preventClickEvent')
                ? (a.removeData(c.target, b.widgetName + '.preventClickEvent'),
                  c.stopImmediatePropagation(),
                  !1)
                : void 0;
            }),
            (this.started = !1);
        },
        _mouseDestroy: function () {
          this.element.off('.' + this.widgetName),
            this._mouseMoveDelegate &&
              this.document
                .off('mousemove.' + this.widgetName, this._mouseMoveDelegate)
                .off('mouseup.' + this.widgetName, this._mouseUpDelegate);
        },
        _mouseDown: function (b) {
          if (!q) {
            (this._mouseMoved = !1),
              this._mouseStarted && this._mouseUp(b),
              (this._mouseDownEvent = b);
            var c = this,
              d = 1 === b.which,
              e =
                !(
                  'string' != typeof this.options.cancel || !b.target.nodeName
                ) && a(b.target).closest(this.options.cancel).length;
            return (
              !(d && !e && this._mouseCapture(b)) ||
              ((this.mouseDelayMet = !this.options.delay),
              this.mouseDelayMet ||
                (this._mouseDelayTimer = setTimeout(function () {
                  c.mouseDelayMet = !0;
                }, this.options.delay)),
              this._mouseDistanceMet(b) &&
              this._mouseDelayMet(b) &&
              ((this._mouseStarted = !1 !== this._mouseStart(b)),
              !this._mouseStarted)
                ? (b.preventDefault(), !0)
                : (!0 ===
                    a.data(b.target, this.widgetName + '.preventClickEvent') &&
                    a.removeData(
                      b.target,
                      this.widgetName + '.preventClickEvent'
                    ),
                  (this._mouseMoveDelegate = function (a) {
                    return c._mouseMove(a);
                  }),
                  (this._mouseUpDelegate = function (a) {
                    return c._mouseUp(a);
                  }),
                  this.document
                    .on('mousemove.' + this.widgetName, this._mouseMoveDelegate)
                    .on('mouseup.' + this.widgetName, this._mouseUpDelegate),
                  b.preventDefault(),
                  (q = !0),
                  !0))
            );
          }
        },
        _mouseMove: function (b) {
          if (this._mouseMoved) {
            if (
              a.ui.ie &&
              (!document.documentMode || 9 > document.documentMode) &&
              !b.button
            )
              return this._mouseUp(b);
            if (!b.which)
              if (
                b.originalEvent.altKey ||
                b.originalEvent.ctrlKey ||
                b.originalEvent.metaKey ||
                b.originalEvent.shiftKey
              )
                this.ignoreMissingWhich = !0;
              else if (!this.ignoreMissingWhich) return this._mouseUp(b);
          }
          return (
            (b.which || b.button) && (this._mouseMoved = !0),
            this._mouseStarted
              ? (this._mouseDrag(b), b.preventDefault())
              : (this._mouseDistanceMet(b) &&
                  this._mouseDelayMet(b) &&
                  ((this._mouseStarted =
                    !1 !== this._mouseStart(this._mouseDownEvent, b)),
                  this._mouseStarted ? this._mouseDrag(b) : this._mouseUp(b)),
                !this._mouseStarted)
          );
        },
        _mouseUp: function (b) {
          this.document
            .off('mousemove.' + this.widgetName, this._mouseMoveDelegate)
            .off('mouseup.' + this.widgetName, this._mouseUpDelegate),
            this._mouseStarted &&
              ((this._mouseStarted = !1),
              b.target === this._mouseDownEvent.target &&
                a.data(b.target, this.widgetName + '.preventClickEvent', !0),
              this._mouseStop(b)),
            this._mouseDelayTimer &&
              (clearTimeout(this._mouseDelayTimer),
              delete this._mouseDelayTimer),
            (this.ignoreMissingWhich = !1),
            (q = !1),
            b.preventDefault();
        },
        _mouseDistanceMet: function (a) {
          return (
            Math.max(
              Math.abs(this._mouseDownEvent.pageX - a.pageX),
              Math.abs(this._mouseDownEvent.pageY - a.pageY)
            ) >= this.options.distance
          );
        },
        _mouseDelayMet: function () {
          return this.mouseDelayMet;
        },
        _mouseStart: function () {},
        _mouseDrag: function () {},
        _mouseStop: function () {},
        _mouseCapture: function () {
          return !0;
        }
      }),
      (a.ui.plugin = {
        add: function (b, c, d) {
          var e,
            f = a.ui[b].prototype;
          for (e in d)
            (f.plugins[e] = f.plugins[e] || []), f.plugins[e].push([c, d[e]]);
        },
        call: function (a, b, c, d) {
          var e,
            f = a.plugins[b];
          if (
            f &&
            (d ||
              (a.element[0].parentNode &&
                11 !== a.element[0].parentNode.nodeType))
          )
            for (e = 0; f.length > e; e++)
              a.options[f[e][0]] && f[e][1].apply(a.element, c);
        }
      }),
      (a.ui.safeBlur = function (b) {
        b && 'body' !== b.nodeName.toLowerCase() && a(b).trigger('blur');
      }),
      a.widget('ui.draggable', a.ui.mouse, {
        version: '1.12.1',
        widgetEventPrefix: 'drag',
        options: {
          addClasses: !0,
          appendTo: 'parent',
          axis: !1,
          connectToSortable: !1,
          containment: !1,
          cursor: 'auto',
          cursorAt: !1,
          grid: !1,
          handle: !1,
          helper: 'original',
          iframeFix: !1,
          opacity: !1,
          refreshPositions: !1,
          revert: !1,
          revertDuration: 500,
          scope: 'default',
          scroll: !0,
          scrollSensitivity: 20,
          scrollSpeed: 20,
          snap: !1,
          snapMode: 'both',
          snapTolerance: 20,
          stack: !1,
          zIndex: !1,
          drag: null,
          start: null,
          stop: null
        },
        _create: function () {
          'original' === this.options.helper && this._setPositionRelative(),
            this.options.addClasses && this._addClass('ui-draggable'),
            this._setHandleClassName(),
            this._mouseInit();
        },
        _setOption: function (a, b) {
          this._super(a, b),
            'handle' === a &&
              (this._removeHandleClassName(), this._setHandleClassName());
        },
        _destroy: function () {
          return (this.helper || this.element).is('.ui-draggable-dragging')
            ? void (this.destroyOnClear = !0)
            : (this._removeHandleClassName(), void this._mouseDestroy());
        },
        _mouseCapture: function (b) {
          var c = this.options;
          return (
            !(
              this.helper ||
              c.disabled ||
              a(b.target).closest('.ui-resizable-handle').length > 0
            ) &&
            ((this.handle = this._getHandle(b)),
            !!this.handle &&
              (this._blurActiveElement(b),
              this._blockFrames(!0 === c.iframeFix ? 'iframe' : c.iframeFix),
              !0))
          );
        },
        _blockFrames: function (b) {
          this.iframeBlocks = this.document.find(b).map(function () {
            var b = a(this);
            return a('<div>')
              .css('position', 'absolute')
              .appendTo(b.parent())
              .outerWidth(b.outerWidth())
              .outerHeight(b.outerHeight())
              .offset(b.offset())[0];
          });
        },
        _unblockFrames: function () {
          this.iframeBlocks &&
            (this.iframeBlocks.remove(), delete this.iframeBlocks);
        },
        _blurActiveElement: function (b) {
          var c = a.ui.safeActiveElement(this.document[0]);
          a(b.target).closest(c).length || a.ui.safeBlur(c);
        },
        _mouseStart: function (b) {
          var c = this.options;
          return (
            (this.helper = this._createHelper(b)),
            this._addClass(this.helper, 'ui-draggable-dragging'),
            this._cacheHelperProportions(),
            a.ui.ddmanager && (a.ui.ddmanager.current = this),
            this._cacheMargins(),
            (this.cssPosition = this.helper.css('position')),
            (this.scrollParent = this.helper.scrollParent(!0)),
            (this.offsetParent = this.helper.offsetParent()),
            (this.hasFixedAncestor =
              this.helper.parents().filter(function () {
                return 'fixed' === a(this).css('position');
              }).length > 0),
            (this.positionAbs = this.element.offset()),
            this._refreshOffsets(b),
            (this.originalPosition = this.position =
              this._generatePosition(b, !1)),
            (this.originalPageX = b.pageX),
            (this.originalPageY = b.pageY),
            c.cursorAt && this._adjustOffsetFromHelper(c.cursorAt),
            this._setContainment(),
            !1 === this._trigger('start', b)
              ? (this._clear(), !1)
              : (this._cacheHelperProportions(),
                a.ui.ddmanager &&
                  !c.dropBehaviour &&
                  a.ui.ddmanager.prepareOffsets(this, b),
                this._mouseDrag(b, !0),
                a.ui.ddmanager && a.ui.ddmanager.dragStart(this, b),
                !0)
          );
        },
        _refreshOffsets: function (a) {
          (this.offset = {
            top: this.positionAbs.top - this.margins.top,
            left: this.positionAbs.left - this.margins.left,
            scroll: !1,
            parent: this._getParentOffset(),
            relative: this._getRelativeOffset()
          }),
            (this.offset.click = {
              left: a.pageX - this.offset.left,
              top: a.pageY - this.offset.top
            });
        },
        _mouseDrag: function (b, c) {
          if (
            (this.hasFixedAncestor &&
              (this.offset.parent = this._getParentOffset()),
            (this.position = this._generatePosition(b, !0)),
            (this.positionAbs = this._convertPositionTo('absolute')),
            !c)
          ) {
            var d = this._uiHash();
            if (!1 === this._trigger('drag', b, d))
              return this._mouseUp(new a.Event('mouseup', b)), !1;
            this.position = d.position;
          }
          return (
            (this.helper[0].style.left = this.position.left + 'px'),
            (this.helper[0].style.top = this.position.top + 'px'),
            a.ui.ddmanager && a.ui.ddmanager.drag(this, b),
            !1
          );
        },
        _mouseStop: function (b) {
          var c = this,
            d = !1;
          return (
            a.ui.ddmanager &&
              !this.options.dropBehaviour &&
              (d = a.ui.ddmanager.drop(this, b)),
            this.dropped && ((d = this.dropped), (this.dropped = !1)),
            ('invalid' === this.options.revert && !d) ||
            ('valid' === this.options.revert && d) ||
            !0 === this.options.revert ||
            (a.isFunction(this.options.revert) &&
              this.options.revert.call(this.element, d))
              ? a(this.helper).animate(
                  this.originalPosition,
                  parseInt(this.options.revertDuration, 10),
                  function () {
                    !1 !== c._trigger('stop', b) && c._clear();
                  }
                )
              : !1 !== this._trigger('stop', b) && this._clear(),
            !1
          );
        },
        _mouseUp: function (b) {
          return (
            this._unblockFrames(),
            a.ui.ddmanager && a.ui.ddmanager.dragStop(this, b),
            this.handleElement.is(b.target) && this.element.trigger('focus'),
            a.ui.mouse.prototype._mouseUp.call(this, b)
          );
        },
        cancel: function () {
          return (
            this.helper.is('.ui-draggable-dragging')
              ? this._mouseUp(
                  new a.Event('mouseup', { target: this.element[0] })
                )
              : this._clear(),
            this
          );
        },
        _getHandle: function (b) {
          return (
            !this.options.handle ||
            !!a(b.target).closest(this.element.find(this.options.handle)).length
          );
        },
        _setHandleClassName: function () {
          (this.handleElement = this.options.handle
            ? this.element.find(this.options.handle)
            : this.element),
            this._addClass(this.handleElement, 'ui-draggable-handle');
        },
        _removeHandleClassName: function () {
          this._removeClass(this.handleElement, 'ui-draggable-handle');
        },
        _createHelper: function (b) {
          var c = this.options,
            d = a.isFunction(c.helper),
            e = d
              ? a(c.helper.apply(this.element[0], [b]))
              : 'clone' === c.helper
                ? this.element.clone().removeAttr('id')
                : this.element;
          return (
            e.parents('body').length ||
              e.appendTo(
                'parent' === c.appendTo
                  ? this.element[0].parentNode
                  : c.appendTo
              ),
            d && e[0] === this.element[0] && this._setPositionRelative(),
            e[0] === this.element[0] ||
              /(fixed|absolute)/.test(e.css('position')) ||
              e.css('position', 'absolute'),
            e
          );
        },
        _setPositionRelative: function () {
          /^(?:r|a|f)/.test(this.element.css('position')) ||
            (this.element[0].style.position = 'relative');
        },
        _adjustOffsetFromHelper: function (b) {
          'string' == typeof b && (b = b.split(' ')),
            a.isArray(b) && (b = { left: +b[0], top: +b[1] || 0 }),
            'left' in b &&
              (this.offset.click.left = b.left + this.margins.left),
            'right' in b &&
              (this.offset.click.left =
                this.helperProportions.width - b.right + this.margins.left),
            'top' in b && (this.offset.click.top = b.top + this.margins.top),
            'bottom' in b &&
              (this.offset.click.top =
                this.helperProportions.height - b.bottom + this.margins.top);
        },
        _isRootNode: function (a) {
          return /(html|body)/i.test(a.tagName) || a === this.document[0];
        },
        _getParentOffset: function () {
          var b = this.offsetParent.offset(),
            c = this.document[0];
          return (
            'absolute' === this.cssPosition &&
              this.scrollParent[0] !== c &&
              a.contains(this.scrollParent[0], this.offsetParent[0]) &&
              ((b.left += this.scrollParent.scrollLeft()),
              (b.top += this.scrollParent.scrollTop())),
            this._isRootNode(this.offsetParent[0]) && (b = { top: 0, left: 0 }),
            {
              top:
                b.top +
                (parseInt(this.offsetParent.css('borderTopWidth'), 10) || 0),
              left:
                b.left +
                (parseInt(this.offsetParent.css('borderLeftWidth'), 10) || 0)
            }
          );
        },
        _getRelativeOffset: function () {
          if ('relative' !== this.cssPosition) return { top: 0, left: 0 };
          var a = this.element.position(),
            b = this._isRootNode(this.scrollParent[0]);
          return {
            top:
              a.top -
              (parseInt(this.helper.css('top'), 10) || 0) +
              (b ? 0 : this.scrollParent.scrollTop()),
            left:
              a.left -
              (parseInt(this.helper.css('left'), 10) || 0) +
              (b ? 0 : this.scrollParent.scrollLeft())
          };
        },
        _cacheMargins: function () {
          this.margins = {
            left: parseInt(this.element.css('marginLeft'), 10) || 0,
            top: parseInt(this.element.css('marginTop'), 10) || 0,
            right: parseInt(this.element.css('marginRight'), 10) || 0,
            bottom: parseInt(this.element.css('marginBottom'), 10) || 0
          };
        },
        _cacheHelperProportions: function () {
          this.helperProportions = {
            width: this.helper.outerWidth(),
            height: this.helper.outerHeight()
          };
        },
        _setContainment: function () {
          var b,
            c,
            d,
            e = this.options,
            f = this.document[0];
          return (
            (this.relativeContainer = null),
            e.containment
              ? 'window' === e.containment
                ? void (this.containment = [
                    a(window).scrollLeft() -
                      this.offset.relative.left -
                      this.offset.parent.left,
                    a(window).scrollTop() -
                      this.offset.relative.top -
                      this.offset.parent.top,
                    a(window).scrollLeft() +
                      a(window).width() -
                      this.helperProportions.width -
                      this.margins.left,
                    a(window).scrollTop() +
                      (a(window).height() || f.body.parentNode.scrollHeight) -
                      this.helperProportions.height -
                      this.margins.top
                  ])
                : 'document' === e.containment
                  ? void (this.containment = [
                      0,
                      0,
                      a(f).width() -
                        this.helperProportions.width -
                        this.margins.left,
                      (a(f).height() || f.body.parentNode.scrollHeight) -
                        this.helperProportions.height -
                        this.margins.top
                    ])
                  : e.containment.constructor === Array
                    ? void (this.containment = e.containment)
                    : ('parent' === e.containment &&
                        (e.containment = this.helper[0].parentNode),
                      (c = a(e.containment)),
                      void (
                        (d = c[0]) &&
                        ((b = /(scroll|auto)/.test(c.css('overflow'))),
                        (this.containment = [
                          (parseInt(c.css('borderLeftWidth'), 10) || 0) +
                            (parseInt(c.css('paddingLeft'), 10) || 0),
                          (parseInt(c.css('borderTopWidth'), 10) || 0) +
                            (parseInt(c.css('paddingTop'), 10) || 0),
                          (b
                            ? Math.max(d.scrollWidth, d.offsetWidth)
                            : d.offsetWidth) -
                            (parseInt(c.css('borderRightWidth'), 10) || 0) -
                            (parseInt(c.css('paddingRight'), 10) || 0) -
                            this.helperProportions.width -
                            this.margins.left -
                            this.margins.right,
                          (b
                            ? Math.max(d.scrollHeight, d.offsetHeight)
                            : d.offsetHeight) -
                            (parseInt(c.css('borderBottomWidth'), 10) || 0) -
                            (parseInt(c.css('paddingBottom'), 10) || 0) -
                            this.helperProportions.height -
                            this.margins.top -
                            this.margins.bottom
                        ]),
                        (this.relativeContainer = c))
                      ))
              : void (this.containment = null)
          );
        },
        _convertPositionTo: function (a, b) {
          b || (b = this.position);
          var c = 'absolute' === a ? 1 : -1,
            d = this._isRootNode(this.scrollParent[0]);
          return {
            top:
              b.top +
              this.offset.relative.top * c +
              this.offset.parent.top * c -
              ('fixed' === this.cssPosition
                ? -this.offset.scroll.top
                : d
                  ? 0
                  : this.offset.scroll.top) *
                c,
            left:
              b.left +
              this.offset.relative.left * c +
              this.offset.parent.left * c -
              ('fixed' === this.cssPosition
                ? -this.offset.scroll.left
                : d
                  ? 0
                  : this.offset.scroll.left) *
                c
          };
        },
        _generatePosition: function (a, b) {
          var c,
            d,
            e,
            f,
            g = this.options,
            h = this._isRootNode(this.scrollParent[0]),
            i = a.pageX,
            j = a.pageY;
          return (
            (h && this.offset.scroll) ||
              (this.offset.scroll = {
                top: this.scrollParent.scrollTop(),
                left: this.scrollParent.scrollLeft()
              }),
            b &&
              (this.containment &&
                (this.relativeContainer
                  ? ((d = this.relativeContainer.offset()),
                    (c = [
                      this.containment[0] + d.left,
                      this.containment[1] + d.top,
                      this.containment[2] + d.left,
                      this.containment[3] + d.top
                    ]))
                  : (c = this.containment),
                a.pageX - this.offset.click.left < c[0] &&
                  (i = c[0] + this.offset.click.left),
                a.pageY - this.offset.click.top < c[1] &&
                  (j = c[1] + this.offset.click.top),
                a.pageX - this.offset.click.left > c[2] &&
                  (i = c[2] + this.offset.click.left),
                a.pageY - this.offset.click.top > c[3] &&
                  (j = c[3] + this.offset.click.top)),
              g.grid &&
                ((e = g.grid[1]
                  ? this.originalPageY +
                    Math.round((j - this.originalPageY) / g.grid[1]) * g.grid[1]
                  : this.originalPageY),
                (j = c
                  ? e - this.offset.click.top >= c[1] ||
                    e - this.offset.click.top > c[3]
                    ? e
                    : e - this.offset.click.top >= c[1]
                      ? e - g.grid[1]
                      : e + g.grid[1]
                  : e),
                (f = g.grid[0]
                  ? this.originalPageX +
                    Math.round((i - this.originalPageX) / g.grid[0]) * g.grid[0]
                  : this.originalPageX),
                (i = c
                  ? f - this.offset.click.left >= c[0] ||
                    f - this.offset.click.left > c[2]
                    ? f
                    : f - this.offset.click.left >= c[0]
                      ? f - g.grid[0]
                      : f + g.grid[0]
                  : f)),
              'y' === g.axis && (i = this.originalPageX),
              'x' === g.axis && (j = this.originalPageY)),
            {
              top:
                j -
                this.offset.click.top -
                this.offset.relative.top -
                this.offset.parent.top +
                ('fixed' === this.cssPosition
                  ? -this.offset.scroll.top
                  : h
                    ? 0
                    : this.offset.scroll.top),
              left:
                i -
                this.offset.click.left -
                this.offset.relative.left -
                this.offset.parent.left +
                ('fixed' === this.cssPosition
                  ? -this.offset.scroll.left
                  : h
                    ? 0
                    : this.offset.scroll.left)
            }
          );
        },
        _clear: function () {
          this._removeClass(this.helper, 'ui-draggable-dragging'),
            this.helper[0] === this.element[0] ||
              this.cancelHelperRemoval ||
              this.helper.remove(),
            (this.helper = null),
            (this.cancelHelperRemoval = !1),
            this.destroyOnClear && this.destroy();
        },
        _trigger: function (b, c, d) {
          return (
            (d = d || this._uiHash()),
            a.ui.plugin.call(this, b, [c, d, this], !0),
            /^(drag|start|stop)/.test(b) &&
              ((this.positionAbs = this._convertPositionTo('absolute')),
              (d.offset = this.positionAbs)),
            a.Widget.prototype._trigger.call(this, b, c, d)
          );
        },
        plugins: {},
        _uiHash: function () {
          return {
            helper: this.helper,
            position: this.position,
            originalPosition: this.originalPosition,
            offset: this.positionAbs
          };
        }
      }),
      a.ui.plugin.add('draggable', 'connectToSortable', {
        start: function (b, c, d) {
          var e = a.extend({}, c, { item: d.element });
          (d.sortables = []),
            a(d.options.connectToSortable).each(function () {
              var c = a(this).sortable('instance');
              c &&
                !c.options.disabled &&
                (d.sortables.push(c),
                c.refreshPositions(),
                c._trigger('activate', b, e));
            });
        },
        stop: function (b, c, d) {
          var e = a.extend({}, c, { item: d.element });
          (d.cancelHelperRemoval = !1),
            a.each(d.sortables, function () {
              var a = this;
              a.isOver
                ? ((a.isOver = 0),
                  (d.cancelHelperRemoval = !0),
                  (a.cancelHelperRemoval = !1),
                  (a._storedCSS = {
                    position: a.placeholder.css('position'),
                    top: a.placeholder.css('top'),
                    left: a.placeholder.css('left')
                  }),
                  a._mouseStop(b),
                  (a.options.helper = a.options._helper))
                : ((a.cancelHelperRemoval = !0),
                  a._trigger('deactivate', b, e));
            });
        },
        drag: function (b, c, d) {
          a.each(d.sortables, function () {
            var e = !1,
              f = this;
            (f.positionAbs = d.positionAbs),
              (f.helperProportions = d.helperProportions),
              (f.offset.click = d.offset.click),
              f._intersectsWith(f.containerCache) &&
                ((e = !0),
                a.each(d.sortables, function () {
                  return (
                    (this.positionAbs = d.positionAbs),
                    (this.helperProportions = d.helperProportions),
                    (this.offset.click = d.offset.click),
                    this !== f &&
                      this._intersectsWith(this.containerCache) &&
                      a.contains(f.element[0], this.element[0]) &&
                      (e = !1),
                    e
                  );
                })),
              e
                ? (f.isOver ||
                    ((f.isOver = 1),
                    (d._parent = c.helper.parent()),
                    (f.currentItem = c.helper
                      .appendTo(f.element)
                      .data('ui-sortable-item', !0)),
                    (f.options._helper = f.options.helper),
                    (f.options.helper = function () {
                      return c.helper[0];
                    }),
                    (b.target = f.currentItem[0]),
                    f._mouseCapture(b, !0),
                    f._mouseStart(b, !0, !0),
                    (f.offset.click.top = d.offset.click.top),
                    (f.offset.click.left = d.offset.click.left),
                    (f.offset.parent.left -=
                      d.offset.parent.left - f.offset.parent.left),
                    (f.offset.parent.top -=
                      d.offset.parent.top - f.offset.parent.top),
                    d._trigger('toSortable', b),
                    (d.dropped = f.element),
                    a.each(d.sortables, function () {
                      this.refreshPositions();
                    }),
                    (d.currentItem = d.element),
                    (f.fromOutside = d)),
                  f.currentItem && (f._mouseDrag(b), (c.position = f.position)))
                : f.isOver &&
                  ((f.isOver = 0),
                  (f.cancelHelperRemoval = !0),
                  (f.options._revert = f.options.revert),
                  (f.options.revert = !1),
                  f._trigger('out', b, f._uiHash(f)),
                  f._mouseStop(b, !0),
                  (f.options.revert = f.options._revert),
                  (f.options.helper = f.options._helper),
                  f.placeholder && f.placeholder.remove(),
                  c.helper.appendTo(d._parent),
                  d._refreshOffsets(b),
                  (c.position = d._generatePosition(b, !0)),
                  d._trigger('fromSortable', b),
                  (d.dropped = !1),
                  a.each(d.sortables, function () {
                    this.refreshPositions();
                  }));
          });
        }
      }),
      a.ui.plugin.add('draggable', 'cursor', {
        start: function (b, c, d) {
          var e = a('body'),
            f = d.options;
          e.css('cursor') && (f._cursor = e.css('cursor')),
            e.css('cursor', f.cursor);
        },
        stop: function (b, c, d) {
          var e = d.options;
          e._cursor && a('body').css('cursor', e._cursor);
        }
      }),
      a.ui.plugin.add('draggable', 'opacity', {
        start: function (b, c, d) {
          var e = a(c.helper),
            f = d.options;
          e.css('opacity') && (f._opacity = e.css('opacity')),
            e.css('opacity', f.opacity);
        },
        stop: function (b, c, d) {
          var e = d.options;
          e._opacity && a(c.helper).css('opacity', e._opacity);
        }
      }),
      a.ui.plugin.add('draggable', 'scroll', {
        start: function (a, b, c) {
          c.scrollParentNotHidden ||
            (c.scrollParentNotHidden = c.helper.scrollParent(!1)),
            c.scrollParentNotHidden[0] !== c.document[0] &&
              'HTML' !== c.scrollParentNotHidden[0].tagName &&
              (c.overflowOffset = c.scrollParentNotHidden.offset());
        },
        drag: function (b, c, d) {
          var e = d.options,
            f = !1,
            g = d.scrollParentNotHidden[0],
            h = d.document[0];
          g !== h && 'HTML' !== g.tagName
            ? ((e.axis && 'x' === e.axis) ||
                (d.overflowOffset.top + g.offsetHeight - b.pageY <
                e.scrollSensitivity
                  ? (g.scrollTop = f = g.scrollTop + e.scrollSpeed)
                  : b.pageY - d.overflowOffset.top < e.scrollSensitivity &&
                    (g.scrollTop = f = g.scrollTop - e.scrollSpeed)),
              (e.axis && 'y' === e.axis) ||
                (d.overflowOffset.left + g.offsetWidth - b.pageX <
                e.scrollSensitivity
                  ? (g.scrollLeft = f = g.scrollLeft + e.scrollSpeed)
                  : b.pageX - d.overflowOffset.left < e.scrollSensitivity &&
                    (g.scrollLeft = f = g.scrollLeft - e.scrollSpeed)))
            : ((e.axis && 'x' === e.axis) ||
                (b.pageY - a(h).scrollTop() < e.scrollSensitivity
                  ? (f = a(h).scrollTop(a(h).scrollTop() - e.scrollSpeed))
                  : a(window).height() - (b.pageY - a(h).scrollTop()) <
                      e.scrollSensitivity &&
                    (f = a(h).scrollTop(a(h).scrollTop() + e.scrollSpeed))),
              (e.axis && 'y' === e.axis) ||
                (b.pageX - a(h).scrollLeft() < e.scrollSensitivity
                  ? (f = a(h).scrollLeft(a(h).scrollLeft() - e.scrollSpeed))
                  : a(window).width() - (b.pageX - a(h).scrollLeft()) <
                      e.scrollSensitivity &&
                    (f = a(h).scrollLeft(a(h).scrollLeft() + e.scrollSpeed)))),
            !1 !== f &&
              a.ui.ddmanager &&
              !e.dropBehaviour &&
              a.ui.ddmanager.prepareOffsets(d, b);
        }
      }),
      a.ui.plugin.add('draggable', 'snap', {
        start: function (b, c, d) {
          var e = d.options;
          (d.snapElements = []),
            a(
              e.snap.constructor !== String
                ? e.snap.items || ':data(ui-draggable)'
                : e.snap
            ).each(function () {
              var b = a(this),
                c = b.offset();
              this !== d.element[0] &&
                d.snapElements.push({
                  item: this,
                  width: b.outerWidth(),
                  height: b.outerHeight(),
                  top: c.top,
                  left: c.left
                });
            });
        },
        drag: function (b, c, d) {
          var e,
            f,
            g,
            h,
            i,
            j,
            k,
            l,
            m,
            n,
            o = d.options,
            p = o.snapTolerance,
            q = c.offset.left,
            r = q + d.helperProportions.width,
            s = c.offset.top,
            t = s + d.helperProportions.height;
          for (m = d.snapElements.length - 1; m >= 0; m--)
            (i = d.snapElements[m].left - d.margins.left),
              (j = i + d.snapElements[m].width),
              (k = d.snapElements[m].top - d.margins.top),
              (l = k + d.snapElements[m].height),
              i - p > r ||
              q > j + p ||
              k - p > t ||
              s > l + p ||
              !a.contains(
                d.snapElements[m].item.ownerDocument,
                d.snapElements[m].item
              )
                ? (d.snapElements[m].snapping &&
                    d.options.snap.release &&
                    d.options.snap.release.call(
                      d.element,
                      b,
                      a.extend(d._uiHash(), {
                        snapItem: d.snapElements[m].item
                      })
                    ),
                  (d.snapElements[m].snapping = !1))
                : ('inner' !== o.snapMode &&
                    ((e = p >= Math.abs(k - t)),
                    (f = p >= Math.abs(l - s)),
                    (g = p >= Math.abs(i - r)),
                    (h = p >= Math.abs(j - q)),
                    e &&
                      (c.position.top = d._convertPositionTo('relative', {
                        top: k - d.helperProportions.height,
                        left: 0
                      }).top),
                    f &&
                      (c.position.top = d._convertPositionTo('relative', {
                        top: l,
                        left: 0
                      }).top),
                    g &&
                      (c.position.left = d._convertPositionTo('relative', {
                        top: 0,
                        left: i - d.helperProportions.width
                      }).left),
                    h &&
                      (c.position.left = d._convertPositionTo('relative', {
                        top: 0,
                        left: j
                      }).left)),
                  (n = e || f || g || h),
                  'outer' !== o.snapMode &&
                    ((e = p >= Math.abs(k - s)),
                    (f = p >= Math.abs(l - t)),
                    (g = p >= Math.abs(i - q)),
                    (h = p >= Math.abs(j - r)),
                    e &&
                      (c.position.top = d._convertPositionTo('relative', {
                        top: k,
                        left: 0
                      }).top),
                    f &&
                      (c.position.top = d._convertPositionTo('relative', {
                        top: l - d.helperProportions.height,
                        left: 0
                      }).top),
                    g &&
                      (c.position.left = d._convertPositionTo('relative', {
                        top: 0,
                        left: i
                      }).left),
                    h &&
                      (c.position.left = d._convertPositionTo('relative', {
                        top: 0,
                        left: j - d.helperProportions.width
                      }).left)),
                  !d.snapElements[m].snapping &&
                    (e || f || g || h || n) &&
                    d.options.snap.snap &&
                    d.options.snap.snap.call(
                      d.element,
                      b,
                      a.extend(d._uiHash(), {
                        snapItem: d.snapElements[m].item
                      })
                    ),
                  (d.snapElements[m].snapping = e || f || g || h || n));
        }
      }),
      a.ui.plugin.add('draggable', 'stack', {
        start: function (b, c, d) {
          var e,
            f = d.options,
            g = a.makeArray(a(f.stack)).sort(function (b, c) {
              return (
                (parseInt(a(b).css('zIndex'), 10) || 0) -
                (parseInt(a(c).css('zIndex'), 10) || 0)
              );
            });
          g.length &&
            ((e = parseInt(a(g[0]).css('zIndex'), 10) || 0),
            a(g).each(function (b) {
              a(this).css('zIndex', e + b);
            }),
            this.css('zIndex', e + g.length));
        }
      }),
      a.ui.plugin.add('draggable', 'zIndex', {
        start: function (b, c, d) {
          var e = a(c.helper),
            f = d.options;
          e.css('zIndex') && (f._zIndex = e.css('zIndex')),
            e.css('zIndex', f.zIndex);
        },
        stop: function (b, c, d) {
          var e = d.options;
          e._zIndex && a(c.helper).css('zIndex', e._zIndex);
        }
      }),
      a.ui.draggable,
      a.widget('ui.resizable', a.ui.mouse, {
        version: '1.12.1',
        widgetEventPrefix: 'resize',
        options: {
          alsoResize: !1,
          animate: !1,
          animateDuration: 'slow',
          animateEasing: 'swing',
          aspectRatio: !1,
          autoHide: !1,
          classes: {
            'ui-resizable-se': 'ui-icon ui-icon-gripsmall-diagonal-se'
          },
          containment: !1,
          ghost: !1,
          grid: !1,
          handles: 'e,s,se',
          helper: !1,
          maxHeight: null,
          maxWidth: null,
          minHeight: 10,
          minWidth: 10,
          zIndex: 90,
          resize: null,
          start: null,
          stop: null
        },
        _num: function (a) {
          return parseFloat(a) || 0;
        },
        _isNumber: function (a) {
          return !isNaN(parseFloat(a));
        },
        _hasScroll: function (b, c) {
          if ('hidden' === a(b).css('overflow')) return !1;
          var d = c && 'left' === c ? 'scrollLeft' : 'scrollTop',
            e = !1;
          return b[d] > 0 || ((b[d] = 1), (e = b[d] > 0), (b[d] = 0), e);
        },
        _create: function () {
          var b,
            c = this.options,
            d = this;
          this._addClass('ui-resizable'),
            a.extend(this, {
              _aspectRatio: !!c.aspectRatio,
              aspectRatio: c.aspectRatio,
              originalElement: this.element,
              _proportionallyResizeElements: [],
              _helper:
                c.helper || c.ghost || c.animate
                  ? c.helper || 'ui-resizable-helper'
                  : null
            }),
            this.element[0].nodeName.match(
              /^(canvas|textarea|input|select|button|img)$/i
            ) &&
              (this.element.wrap(
                a(
                  "<div class='ui-wrapper' style='overflow: hidden;'></div>"
                ).css({
                  position: this.element.css('position'),
                  width: this.element.outerWidth(),
                  height: this.element.outerHeight(),
                  top: this.element.css('top'),
                  left: this.element.css('left')
                })
              ),
              (this.element = this.element
                .parent()
                .data('ui-resizable', this.element.resizable('instance'))),
              (this.elementIsWrapper = !0),
              (b = {
                marginTop: this.originalElement.css('marginTop'),
                marginRight: this.originalElement.css('marginRight'),
                marginBottom: this.originalElement.css('marginBottom'),
                marginLeft: this.originalElement.css('marginLeft')
              }),
              this.element.css(b),
              this.originalElement.css('margin', 0),
              (this.originalResizeStyle = this.originalElement.css('resize')),
              this.originalElement.css('resize', 'none'),
              this._proportionallyResizeElements.push(
                this.originalElement.css({
                  position: 'static',
                  zoom: 1,
                  display: 'block'
                })
              ),
              this.originalElement.css(b),
              this._proportionallyResize()),
            this._setupHandles(),
            c.autoHide &&
              a(this.element)
                .on('mouseenter', function () {
                  c.disabled ||
                    (d._removeClass('ui-resizable-autohide'),
                    d._handles.show());
                })
                .on('mouseleave', function () {
                  c.disabled ||
                    d.resizing ||
                    (d._addClass('ui-resizable-autohide'), d._handles.hide());
                }),
            this._mouseInit();
        },
        _destroy: function () {
          this._mouseDestroy();
          var b,
            c = function (b) {
              a(b)
                .removeData('resizable')
                .removeData('ui-resizable')
                .off('.resizable')
                .find('.ui-resizable-handle')
                .remove();
            };
          return (
            this.elementIsWrapper &&
              (c(this.element),
              (b = this.element),
              this.originalElement
                .css({
                  position: b.css('position'),
                  width: b.outerWidth(),
                  height: b.outerHeight(),
                  top: b.css('top'),
                  left: b.css('left')
                })
                .insertAfter(b),
              b.remove()),
            this.originalElement.css('resize', this.originalResizeStyle),
            c(this.originalElement),
            this
          );
        },
        _setOption: function (a, b) {
          switch ((this._super(a, b), a)) {
            case 'handles':
              this._removeHandles(), this._setupHandles();
          }
        },
        _setupHandles: function () {
          var b,
            c,
            d,
            e,
            f,
            g = this.options,
            h = this;
          if (
            ((this.handles =
              g.handles ||
              (a('.ui-resizable-handle', this.element).length
                ? {
                    n: '.ui-resizable-n',
                    e: '.ui-resizable-e',
                    s: '.ui-resizable-s',
                    w: '.ui-resizable-w',
                    se: '.ui-resizable-se',
                    sw: '.ui-resizable-sw',
                    ne: '.ui-resizable-ne',
                    nw: '.ui-resizable-nw'
                  }
                : 'e,s,se')),
            (this._handles = a()),
            this.handles.constructor === String)
          )
            for (
              'all' === this.handles && (this.handles = 'n,e,s,w,se,sw,ne,nw'),
                d = this.handles.split(','),
                this.handles = {},
                c = 0;
              d.length > c;
              c++
            )
              (b = a.trim(d[c])),
                (e = 'ui-resizable-' + b),
                (f = a('<div>')),
                this._addClass(f, 'ui-resizable-handle ' + e),
                f.css({ zIndex: g.zIndex }),
                (this.handles[b] = '.ui-resizable-' + b),
                this.element.append(f);
          (this._renderAxis = function (b) {
            var c, d, e, f;
            b = b || this.element;
            for (c in this.handles)
              this.handles[c].constructor === String
                ? (this.handles[c] = this.element
                    .children(this.handles[c])
                    .first()
                    .show())
                : (this.handles[c].jquery || this.handles[c].nodeType) &&
                  ((this.handles[c] = a(this.handles[c])),
                  this._on(this.handles[c], { mousedown: h._mouseDown })),
                this.elementIsWrapper &&
                  this.originalElement[0].nodeName.match(
                    /^(textarea|input|select|button)$/i
                  ) &&
                  ((d = a(this.handles[c], this.element)),
                  (f = /sw|ne|nw|se|n|s/.test(c)
                    ? d.outerHeight()
                    : d.outerWidth()),
                  (e = [
                    'padding',
                    /ne|nw|n/.test(c)
                      ? 'Top'
                      : /se|sw|s/.test(c)
                        ? 'Bottom'
                        : /^e$/.test(c)
                          ? 'Right'
                          : 'Left'
                  ].join('')),
                  b.css(e, f),
                  this._proportionallyResize()),
                (this._handles = this._handles.add(this.handles[c]));
          }),
            this._renderAxis(this.element),
            (this._handles = this._handles.add(
              this.element.find('.ui-resizable-handle')
            )),
            this._handles.disableSelection(),
            this._handles.on('mouseover', function () {
              h.resizing ||
                (this.className &&
                  (f = this.className.match(
                    /ui-resizable-(se|sw|ne|nw|n|e|s|w)/i
                  )),
                (h.axis = f && f[1] ? f[1] : 'se'));
            }),
            g.autoHide &&
              (this._handles.hide(), this._addClass('ui-resizable-autohide'));
        },
        _removeHandles: function () {
          this._handles.remove();
        },
        _mouseCapture: function (b) {
          var c,
            d,
            e = !1;
          for (c in this.handles)
            ((d = a(this.handles[c])[0]) === b.target ||
              a.contains(d, b.target)) &&
              (e = !0);
          return !this.options.disabled && e;
        },
        _mouseStart: function (b) {
          var c,
            d,
            e,
            f = this.options,
            g = this.element;
          return (
            (this.resizing = !0),
            this._renderProxy(),
            (c = this._num(this.helper.css('left'))),
            (d = this._num(this.helper.css('top'))),
            f.containment &&
              ((c += a(f.containment).scrollLeft() || 0),
              (d += a(f.containment).scrollTop() || 0)),
            (this.offset = this.helper.offset()),
            (this.position = { left: c, top: d }),
            (this.size = this._helper
              ? { width: this.helper.width(), height: this.helper.height() }
              : { width: g.width(), height: g.height() }),
            (this.originalSize = this._helper
              ? { width: g.outerWidth(), height: g.outerHeight() }
              : { width: g.width(), height: g.height() }),
            (this.sizeDiff = {
              width: g.outerWidth() - g.width(),
              height: g.outerHeight() - g.height()
            }),
            (this.originalPosition = { left: c, top: d }),
            (this.originalMousePosition = { left: b.pageX, top: b.pageY }),
            (this.aspectRatio =
              'number' == typeof f.aspectRatio
                ? f.aspectRatio
                : this.originalSize.width / this.originalSize.height || 1),
            (e = a('.ui-resizable-' + this.axis).css('cursor')),
            a('body').css('cursor', 'auto' === e ? this.axis + '-resize' : e),
            this._addClass('ui-resizable-resizing'),
            this._propagate('start', b),
            !0
          );
        },
        _mouseDrag: function (b) {
          var c,
            d,
            e = this.originalMousePosition,
            f = this.axis,
            g = b.pageX - e.left || 0,
            h = b.pageY - e.top || 0,
            i = this._change[f];
          return (
            this._updatePrevProperties(),
            !!i &&
              ((c = i.apply(this, [b, g, h])),
              this._updateVirtualBoundaries(b.shiftKey),
              (this._aspectRatio || b.shiftKey) &&
                (c = this._updateRatio(c, b)),
              (c = this._respectSize(c, b)),
              this._updateCache(c),
              this._propagate('resize', b),
              (d = this._applyChanges()),
              !this._helper &&
                this._proportionallyResizeElements.length &&
                this._proportionallyResize(),
              a.isEmptyObject(d) ||
                (this._updatePrevProperties(),
                this._trigger('resize', b, this.ui()),
                this._applyChanges()),
              !1)
          );
        },
        _mouseStop: function (b) {
          this.resizing = !1;
          var c,
            d,
            e,
            f,
            g,
            h,
            i,
            j = this.options,
            k = this;
          return (
            this._helper &&
              ((c = this._proportionallyResizeElements),
              (d = c.length && /textarea/i.test(c[0].nodeName)),
              (e = d && this._hasScroll(c[0], 'left') ? 0 : k.sizeDiff.height),
              (f = d ? 0 : k.sizeDiff.width),
              (g = {
                width: k.helper.width() - f,
                height: k.helper.height() - e
              }),
              (h =
                parseFloat(k.element.css('left')) +
                  (k.position.left - k.originalPosition.left) || null),
              (i =
                parseFloat(k.element.css('top')) +
                  (k.position.top - k.originalPosition.top) || null),
              j.animate || this.element.css(a.extend(g, { top: i, left: h })),
              k.helper.height(k.size.height),
              k.helper.width(k.size.width),
              this._helper && !j.animate && this._proportionallyResize()),
            a('body').css('cursor', 'auto'),
            this._removeClass('ui-resizable-resizing'),
            this._propagate('stop', b),
            this._helper && this.helper.remove(),
            !1
          );
        },
        _updatePrevProperties: function () {
          (this.prevPosition = {
            top: this.position.top,
            left: this.position.left
          }),
            (this.prevSize = {
              width: this.size.width,
              height: this.size.height
            });
        },
        _applyChanges: function () {
          var a = {};
          return (
            this.position.top !== this.prevPosition.top &&
              (a.top = this.position.top + 'px'),
            this.position.left !== this.prevPosition.left &&
              (a.left = this.position.left + 'px'),
            this.size.width !== this.prevSize.width &&
              (a.width = this.size.width + 'px'),
            this.size.height !== this.prevSize.height &&
              (a.height = this.size.height + 'px'),
            this.helper.css(a),
            a
          );
        },
        _updateVirtualBoundaries: function (a) {
          var b,
            c,
            d,
            e,
            f,
            g = this.options;
          (f = {
            minWidth: this._isNumber(g.minWidth) ? g.minWidth : 0,
            maxWidth: this._isNumber(g.maxWidth) ? g.maxWidth : 1 / 0,
            minHeight: this._isNumber(g.minHeight) ? g.minHeight : 0,
            maxHeight: this._isNumber(g.maxHeight) ? g.maxHeight : 1 / 0
          }),
            (this._aspectRatio || a) &&
              ((b = f.minHeight * this.aspectRatio),
              (d = f.minWidth / this.aspectRatio),
              (c = f.maxHeight * this.aspectRatio),
              (e = f.maxWidth / this.aspectRatio),
              b > f.minWidth && (f.minWidth = b),
              d > f.minHeight && (f.minHeight = d),
              f.maxWidth > c && (f.maxWidth = c),
              f.maxHeight > e && (f.maxHeight = e)),
            (this._vBoundaries = f);
        },
        _updateCache: function (a) {
          (this.offset = this.helper.offset()),
            this._isNumber(a.left) && (this.position.left = a.left),
            this._isNumber(a.top) && (this.position.top = a.top),
            this._isNumber(a.height) && (this.size.height = a.height),
            this._isNumber(a.width) && (this.size.width = a.width);
        },
        _updateRatio: function (a) {
          var b = this.position,
            c = this.size,
            d = this.axis;
          return (
            this._isNumber(a.height)
              ? (a.width = a.height * this.aspectRatio)
              : this._isNumber(a.width) &&
                (a.height = a.width / this.aspectRatio),
            'sw' === d &&
              ((a.left = b.left + (c.width - a.width)), (a.top = null)),
            'nw' === d &&
              ((a.top = b.top + (c.height - a.height)),
              (a.left = b.left + (c.width - a.width))),
            a
          );
        },
        _respectSize: function (a) {
          var b = this._vBoundaries,
            c = this.axis,
            d = this._isNumber(a.width) && b.maxWidth && b.maxWidth < a.width,
            e =
              this._isNumber(a.height) && b.maxHeight && b.maxHeight < a.height,
            f = this._isNumber(a.width) && b.minWidth && b.minWidth > a.width,
            g =
              this._isNumber(a.height) && b.minHeight && b.minHeight > a.height,
            h = this.originalPosition.left + this.originalSize.width,
            i = this.originalPosition.top + this.originalSize.height,
            j = /sw|nw|w/.test(c),
            k = /nw|ne|n/.test(c);
          return (
            f && (a.width = b.minWidth),
            g && (a.height = b.minHeight),
            d && (a.width = b.maxWidth),
            e && (a.height = b.maxHeight),
            f && j && (a.left = h - b.minWidth),
            d && j && (a.left = h - b.maxWidth),
            g && k && (a.top = i - b.minHeight),
            e && k && (a.top = i - b.maxHeight),
            a.width || a.height || a.left || !a.top
              ? a.width || a.height || a.top || !a.left || (a.left = null)
              : (a.top = null),
            a
          );
        },
        _getPaddingPlusBorderDimensions: function (a) {
          for (
            var b = 0,
              c = [],
              d = [
                a.css('borderTopWidth'),
                a.css('borderRightWidth'),
                a.css('borderBottomWidth'),
                a.css('borderLeftWidth')
              ],
              e = [
                a.css('paddingTop'),
                a.css('paddingRight'),
                a.css('paddingBottom'),
                a.css('paddingLeft')
              ];
            4 > b;
            b++
          )
            (c[b] = parseFloat(d[b]) || 0), (c[b] += parseFloat(e[b]) || 0);
          return { height: c[0] + c[2], width: c[1] + c[3] };
        },
        _proportionallyResize: function () {
          if (this._proportionallyResizeElements.length)
            for (
              var a, b = 0, c = this.helper || this.element;
              this._proportionallyResizeElements.length > b;
              b++
            )
              (a = this._proportionallyResizeElements[b]),
                this.outerDimensions ||
                  (this.outerDimensions =
                    this._getPaddingPlusBorderDimensions(a)),
                a.css({
                  height: c.height() - this.outerDimensions.height || 0,
                  width: c.width() - this.outerDimensions.width || 0
                });
        },
        _renderProxy: function () {
          var b = this.element,
            c = this.options;
          (this.elementOffset = b.offset()),
            this._helper
              ? ((this.helper =
                  this.helper || a("<div style='overflow:hidden;'></div>")),
                this._addClass(this.helper, this._helper),
                this.helper.css({
                  width: this.element.outerWidth(),
                  height: this.element.outerHeight(),
                  position: 'absolute',
                  left: this.elementOffset.left + 'px',
                  top: this.elementOffset.top + 'px',
                  zIndex: ++c.zIndex
                }),
                this.helper.appendTo('body').disableSelection())
              : (this.helper = this.element);
        },
        _change: {
          e: function (a, b) {
            return { width: this.originalSize.width + b };
          },
          w: function (a, b) {
            var c = this.originalSize;
            return { left: this.originalPosition.left + b, width: c.width - b };
          },
          n: function (a, b, c) {
            var d = this.originalSize;
            return { top: this.originalPosition.top + c, height: d.height - c };
          },
          s: function (a, b, c) {
            return { height: this.originalSize.height + c };
          },
          se: function (b, c, d) {
            return a.extend(
              this._change.s.apply(this, arguments),
              this._change.e.apply(this, [b, c, d])
            );
          },
          sw: function (b, c, d) {
            return a.extend(
              this._change.s.apply(this, arguments),
              this._change.w.apply(this, [b, c, d])
            );
          },
          ne: function (b, c, d) {
            return a.extend(
              this._change.n.apply(this, arguments),
              this._change.e.apply(this, [b, c, d])
            );
          },
          nw: function (b, c, d) {
            return a.extend(
              this._change.n.apply(this, arguments),
              this._change.w.apply(this, [b, c, d])
            );
          }
        },
        _propagate: function (b, c) {
          a.ui.plugin.call(this, b, [c, this.ui()]),
            'resize' !== b && this._trigger(b, c, this.ui());
        },
        plugins: {},
        ui: function () {
          return {
            originalElement: this.originalElement,
            element: this.element,
            helper: this.helper,
            position: this.position,
            size: this.size,
            originalSize: this.originalSize,
            originalPosition: this.originalPosition
          };
        }
      }),
      a.ui.plugin.add('resizable', 'animate', {
        stop: function (b) {
          var c = a(this).resizable('instance'),
            d = c.options,
            e = c._proportionallyResizeElements,
            f = e.length && /textarea/i.test(e[0].nodeName),
            g = f && c._hasScroll(e[0], 'left') ? 0 : c.sizeDiff.height,
            h = f ? 0 : c.sizeDiff.width,
            i = { width: c.size.width - h, height: c.size.height - g },
            j =
              parseFloat(c.element.css('left')) +
                (c.position.left - c.originalPosition.left) || null,
            k =
              parseFloat(c.element.css('top')) +
                (c.position.top - c.originalPosition.top) || null;
          c.element.animate(a.extend(i, k && j ? { top: k, left: j } : {}), {
            duration: d.animateDuration,
            easing: d.animateEasing,
            step: function () {
              var d = {
                width: parseFloat(c.element.css('width')),
                height: parseFloat(c.element.css('height')),
                top: parseFloat(c.element.css('top')),
                left: parseFloat(c.element.css('left'))
              };
              e &&
                e.length &&
                a(e[0]).css({ width: d.width, height: d.height }),
                c._updateCache(d),
                c._propagate('resize', b);
            }
          });
        }
      }),
      a.ui.plugin.add('resizable', 'containment', {
        start: function () {
          var b,
            c,
            d,
            e,
            f,
            g,
            h,
            i = a(this).resizable('instance'),
            j = i.options,
            k = i.element,
            l = j.containment,
            m =
              l instanceof a
                ? l.get(0)
                : /parent/.test(l)
                  ? k.parent().get(0)
                  : l;
          m &&
            ((i.containerElement = a(m)),
            /document/.test(l) || l === document
              ? ((i.containerOffset = { left: 0, top: 0 }),
                (i.containerPosition = { left: 0, top: 0 }),
                (i.parentData = {
                  element: a(document),
                  left: 0,
                  top: 0,
                  width: a(document).width(),
                  height:
                    a(document).height() ||
                    document.body.parentNode.scrollHeight
                }))
              : ((b = a(m)),
                (c = []),
                a(['Top', 'Right', 'Left', 'Bottom']).each(function (a, d) {
                  c[a] = i._num(b.css('padding' + d));
                }),
                (i.containerOffset = b.offset()),
                (i.containerPosition = b.position()),
                (i.containerSize = {
                  height: b.innerHeight() - c[3],
                  width: b.innerWidth() - c[1]
                }),
                (d = i.containerOffset),
                (e = i.containerSize.height),
                (f = i.containerSize.width),
                (g = i._hasScroll(m, 'left') ? m.scrollWidth : f),
                (h = i._hasScroll(m) ? m.scrollHeight : e),
                (i.parentData = {
                  element: m,
                  left: d.left,
                  top: d.top,
                  width: g,
                  height: h
                })));
        },
        resize: function (b) {
          var c,
            d,
            e,
            f,
            g = a(this).resizable('instance'),
            h = g.options,
            i = g.containerOffset,
            j = g.position,
            k = g._aspectRatio || b.shiftKey,
            l = { top: 0, left: 0 },
            m = g.containerElement,
            n = !0;
          m[0] !== document && /static/.test(m.css('position')) && (l = i),
            j.left < (g._helper ? i.left : 0) &&
              ((g.size.width =
                g.size.width +
                (g._helper
                  ? g.position.left - i.left
                  : g.position.left - l.left)),
              k && ((g.size.height = g.size.width / g.aspectRatio), (n = !1)),
              (g.position.left = h.helper ? i.left : 0)),
            j.top < (g._helper ? i.top : 0) &&
              ((g.size.height =
                g.size.height +
                (g._helper ? g.position.top - i.top : g.position.top)),
              k && ((g.size.width = g.size.height * g.aspectRatio), (n = !1)),
              (g.position.top = g._helper ? i.top : 0)),
            (e = g.containerElement.get(0) === g.element.parent().get(0)),
            (f = /relative|absolute/.test(g.containerElement.css('position'))),
            e && f
              ? ((g.offset.left = g.parentData.left + g.position.left),
                (g.offset.top = g.parentData.top + g.position.top))
              : ((g.offset.left = g.element.offset().left),
                (g.offset.top = g.element.offset().top)),
            (c = Math.abs(
              g.sizeDiff.width +
                (g._helper ? g.offset.left - l.left : g.offset.left - i.left)
            )),
            (d = Math.abs(
              g.sizeDiff.height +
                (g._helper ? g.offset.top - l.top : g.offset.top - i.top)
            )),
            c + g.size.width >= g.parentData.width &&
              ((g.size.width = g.parentData.width - c),
              k && ((g.size.height = g.size.width / g.aspectRatio), (n = !1))),
            d + g.size.height >= g.parentData.height &&
              ((g.size.height = g.parentData.height - d),
              k && ((g.size.width = g.size.height * g.aspectRatio), (n = !1))),
            n ||
              ((g.position.left = g.prevPosition.left),
              (g.position.top = g.prevPosition.top),
              (g.size.width = g.prevSize.width),
              (g.size.height = g.prevSize.height));
        },
        stop: function () {
          var b = a(this).resizable('instance'),
            c = b.options,
            d = b.containerOffset,
            e = b.containerPosition,
            f = b.containerElement,
            g = a(b.helper),
            h = g.offset(),
            i = g.outerWidth() - b.sizeDiff.width,
            j = g.outerHeight() - b.sizeDiff.height;
          b._helper &&
            !c.animate &&
            /relative/.test(f.css('position')) &&
            a(this).css({
              left: h.left - e.left - d.left,
              width: i,
              height: j
            }),
            b._helper &&
              !c.animate &&
              /static/.test(f.css('position')) &&
              a(this).css({
                left: h.left - e.left - d.left,
                width: i,
                height: j
              });
        }
      }),
      a.ui.plugin.add('resizable', 'alsoResize', {
        start: function () {
          var b = a(this).resizable('instance'),
            c = b.options;
          a(c.alsoResize).each(function () {
            var b = a(this);
            b.data('ui-resizable-alsoresize', {
              width: parseFloat(b.width()),
              height: parseFloat(b.height()),
              left: parseFloat(b.css('left')),
              top: parseFloat(b.css('top'))
            });
          });
        },
        resize: function (b, c) {
          var d = a(this).resizable('instance'),
            e = d.options,
            f = d.originalSize,
            g = d.originalPosition,
            h = {
              height: d.size.height - f.height || 0,
              width: d.size.width - f.width || 0,
              top: d.position.top - g.top || 0,
              left: d.position.left - g.left || 0
            };
          a(e.alsoResize).each(function () {
            var b = a(this),
              d = a(this).data('ui-resizable-alsoresize'),
              e = {},
              f = b.parents(c.originalElement[0]).length
                ? ['width', 'height']
                : ['width', 'height', 'top', 'left'];
            a.each(f, function (a, b) {
              var c = (d[b] || 0) + (h[b] || 0);
              c && c >= 0 && (e[b] = c || null);
            }),
              b.css(e);
          });
        },
        stop: function () {
          a(this).removeData('ui-resizable-alsoresize');
        }
      }),
      a.ui.plugin.add('resizable', 'ghost', {
        start: function () {
          var b = a(this).resizable('instance'),
            c = b.size;
          (b.ghost = b.originalElement.clone()),
            b.ghost.css({
              opacity: 0.25,
              display: 'block',
              position: 'relative',
              height: c.height,
              width: c.width,
              margin: 0,
              left: 0,
              top: 0
            }),
            b._addClass(b.ghost, 'ui-resizable-ghost'),
            !1 !== a.uiBackCompat &&
              'string' == typeof b.options.ghost &&
              b.ghost.addClass(this.options.ghost),
            b.ghost.appendTo(b.helper);
        },
        resize: function () {
          var b = a(this).resizable('instance');
          b.ghost &&
            b.ghost.css({
              position: 'relative',
              height: b.size.height,
              width: b.size.width
            });
        },
        stop: function () {
          var b = a(this).resizable('instance');
          b.ghost && b.helper && b.helper.get(0).removeChild(b.ghost.get(0));
        }
      }),
      a.ui.plugin.add('resizable', 'grid', {
        resize: function () {
          var b,
            c = a(this).resizable('instance'),
            d = c.options,
            e = c.size,
            f = c.originalSize,
            g = c.originalPosition,
            h = c.axis,
            i = 'number' == typeof d.grid ? [d.grid, d.grid] : d.grid,
            j = i[0] || 1,
            k = i[1] || 1,
            l = Math.round((e.width - f.width) / j) * j,
            m = Math.round((e.height - f.height) / k) * k,
            n = f.width + l,
            o = f.height + m,
            p = d.maxWidth && n > d.maxWidth,
            q = d.maxHeight && o > d.maxHeight,
            r = d.minWidth && d.minWidth > n,
            s = d.minHeight && d.minHeight > o;
          (d.grid = i),
            r && (n += j),
            s && (o += k),
            p && (n -= j),
            q && (o -= k),
            /^(se|s|e)$/.test(h)
              ? ((c.size.width = n), (c.size.height = o))
              : /^(ne)$/.test(h)
                ? ((c.size.width = n),
                  (c.size.height = o),
                  (c.position.top = g.top - m))
                : /^(sw)$/.test(h)
                  ? ((c.size.width = n),
                    (c.size.height = o),
                    (c.position.left = g.left - l))
                  : ((0 >= o - k || 0 >= n - j) &&
                      (b = c._getPaddingPlusBorderDimensions(this)),
                    o - k > 0
                      ? ((c.size.height = o), (c.position.top = g.top - m))
                      : ((o = k - b.height),
                        (c.size.height = o),
                        (c.position.top = g.top + f.height - o)),
                    n - j > 0
                      ? ((c.size.width = n), (c.position.left = g.left - l))
                      : ((n = j - b.width),
                        (c.size.width = n),
                        (c.position.left = g.left + f.width - n)));
        }
      }),
      a.ui.resizable,
      a.widget('ui.dialog', {
        version: '1.12.1',
        options: {
          appendTo: 'body',
          autoOpen: !0,
          buttons: [],
          classes: {
            'ui-dialog': 'ui-corner-all',
            'ui-dialog-titlebar': 'ui-corner-all'
          },
          closeOnEscape: !0,
          closeText: 'Close',
          draggable: !0,
          hide: null,
          height: 'auto',
          maxHeight: null,
          maxWidth: null,
          minHeight: 150,
          minWidth: 150,
          modal: !1,
          position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'fit',
            using: function (b) {
              var c = a(this).css(b).offset().top;
              0 > c && a(this).css('top', b.top - c);
            }
          },
          resizable: !0,
          show: null,
          title: null,
          width: 300,
          beforeClose: null,
          close: null,
          drag: null,
          dragStart: null,
          dragStop: null,
          focus: null,
          open: null,
          resize: null,
          resizeStart: null,
          resizeStop: null
        },
        sizeRelatedOptions: {
          buttons: !0,
          height: !0,
          maxHeight: !0,
          maxWidth: !0,
          minHeight: !0,
          minWidth: !0,
          width: !0
        },
        resizableRelatedOptions: {
          maxHeight: !0,
          maxWidth: !0,
          minHeight: !0,
          minWidth: !0
        },
        _create: function () {
          (this.originalCss = {
            display: this.element[0].style.display,
            width: this.element[0].style.width,
            minHeight: this.element[0].style.minHeight,
            maxHeight: this.element[0].style.maxHeight,
            height: this.element[0].style.height
          }),
            (this.originalPosition = {
              parent: this.element.parent(),
              index: this.element.parent().children().index(this.element)
            }),
            (this.originalTitle = this.element.attr('title')),
            null == this.options.title &&
              null != this.originalTitle &&
              (this.options.title = this.originalTitle),
            this.options.disabled && (this.options.disabled = !1),
            this._createWrapper(),
            this.element.show().removeAttr('title').appendTo(this.uiDialog),
            this._addClass('ui-dialog-content', 'ui-widget-content'),
            this._createTitlebar(),
            this._createButtonPane(),
            this.options.draggable && a.fn.draggable && this._makeDraggable(),
            this.options.resizable && a.fn.resizable && this._makeResizable(),
            (this._isOpen = !1),
            this._trackFocus();
        },
        _init: function () {
          this.options.autoOpen && this.open();
        },
        _appendTo: function () {
          var b = this.options.appendTo;
          return b && (b.jquery || b.nodeType)
            ? a(b)
            : this.document.find(b || 'body').eq(0);
        },
        _destroy: function () {
          var a,
            b = this.originalPosition;
          this._untrackInstance(),
            this._destroyOverlay(),
            this.element.removeUniqueId().css(this.originalCss).detach(),
            this.uiDialog.remove(),
            this.originalTitle &&
              this.element.attr('title', this.originalTitle),
            (a = b.parent.children().eq(b.index)),
            a.length && a[0] !== this.element[0]
              ? a.before(this.element)
              : b.parent.append(this.element);
        },
        widget: function () {
          return this.uiDialog;
        },
        disable: a.noop,
        enable: a.noop,
        close: function (b) {
          var c = this;
          this._isOpen &&
            !1 !== this._trigger('beforeClose', b) &&
            ((this._isOpen = !1),
            (this._focusedElement = null),
            this._destroyOverlay(),
            this._untrackInstance(),
            this.opener.filter(':focusable').trigger('focus').length ||
              a.ui.safeBlur(a.ui.safeActiveElement(this.document[0])),
            this._hide(this.uiDialog, this.options.hide, function () {
              c._trigger('close', b);
            }));
        },
        isOpen: function () {
          return this._isOpen;
        },
        moveToTop: function () {
          this._moveToTop();
        },
        _moveToTop: function (b, c) {
          var d = !1,
            e = this.uiDialog
              .siblings('.ui-front:visible')
              .map(function () {
                return +a(this).css('z-index');
              })
              .get(),
            f = Math.max.apply(null, e);
          return (
            f >= +this.uiDialog.css('z-index') &&
              (this.uiDialog.css('z-index', f + 1), (d = !0)),
            d && !c && this._trigger('focus', b),
            d
          );
        },
        open: function () {
          var b = this;
          return this._isOpen
            ? void (this._moveToTop() && this._focusTabbable())
            : ((this._isOpen = !0),
              (this.opener = a(a.ui.safeActiveElement(this.document[0]))),
              this._size(),
              this._position(),
              this._createOverlay(),
              this._moveToTop(null, !0),
              this.overlay &&
                this.overlay.css('z-index', this.uiDialog.css('z-index') - 1),
              this._show(this.uiDialog, this.options.show, function () {
                b._focusTabbable(), b._trigger('focus');
              }),
              this._makeFocusTarget(),
              void this._trigger('open'));
        },
        _focusTabbable: function () {
          var a = this._focusedElement;
          a || (a = this.element.find('[autofocus]')),
            a.length || (a = this.element.find(':tabbable')),
            a.length || (a = this.uiDialogButtonPane.find(':tabbable')),
            a.length || (a = this.uiDialogTitlebarClose.filter(':tabbable')),
            a.length || (a = this.uiDialog),
            a.eq(0).trigger('focus');
        },
        _keepFocus: function (b) {
          function c() {
            var b = a.ui.safeActiveElement(this.document[0]);
            this.uiDialog[0] === b ||
              a.contains(this.uiDialog[0], b) ||
              this._focusTabbable();
          }
          b.preventDefault(), c.call(this), this._delay(c);
        },
        _createWrapper: function () {
          (this.uiDialog = a('<div>')
            .hide()
            .attr({ tabIndex: -1, role: 'dialog' })
            .appendTo(this._appendTo())),
            this._addClass(
              this.uiDialog,
              'ui-dialog',
              'ui-widget ui-widget-content ui-front'
            ),
            this._on(this.uiDialog, {
              keydown: function (b) {
                if (
                  this.options.closeOnEscape &&
                  !b.isDefaultPrevented() &&
                  b.keyCode &&
                  b.keyCode === a.ui.keyCode.ESCAPE
                )
                  return b.preventDefault(), void this.close(b);
                if (b.keyCode === a.ui.keyCode.TAB && !b.isDefaultPrevented()) {
                  var c = this.uiDialog.find(':tabbable'),
                    d = c.filter(':first'),
                    e = c.filter(':last');
                  (b.target !== e[0] && b.target !== this.uiDialog[0]) ||
                  b.shiftKey
                    ? (b.target !== d[0] && b.target !== this.uiDialog[0]) ||
                      !b.shiftKey ||
                      (this._delay(function () {
                        e.trigger('focus');
                      }),
                      b.preventDefault())
                    : (this._delay(function () {
                        d.trigger('focus');
                      }),
                      b.preventDefault());
                }
              },
              mousedown: function (a) {
                this._moveToTop(a) && this._focusTabbable();
              }
            }),
            this.element.find('[aria-describedby]').length ||
              this.uiDialog.attr({
                'aria-describedby': this.element.uniqueId().attr('id')
              });
        },
        _createTitlebar: function () {
          var b;
          (this.uiDialogTitlebar = a('<div>')),
            this._addClass(
              this.uiDialogTitlebar,
              'ui-dialog-titlebar',
              'ui-widget-header ui-helper-clearfix'
            ),
            this._on(this.uiDialogTitlebar, {
              mousedown: function (b) {
                a(b.target).closest('.ui-dialog-titlebar-close') ||
                  this.uiDialog.trigger('focus');
              }
            }),
            (this.uiDialogTitlebarClose = a("<button type='button'></button>")
              .button({
                label: a('<a>').text(this.options.closeText).html(),
                icon: 'ui-icon-closethick',
                showLabel: !1
              })
              .appendTo(this.uiDialogTitlebar)),
            this._addClass(
              this.uiDialogTitlebarClose,
              'ui-dialog-titlebar-close'
            ),
            this._on(this.uiDialogTitlebarClose, {
              click: function (a) {
                a.preventDefault(), this.close(a);
              }
            }),
            (b = a('<span>').uniqueId().prependTo(this.uiDialogTitlebar)),
            this._addClass(b, 'ui-dialog-title'),
            this._title(b),
            this.uiDialogTitlebar.prependTo(this.uiDialog),
            this.uiDialog.attr({ 'aria-labelledby': b.attr('id') });
        },
        _title: function (a) {
          this.options.title ? a.text(this.options.title) : a.html('&#160;');
        },
        _createButtonPane: function () {
          (this.uiDialogButtonPane = a('<div>')),
            this._addClass(
              this.uiDialogButtonPane,
              'ui-dialog-buttonpane',
              'ui-widget-content ui-helper-clearfix'
            ),
            (this.uiButtonSet = a('<div>').appendTo(this.uiDialogButtonPane)),
            this._addClass(this.uiButtonSet, 'ui-dialog-buttonset'),
            this._createButtons();
        },
        _createButtons: function () {
          var b = this,
            c = this.options.buttons;
          return (
            this.uiDialogButtonPane.remove(),
            this.uiButtonSet.empty(),
            a.isEmptyObject(c) || (a.isArray(c) && !c.length)
              ? void this._removeClass(this.uiDialog, 'ui-dialog-buttons')
              : (a.each(c, function (c, d) {
                  var e, f;
                  (d = a.isFunction(d) ? { click: d, text: c } : d),
                    (d = a.extend({ type: 'button' }, d)),
                    (e = d.click),
                    (f = {
                      icon: d.icon,
                      iconPosition: d.iconPosition,
                      showLabel: d.showLabel,
                      icons: d.icons,
                      text: d.text
                    }),
                    delete d.click,
                    delete d.icon,
                    delete d.iconPosition,
                    delete d.showLabel,
                    delete d.icons,
                    'boolean' == typeof d.text && delete d.text,
                    a('<button></button>', d)
                      .button(f)
                      .appendTo(b.uiButtonSet)
                      .on('click', function () {
                        e.apply(b.element[0], arguments);
                      });
                }),
                this._addClass(this.uiDialog, 'ui-dialog-buttons'),
                void this.uiDialogButtonPane.appendTo(this.uiDialog))
          );
        },
        _makeDraggable: function () {
          function b(a) {
            return { position: a.position, offset: a.offset };
          }
          var c = this,
            d = this.options;
          this.uiDialog.draggable({
            cancel: '.ui-dialog-content, .ui-dialog-titlebar-close',
            handle: '.ui-dialog-titlebar',
            containment: 'document',
            start: function (d, e) {
              c._addClass(a(this), 'ui-dialog-dragging'),
                c._blockFrames(),
                c._trigger('dragStart', d, b(e));
            },
            drag: function (a, d) {
              c._trigger('drag', a, b(d));
            },
            stop: function (e, f) {
              var g = f.offset.left - c.document.scrollLeft(),
                h = f.offset.top - c.document.scrollTop();
              (d.position = {
                my: 'left top',
                at:
                  'left' +
                  (g >= 0 ? '+' : '') +
                  g +
                  ' top' +
                  (h >= 0 ? '+' : '') +
                  h,
                of: c.window
              }),
                c._removeClass(a(this), 'ui-dialog-dragging'),
                c._unblockFrames(),
                c._trigger('dragStop', e, b(f));
            }
          });
        },
        _makeResizable: function () {
          function b(a) {
            return {
              originalPosition: a.originalPosition,
              originalSize: a.originalSize,
              position: a.position,
              size: a.size
            };
          }
          var c = this,
            d = this.options,
            e = d.resizable,
            f = this.uiDialog.css('position'),
            g = 'string' == typeof e ? e : 'n,e,s,w,se,sw,ne,nw';
          this.uiDialog
            .resizable({
              cancel: '.ui-dialog-content',
              containment: 'document',
              alsoResize: this.element,
              maxWidth: d.maxWidth,
              maxHeight: d.maxHeight,
              minWidth: d.minWidth,
              minHeight: this._minHeight(),
              handles: g,
              start: function (d, e) {
                c._addClass(a(this), 'ui-dialog-resizing'),
                  c._blockFrames(),
                  c._trigger('resizeStart', d, b(e));
              },
              resize: function (a, d) {
                c._trigger('resize', a, b(d));
              },
              stop: function (e, f) {
                var g = c.uiDialog.offset(),
                  h = g.left - c.document.scrollLeft(),
                  i = g.top - c.document.scrollTop();
                (d.height = c.uiDialog.height()),
                  (d.width = c.uiDialog.width()),
                  (d.position = {
                    my: 'left top',
                    at:
                      'left' +
                      (h >= 0 ? '+' : '') +
                      h +
                      ' top' +
                      (i >= 0 ? '+' : '') +
                      i,
                    of: c.window
                  }),
                  c._removeClass(a(this), 'ui-dialog-resizing'),
                  c._unblockFrames(),
                  c._trigger('resizeStop', e, b(f));
              }
            })
            .css('position', f);
        },
        _trackFocus: function () {
          this._on(this.widget(), {
            focusin: function (b) {
              this._makeFocusTarget(), (this._focusedElement = a(b.target));
            }
          });
        },
        _makeFocusTarget: function () {
          this._untrackInstance(), this._trackingInstances().unshift(this);
        },
        _untrackInstance: function () {
          var b = this._trackingInstances(),
            c = a.inArray(this, b);
          -1 !== c && b.splice(c, 1);
        },
        _trackingInstances: function () {
          var a = this.document.data('ui-dialog-instances');
          return (
            a || ((a = []), this.document.data('ui-dialog-instances', a)), a
          );
        },
        _minHeight: function () {
          var a = this.options;
          return 'auto' === a.height
            ? a.minHeight
            : Math.min(a.minHeight, a.height);
        },
        _position: function () {
          var a = this.uiDialog.is(':visible');
          a || this.uiDialog.show(),
            this.uiDialog.position(this.options.position),
            a || this.uiDialog.hide();
        },
        _setOptions: function (b) {
          var c = this,
            d = !1,
            e = {};
          a.each(b, function (a, b) {
            c._setOption(a, b),
              a in c.sizeRelatedOptions && (d = !0),
              a in c.resizableRelatedOptions && (e[a] = b);
          }),
            d && (this._size(), this._position()),
            this.uiDialog.is(':data(ui-resizable)') &&
              this.uiDialog.resizable('option', e);
        },
        _setOption: function (b, c) {
          var d,
            e,
            f = this.uiDialog;
          'disabled' !== b &&
            (this._super(b, c),
            'appendTo' === b && this.uiDialog.appendTo(this._appendTo()),
            'buttons' === b && this._createButtons(),
            'closeText' === b &&
              this.uiDialogTitlebarClose.button({
                label: a('<a>')
                  .text('' + this.options.closeText)
                  .html()
              }),
            'draggable' === b &&
              ((d = f.is(':data(ui-draggable)')),
              d && !c && f.draggable('destroy'),
              !d && c && this._makeDraggable()),
            'position' === b && this._position(),
            'resizable' === b &&
              ((e = f.is(':data(ui-resizable)')),
              e && !c && f.resizable('destroy'),
              e && 'string' == typeof c && f.resizable('option', 'handles', c),
              e || !1 === c || this._makeResizable()),
            'title' === b &&
              this._title(this.uiDialogTitlebar.find('.ui-dialog-title')));
        },
        _size: function () {
          var a,
            b,
            c,
            d = this.options;
          this.element
            .show()
            .css({ width: 'auto', minHeight: 0, maxHeight: 'none', height: 0 }),
            d.minWidth > d.width && (d.width = d.minWidth),
            (a = this.uiDialog
              .css({ height: 'auto', width: d.width })
              .outerHeight()),
            (b = Math.max(0, d.minHeight - a)),
            (c =
              'number' == typeof d.maxHeight
                ? Math.max(0, d.maxHeight - a)
                : 'none'),
            'auto' === d.height
              ? this.element.css({ minHeight: b, maxHeight: c, height: 'auto' })
              : this.element.height(Math.max(0, d.height - a)),
            this.uiDialog.is(':data(ui-resizable)') &&
              this.uiDialog.resizable('option', 'minHeight', this._minHeight());
        },
        _blockFrames: function () {
          this.iframeBlocks = this.document.find('iframe').map(function () {
            var b = a(this);
            return a('<div>')
              .css({
                position: 'absolute',
                width: b.outerWidth(),
                height: b.outerHeight()
              })
              .appendTo(b.parent())
              .offset(b.offset())[0];
          });
        },
        _unblockFrames: function () {
          this.iframeBlocks &&
            (this.iframeBlocks.remove(), delete this.iframeBlocks);
        },
        _allowInteraction: function (b) {
          return (
            !!a(b.target).closest('.ui-dialog').length ||
            !!a(b.target).closest('.ui-datepicker').length
          );
        },
        _createOverlay: function () {
          if (this.options.modal) {
            var b = !0;
            this._delay(function () {
              b = !1;
            }),
              this.document.data('ui-dialog-overlays') ||
                this._on(this.document, {
                  focusin: function (a) {
                    b ||
                      this._allowInteraction(a) ||
                      (a.preventDefault(),
                      this._trackingInstances()[0]._focusTabbable());
                  }
                }),
              (this.overlay = a('<div>').appendTo(this._appendTo())),
              this._addClass(this.overlay, null, 'ui-widget-overlay ui-front'),
              this._on(this.overlay, { mousedown: '_keepFocus' }),
              this.document.data(
                'ui-dialog-overlays',
                (this.document.data('ui-dialog-overlays') || 0) + 1
              );
          }
        },
        _destroyOverlay: function () {
          if (this.options.modal && this.overlay) {
            var a = this.document.data('ui-dialog-overlays') - 1;
            a
              ? this.document.data('ui-dialog-overlays', a)
              : (this._off(this.document, 'focusin'),
                this.document.removeData('ui-dialog-overlays')),
              this.overlay.remove(),
              (this.overlay = null);
          }
        }
      }),
      !1 !== a.uiBackCompat &&
        a.widget('ui.dialog', a.ui.dialog, {
          options: { dialogClass: '' },
          _createWrapper: function () {
            this._super(), this.uiDialog.addClass(this.options.dialogClass);
          },
          _setOption: function (a, b) {
            'dialogClass' === a &&
              this.uiDialog.removeClass(this.options.dialogClass).addClass(b),
              this._superApply(arguments);
          }
        }),
      a.ui.dialog,
      a.widget('ui.droppable', {
        version: '1.12.1',
        widgetEventPrefix: 'drop',
        options: {
          accept: '*',
          addClasses: !0,
          greedy: !1,
          scope: 'default',
          tolerance: 'intersect',
          activate: null,
          deactivate: null,
          drop: null,
          out: null,
          over: null
        },
        _create: function () {
          var b,
            c = this.options,
            d = c.accept;
          (this.isover = !1),
            (this.isout = !0),
            (this.accept = a.isFunction(d)
              ? d
              : function (a) {
                  return a.is(d);
                }),
            (this.proportions = function () {
              return arguments.length
                ? void (b = arguments[0])
                : b ||
                    (b = {
                      width: this.element[0].offsetWidth,
                      height: this.element[0].offsetHeight
                    });
            }),
            this._addToManager(c.scope),
            c.addClasses && this._addClass('ui-droppable');
        },
        _addToManager: function (b) {
          (a.ui.ddmanager.droppables[b] = a.ui.ddmanager.droppables[b] || []),
            a.ui.ddmanager.droppables[b].push(this);
        },
        _splice: function (a) {
          for (var b = 0; a.length > b; b++) a[b] === this && a.splice(b, 1);
        },
        _destroy: function () {
          var b = a.ui.ddmanager.droppables[this.options.scope];
          this._splice(b);
        },
        _setOption: function (b, c) {
          if ('accept' === b)
            this.accept = a.isFunction(c)
              ? c
              : function (a) {
                  return a.is(c);
                };
          else if ('scope' === b) {
            var d = a.ui.ddmanager.droppables[this.options.scope];
            this._splice(d), this._addToManager(c);
          }
          this._super(b, c);
        },
        _activate: function (b) {
          var c = a.ui.ddmanager.current;
          this._addActiveClass(), c && this._trigger('activate', b, this.ui(c));
        },
        _deactivate: function (b) {
          var c = a.ui.ddmanager.current;
          this._removeActiveClass(),
            c && this._trigger('deactivate', b, this.ui(c));
        },
        _over: function (b) {
          var c = a.ui.ddmanager.current;
          c &&
            (c.currentItem || c.element)[0] !== this.element[0] &&
            this.accept.call(this.element[0], c.currentItem || c.element) &&
            (this._addHoverClass(), this._trigger('over', b, this.ui(c)));
        },
        _out: function (b) {
          var c = a.ui.ddmanager.current;
          c &&
            (c.currentItem || c.element)[0] !== this.element[0] &&
            this.accept.call(this.element[0], c.currentItem || c.element) &&
            (this._removeHoverClass(), this._trigger('out', b, this.ui(c)));
        },
        _drop: function (b, c) {
          var d = c || a.ui.ddmanager.current,
            e = !1;
          return (
            !(!d || (d.currentItem || d.element)[0] === this.element[0]) &&
            (this.element
              .find(':data(ui-droppable)')
              .not('.ui-draggable-dragging')
              .each(function () {
                var c = a(this).droppable('instance');
                return c.options.greedy &&
                  !c.options.disabled &&
                  c.options.scope === d.options.scope &&
                  c.accept.call(c.element[0], d.currentItem || d.element) &&
                  r(
                    d,
                    a.extend(c, { offset: c.element.offset() }),
                    c.options.tolerance,
                    b
                  )
                  ? ((e = !0), !1)
                  : void 0;
              }),
            !e &&
              !!this.accept.call(this.element[0], d.currentItem || d.element) &&
              (this._removeActiveClass(),
              this._removeHoverClass(),
              this._trigger('drop', b, this.ui(d)),
              this.element))
          );
        },
        ui: function (a) {
          return {
            draggable: a.currentItem || a.element,
            helper: a.helper,
            position: a.position,
            offset: a.positionAbs
          };
        },
        _addHoverClass: function () {
          this._addClass('ui-droppable-hover');
        },
        _removeHoverClass: function () {
          this._removeClass('ui-droppable-hover');
        },
        _addActiveClass: function () {
          this._addClass('ui-droppable-active');
        },
        _removeActiveClass: function () {
          this._removeClass('ui-droppable-active');
        }
      });
    var r = (a.ui.intersect = (function () {
      function a(a, b, c) {
        return a >= b && b + c > a;
      }
      return function (b, c, d, e) {
        if (!c.offset) return !1;
        var f = (b.positionAbs || b.position.absolute).left + b.margins.left,
          g = (b.positionAbs || b.position.absolute).top + b.margins.top,
          h = f + b.helperProportions.width,
          i = g + b.helperProportions.height,
          j = c.offset.left,
          k = c.offset.top,
          l = j + c.proportions().width,
          m = k + c.proportions().height;
        switch (d) {
          case 'fit':
            return f >= j && l >= h && g >= k && m >= i;
          case 'intersect':
            return (
              f + b.helperProportions.width / 2 > j &&
              l > h - b.helperProportions.width / 2 &&
              g + b.helperProportions.height / 2 > k &&
              m > i - b.helperProportions.height / 2
            );
          case 'pointer':
            return (
              a(e.pageY, k, c.proportions().height) &&
              a(e.pageX, j, c.proportions().width)
            );
          case 'touch':
            return (
              ((g >= k && m >= g) || (i >= k && m >= i) || (k > g && i > m)) &&
              ((f >= j && l >= f) || (h >= j && l >= h) || (j > f && h > l))
            );
          default:
            return !1;
        }
      };
    })());
    (a.ui.ddmanager = {
      current: null,
      droppables: { default: [] },
      prepareOffsets: function (b, c) {
        var d,
          e,
          f = a.ui.ddmanager.droppables[b.options.scope] || [],
          g = c ? c.type : null,
          h = (b.currentItem || b.element)
            .find(':data(ui-droppable)')
            .addBack();
        a: for (d = 0; f.length > d; d++)
          if (
            !(
              f[d].options.disabled ||
              (b &&
                !f[d].accept.call(f[d].element[0], b.currentItem || b.element))
            )
          ) {
            for (e = 0; h.length > e; e++)
              if (h[e] === f[d].element[0]) {
                f[d].proportions().height = 0;
                continue a;
              }
            (f[d].visible = 'none' !== f[d].element.css('display')),
              f[d].visible &&
                ('mousedown' === g && f[d]._activate.call(f[d], c),
                (f[d].offset = f[d].element.offset()),
                f[d].proportions({
                  width: f[d].element[0].offsetWidth,
                  height: f[d].element[0].offsetHeight
                }));
          }
      },
      drop: function (b, c) {
        var d = !1;
        return (
          a.each(
            (a.ui.ddmanager.droppables[b.options.scope] || []).slice(),
            function () {
              this.options &&
                (!this.options.disabled &&
                  this.visible &&
                  r(b, this, this.options.tolerance, c) &&
                  (d = this._drop.call(this, c) || d),
                !this.options.disabled &&
                  this.visible &&
                  this.accept.call(
                    this.element[0],
                    b.currentItem || b.element
                  ) &&
                  ((this.isout = !0),
                  (this.isover = !1),
                  this._deactivate.call(this, c)));
            }
          ),
          d
        );
      },
      dragStart: function (b, c) {
        b.element.parentsUntil('body').on('scroll.droppable', function () {
          b.options.refreshPositions || a.ui.ddmanager.prepareOffsets(b, c);
        });
      },
      drag: function (b, c) {
        b.options.refreshPositions && a.ui.ddmanager.prepareOffsets(b, c),
          a.each(a.ui.ddmanager.droppables[b.options.scope] || [], function () {
            if (!this.options.disabled && !this.greedyChild && this.visible) {
              var d,
                e,
                f,
                g = r(b, this, this.options.tolerance, c),
                h =
                  !g && this.isover
                    ? 'isout'
                    : g && !this.isover
                      ? 'isover'
                      : null;
              h &&
                (this.options.greedy &&
                  ((e = this.options.scope),
                  (f = this.element
                    .parents(':data(ui-droppable)')
                    .filter(function () {
                      return a(this).droppable('instance').options.scope === e;
                    })),
                  f.length &&
                    ((d = a(f[0]).droppable('instance')),
                    (d.greedyChild = 'isover' === h))),
                d &&
                  'isover' === h &&
                  ((d.isover = !1), (d.isout = !0), d._out.call(d, c)),
                (this[h] = !0),
                (this['isout' === h ? 'isover' : 'isout'] = !1),
                this['isover' === h ? '_over' : '_out'].call(this, c),
                d &&
                  'isout' === h &&
                  ((d.isout = !1), (d.isover = !0), d._over.call(d, c)));
            }
          });
      },
      dragStop: function (b, c) {
        b.element.parentsUntil('body').off('scroll.droppable'),
          b.options.refreshPositions || a.ui.ddmanager.prepareOffsets(b, c);
      }
    }),
      !1 !== a.uiBackCompat &&
        a.widget('ui.droppable', a.ui.droppable, {
          options: { hoverClass: !1, activeClass: !1 },
          _addActiveClass: function () {
            this._super(),
              this.options.activeClass &&
                this.element.addClass(this.options.activeClass);
          },
          _removeActiveClass: function () {
            this._super(),
              this.options.activeClass &&
                this.element.removeClass(this.options.activeClass);
          },
          _addHoverClass: function () {
            this._super(),
              this.options.hoverClass &&
                this.element.addClass(this.options.hoverClass);
          },
          _removeHoverClass: function () {
            this._super(),
              this.options.hoverClass &&
                this.element.removeClass(this.options.hoverClass);
          }
        }),
      a.ui.droppable,
      a.widget('ui.progressbar', {
        version: '1.12.1',
        options: {
          classes: {
            'ui-progressbar': 'ui-corner-all',
            'ui-progressbar-value': 'ui-corner-left',
            'ui-progressbar-complete': 'ui-corner-right'
          },
          max: 100,
          value: 0,
          change: null,
          complete: null
        },
        min: 0,
        _create: function () {
          (this.oldValue = this.options.value = this._constrainedValue()),
            this.element.attr({
              role: 'progressbar',
              'aria-valuemin': this.min
            }),
            this._addClass('ui-progressbar', 'ui-widget ui-widget-content'),
            (this.valueDiv = a('<div>').appendTo(this.element)),
            this._addClass(
              this.valueDiv,
              'ui-progressbar-value',
              'ui-widget-header'
            ),
            this._refreshValue();
        },
        _destroy: function () {
          this.element.removeAttr(
            'role aria-valuemin aria-valuemax aria-valuenow'
          ),
            this.valueDiv.remove();
        },
        value: function (a) {
          return void 0 === a
            ? this.options.value
            : ((this.options.value = this._constrainedValue(a)),
              void this._refreshValue());
        },
        _constrainedValue: function (a) {
          return (
            void 0 === a && (a = this.options.value),
            (this.indeterminate = !1 === a),
            'number' != typeof a && (a = 0),
            !this.indeterminate &&
              Math.min(this.options.max, Math.max(this.min, a))
          );
        },
        _setOptions: function (a) {
          var b = a.value;
          delete a.value,
            this._super(a),
            (this.options.value = this._constrainedValue(b)),
            this._refreshValue();
        },
        _setOption: function (a, b) {
          'max' === a && (b = Math.max(this.min, b)), this._super(a, b);
        },
        _setOptionDisabled: function (a) {
          this._super(a),
            this.element.attr('aria-disabled', a),
            this._toggleClass(null, 'ui-state-disabled', !!a);
        },
        _percentage: function () {
          return this.indeterminate
            ? 100
            : (100 * (this.options.value - this.min)) /
                (this.options.max - this.min);
        },
        _refreshValue: function () {
          var b = this.options.value,
            c = this._percentage();
          this.valueDiv
            .toggle(this.indeterminate || b > this.min)
            .width(c.toFixed(0) + '%'),
            this._toggleClass(
              this.valueDiv,
              'ui-progressbar-complete',
              null,
              b === this.options.max
            )._toggleClass(
              'ui-progressbar-indeterminate',
              null,
              this.indeterminate
            ),
            this.indeterminate
              ? (this.element.removeAttr('aria-valuenow'),
                this.overlayDiv ||
                  ((this.overlayDiv = a('<div>').appendTo(this.valueDiv)),
                  this._addClass(this.overlayDiv, 'ui-progressbar-overlay')))
              : (this.element.attr({
                  'aria-valuemax': this.options.max,
                  'aria-valuenow': b
                }),
                this.overlayDiv &&
                  (this.overlayDiv.remove(), (this.overlayDiv = null))),
            this.oldValue !== b &&
              ((this.oldValue = b), this._trigger('change')),
            b === this.options.max && this._trigger('complete');
        }
      }),
      a.widget('ui.selectable', a.ui.mouse, {
        version: '1.12.1',
        options: {
          appendTo: 'body',
          autoRefresh: !0,
          distance: 0,
          filter: '*',
          tolerance: 'touch',
          selected: null,
          selecting: null,
          start: null,
          stop: null,
          unselected: null,
          unselecting: null
        },
        _create: function () {
          var b = this;
          this._addClass('ui-selectable'),
            (this.dragged = !1),
            (this.refresh = function () {
              (b.elementPos = a(b.element[0]).offset()),
                (b.selectees = a(b.options.filter, b.element[0])),
                b._addClass(b.selectees, 'ui-selectee'),
                b.selectees.each(function () {
                  var c = a(this),
                    d = c.offset(),
                    e = {
                      left: d.left - b.elementPos.left,
                      top: d.top - b.elementPos.top
                    };
                  a.data(this, 'selectable-item', {
                    element: this,
                    $element: c,
                    left: e.left,
                    top: e.top,
                    right: e.left + c.outerWidth(),
                    bottom: e.top + c.outerHeight(),
                    startselected: !1,
                    selected: c.hasClass('ui-selected'),
                    selecting: c.hasClass('ui-selecting'),
                    unselecting: c.hasClass('ui-unselecting')
                  });
                });
            }),
            this.refresh(),
            this._mouseInit(),
            (this.helper = a('<div>')),
            this._addClass(this.helper, 'ui-selectable-helper');
        },
        _destroy: function () {
          this.selectees.removeData('selectable-item'), this._mouseDestroy();
        },
        _mouseStart: function (b) {
          var c = this,
            d = this.options;
          (this.opos = [b.pageX, b.pageY]),
            (this.elementPos = a(this.element[0]).offset()),
            this.options.disabled ||
              ((this.selectees = a(d.filter, this.element[0])),
              this._trigger('start', b),
              a(d.appendTo).append(this.helper),
              this.helper.css({
                left: b.pageX,
                top: b.pageY,
                width: 0,
                height: 0
              }),
              d.autoRefresh && this.refresh(),
              this.selectees.filter('.ui-selected').each(function () {
                var d = a.data(this, 'selectable-item');
                (d.startselected = !0),
                  b.metaKey ||
                    b.ctrlKey ||
                    (c._removeClass(d.$element, 'ui-selected'),
                    (d.selected = !1),
                    c._addClass(d.$element, 'ui-unselecting'),
                    (d.unselecting = !0),
                    c._trigger('unselecting', b, { unselecting: d.element }));
              }),
              a(b.target)
                .parents()
                .addBack()
                .each(function () {
                  var d,
                    e = a.data(this, 'selectable-item');
                  return e
                    ? ((d =
                        (!b.metaKey && !b.ctrlKey) ||
                        !e.$element.hasClass('ui-selected')),
                      c
                        ._removeClass(
                          e.$element,
                          d ? 'ui-unselecting' : 'ui-selected'
                        )
                        ._addClass(
                          e.$element,
                          d ? 'ui-selecting' : 'ui-unselecting'
                        ),
                      (e.unselecting = !d),
                      (e.selecting = d),
                      (e.selected = d),
                      d
                        ? c._trigger('selecting', b, { selecting: e.element })
                        : c._trigger('unselecting', b, {
                            unselecting: e.element
                          }),
                      !1)
                    : void 0;
                }));
        },
        _mouseDrag: function (b) {
          if (((this.dragged = !0), !this.options.disabled)) {
            var c,
              d = this,
              e = this.options,
              f = this.opos[0],
              g = this.opos[1],
              h = b.pageX,
              i = b.pageY;
            return (
              f > h && ((c = h), (h = f), (f = c)),
              g > i && ((c = i), (i = g), (g = c)),
              this.helper.css({ left: f, top: g, width: h - f, height: i - g }),
              this.selectees.each(function () {
                var c = a.data(this, 'selectable-item'),
                  j = !1,
                  k = {};
                c &&
                  c.element !== d.element[0] &&
                  ((k.left = c.left + d.elementPos.left),
                  (k.right = c.right + d.elementPos.left),
                  (k.top = c.top + d.elementPos.top),
                  (k.bottom = c.bottom + d.elementPos.top),
                  'touch' === e.tolerance
                    ? (j = !(
                        k.left > h ||
                        f > k.right ||
                        k.top > i ||
                        g > k.bottom
                      ))
                    : 'fit' === e.tolerance &&
                      (j =
                        k.left > f && h > k.right && k.top > g && i > k.bottom),
                  j
                    ? (c.selected &&
                        (d._removeClass(c.$element, 'ui-selected'),
                        (c.selected = !1)),
                      c.unselecting &&
                        (d._removeClass(c.$element, 'ui-unselecting'),
                        (c.unselecting = !1)),
                      c.selecting ||
                        (d._addClass(c.$element, 'ui-selecting'),
                        (c.selecting = !0),
                        d._trigger('selecting', b, { selecting: c.element })))
                    : (c.selecting &&
                        ((b.metaKey || b.ctrlKey) && c.startselected
                          ? (d._removeClass(c.$element, 'ui-selecting'),
                            (c.selecting = !1),
                            d._addClass(c.$element, 'ui-selected'),
                            (c.selected = !0))
                          : (d._removeClass(c.$element, 'ui-selecting'),
                            (c.selecting = !1),
                            c.startselected &&
                              (d._addClass(c.$element, 'ui-unselecting'),
                              (c.unselecting = !0)),
                            d._trigger('unselecting', b, {
                              unselecting: c.element
                            }))),
                      c.selected &&
                        (b.metaKey ||
                          b.ctrlKey ||
                          c.startselected ||
                          (d._removeClass(c.$element, 'ui-selected'),
                          (c.selected = !1),
                          d._addClass(c.$element, 'ui-unselecting'),
                          (c.unselecting = !0),
                          d._trigger('unselecting', b, {
                            unselecting: c.element
                          })))));
              }),
              !1
            );
          }
        },
        _mouseStop: function (b) {
          var c = this;
          return (
            (this.dragged = !1),
            a('.ui-unselecting', this.element[0]).each(function () {
              var d = a.data(this, 'selectable-item');
              c._removeClass(d.$element, 'ui-unselecting'),
                (d.unselecting = !1),
                (d.startselected = !1),
                c._trigger('unselected', b, { unselected: d.element });
            }),
            a('.ui-selecting', this.element[0]).each(function () {
              var d = a.data(this, 'selectable-item');
              c
                ._removeClass(d.$element, 'ui-selecting')
                ._addClass(d.$element, 'ui-selected'),
                (d.selecting = !1),
                (d.selected = !0),
                (d.startselected = !0),
                c._trigger('selected', b, { selected: d.element });
            }),
            this._trigger('stop', b),
            this.helper.remove(),
            !1
          );
        }
      }),
      a.widget('ui.selectmenu', [
        a.ui.formResetMixin,
        {
          version: '1.12.1',
          defaultElement: '<select>',
          options: {
            appendTo: null,
            classes: {
              'ui-selectmenu-button-open': 'ui-corner-top',
              'ui-selectmenu-button-closed': 'ui-corner-all'
            },
            disabled: null,
            icons: { button: 'ui-icon-triangle-1-s' },
            position: { my: 'left top', at: 'left bottom', collision: 'none' },
            width: !1,
            change: null,
            close: null,
            focus: null,
            open: null,
            select: null
          },
          _create: function () {
            var b = this.element.uniqueId().attr('id');
            (this.ids = {
              element: b,
              button: b + '-button',
              menu: b + '-menu'
            }),
              this._drawButton(),
              this._drawMenu(),
              this._bindFormResetHandler(),
              (this._rendered = !1),
              (this.menuItems = a());
          },
          _drawButton: function () {
            var b,
              c = this,
              d = this._parseOption(
                this.element.find('option:selected'),
                this.element[0].selectedIndex
              );
            (this.labels = this.element.labels().attr('for', this.ids.button)),
              this._on(this.labels, {
                click: function (a) {
                  this.button.focus(), a.preventDefault();
                }
              }),
              this.element.hide(),
              (this.button = a('<span>', {
                tabindex: this.options.disabled ? -1 : 0,
                id: this.ids.button,
                role: 'combobox',
                'aria-expanded': 'false',
                'aria-autocomplete': 'list',
                'aria-owns': this.ids.menu,
                'aria-haspopup': 'true',
                title: this.element.attr('title')
              }).insertAfter(this.element)),
              this._addClass(
                this.button,
                'ui-selectmenu-button ui-selectmenu-button-closed',
                'ui-button ui-widget'
              ),
              (b = a('<span>').appendTo(this.button)),
              this._addClass(
                b,
                'ui-selectmenu-icon',
                'ui-icon ' + this.options.icons.button
              ),
              (this.buttonItem = this._renderButtonItem(d).appendTo(
                this.button
              )),
              !1 !== this.options.width && this._resizeButton(),
              this._on(this.button, this._buttonEvents),
              this.button.one('focusin', function () {
                c._rendered || c._refreshMenu();
              });
          },
          _drawMenu: function () {
            var b = this;
            (this.menu = a('<ul>', {
              'aria-hidden': 'true',
              'aria-labelledby': this.ids.button,
              id: this.ids.menu
            })),
              (this.menuWrap = a('<div>').append(this.menu)),
              this._addClass(this.menuWrap, 'ui-selectmenu-menu', 'ui-front'),
              this.menuWrap.appendTo(this._appendTo()),
              (this.menuInstance = this.menu
                .menu({
                  classes: { 'ui-menu': 'ui-corner-bottom' },
                  role: 'listbox',
                  select: function (a, c) {
                    a.preventDefault(),
                      b._setSelection(),
                      b._select(c.item.data('ui-selectmenu-item'), a);
                  },
                  focus: function (a, c) {
                    var d = c.item.data('ui-selectmenu-item');
                    null != b.focusIndex &&
                      d.index !== b.focusIndex &&
                      (b._trigger('focus', a, { item: d }),
                      b.isOpen || b._select(d, a)),
                      (b.focusIndex = d.index),
                      b.button.attr(
                        'aria-activedescendant',
                        b.menuItems.eq(d.index).attr('id')
                      );
                  }
                })
                .menu('instance')),
              this.menuInstance._off(this.menu, 'mouseleave'),
              (this.menuInstance._closeOnDocumentClick = function () {
                return !1;
              }),
              (this.menuInstance._isDivider = function () {
                return !1;
              });
          },
          refresh: function () {
            this._refreshMenu(),
              this.buttonItem.replaceWith(
                (this.buttonItem = this._renderButtonItem(
                  this._getSelectedItem().data('ui-selectmenu-item') || {}
                ))
              ),
              null === this.options.width && this._resizeButton();
          },
          _refreshMenu: function () {
            var a,
              b = this.element.find('option');
            this.menu.empty(),
              this._parseOptions(b),
              this._renderMenu(this.menu, this.items),
              this.menuInstance.refresh(),
              (this.menuItems = this.menu
                .find('li')
                .not('.ui-selectmenu-optgroup')
                .find('.ui-menu-item-wrapper')),
              (this._rendered = !0),
              b.length &&
                ((a = this._getSelectedItem()),
                this.menuInstance.focus(null, a),
                this._setAria(a.data('ui-selectmenu-item')),
                this._setOption('disabled', this.element.prop('disabled')));
          },
          open: function (a) {
            this.options.disabled ||
              (this._rendered
                ? (this._removeClass(
                    this.menu.find('.ui-state-active'),
                    null,
                    'ui-state-active'
                  ),
                  this.menuInstance.focus(null, this._getSelectedItem()))
                : this._refreshMenu(),
              this.menuItems.length &&
                ((this.isOpen = !0),
                this._toggleAttr(),
                this._resizeMenu(),
                this._position(),
                this._on(this.document, this._documentClick),
                this._trigger('open', a)));
          },
          _position: function () {
            this.menuWrap.position(
              a.extend({ of: this.button }, this.options.position)
            );
          },
          close: function (a) {
            this.isOpen &&
              ((this.isOpen = !1),
              this._toggleAttr(),
              (this.range = null),
              this._off(this.document),
              this._trigger('close', a));
          },
          widget: function () {
            return this.button;
          },
          menuWidget: function () {
            return this.menu;
          },
          _renderButtonItem: function (b) {
            var c = a('<span>');
            return (
              this._setText(c, b.label),
              this._addClass(c, 'ui-selectmenu-text'),
              c
            );
          },
          _renderMenu: function (b, c) {
            var d = this,
              e = '';
            a.each(c, function (c, f) {
              var g;
              f.optgroup !== e &&
                ((g = a('<li>', { text: f.optgroup })),
                d._addClass(
                  g,
                  'ui-selectmenu-optgroup',
                  'ui-menu-divider' +
                    (f.element.parent('optgroup').prop('disabled')
                      ? ' ui-state-disabled'
                      : '')
                ),
                g.appendTo(b),
                (e = f.optgroup)),
                d._renderItemData(b, f);
            });
          },
          _renderItemData: function (a, b) {
            return this._renderItem(a, b).data('ui-selectmenu-item', b);
          },
          _renderItem: function (b, c) {
            var d = a('<li>'),
              e = a('<div>', { title: c.element.attr('title') });
            return (
              c.disabled && this._addClass(d, null, 'ui-state-disabled'),
              this._setText(e, c.label),
              d.append(e).appendTo(b)
            );
          },
          _setText: function (a, b) {
            b ? a.text(b) : a.html('&#160;');
          },
          _move: function (a, b) {
            var c,
              d,
              e = '.ui-menu-item';
            this.isOpen
              ? (c = this.menuItems.eq(this.focusIndex).parent('li'))
              : ((c = this.menuItems
                  .eq(this.element[0].selectedIndex)
                  .parent('li')),
                (e += ':not(.ui-state-disabled)')),
              (d =
                'first' === a || 'last' === a
                  ? c['first' === a ? 'prevAll' : 'nextAll'](e).eq(-1)
                  : c[a + 'All'](e).eq(0)),
              d.length && this.menuInstance.focus(b, d);
          },
          _getSelectedItem: function () {
            return this.menuItems
              .eq(this.element[0].selectedIndex)
              .parent('li');
          },
          _toggle: function (a) {
            this[this.isOpen ? 'close' : 'open'](a);
          },
          _setSelection: function () {
            var a;
            this.range &&
              (window.getSelection
                ? ((a = window.getSelection()),
                  a.removeAllRanges(),
                  a.addRange(this.range))
                : this.range.select(),
              this.button.focus());
          },
          _documentClick: {
            mousedown: function (b) {
              this.isOpen &&
                (a(b.target).closest(
                  '.ui-selectmenu-menu, #' +
                    a.ui.escapeSelector(this.ids.button)
                ).length ||
                  this.close(b));
            }
          },
          _buttonEvents: {
            mousedown: function () {
              var a;
              window.getSelection
                ? ((a = window.getSelection()),
                  a.rangeCount && (this.range = a.getRangeAt(0)))
                : (this.range = document.selection.createRange());
            },
            click: function (a) {
              this._setSelection(), this._toggle(a);
            },
            keydown: function (b) {
              var c = !0;
              switch (b.keyCode) {
                case a.ui.keyCode.TAB:
                case a.ui.keyCode.ESCAPE:
                  this.close(b), (c = !1);
                  break;
                case a.ui.keyCode.ENTER:
                  this.isOpen && this._selectFocusedItem(b);
                  break;
                case a.ui.keyCode.UP:
                  b.altKey ? this._toggle(b) : this._move('prev', b);
                  break;
                case a.ui.keyCode.DOWN:
                  b.altKey ? this._toggle(b) : this._move('next', b);
                  break;
                case a.ui.keyCode.SPACE:
                  this.isOpen ? this._selectFocusedItem(b) : this._toggle(b);
                  break;
                case a.ui.keyCode.LEFT:
                  this._move('prev', b);
                  break;
                case a.ui.keyCode.RIGHT:
                  this._move('next', b);
                  break;
                case a.ui.keyCode.HOME:
                case a.ui.keyCode.PAGE_UP:
                  this._move('first', b);
                  break;
                case a.ui.keyCode.END:
                case a.ui.keyCode.PAGE_DOWN:
                  this._move('last', b);
                  break;
                default:
                  this.menu.trigger(b), (c = !1);
              }
              c && b.preventDefault();
            }
          },
          _selectFocusedItem: function (a) {
            var b = this.menuItems.eq(this.focusIndex).parent('li');
            b.hasClass('ui-state-disabled') ||
              this._select(b.data('ui-selectmenu-item'), a);
          },
          _select: function (a, b) {
            var c = this.element[0].selectedIndex;
            (this.element[0].selectedIndex = a.index),
              this.buttonItem.replaceWith(
                (this.buttonItem = this._renderButtonItem(a))
              ),
              this._setAria(a),
              this._trigger('select', b, { item: a }),
              a.index !== c && this._trigger('change', b, { item: a }),
              this.close(b);
          },
          _setAria: function (a) {
            var b = this.menuItems.eq(a.index).attr('id');
            this.button.attr({
              'aria-labelledby': b,
              'aria-activedescendant': b
            }),
              this.menu.attr('aria-activedescendant', b);
          },
          _setOption: function (a, b) {
            if ('icons' === a) {
              var c = this.button.find('span.ui-icon');
              this._removeClass(c, null, this.options.icons.button)._addClass(
                c,
                null,
                b.button
              );
            }
            this._super(a, b),
              'appendTo' === a && this.menuWrap.appendTo(this._appendTo()),
              'width' === a && this._resizeButton();
          },
          _setOptionDisabled: function (a) {
            this._super(a),
              this.menuInstance.option('disabled', a),
              this.button.attr('aria-disabled', a),
              this._toggleClass(this.button, null, 'ui-state-disabled', a),
              this.element.prop('disabled', a),
              a
                ? (this.button.attr('tabindex', -1), this.close())
                : this.button.attr('tabindex', 0);
          },
          _appendTo: function () {
            var b = this.options.appendTo;
            return (
              b &&
                (b =
                  b.jquery || b.nodeType ? a(b) : this.document.find(b).eq(0)),
              (b && b[0]) || (b = this.element.closest('.ui-front, dialog')),
              b.length || (b = this.document[0].body),
              b
            );
          },
          _toggleAttr: function () {
            this.button.attr('aria-expanded', this.isOpen),
              this._removeClass(
                this.button,
                'ui-selectmenu-button-' + (this.isOpen ? 'closed' : 'open')
              )
                ._addClass(
                  this.button,
                  'ui-selectmenu-button-' + (this.isOpen ? 'open' : 'closed')
                )
                ._toggleClass(
                  this.menuWrap,
                  'ui-selectmenu-open',
                  null,
                  this.isOpen
                ),
              this.menu.attr('aria-hidden', !this.isOpen);
          },
          _resizeButton: function () {
            var a = this.options.width;
            return !1 === a
              ? void this.button.css('width', '')
              : (null === a &&
                  ((a = this.element.show().outerWidth()), this.element.hide()),
                void this.button.outerWidth(a));
          },
          _resizeMenu: function () {
            this.menu.outerWidth(
              Math.max(
                this.button.outerWidth(),
                this.menu.width('').outerWidth() + 1
              )
            );
          },
          _getCreateOptions: function () {
            var a = this._super();
            return (a.disabled = this.element.prop('disabled')), a;
          },
          _parseOptions: function (b) {
            var c = this,
              d = [];
            b.each(function (b, e) {
              d.push(c._parseOption(a(e), b));
            }),
              (this.items = d);
          },
          _parseOption: function (a, b) {
            var c = a.parent('optgroup');
            return {
              element: a,
              index: b,
              value: a.val(),
              label: a.text(),
              optgroup: c.attr('label') || '',
              disabled: c.prop('disabled') || a.prop('disabled')
            };
          },
          _destroy: function () {
            this._unbindFormResetHandler(),
              this.menuWrap.remove(),
              this.button.remove(),
              this.element.show(),
              this.element.removeUniqueId(),
              this.labels.attr('for', this.ids.element);
          }
        }
      ]),
      a.widget('ui.slider', a.ui.mouse, {
        version: '1.12.1',
        widgetEventPrefix: 'slide',
        options: {
          animate: !1,
          classes: {
            'ui-slider': 'ui-corner-all',
            'ui-slider-handle': 'ui-corner-all',
            'ui-slider-range': 'ui-corner-all ui-widget-header'
          },
          distance: 0,
          max: 100,
          min: 0,
          orientation: 'horizontal',
          range: !1,
          step: 1,
          value: 0,
          values: null,
          change: null,
          slide: null,
          start: null,
          stop: null
        },
        numPages: 5,
        _create: function () {
          (this._keySliding = !1),
            (this._mouseSliding = !1),
            (this._animateOff = !0),
            (this._handleIndex = null),
            this._detectOrientation(),
            this._mouseInit(),
            this._calculateNewMax(),
            this._addClass(
              'ui-slider ui-slider-' + this.orientation,
              'ui-widget ui-widget-content'
            ),
            this._refresh(),
            (this._animateOff = !1);
        },
        _refresh: function () {
          this._createRange(),
            this._createHandles(),
            this._setupEvents(),
            this._refreshValue();
        },
        _createHandles: function () {
          var b,
            c,
            d = this.options,
            e = this.element.find('.ui-slider-handle'),
            f = [];
          for (
            c = (d.values && d.values.length) || 1,
              e.length > c && (e.slice(c).remove(), (e = e.slice(0, c))),
              b = e.length;
            c > b;
            b++
          )
            f.push("<span tabindex='0'></span>");
          (this.handles = e.add(a(f.join('')).appendTo(this.element))),
            this._addClass(
              this.handles,
              'ui-slider-handle',
              'ui-state-default'
            ),
            (this.handle = this.handles.eq(0)),
            this.handles.each(function (b) {
              a(this).data('ui-slider-handle-index', b).attr('tabIndex', 0);
            });
        },
        _createRange: function () {
          var b = this.options;
          b.range
            ? (!0 === b.range &&
                (b.values
                  ? b.values.length && 2 !== b.values.length
                    ? (b.values = [b.values[0], b.values[0]])
                    : a.isArray(b.values) && (b.values = b.values.slice(0))
                  : (b.values = [this._valueMin(), this._valueMin()])),
              this.range && this.range.length
                ? (this._removeClass(
                    this.range,
                    'ui-slider-range-min ui-slider-range-max'
                  ),
                  this.range.css({ left: '', bottom: '' }))
                : ((this.range = a('<div>').appendTo(this.element)),
                  this._addClass(this.range, 'ui-slider-range')),
              ('min' === b.range || 'max' === b.range) &&
                this._addClass(this.range, 'ui-slider-range-' + b.range))
            : (this.range && this.range.remove(), (this.range = null));
        },
        _setupEvents: function () {
          this._off(this.handles),
            this._on(this.handles, this._handleEvents),
            this._hoverable(this.handles),
            this._focusable(this.handles);
        },
        _destroy: function () {
          this.handles.remove(),
            this.range && this.range.remove(),
            this._mouseDestroy();
        },
        _mouseCapture: function (b) {
          var c,
            d,
            e,
            f,
            g,
            h,
            i,
            j = this,
            k = this.options;
          return (
            !k.disabled &&
            ((this.elementSize = {
              width: this.element.outerWidth(),
              height: this.element.outerHeight()
            }),
            (this.elementOffset = this.element.offset()),
            (c = { x: b.pageX, y: b.pageY }),
            (d = this._normValueFromMouse(c)),
            (e = this._valueMax() - this._valueMin() + 1),
            this.handles.each(function (b) {
              var c = Math.abs(d - j.values(b));
              (e > c ||
                (e === c &&
                  (b === j._lastChangedValue || j.values(b) === k.min))) &&
                ((e = c), (f = a(this)), (g = b));
            }),
            !1 !== this._start(b, g) &&
              ((this._mouseSliding = !0),
              (this._handleIndex = g),
              this._addClass(f, null, 'ui-state-active'),
              f.trigger('focus'),
              (h = f.offset()),
              (i = !a(b.target).parents().addBack().is('.ui-slider-handle')),
              (this._clickOffset = i
                ? { left: 0, top: 0 }
                : {
                    left: b.pageX - h.left - f.width() / 2,
                    top:
                      b.pageY -
                      h.top -
                      f.height() / 2 -
                      (parseInt(f.css('borderTopWidth'), 10) || 0) -
                      (parseInt(f.css('borderBottomWidth'), 10) || 0) +
                      (parseInt(f.css('marginTop'), 10) || 0)
                  }),
              this.handles.hasClass('ui-state-hover') || this._slide(b, g, d),
              (this._animateOff = !0),
              !0))
          );
        },
        _mouseStart: function () {
          return !0;
        },
        _mouseDrag: function (a) {
          var b = { x: a.pageX, y: a.pageY },
            c = this._normValueFromMouse(b);
          return this._slide(a, this._handleIndex, c), !1;
        },
        _mouseStop: function (a) {
          return (
            this._removeClass(this.handles, null, 'ui-state-active'),
            (this._mouseSliding = !1),
            this._stop(a, this._handleIndex),
            this._change(a, this._handleIndex),
            (this._handleIndex = null),
            (this._clickOffset = null),
            (this._animateOff = !1),
            !1
          );
        },
        _detectOrientation: function () {
          this.orientation =
            'vertical' === this.options.orientation ? 'vertical' : 'horizontal';
        },
        _normValueFromMouse: function (a) {
          var b, c, d, e, f;
          return (
            'horizontal' === this.orientation
              ? ((b = this.elementSize.width),
                (c =
                  a.x -
                  this.elementOffset.left -
                  (this._clickOffset ? this._clickOffset.left : 0)))
              : ((b = this.elementSize.height),
                (c =
                  a.y -
                  this.elementOffset.top -
                  (this._clickOffset ? this._clickOffset.top : 0))),
            (d = c / b),
            d > 1 && (d = 1),
            0 > d && (d = 0),
            'vertical' === this.orientation && (d = 1 - d),
            (e = this._valueMax() - this._valueMin()),
            (f = this._valueMin() + d * e),
            this._trimAlignValue(f)
          );
        },
        _uiHash: function (a, b, c) {
          var d = {
            handle: this.handles[a],
            handleIndex: a,
            value: void 0 !== b ? b : this.value()
          };
          return (
            this._hasMultipleValues() &&
              ((d.value = void 0 !== b ? b : this.values(a)),
              (d.values = c || this.values())),
            d
          );
        },
        _hasMultipleValues: function () {
          return this.options.values && this.options.values.length;
        },
        _start: function (a, b) {
          return this._trigger('start', a, this._uiHash(b));
        },
        _slide: function (a, b, c) {
          var d,
            e = this.value(),
            f = this.values();
          this._hasMultipleValues() &&
            ((d = this.values(b ? 0 : 1)),
            (e = this.values(b)),
            2 === this.options.values.length &&
              !0 === this.options.range &&
              (c = 0 === b ? Math.min(d, c) : Math.max(d, c)),
            (f[b] = c)),
            c !== e &&
              !1 !== this._trigger('slide', a, this._uiHash(b, c, f)) &&
              (this._hasMultipleValues() ? this.values(b, c) : this.value(c));
        },
        _stop: function (a, b) {
          this._trigger('stop', a, this._uiHash(b));
        },
        _change: function (a, b) {
          this._keySliding ||
            this._mouseSliding ||
            ((this._lastChangedValue = b),
            this._trigger('change', a, this._uiHash(b)));
        },
        value: function (a) {
          return arguments.length
            ? ((this.options.value = this._trimAlignValue(a)),
              this._refreshValue(),
              void this._change(null, 0))
            : this._value();
        },
        values: function (b, c) {
          var d, e, f;
          if (arguments.length > 1)
            return (
              (this.options.values[b] = this._trimAlignValue(c)),
              this._refreshValue(),
              void this._change(null, b)
            );
          if (!arguments.length) return this._values();
          if (!a.isArray(arguments[0]))
            return this._hasMultipleValues() ? this._values(b) : this.value();
          for (
            d = this.options.values, e = arguments[0], f = 0;
            d.length > f;
            f += 1
          )
            (d[f] = this._trimAlignValue(e[f])), this._change(null, f);
          this._refreshValue();
        },
        _setOption: function (b, c) {
          var d,
            e = 0;
          switch (
            ('range' === b &&
              !0 === this.options.range &&
              ('min' === c
                ? ((this.options.value = this._values(0)),
                  (this.options.values = null))
                : 'max' === c &&
                  ((this.options.value = this._values(
                    this.options.values.length - 1
                  )),
                  (this.options.values = null))),
            a.isArray(this.options.values) && (e = this.options.values.length),
            this._super(b, c),
            b)
          ) {
            case 'orientation':
              this._detectOrientation(),
                this._removeClass(
                  'ui-slider-horizontal ui-slider-vertical'
                )._addClass('ui-slider-' + this.orientation),
                this._refreshValue(),
                this.options.range && this._refreshRange(c),
                this.handles.css('horizontal' === c ? 'bottom' : 'left', '');
              break;
            case 'value':
              (this._animateOff = !0),
                this._refreshValue(),
                this._change(null, 0),
                (this._animateOff = !1);
              break;
            case 'values':
              for (
                this._animateOff = !0, this._refreshValue(), d = e - 1;
                d >= 0;
                d--
              )
                this._change(null, d);
              this._animateOff = !1;
              break;
            case 'step':
            case 'min':
            case 'max':
              (this._animateOff = !0),
                this._calculateNewMax(),
                this._refreshValue(),
                (this._animateOff = !1);
              break;
            case 'range':
              (this._animateOff = !0), this._refresh(), (this._animateOff = !1);
          }
        },
        _setOptionDisabled: function (a) {
          this._super(a), this._toggleClass(null, 'ui-state-disabled', !!a);
        },
        _value: function () {
          var a = this.options.value;
          return (a = this._trimAlignValue(a));
        },
        _values: function (a) {
          var b, c, d;
          if (arguments.length)
            return (b = this.options.values[a]), (b = this._trimAlignValue(b));
          if (this._hasMultipleValues()) {
            for (c = this.options.values.slice(), d = 0; c.length > d; d += 1)
              c[d] = this._trimAlignValue(c[d]);
            return c;
          }
          return [];
        },
        _trimAlignValue: function (a) {
          if (this._valueMin() >= a) return this._valueMin();
          if (a >= this._valueMax()) return this._valueMax();
          var b = this.options.step > 0 ? this.options.step : 1,
            c = (a - this._valueMin()) % b,
            d = a - c;
          return (
            2 * Math.abs(c) >= b && (d += c > 0 ? b : -b),
            parseFloat(d.toFixed(5))
          );
        },
        _calculateNewMax: function () {
          var a = this.options.max,
            b = this._valueMin(),
            c = this.options.step;
          (a = Math.round((a - b) / c) * c + b),
            a > this.options.max && (a -= c),
            (this.max = parseFloat(a.toFixed(this._precision())));
        },
        _precision: function () {
          var a = this._precisionOf(this.options.step);
          return (
            null !== this.options.min &&
              (a = Math.max(a, this._precisionOf(this.options.min))),
            a
          );
        },
        _precisionOf: function (a) {
          var b = '' + a,
            c = b.indexOf('.');
          return -1 === c ? 0 : b.length - c - 1;
        },
        _valueMin: function () {
          return this.options.min;
        },
        _valueMax: function () {
          return this.max;
        },
        _refreshRange: function (a) {
          'vertical' === a && this.range.css({ width: '', left: '' }),
            'horizontal' === a && this.range.css({ height: '', bottom: '' });
        },
        _refreshValue: function () {
          var b,
            c,
            d,
            e,
            f,
            g = this.options.range,
            h = this.options,
            i = this,
            j = !this._animateOff && h.animate,
            k = {};
          this._hasMultipleValues()
            ? this.handles.each(function (d) {
                (c =
                  ((i.values(d) - i._valueMin()) /
                    (i._valueMax() - i._valueMin())) *
                  100),
                  (k['horizontal' === i.orientation ? 'left' : 'bottom'] =
                    c + '%'),
                  a(this).stop(1, 1)[j ? 'animate' : 'css'](k, h.animate),
                  !0 === i.options.range &&
                    ('horizontal' === i.orientation
                      ? (0 === d &&
                          i.range
                            .stop(1, 1)
                            [j ? 'animate' : 'css'](
                              { left: c + '%' },
                              h.animate
                            ),
                        1 === d &&
                          i.range[j ? 'animate' : 'css'](
                            { width: c - b + '%' },
                            { queue: !1, duration: h.animate }
                          ))
                      : (0 === d &&
                          i.range
                            .stop(1, 1)
                            [j ? 'animate' : 'css'](
                              { bottom: c + '%' },
                              h.animate
                            ),
                        1 === d &&
                          i.range[j ? 'animate' : 'css'](
                            { height: c - b + '%' },
                            { queue: !1, duration: h.animate }
                          ))),
                  (b = c);
              })
            : ((d = this.value()),
              (e = this._valueMin()),
              (f = this._valueMax()),
              (c = f !== e ? ((d - e) / (f - e)) * 100 : 0),
              (k['horizontal' === this.orientation ? 'left' : 'bottom'] =
                c + '%'),
              this.handle.stop(1, 1)[j ? 'animate' : 'css'](k, h.animate),
              'min' === g &&
                'horizontal' === this.orientation &&
                this.range
                  .stop(1, 1)
                  [j ? 'animate' : 'css']({ width: c + '%' }, h.animate),
              'max' === g &&
                'horizontal' === this.orientation &&
                this.range
                  .stop(1, 1)
                  [j ? 'animate' : 'css']({ width: 100 - c + '%' }, h.animate),
              'min' === g &&
                'vertical' === this.orientation &&
                this.range
                  .stop(1, 1)
                  [j ? 'animate' : 'css']({ height: c + '%' }, h.animate),
              'max' === g &&
                'vertical' === this.orientation &&
                this.range
                  .stop(1, 1)
                  [j ? 'animate' : 'css'](
                    { height: 100 - c + '%' },
                    h.animate
                  ));
        },
        _handleEvents: {
          keydown: function (b) {
            var c,
              d,
              e,
              f = a(b.target).data('ui-slider-handle-index');
            switch (b.keyCode) {
              case a.ui.keyCode.HOME:
              case a.ui.keyCode.END:
              case a.ui.keyCode.PAGE_UP:
              case a.ui.keyCode.PAGE_DOWN:
              case a.ui.keyCode.UP:
              case a.ui.keyCode.RIGHT:
              case a.ui.keyCode.DOWN:
              case a.ui.keyCode.LEFT:
                if (
                  (b.preventDefault(),
                  !this._keySliding &&
                    ((this._keySliding = !0),
                    this._addClass(a(b.target), null, 'ui-state-active'),
                    !1 === this._start(b, f)))
                )
                  return;
            }
            switch (
              ((e = this.options.step),
              (c = d =
                this._hasMultipleValues() ? this.values(f) : this.value()),
              b.keyCode)
            ) {
              case a.ui.keyCode.HOME:
                d = this._valueMin();
                break;
              case a.ui.keyCode.END:
                d = this._valueMax();
                break;
              case a.ui.keyCode.PAGE_UP:
                d = this._trimAlignValue(
                  c + (this._valueMax() - this._valueMin()) / this.numPages
                );
                break;
              case a.ui.keyCode.PAGE_DOWN:
                d = this._trimAlignValue(
                  c - (this._valueMax() - this._valueMin()) / this.numPages
                );
                break;
              case a.ui.keyCode.UP:
              case a.ui.keyCode.RIGHT:
                if (c === this._valueMax()) return;
                d = this._trimAlignValue(c + e);
                break;
              case a.ui.keyCode.DOWN:
              case a.ui.keyCode.LEFT:
                if (c === this._valueMin()) return;
                d = this._trimAlignValue(c - e);
            }
            this._slide(b, f, d);
          },
          keyup: function (b) {
            var c = a(b.target).data('ui-slider-handle-index');
            this._keySliding &&
              ((this._keySliding = !1),
              this._stop(b, c),
              this._change(b, c),
              this._removeClass(a(b.target), null, 'ui-state-active'));
          }
        }
      }),
      a.widget('ui.sortable', a.ui.mouse, {
        version: '1.12.1',
        widgetEventPrefix: 'sort',
        ready: !1,
        options: {
          appendTo: 'parent',
          axis: !1,
          connectWith: !1,
          containment: !1,
          cursor: 'auto',
          cursorAt: !1,
          dropOnEmpty: !0,
          forcePlaceholderSize: !1,
          forceHelperSize: !1,
          grid: !1,
          handle: !1,
          helper: 'original',
          items: '> *',
          opacity: !1,
          placeholder: !1,
          revert: !1,
          scroll: !0,
          scrollSensitivity: 20,
          scrollSpeed: 20,
          scope: 'default',
          tolerance: 'intersect',
          zIndex: 1e3,
          activate: null,
          beforeStop: null,
          change: null,
          deactivate: null,
          out: null,
          over: null,
          receive: null,
          remove: null,
          sort: null,
          start: null,
          stop: null,
          update: null
        },
        _isOverAxis: function (a, b, c) {
          return a >= b && b + c > a;
        },
        _isFloating: function (a) {
          return (
            /left|right/.test(a.css('float')) ||
            /inline|table-cell/.test(a.css('display'))
          );
        },
        _create: function () {
          (this.containerCache = {}),
            this._addClass('ui-sortable'),
            this.refresh(),
            (this.offset = this.element.offset()),
            this._mouseInit(),
            this._setHandleClassName(),
            (this.ready = !0);
        },
        _setOption: function (a, b) {
          this._super(a, b), 'handle' === a && this._setHandleClassName();
        },
        _setHandleClassName: function () {
          var b = this;
          this._removeClass(
            this.element.find('.ui-sortable-handle'),
            'ui-sortable-handle'
          ),
            a.each(this.items, function () {
              b._addClass(
                this.instance.options.handle
                  ? this.item.find(this.instance.options.handle)
                  : this.item,
                'ui-sortable-handle'
              );
            });
        },
        _destroy: function () {
          this._mouseDestroy();
          for (var a = this.items.length - 1; a >= 0; a--)
            this.items[a].item.removeData(this.widgetName + '-item');
          return this;
        },
        _mouseCapture: function (b, c) {
          var d = null,
            e = !1,
            f = this;
          return (
            !this.reverting &&
            !this.options.disabled &&
            'static' !== this.options.type &&
            (this._refreshItems(b),
            a(b.target)
              .parents()
              .each(function () {
                return a.data(this, f.widgetName + '-item') === f
                  ? ((d = a(this)), !1)
                  : void 0;
              }),
            a.data(b.target, f.widgetName + '-item') === f && (d = a(b.target)),
            !!d &&
              !(
                this.options.handle &&
                !c &&
                (a(this.options.handle, d)
                  .find('*')
                  .addBack()
                  .each(function () {
                    this === b.target && (e = !0);
                  }),
                !e)
              ) &&
              ((this.currentItem = d), this._removeCurrentsFromItems(), !0))
          );
        },
        _mouseStart: function (b, c, d) {
          var e,
            f,
            g = this.options;
          if (
            ((this.currentContainer = this),
            this.refreshPositions(),
            (this.helper = this._createHelper(b)),
            this._cacheHelperProportions(),
            this._cacheMargins(),
            (this.scrollParent = this.helper.scrollParent()),
            (this.offset = this.currentItem.offset()),
            (this.offset = {
              top: this.offset.top - this.margins.top,
              left: this.offset.left - this.margins.left
            }),
            a.extend(this.offset, {
              click: {
                left: b.pageX - this.offset.left,
                top: b.pageY - this.offset.top
              },
              parent: this._getParentOffset(),
              relative: this._getRelativeOffset()
            }),
            this.helper.css('position', 'absolute'),
            (this.cssPosition = this.helper.css('position')),
            (this.originalPosition = this._generatePosition(b)),
            (this.originalPageX = b.pageX),
            (this.originalPageY = b.pageY),
            g.cursorAt && this._adjustOffsetFromHelper(g.cursorAt),
            (this.domPosition = {
              prev: this.currentItem.prev()[0],
              parent: this.currentItem.parent()[0]
            }),
            this.helper[0] !== this.currentItem[0] && this.currentItem.hide(),
            this._createPlaceholder(),
            g.containment && this._setContainment(),
            g.cursor &&
              'auto' !== g.cursor &&
              ((f = this.document.find('body')),
              (this.storedCursor = f.css('cursor')),
              f.css('cursor', g.cursor),
              (this.storedStylesheet = a(
                '<style>*{ cursor: ' + g.cursor + ' !important; }</style>'
              ).appendTo(f))),
            g.opacity &&
              (this.helper.css('opacity') &&
                (this._storedOpacity = this.helper.css('opacity')),
              this.helper.css('opacity', g.opacity)),
            g.zIndex &&
              (this.helper.css('zIndex') &&
                (this._storedZIndex = this.helper.css('zIndex')),
              this.helper.css('zIndex', g.zIndex)),
            this.scrollParent[0] !== this.document[0] &&
              'HTML' !== this.scrollParent[0].tagName &&
              (this.overflowOffset = this.scrollParent.offset()),
            this._trigger('start', b, this._uiHash()),
            this._preserveHelperProportions || this._cacheHelperProportions(),
            !d)
          )
            for (e = this.containers.length - 1; e >= 0; e--)
              this.containers[e]._trigger('activate', b, this._uiHash(this));
          return (
            a.ui.ddmanager && (a.ui.ddmanager.current = this),
            a.ui.ddmanager &&
              !g.dropBehaviour &&
              a.ui.ddmanager.prepareOffsets(this, b),
            (this.dragging = !0),
            this._addClass(this.helper, 'ui-sortable-helper'),
            this._mouseDrag(b),
            !0
          );
        },
        _mouseDrag: function (b) {
          var c,
            d,
            e,
            f,
            g = this.options,
            h = !1;
          for (
            this.position = this._generatePosition(b),
              this.positionAbs = this._convertPositionTo('absolute'),
              this.lastPositionAbs || (this.lastPositionAbs = this.positionAbs),
              this.options.scroll &&
                (this.scrollParent[0] !== this.document[0] &&
                'HTML' !== this.scrollParent[0].tagName
                  ? (this.overflowOffset.top +
                      this.scrollParent[0].offsetHeight -
                      b.pageY <
                    g.scrollSensitivity
                      ? (this.scrollParent[0].scrollTop = h =
                          this.scrollParent[0].scrollTop + g.scrollSpeed)
                      : b.pageY - this.overflowOffset.top <
                          g.scrollSensitivity &&
                        (this.scrollParent[0].scrollTop = h =
                          this.scrollParent[0].scrollTop - g.scrollSpeed),
                    this.overflowOffset.left +
                      this.scrollParent[0].offsetWidth -
                      b.pageX <
                    g.scrollSensitivity
                      ? (this.scrollParent[0].scrollLeft = h =
                          this.scrollParent[0].scrollLeft + g.scrollSpeed)
                      : b.pageX - this.overflowOffset.left <
                          g.scrollSensitivity &&
                        (this.scrollParent[0].scrollLeft = h =
                          this.scrollParent[0].scrollLeft - g.scrollSpeed))
                  : (b.pageY - this.document.scrollTop() < g.scrollSensitivity
                      ? (h = this.document.scrollTop(
                          this.document.scrollTop() - g.scrollSpeed
                        ))
                      : this.window.height() -
                          (b.pageY - this.document.scrollTop()) <
                          g.scrollSensitivity &&
                        (h = this.document.scrollTop(
                          this.document.scrollTop() + g.scrollSpeed
                        )),
                    b.pageX - this.document.scrollLeft() < g.scrollSensitivity
                      ? (h = this.document.scrollLeft(
                          this.document.scrollLeft() - g.scrollSpeed
                        ))
                      : this.window.width() -
                          (b.pageX - this.document.scrollLeft()) <
                          g.scrollSensitivity &&
                        (h = this.document.scrollLeft(
                          this.document.scrollLeft() + g.scrollSpeed
                        ))),
                !1 !== h &&
                  a.ui.ddmanager &&
                  !g.dropBehaviour &&
                  a.ui.ddmanager.prepareOffsets(this, b)),
              this.positionAbs = this._convertPositionTo('absolute'),
              (this.options.axis && 'y' === this.options.axis) ||
                (this.helper[0].style.left = this.position.left + 'px'),
              (this.options.axis && 'x' === this.options.axis) ||
                (this.helper[0].style.top = this.position.top + 'px'),
              c = this.items.length - 1;
            c >= 0;
            c--
          )
            if (
              ((d = this.items[c]),
              (e = d.item[0]),
              (f = this._intersectsWithPointer(d)) &&
                d.instance === this.currentContainer &&
                e !== this.currentItem[0] &&
                this.placeholder[1 === f ? 'next' : 'prev']()[0] !== e &&
                !a.contains(this.placeholder[0], e) &&
                ('semi-dynamic' !== this.options.type ||
                  !a.contains(this.element[0], e)))
            ) {
              if (
                ((this.direction = 1 === f ? 'down' : 'up'),
                'pointer' !== this.options.tolerance &&
                  !this._intersectsWithSides(d))
              )
                break;
              this._rearrange(b, d), this._trigger('change', b, this._uiHash());
              break;
            }
          return (
            this._contactContainers(b),
            a.ui.ddmanager && a.ui.ddmanager.drag(this, b),
            this._trigger('sort', b, this._uiHash()),
            (this.lastPositionAbs = this.positionAbs),
            !1
          );
        },
        _mouseStop: function (b, c) {
          if (b) {
            if (
              (a.ui.ddmanager &&
                !this.options.dropBehaviour &&
                a.ui.ddmanager.drop(this, b),
              this.options.revert)
            ) {
              var d = this,
                e = this.placeholder.offset(),
                f = this.options.axis,
                g = {};
              (f && 'x' !== f) ||
                (g.left =
                  e.left -
                  this.offset.parent.left -
                  this.margins.left +
                  (this.offsetParent[0] === this.document[0].body
                    ? 0
                    : this.offsetParent[0].scrollLeft)),
                (f && 'y' !== f) ||
                  (g.top =
                    e.top -
                    this.offset.parent.top -
                    this.margins.top +
                    (this.offsetParent[0] === this.document[0].body
                      ? 0
                      : this.offsetParent[0].scrollTop)),
                (this.reverting = !0),
                a(this.helper).animate(
                  g,
                  parseInt(this.options.revert, 10) || 500,
                  function () {
                    d._clear(b);
                  }
                );
            } else this._clear(b, c);
            return !1;
          }
        },
        cancel: function () {
          if (this.dragging) {
            this._mouseUp(new a.Event('mouseup', { target: null })),
              'original' === this.options.helper
                ? (this.currentItem.css(this._storedCSS),
                  this._removeClass(this.currentItem, 'ui-sortable-helper'))
                : this.currentItem.show();
            for (var b = this.containers.length - 1; b >= 0; b--)
              this.containers[b]._trigger(
                'deactivate',
                null,
                this._uiHash(this)
              ),
                this.containers[b].containerCache.over &&
                  (this.containers[b]._trigger('out', null, this._uiHash(this)),
                  (this.containers[b].containerCache.over = 0));
          }
          return (
            this.placeholder &&
              (this.placeholder[0].parentNode &&
                this.placeholder[0].parentNode.removeChild(this.placeholder[0]),
              'original' !== this.options.helper &&
                this.helper &&
                this.helper[0].parentNode &&
                this.helper.remove(),
              a.extend(this, {
                helper: null,
                dragging: !1,
                reverting: !1,
                _noFinalSort: null
              }),
              this.domPosition.prev
                ? a(this.domPosition.prev).after(this.currentItem)
                : a(this.domPosition.parent).prepend(this.currentItem)),
            this
          );
        },
        serialize: function (b) {
          var c = this._getItemsAsjQuery(b && b.connected),
            d = [];
          return (
            (b = b || {}),
            a(c).each(function () {
              var c = (a(b.item || this).attr(b.attribute || 'id') || '').match(
                b.expression || /(.+)[\-=_](.+)/
              );
              c &&
                d.push(
                  (b.key || c[1] + '[]') +
                    '=' +
                    (b.key && b.expression ? c[1] : c[2])
                );
            }),
            !d.length && b.key && d.push(b.key + '='),
            d.join('&')
          );
        },
        toArray: function (b) {
          var c = this._getItemsAsjQuery(b && b.connected),
            d = [];
          return (
            (b = b || {}),
            c.each(function () {
              d.push(a(b.item || this).attr(b.attribute || 'id') || '');
            }),
            d
          );
        },
        _intersectsWith: function (a) {
          var b = this.positionAbs.left,
            c = b + this.helperProportions.width,
            d = this.positionAbs.top,
            e = d + this.helperProportions.height,
            f = a.left,
            g = f + a.width,
            h = a.top,
            i = h + a.height,
            j = this.offset.click.top,
            k = this.offset.click.left,
            l = 'x' === this.options.axis || (d + j > h && i > d + j),
            m = 'y' === this.options.axis || (b + k > f && g > b + k),
            n = l && m;
          return 'pointer' === this.options.tolerance ||
            this.options.forcePointerForContainers ||
            ('pointer' !== this.options.tolerance &&
              this.helperProportions[this.floating ? 'width' : 'height'] >
                a[this.floating ? 'width' : 'height'])
            ? n
            : b + this.helperProportions.width / 2 > f &&
                g > c - this.helperProportions.width / 2 &&
                d + this.helperProportions.height / 2 > h &&
                i > e - this.helperProportions.height / 2;
        },
        _intersectsWithPointer: function (a) {
          var b,
            c,
            d =
              'x' === this.options.axis ||
              this._isOverAxis(
                this.positionAbs.top + this.offset.click.top,
                a.top,
                a.height
              ),
            e =
              'y' === this.options.axis ||
              this._isOverAxis(
                this.positionAbs.left + this.offset.click.left,
                a.left,
                a.width
              );
          return (
            !(!d || !e) &&
            ((b = this._getDragVerticalDirection()),
            (c = this._getDragHorizontalDirection()),
            this.floating
              ? 'right' === c || 'down' === b
                ? 2
                : 1
              : b && ('down' === b ? 2 : 1))
          );
        },
        _intersectsWithSides: function (a) {
          var b = this._isOverAxis(
              this.positionAbs.top + this.offset.click.top,
              a.top + a.height / 2,
              a.height
            ),
            c = this._isOverAxis(
              this.positionAbs.left + this.offset.click.left,
              a.left + a.width / 2,
              a.width
            ),
            d = this._getDragVerticalDirection(),
            e = this._getDragHorizontalDirection();
          return this.floating && e
            ? ('right' === e && c) || ('left' === e && !c)
            : d && (('down' === d && b) || ('up' === d && !b));
        },
        _getDragVerticalDirection: function () {
          var a = this.positionAbs.top - this.lastPositionAbs.top;
          return 0 !== a && (a > 0 ? 'down' : 'up');
        },
        _getDragHorizontalDirection: function () {
          var a = this.positionAbs.left - this.lastPositionAbs.left;
          return 0 !== a && (a > 0 ? 'right' : 'left');
        },
        refresh: function (a) {
          return (
            this._refreshItems(a),
            this._setHandleClassName(),
            this.refreshPositions(),
            this
          );
        },
        _connectWith: function () {
          var a = this.options;
          return a.connectWith.constructor === String
            ? [a.connectWith]
            : a.connectWith;
        },
        _getItemsAsjQuery: function (b) {
          function c() {
            h.push(this);
          }
          var d,
            e,
            f,
            g,
            h = [],
            i = [],
            j = this._connectWith();
          if (j && b)
            for (d = j.length - 1; d >= 0; d--)
              for (f = a(j[d], this.document[0]), e = f.length - 1; e >= 0; e--)
                (g = a.data(f[e], this.widgetFullName)) &&
                  g !== this &&
                  !g.options.disabled &&
                  i.push([
                    a.isFunction(g.options.items)
                      ? g.options.items.call(g.element)
                      : a(g.options.items, g.element)
                          .not('.ui-sortable-helper')
                          .not('.ui-sortable-placeholder'),
                    g
                  ]);
          for (
            i.push([
              a.isFunction(this.options.items)
                ? this.options.items.call(this.element, null, {
                    options: this.options,
                    item: this.currentItem
                  })
                : a(this.options.items, this.element)
                    .not('.ui-sortable-helper')
                    .not('.ui-sortable-placeholder'),
              this
            ]),
              d = i.length - 1;
            d >= 0;
            d--
          )
            i[d][0].each(c);
          return a(h);
        },
        _removeCurrentsFromItems: function () {
          var b = this.currentItem.find(':data(' + this.widgetName + '-item)');
          this.items = a.grep(this.items, function (a) {
            for (var c = 0; b.length > c; c++)
              if (b[c] === a.item[0]) return !1;
            return !0;
          });
        },
        _refreshItems: function (b) {
          (this.items = []), (this.containers = [this]);
          var c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            k = this.items,
            l = [
              [
                a.isFunction(this.options.items)
                  ? this.options.items.call(this.element[0], b, {
                      item: this.currentItem
                    })
                  : a(this.options.items, this.element),
                this
              ]
            ],
            m = this._connectWith();
          if (m && this.ready)
            for (c = m.length - 1; c >= 0; c--)
              for (e = a(m[c], this.document[0]), d = e.length - 1; d >= 0; d--)
                (f = a.data(e[d], this.widgetFullName)) &&
                  f !== this &&
                  !f.options.disabled &&
                  (l.push([
                    a.isFunction(f.options.items)
                      ? f.options.items.call(f.element[0], b, {
                          item: this.currentItem
                        })
                      : a(f.options.items, f.element),
                    f
                  ]),
                  this.containers.push(f));
          for (c = l.length - 1; c >= 0; c--)
            for (g = l[c][1], h = l[c][0], d = 0, j = h.length; j > d; d++)
              (i = a(h[d])),
                i.data(this.widgetName + '-item', g),
                k.push({
                  item: i,
                  instance: g,
                  width: 0,
                  height: 0,
                  left: 0,
                  top: 0
                });
        },
        refreshPositions: function (b) {
          (this.floating =
            !!this.items.length &&
            ('x' === this.options.axis ||
              this._isFloating(this.items[0].item))),
            this.offsetParent &&
              this.helper &&
              (this.offset.parent = this._getParentOffset());
          var c, d, e, f;
          for (c = this.items.length - 1; c >= 0; c--)
            (d = this.items[c]),
              (d.instance !== this.currentContainer &&
                this.currentContainer &&
                d.item[0] !== this.currentItem[0]) ||
                ((e = this.options.toleranceElement
                  ? a(this.options.toleranceElement, d.item)
                  : d.item),
                b || ((d.width = e.outerWidth()), (d.height = e.outerHeight())),
                (f = e.offset()),
                (d.left = f.left),
                (d.top = f.top));
          if (this.options.custom && this.options.custom.refreshContainers)
            this.options.custom.refreshContainers.call(this);
          else
            for (c = this.containers.length - 1; c >= 0; c--)
              (f = this.containers[c].element.offset()),
                (this.containers[c].containerCache.left = f.left),
                (this.containers[c].containerCache.top = f.top),
                (this.containers[c].containerCache.width =
                  this.containers[c].element.outerWidth()),
                (this.containers[c].containerCache.height =
                  this.containers[c].element.outerHeight());
          return this;
        },
        _createPlaceholder: function (b) {
          b = b || this;
          var c,
            d = b.options;
          (d.placeholder && d.placeholder.constructor !== String) ||
            ((c = d.placeholder),
            (d.placeholder = {
              element: function () {
                var d = b.currentItem[0].nodeName.toLowerCase(),
                  e = a('<' + d + '>', b.document[0]);
                return (
                  b
                    ._addClass(
                      e,
                      'ui-sortable-placeholder',
                      c || b.currentItem[0].className
                    )
                    ._removeClass(e, 'ui-sortable-helper'),
                  'tbody' === d
                    ? b._createTrPlaceholder(
                        b.currentItem.find('tr').eq(0),
                        a('<tr>', b.document[0]).appendTo(e)
                      )
                    : 'tr' === d
                      ? b._createTrPlaceholder(b.currentItem, e)
                      : 'img' === d && e.attr('src', b.currentItem.attr('src')),
                  c || e.css('visibility', 'hidden'),
                  e
                );
              },
              update: function (a, e) {
                (!c || d.forcePlaceholderSize) &&
                  (e.height() ||
                    e.height(
                      b.currentItem.innerHeight() -
                        parseInt(b.currentItem.css('paddingTop') || 0, 10) -
                        parseInt(b.currentItem.css('paddingBottom') || 0, 10)
                    ),
                  e.width() ||
                    e.width(
                      b.currentItem.innerWidth() -
                        parseInt(b.currentItem.css('paddingLeft') || 0, 10) -
                        parseInt(b.currentItem.css('paddingRight') || 0, 10)
                    ));
              }
            })),
            (b.placeholder = a(
              d.placeholder.element.call(b.element, b.currentItem)
            )),
            b.currentItem.after(b.placeholder),
            d.placeholder.update(b, b.placeholder);
        },
        _createTrPlaceholder: function (b, c) {
          var d = this;
          b.children().each(function () {
            a('<td>&#160;</td>', d.document[0])
              .attr('colspan', a(this).attr('colspan') || 1)
              .appendTo(c);
          });
        },
        _contactContainers: function (b) {
          var c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            k,
            l,
            m = null,
            n = null;
          for (c = this.containers.length - 1; c >= 0; c--)
            if (!a.contains(this.currentItem[0], this.containers[c].element[0]))
              if (this._intersectsWith(this.containers[c].containerCache)) {
                if (
                  m &&
                  a.contains(this.containers[c].element[0], m.element[0])
                )
                  continue;
                (m = this.containers[c]), (n = c);
              } else
                this.containers[c].containerCache.over &&
                  (this.containers[c]._trigger('out', b, this._uiHash(this)),
                  (this.containers[c].containerCache.over = 0));
          if (m)
            if (1 === this.containers.length)
              this.containers[n].containerCache.over ||
                (this.containers[n]._trigger('over', b, this._uiHash(this)),
                (this.containers[n].containerCache.over = 1));
            else {
              for (
                e = 1e4,
                  f = null,
                  k = m.floating || this._isFloating(this.currentItem),
                  g = k ? 'left' : 'top',
                  h = k ? 'width' : 'height',
                  l = k ? 'pageX' : 'pageY',
                  d = this.items.length - 1;
                d >= 0;
                d--
              )
                a.contains(
                  this.containers[n].element[0],
                  this.items[d].item[0]
                ) &&
                  this.items[d].item[0] !== this.currentItem[0] &&
                  ((i = this.items[d].item.offset()[g]),
                  (j = !1),
                  b[l] - i > this.items[d][h] / 2 && (j = !0),
                  e > Math.abs(b[l] - i) &&
                    ((e = Math.abs(b[l] - i)),
                    (f = this.items[d]),
                    (this.direction = j ? 'up' : 'down')));
              if (!f && !this.options.dropOnEmpty) return;
              if (this.currentContainer === this.containers[n])
                return void (
                  this.currentContainer.containerCache.over ||
                  (this.containers[n]._trigger('over', b, this._uiHash()),
                  (this.currentContainer.containerCache.over = 1))
                );
              f
                ? this._rearrange(b, f, null, !0)
                : this._rearrange(b, null, this.containers[n].element, !0),
                this._trigger('change', b, this._uiHash()),
                this.containers[n]._trigger('change', b, this._uiHash(this)),
                (this.currentContainer = this.containers[n]),
                this.options.placeholder.update(
                  this.currentContainer,
                  this.placeholder
                ),
                this.containers[n]._trigger('over', b, this._uiHash(this)),
                (this.containers[n].containerCache.over = 1);
            }
        },
        _createHelper: function (b) {
          var c = this.options,
            d = a.isFunction(c.helper)
              ? a(c.helper.apply(this.element[0], [b, this.currentItem]))
              : 'clone' === c.helper
                ? this.currentItem.clone()
                : this.currentItem;
          return (
            d.parents('body').length ||
              a(
                'parent' !== c.appendTo
                  ? c.appendTo
                  : this.currentItem[0].parentNode
              )[0].appendChild(d[0]),
            d[0] === this.currentItem[0] &&
              (this._storedCSS = {
                width: this.currentItem[0].style.width,
                height: this.currentItem[0].style.height,
                position: this.currentItem.css('position'),
                top: this.currentItem.css('top'),
                left: this.currentItem.css('left')
              }),
            (!d[0].style.width || c.forceHelperSize) &&
              d.width(this.currentItem.width()),
            (!d[0].style.height || c.forceHelperSize) &&
              d.height(this.currentItem.height()),
            d
          );
        },
        _adjustOffsetFromHelper: function (b) {
          'string' == typeof b && (b = b.split(' ')),
            a.isArray(b) && (b = { left: +b[0], top: +b[1] || 0 }),
            'left' in b &&
              (this.offset.click.left = b.left + this.margins.left),
            'right' in b &&
              (this.offset.click.left =
                this.helperProportions.width - b.right + this.margins.left),
            'top' in b && (this.offset.click.top = b.top + this.margins.top),
            'bottom' in b &&
              (this.offset.click.top =
                this.helperProportions.height - b.bottom + this.margins.top);
        },
        _getParentOffset: function () {
          this.offsetParent = this.helper.offsetParent();
          var b = this.offsetParent.offset();
          return (
            'absolute' === this.cssPosition &&
              this.scrollParent[0] !== this.document[0] &&
              a.contains(this.scrollParent[0], this.offsetParent[0]) &&
              ((b.left += this.scrollParent.scrollLeft()),
              (b.top += this.scrollParent.scrollTop())),
            (this.offsetParent[0] === this.document[0].body ||
              (this.offsetParent[0].tagName &&
                'html' === this.offsetParent[0].tagName.toLowerCase() &&
                a.ui.ie)) &&
              (b = { top: 0, left: 0 }),
            {
              top:
                b.top +
                (parseInt(this.offsetParent.css('borderTopWidth'), 10) || 0),
              left:
                b.left +
                (parseInt(this.offsetParent.css('borderLeftWidth'), 10) || 0)
            }
          );
        },
        _getRelativeOffset: function () {
          if ('relative' === this.cssPosition) {
            var a = this.currentItem.position();
            return {
              top:
                a.top -
                (parseInt(this.helper.css('top'), 10) || 0) +
                this.scrollParent.scrollTop(),
              left:
                a.left -
                (parseInt(this.helper.css('left'), 10) || 0) +
                this.scrollParent.scrollLeft()
            };
          }
          return { top: 0, left: 0 };
        },
        _cacheMargins: function () {
          this.margins = {
            left: parseInt(this.currentItem.css('marginLeft'), 10) || 0,
            top: parseInt(this.currentItem.css('marginTop'), 10) || 0
          };
        },
        _cacheHelperProportions: function () {
          this.helperProportions = {
            width: this.helper.outerWidth(),
            height: this.helper.outerHeight()
          };
        },
        _setContainment: function () {
          var b,
            c,
            d,
            e = this.options;
          'parent' === e.containment &&
            (e.containment = this.helper[0].parentNode),
            ('document' === e.containment || 'window' === e.containment) &&
              (this.containment = [
                0 - this.offset.relative.left - this.offset.parent.left,
                0 - this.offset.relative.top - this.offset.parent.top,
                'document' === e.containment
                  ? this.document.width()
                  : this.window.width() -
                    this.helperProportions.width -
                    this.margins.left,
                ('document' === e.containment
                  ? this.document.height() ||
                    document.body.parentNode.scrollHeight
                  : this.window.height() ||
                    this.document[0].body.parentNode.scrollHeight) -
                  this.helperProportions.height -
                  this.margins.top
              ]),
            /^(document|window|parent)$/.test(e.containment) ||
              ((b = a(e.containment)[0]),
              (c = a(e.containment).offset()),
              (d = 'hidden' !== a(b).css('overflow')),
              (this.containment = [
                c.left +
                  (parseInt(a(b).css('borderLeftWidth'), 10) || 0) +
                  (parseInt(a(b).css('paddingLeft'), 10) || 0) -
                  this.margins.left,
                c.top +
                  (parseInt(a(b).css('borderTopWidth'), 10) || 0) +
                  (parseInt(a(b).css('paddingTop'), 10) || 0) -
                  this.margins.top,
                c.left +
                  (d ? Math.max(b.scrollWidth, b.offsetWidth) : b.offsetWidth) -
                  (parseInt(a(b).css('borderLeftWidth'), 10) || 0) -
                  (parseInt(a(b).css('paddingRight'), 10) || 0) -
                  this.helperProportions.width -
                  this.margins.left,
                c.top +
                  (d
                    ? Math.max(b.scrollHeight, b.offsetHeight)
                    : b.offsetHeight) -
                  (parseInt(a(b).css('borderTopWidth'), 10) || 0) -
                  (parseInt(a(b).css('paddingBottom'), 10) || 0) -
                  this.helperProportions.height -
                  this.margins.top
              ]));
        },
        _convertPositionTo: function (b, c) {
          c || (c = this.position);
          var d = 'absolute' === b ? 1 : -1,
            e =
              'absolute' !== this.cssPosition ||
              (this.scrollParent[0] !== this.document[0] &&
                a.contains(this.scrollParent[0], this.offsetParent[0]))
                ? this.scrollParent
                : this.offsetParent,
            f = /(html|body)/i.test(e[0].tagName);
          return {
            top:
              c.top +
              this.offset.relative.top * d +
              this.offset.parent.top * d -
              ('fixed' === this.cssPosition
                ? -this.scrollParent.scrollTop()
                : f
                  ? 0
                  : e.scrollTop()) *
                d,
            left:
              c.left +
              this.offset.relative.left * d +
              this.offset.parent.left * d -
              ('fixed' === this.cssPosition
                ? -this.scrollParent.scrollLeft()
                : f
                  ? 0
                  : e.scrollLeft()) *
                d
          };
        },
        _generatePosition: function (b) {
          var c,
            d,
            e = this.options,
            f = b.pageX,
            g = b.pageY,
            h =
              'absolute' !== this.cssPosition ||
              (this.scrollParent[0] !== this.document[0] &&
                a.contains(this.scrollParent[0], this.offsetParent[0]))
                ? this.scrollParent
                : this.offsetParent,
            i = /(html|body)/i.test(h[0].tagName);
          return (
            'relative' !== this.cssPosition ||
              (this.scrollParent[0] !== this.document[0] &&
                this.scrollParent[0] !== this.offsetParent[0]) ||
              (this.offset.relative = this._getRelativeOffset()),
            this.originalPosition &&
              (this.containment &&
                (b.pageX - this.offset.click.left < this.containment[0] &&
                  (f = this.containment[0] + this.offset.click.left),
                b.pageY - this.offset.click.top < this.containment[1] &&
                  (g = this.containment[1] + this.offset.click.top),
                b.pageX - this.offset.click.left > this.containment[2] &&
                  (f = this.containment[2] + this.offset.click.left),
                b.pageY - this.offset.click.top > this.containment[3] &&
                  (g = this.containment[3] + this.offset.click.top)),
              e.grid &&
                ((c =
                  this.originalPageY +
                  Math.round((g - this.originalPageY) / e.grid[1]) * e.grid[1]),
                (g = this.containment
                  ? c - this.offset.click.top >= this.containment[1] &&
                    c - this.offset.click.top <= this.containment[3]
                    ? c
                    : c - this.offset.click.top >= this.containment[1]
                      ? c - e.grid[1]
                      : c + e.grid[1]
                  : c),
                (d =
                  this.originalPageX +
                  Math.round((f - this.originalPageX) / e.grid[0]) * e.grid[0]),
                (f = this.containment
                  ? d - this.offset.click.left >= this.containment[0] &&
                    d - this.offset.click.left <= this.containment[2]
                    ? d
                    : d - this.offset.click.left >= this.containment[0]
                      ? d - e.grid[0]
                      : d + e.grid[0]
                  : d))),
            {
              top:
                g -
                this.offset.click.top -
                this.offset.relative.top -
                this.offset.parent.top +
                ('fixed' === this.cssPosition
                  ? -this.scrollParent.scrollTop()
                  : i
                    ? 0
                    : h.scrollTop()),
              left:
                f -
                this.offset.click.left -
                this.offset.relative.left -
                this.offset.parent.left +
                ('fixed' === this.cssPosition
                  ? -this.scrollParent.scrollLeft()
                  : i
                    ? 0
                    : h.scrollLeft())
            }
          );
        },
        _rearrange: function (a, b, c, d) {
          c
            ? c[0].appendChild(this.placeholder[0])
            : b.item[0].parentNode.insertBefore(
                this.placeholder[0],
                'down' === this.direction ? b.item[0] : b.item[0].nextSibling
              ),
            (this.counter = this.counter ? ++this.counter : 1);
          var e = this.counter;
          this._delay(function () {
            e === this.counter && this.refreshPositions(!d);
          });
        },
        _clear: function (a, b) {
          function c(a, b, c) {
            return function (d) {
              c._trigger(a, d, b._uiHash(b));
            };
          }
          this.reverting = !1;
          var d,
            e = [];
          if (
            (!this._noFinalSort &&
              this.currentItem.parent().length &&
              this.placeholder.before(this.currentItem),
            (this._noFinalSort = null),
            this.helper[0] === this.currentItem[0])
          ) {
            for (d in this._storedCSS)
              ('auto' === this._storedCSS[d] ||
                'static' === this._storedCSS[d]) &&
                (this._storedCSS[d] = '');
            this.currentItem.css(this._storedCSS),
              this._removeClass(this.currentItem, 'ui-sortable-helper');
          } else this.currentItem.show();
          for (
            this.fromOutside &&
              !b &&
              e.push(function (a) {
                this._trigger('receive', a, this._uiHash(this.fromOutside));
              }),
              (!this.fromOutside &&
                this.domPosition.prev ===
                  this.currentItem.prev().not('.ui-sortable-helper')[0] &&
                this.domPosition.parent === this.currentItem.parent()[0]) ||
                b ||
                e.push(function (a) {
                  this._trigger('update', a, this._uiHash());
                }),
              this !== this.currentContainer &&
                (b ||
                  (e.push(function (a) {
                    this._trigger('remove', a, this._uiHash());
                  }),
                  e.push(
                    function (a) {
                      return function (b) {
                        a._trigger('receive', b, this._uiHash(this));
                      };
                    }.call(this, this.currentContainer)
                  ),
                  e.push(
                    function (a) {
                      return function (b) {
                        a._trigger('update', b, this._uiHash(this));
                      };
                    }.call(this, this.currentContainer)
                  ))),
              d = this.containers.length - 1;
            d >= 0;
            d--
          )
            b || e.push(c('deactivate', this, this.containers[d])),
              this.containers[d].containerCache.over &&
                (e.push(c('out', this, this.containers[d])),
                (this.containers[d].containerCache.over = 0));
          if (
            (this.storedCursor &&
              (this.document.find('body').css('cursor', this.storedCursor),
              this.storedStylesheet.remove()),
            this._storedOpacity &&
              this.helper.css('opacity', this._storedOpacity),
            this._storedZIndex &&
              this.helper.css(
                'zIndex',
                'auto' === this._storedZIndex ? '' : this._storedZIndex
              ),
            (this.dragging = !1),
            b || this._trigger('beforeStop', a, this._uiHash()),
            this.placeholder[0].parentNode.removeChild(this.placeholder[0]),
            this.cancelHelperRemoval ||
              (this.helper[0] !== this.currentItem[0] && this.helper.remove(),
              (this.helper = null)),
            !b)
          ) {
            for (d = 0; e.length > d; d++) e[d].call(this, a);
            this._trigger('stop', a, this._uiHash());
          }
          return (this.fromOutside = !1), !this.cancelHelperRemoval;
        },
        _trigger: function () {
          !1 === a.Widget.prototype._trigger.apply(this, arguments) &&
            this.cancel();
        },
        _uiHash: function (b) {
          var c = b || this;
          return {
            helper: c.helper,
            placeholder: c.placeholder || a([]),
            position: c.position,
            originalPosition: c.originalPosition,
            offset: c.positionAbs,
            item: c.currentItem,
            sender: b ? b.element : null
          };
        }
      }),
      a.widget('ui.spinner', {
        version: '1.12.1',
        defaultElement: '<input>',
        widgetEventPrefix: 'spin',
        options: {
          classes: {
            'ui-spinner': 'ui-corner-all',
            'ui-spinner-down': 'ui-corner-br',
            'ui-spinner-up': 'ui-corner-tr'
          },
          culture: null,
          icons: { down: 'ui-icon-triangle-1-s', up: 'ui-icon-triangle-1-n' },
          incremental: !0,
          max: null,
          min: null,
          numberFormat: null,
          page: 10,
          step: 1,
          change: null,
          spin: null,
          start: null,
          stop: null
        },
        _create: function () {
          this._setOption('max', this.options.max),
            this._setOption('min', this.options.min),
            this._setOption('step', this.options.step),
            '' !== this.value() && this._value(this.element.val(), !0),
            this._draw(),
            this._on(this._events),
            this._refresh(),
            this._on(this.window, {
              beforeunload: function () {
                this.element.removeAttr('autocomplete');
              }
            });
        },
        _getCreateOptions: function () {
          var b = this._super(),
            c = this.element;
          return (
            a.each(['min', 'max', 'step'], function (a, d) {
              var e = c.attr(d);
              null != e && e.length && (b[d] = e);
            }),
            b
          );
        },
        _events: {
          keydown: function (a) {
            this._start(a) && this._keydown(a) && a.preventDefault();
          },
          keyup: '_stop',
          focus: function () {
            this.previous = this.element.val();
          },
          blur: function (a) {
            return this.cancelBlur
              ? void delete this.cancelBlur
              : (this._stop(),
                this._refresh(),
                void (
                  this.previous !== this.element.val() &&
                  this._trigger('change', a)
                ));
          },
          mousewheel: function (a, b) {
            if (b) {
              if (!this.spinning && !this._start(a)) return !1;
              this._spin((b > 0 ? 1 : -1) * this.options.step, a),
                clearTimeout(this.mousewheelTimer),
                (this.mousewheelTimer = this._delay(function () {
                  this.spinning && this._stop(a);
                }, 100)),
                a.preventDefault();
            }
          },
          'mousedown .ui-spinner-button': function (b) {
            function c() {
              this.element[0] === a.ui.safeActiveElement(this.document[0]) ||
                (this.element.trigger('focus'),
                (this.previous = d),
                this._delay(function () {
                  this.previous = d;
                }));
            }
            var d;
            (d =
              this.element[0] === a.ui.safeActiveElement(this.document[0])
                ? this.previous
                : this.element.val()),
              b.preventDefault(),
              c.call(this),
              (this.cancelBlur = !0),
              this._delay(function () {
                delete this.cancelBlur, c.call(this);
              }),
              !1 !== this._start(b) &&
                this._repeat(
                  null,
                  a(b.currentTarget).hasClass('ui-spinner-up') ? 1 : -1,
                  b
                );
          },
          'mouseup .ui-spinner-button': '_stop',
          'mouseenter .ui-spinner-button': function (b) {
            return a(b.currentTarget).hasClass('ui-state-active')
              ? !1 !== this._start(b) &&
                  void this._repeat(
                    null,
                    a(b.currentTarget).hasClass('ui-spinner-up') ? 1 : -1,
                    b
                  )
              : void 0;
          },
          'mouseleave .ui-spinner-button': '_stop'
        },
        _enhance: function () {
          this.uiSpinner = this.element
            .attr('autocomplete', 'off')
            .wrap('<span>')
            .parent()
            .append('<a></a><a></a>');
        },
        _draw: function () {
          this._enhance(),
            this._addClass(
              this.uiSpinner,
              'ui-spinner',
              'ui-widget ui-widget-content'
            ),
            this._addClass('ui-spinner-input'),
            this.element.attr('role', 'spinbutton'),
            (this.buttons = this.uiSpinner
              .children('a')
              .attr('tabIndex', -1)
              .attr('aria-hidden', !0)
              .button({ classes: { 'ui-button': '' } })),
            this._removeClass(this.buttons, 'ui-corner-all'),
            this._addClass(
              this.buttons.first(),
              'ui-spinner-button ui-spinner-up'
            ),
            this._addClass(
              this.buttons.last(),
              'ui-spinner-button ui-spinner-down'
            ),
            this.buttons
              .first()
              .button({ icon: this.options.icons.up, showLabel: !1 }),
            this.buttons
              .last()
              .button({ icon: this.options.icons.down, showLabel: !1 }),
            this.buttons.height() > Math.ceil(0.5 * this.uiSpinner.height()) &&
              this.uiSpinner.height() > 0 &&
              this.uiSpinner.height(this.uiSpinner.height());
        },
        _keydown: function (b) {
          var c = this.options,
            d = a.ui.keyCode;
          switch (b.keyCode) {
            case d.UP:
              return this._repeat(null, 1, b), !0;
            case d.DOWN:
              return this._repeat(null, -1, b), !0;
            case d.PAGE_UP:
              return this._repeat(null, c.page, b), !0;
            case d.PAGE_DOWN:
              return this._repeat(null, -c.page, b), !0;
          }
          return !1;
        },
        _start: function (a) {
          return (
            !(!this.spinning && !1 === this._trigger('start', a)) &&
            (this.counter || (this.counter = 1), (this.spinning = !0), !0)
          );
        },
        _repeat: function (a, b, c) {
          (a = a || 500),
            clearTimeout(this.timer),
            (this.timer = this._delay(function () {
              this._repeat(40, b, c);
            }, a)),
            this._spin(b * this.options.step, c);
        },
        _spin: function (a, b) {
          var c = this.value() || 0;
          this.counter || (this.counter = 1),
            (c = this._adjustValue(c + a * this._increment(this.counter))),
            (this.spinning && !1 === this._trigger('spin', b, { value: c })) ||
              (this._value(c), this.counter++);
        },
        _increment: function (b) {
          var c = this.options.incremental;
          return c
            ? a.isFunction(c)
              ? c(b)
              : Math.floor(
                  (b * b * b) / 5e4 - (b * b) / 500 + (17 * b) / 200 + 1
                )
            : 1;
        },
        _precision: function () {
          var a = this._precisionOf(this.options.step);
          return (
            null !== this.options.min &&
              (a = Math.max(a, this._precisionOf(this.options.min))),
            a
          );
        },
        _precisionOf: function (a) {
          var b = '' + a,
            c = b.indexOf('.');
          return -1 === c ? 0 : b.length - c - 1;
        },
        _adjustValue: function (a) {
          var b,
            c,
            d = this.options;
          return (
            (b = null !== d.min ? d.min : 0),
            (c = a - b),
            (c = Math.round(c / d.step) * d.step),
            (a = b + c),
            (a = parseFloat(a.toFixed(this._precision()))),
            null !== d.max && a > d.max
              ? d.max
              : null !== d.min && d.min > a
                ? d.min
                : a
          );
        },
        _stop: function (a) {
          this.spinning &&
            (clearTimeout(this.timer),
            clearTimeout(this.mousewheelTimer),
            (this.counter = 0),
            (this.spinning = !1),
            this._trigger('stop', a));
        },
        _setOption: function (a, b) {
          var c, d, e;
          return 'culture' === a || 'numberFormat' === a
            ? ((c = this._parse(this.element.val())),
              (this.options[a] = b),
              void this.element.val(this._format(c)))
            : (('max' === a || 'min' === a || 'step' === a) &&
                'string' == typeof b &&
                (b = this._parse(b)),
              'icons' === a &&
                ((d = this.buttons.first().find('.ui-icon')),
                this._removeClass(d, null, this.options.icons.up),
                this._addClass(d, null, b.up),
                (e = this.buttons.last().find('.ui-icon')),
                this._removeClass(e, null, this.options.icons.down),
                this._addClass(e, null, b.down)),
              void this._super(a, b));
        },
        _setOptionDisabled: function (a) {
          this._super(a),
            this._toggleClass(this.uiSpinner, null, 'ui-state-disabled', !!a),
            this.element.prop('disabled', !!a),
            this.buttons.button(a ? 'disable' : 'enable');
        },
        _setOptions: h(function (a) {
          this._super(a);
        }),
        _parse: function (a) {
          return (
            'string' == typeof a &&
              '' !== a &&
              (a =
                window.Globalize && this.options.numberFormat
                  ? Globalize.parseFloat(a, 10, this.options.culture)
                  : +a),
            '' === a || isNaN(a) ? null : a
          );
        },
        _format: function (a) {
          return '' === a
            ? ''
            : window.Globalize && this.options.numberFormat
              ? Globalize.format(
                  a,
                  this.options.numberFormat,
                  this.options.culture
                )
              : a;
        },
        _refresh: function () {
          this.element.attr({
            'aria-valuemin': this.options.min,
            'aria-valuemax': this.options.max,
            'aria-valuenow': this._parse(this.element.val())
          });
        },
        isValid: function () {
          var a = this.value();
          return null !== a && a === this._adjustValue(a);
        },
        _value: function (a, b) {
          var c;
          '' !== a &&
            null !== (c = this._parse(a)) &&
            (b || (c = this._adjustValue(c)), (a = this._format(c))),
            this.element.val(a),
            this._refresh();
        },
        _destroy: function () {
          this.element
            .prop('disabled', !1)
            .removeAttr(
              'autocomplete role aria-valuemin aria-valuemax aria-valuenow'
            ),
            this.uiSpinner.replaceWith(this.element);
        },
        stepUp: h(function (a) {
          this._stepUp(a);
        }),
        _stepUp: function (a) {
          this._start() &&
            (this._spin((a || 1) * this.options.step), this._stop());
        },
        stepDown: h(function (a) {
          this._stepDown(a);
        }),
        _stepDown: function (a) {
          this._start() &&
            (this._spin((a || 1) * -this.options.step), this._stop());
        },
        pageUp: h(function (a) {
          this._stepUp((a || 1) * this.options.page);
        }),
        pageDown: h(function (a) {
          this._stepDown((a || 1) * this.options.page);
        }),
        value: function (a) {
          return arguments.length
            ? void h(this._value).call(this, a)
            : this._parse(this.element.val());
        },
        widget: function () {
          return this.uiSpinner;
        }
      }),
      !1 !== a.uiBackCompat &&
        a.widget('ui.spinner', a.ui.spinner, {
          _enhance: function () {
            this.uiSpinner = this.element
              .attr('autocomplete', 'off')
              .wrap(this._uiSpinnerHtml())
              .parent()
              .append(this._buttonHtml());
          },
          _uiSpinnerHtml: function () {
            return '<span>';
          },
          _buttonHtml: function () {
            return '<a></a><a></a>';
          }
        }),
      a.ui.spinner,
      a.widget('ui.tabs', {
        version: '1.12.1',
        delay: 300,
        options: {
          active: null,
          classes: {
            'ui-tabs': 'ui-corner-all',
            'ui-tabs-nav': 'ui-corner-all',
            'ui-tabs-panel': 'ui-corner-bottom',
            'ui-tabs-tab': 'ui-corner-top'
          },
          collapsible: !1,
          event: 'click',
          heightStyle: 'content',
          hide: null,
          show: null,
          activate: null,
          beforeActivate: null,
          beforeLoad: null,
          load: null
        },
        _isLocal: (function () {
          var a = /#.*$/;
          return function (b) {
            var c, d;
            (c = b.href.replace(a, '')), (d = location.href.replace(a, ''));
            try {
              c = decodeURIComponent(c);
            } catch (a) {}
            try {
              d = decodeURIComponent(d);
            } catch (a) {}
            return b.hash.length > 1 && c === d;
          };
        })(),
        _create: function () {
          var b = this,
            c = this.options;
          (this.running = !1),
            this._addClass('ui-tabs', 'ui-widget ui-widget-content'),
            this._toggleClass('ui-tabs-collapsible', null, c.collapsible),
            this._processTabs(),
            (c.active = this._initialActive()),
            a.isArray(c.disabled) &&
              (c.disabled = a
                .unique(
                  c.disabled.concat(
                    a.map(this.tabs.filter('.ui-state-disabled'), function (a) {
                      return b.tabs.index(a);
                    })
                  )
                )
                .sort()),
            (this.active =
              !1 !== this.options.active && this.anchors.length
                ? this._findActive(c.active)
                : a()),
            this._refresh(),
            this.active.length && this.load(c.active);
        },
        _initialActive: function () {
          var b = this.options.active,
            c = this.options.collapsible,
            d = location.hash.substring(1);
          return (
            null === b &&
              (d &&
                this.tabs.each(function (c, e) {
                  return a(e).attr('aria-controls') === d
                    ? ((b = c), !1)
                    : void 0;
                }),
              null === b &&
                (b = this.tabs.index(this.tabs.filter('.ui-tabs-active'))),
              (null === b || -1 === b) && (b = !!this.tabs.length && 0)),
            !1 !== b &&
              -1 === (b = this.tabs.index(this.tabs.eq(b))) &&
              (b = !c && 0),
            !c && !1 === b && this.anchors.length && (b = 0),
            b
          );
        },
        _getCreateEventData: function () {
          return {
            tab: this.active,
            panel: this.active.length ? this._getPanelForTab(this.active) : a()
          };
        },
        _tabKeydown: function (b) {
          var c = a(a.ui.safeActiveElement(this.document[0])).closest('li'),
            d = this.tabs.index(c),
            e = !0;
          if (!this._handlePageNav(b)) {
            switch (b.keyCode) {
              case a.ui.keyCode.RIGHT:
              case a.ui.keyCode.DOWN:
                d++;
                break;
              case a.ui.keyCode.UP:
              case a.ui.keyCode.LEFT:
                (e = !1), d--;
                break;
              case a.ui.keyCode.END:
                d = this.anchors.length - 1;
                break;
              case a.ui.keyCode.HOME:
                d = 0;
                break;
              case a.ui.keyCode.SPACE:
                return (
                  b.preventDefault(),
                  clearTimeout(this.activating),
                  void this._activate(d)
                );
              case a.ui.keyCode.ENTER:
                return (
                  b.preventDefault(),
                  clearTimeout(this.activating),
                  void this._activate(d !== this.options.active && d)
                );
              default:
                return;
            }
            b.preventDefault(),
              clearTimeout(this.activating),
              (d = this._focusNextTab(d, e)),
              b.ctrlKey ||
                b.metaKey ||
                (c.attr('aria-selected', 'false'),
                this.tabs.eq(d).attr('aria-selected', 'true'),
                (this.activating = this._delay(function () {
                  this.option('active', d);
                }, this.delay)));
          }
        },
        _panelKeydown: function (b) {
          this._handlePageNav(b) ||
            (b.ctrlKey &&
              b.keyCode === a.ui.keyCode.UP &&
              (b.preventDefault(), this.active.trigger('focus')));
        },
        _handlePageNav: function (b) {
          return b.altKey && b.keyCode === a.ui.keyCode.PAGE_UP
            ? (this._activate(this._focusNextTab(this.options.active - 1, !1)),
              !0)
            : b.altKey && b.keyCode === a.ui.keyCode.PAGE_DOWN
              ? (this._activate(
                  this._focusNextTab(this.options.active + 1, !0)
                ),
                !0)
              : void 0;
        },
        _findNextTab: function (b, c) {
          function d() {
            return b > e && (b = 0), 0 > b && (b = e), b;
          }
          for (
            var e = this.tabs.length - 1;
            -1 !== a.inArray(d(), this.options.disabled);

          )
            b = c ? b + 1 : b - 1;
          return b;
        },
        _focusNextTab: function (a, b) {
          return (
            (a = this._findNextTab(a, b)), this.tabs.eq(a).trigger('focus'), a
          );
        },
        _setOption: function (a, b) {
          return 'active' === a
            ? void this._activate(b)
            : (this._super(a, b),
              'collapsible' === a &&
                (this._toggleClass('ui-tabs-collapsible', null, b),
                b || !1 !== this.options.active || this._activate(0)),
              'event' === a && this._setupEvents(b),
              void ('heightStyle' === a && this._setupHeightStyle(b)));
        },
        _sanitizeSelector: function (a) {
          return a
            ? a.replace(/[!"$%&'()*+,.\/:;<=>?@\[\]\^`{|}~]/g, '\\$&')
            : '';
        },
        refresh: function () {
          var b = this.options,
            c = this.tablist.children(':has(a[href])');
          (b.disabled = a.map(c.filter('.ui-state-disabled'), function (a) {
            return c.index(a);
          })),
            this._processTabs(),
            !1 !== b.active && this.anchors.length
              ? this.active.length &&
                !a.contains(this.tablist[0], this.active[0])
                ? this.tabs.length === b.disabled.length
                  ? ((b.active = !1), (this.active = a()))
                  : this._activate(
                      this._findNextTab(Math.max(0, b.active - 1), !1)
                    )
                : (b.active = this.tabs.index(this.active))
              : ((b.active = !1), (this.active = a())),
            this._refresh();
        },
        _refresh: function () {
          this._setOptionDisabled(this.options.disabled),
            this._setupEvents(this.options.event),
            this._setupHeightStyle(this.options.heightStyle),
            this.tabs.not(this.active).attr({
              'aria-selected': 'false',
              'aria-expanded': 'false',
              tabIndex: -1
            }),
            this.panels
              .not(this._getPanelForTab(this.active))
              .hide()
              .attr({ 'aria-hidden': 'true' }),
            this.active.length
              ? (this.active.attr({
                  'aria-selected': 'true',
                  'aria-expanded': 'true',
                  tabIndex: 0
                }),
                this._addClass(
                  this.active,
                  'ui-tabs-active',
                  'ui-state-active'
                ),
                this._getPanelForTab(this.active)
                  .show()
                  .attr({ 'aria-hidden': 'false' }))
              : this.tabs.eq(0).attr('tabIndex', 0);
        },
        _processTabs: function () {
          var b = this,
            c = this.tabs,
            d = this.anchors,
            e = this.panels;
          (this.tablist = this._getList().attr('role', 'tablist')),
            this._addClass(
              this.tablist,
              'ui-tabs-nav',
              'ui-helper-reset ui-helper-clearfix ui-widget-header'
            ),
            this.tablist
              .on('mousedown' + this.eventNamespace, '> li', function (b) {
                a(this).is('.ui-state-disabled') && b.preventDefault();
              })
              .on(
                'focus' + this.eventNamespace,
                '.ui-tabs-anchor',
                function () {
                  a(this).closest('li').is('.ui-state-disabled') && this.blur();
                }
              ),
            (this.tabs = this.tablist
              .find('> li:has(a[href])')
              .attr({ role: 'tab', tabIndex: -1 })),
            this._addClass(this.tabs, 'ui-tabs-tab', 'ui-state-default'),
            (this.anchors = this.tabs
              .map(function () {
                return a('a', this)[0];
              })
              .attr({ role: 'presentation', tabIndex: -1 })),
            this._addClass(this.anchors, 'ui-tabs-anchor'),
            (this.panels = a()),
            this.anchors.each(function (c, d) {
              var e,
                f,
                g,
                h = a(d).uniqueId().attr('id'),
                i = a(d).closest('li'),
                j = i.attr('aria-controls');
              b._isLocal(d)
                ? ((e = d.hash),
                  (g = e.substring(1)),
                  (f = b.element.find(b._sanitizeSelector(e))))
                : ((g = i.attr('aria-controls') || a({}).uniqueId()[0].id),
                  (e = '#' + g),
                  (f = b.element.find(e)),
                  f.length ||
                    ((f = b._createPanel(g)),
                    f.insertAfter(b.panels[c - 1] || b.tablist)),
                  f.attr('aria-live', 'polite')),
                f.length && (b.panels = b.panels.add(f)),
                j && i.data('ui-tabs-aria-controls', j),
                i.attr({ 'aria-controls': g, 'aria-labelledby': h }),
                f.attr('aria-labelledby', h);
            }),
            this.panels.attr('role', 'tabpanel'),
            this._addClass(this.panels, 'ui-tabs-panel', 'ui-widget-content'),
            c &&
              (this._off(c.not(this.tabs)),
              this._off(d.not(this.anchors)),
              this._off(e.not(this.panels)));
        },
        _getList: function () {
          return this.tablist || this.element.find('ol, ul').eq(0);
        },
        _createPanel: function (b) {
          return a('<div>').attr('id', b).data('ui-tabs-destroy', !0);
        },
        _setOptionDisabled: function (b) {
          var c, d, e;
          for (
            a.isArray(b) &&
              (b.length
                ? b.length === this.anchors.length && (b = !0)
                : (b = !1)),
              e = 0;
            (d = this.tabs[e]);
            e++
          )
            (c = a(d)),
              !0 === b || -1 !== a.inArray(e, b)
                ? (c.attr('aria-disabled', 'true'),
                  this._addClass(c, null, 'ui-state-disabled'))
                : (c.removeAttr('aria-disabled'),
                  this._removeClass(c, null, 'ui-state-disabled'));
          (this.options.disabled = b),
            this._toggleClass(
              this.widget(),
              this.widgetFullName + '-disabled',
              null,
              !0 === b
            );
        },
        _setupEvents: function (b) {
          var c = {};
          b &&
            a.each(b.split(' '), function (a, b) {
              c[b] = '_eventHandler';
            }),
            this._off(this.anchors.add(this.tabs).add(this.panels)),
            this._on(!0, this.anchors, {
              click: function (a) {
                a.preventDefault();
              }
            }),
            this._on(this.anchors, c),
            this._on(this.tabs, { keydown: '_tabKeydown' }),
            this._on(this.panels, { keydown: '_panelKeydown' }),
            this._focusable(this.tabs),
            this._hoverable(this.tabs);
        },
        _setupHeightStyle: function (b) {
          var c,
            d = this.element.parent();
          'fill' === b
            ? ((c = d.height()),
              (c -= this.element.outerHeight() - this.element.height()),
              this.element.siblings(':visible').each(function () {
                var b = a(this),
                  d = b.css('position');
                'absolute' !== d && 'fixed' !== d && (c -= b.outerHeight(!0));
              }),
              this.element
                .children()
                .not(this.panels)
                .each(function () {
                  c -= a(this).outerHeight(!0);
                }),
              this.panels
                .each(function () {
                  a(this).height(
                    Math.max(0, c - a(this).innerHeight() + a(this).height())
                  );
                })
                .css('overflow', 'auto'))
            : 'auto' === b &&
              ((c = 0),
              this.panels
                .each(function () {
                  c = Math.max(c, a(this).height('').height());
                })
                .height(c));
        },
        _eventHandler: function (b) {
          var c = this.options,
            d = this.active,
            e = a(b.currentTarget),
            f = e.closest('li'),
            g = f[0] === d[0],
            h = g && c.collapsible,
            i = h ? a() : this._getPanelForTab(f),
            j = d.length ? this._getPanelForTab(d) : a(),
            k = { oldTab: d, oldPanel: j, newTab: h ? a() : f, newPanel: i };
          b.preventDefault(),
            f.hasClass('ui-state-disabled') ||
              f.hasClass('ui-tabs-loading') ||
              this.running ||
              (g && !c.collapsible) ||
              !1 === this._trigger('beforeActivate', b, k) ||
              ((c.active = !h && this.tabs.index(f)),
              (this.active = g ? a() : f),
              this.xhr && this.xhr.abort(),
              j.length ||
                i.length ||
                a.error('jQuery UI Tabs: Mismatching fragment identifier.'),
              i.length && this.load(this.tabs.index(f), b),
              this._toggle(b, k));
        },
        _toggle: function (b, c) {
          function d() {
            (f.running = !1), f._trigger('activate', b, c);
          }
          function e() {
            f._addClass(
              c.newTab.closest('li'),
              'ui-tabs-active',
              'ui-state-active'
            ),
              g.length && f.options.show
                ? f._show(g, f.options.show, d)
                : (g.show(), d());
          }
          var f = this,
            g = c.newPanel,
            h = c.oldPanel;
          (this.running = !0),
            h.length && this.options.hide
              ? this._hide(h, this.options.hide, function () {
                  f._removeClass(
                    c.oldTab.closest('li'),
                    'ui-tabs-active',
                    'ui-state-active'
                  ),
                    e();
                })
              : (this._removeClass(
                  c.oldTab.closest('li'),
                  'ui-tabs-active',
                  'ui-state-active'
                ),
                h.hide(),
                e()),
            h.attr('aria-hidden', 'true'),
            c.oldTab.attr({
              'aria-selected': 'false',
              'aria-expanded': 'false'
            }),
            g.length && h.length
              ? c.oldTab.attr('tabIndex', -1)
              : g.length &&
                this.tabs
                  .filter(function () {
                    return 0 === a(this).attr('tabIndex');
                  })
                  .attr('tabIndex', -1),
            g.attr('aria-hidden', 'false'),
            c.newTab.attr({
              'aria-selected': 'true',
              'aria-expanded': 'true',
              tabIndex: 0
            });
        },
        _activate: function (b) {
          var c,
            d = this._findActive(b);
          d[0] !== this.active[0] &&
            (d.length || (d = this.active),
            (c = d.find('.ui-tabs-anchor')[0]),
            this._eventHandler({
              target: c,
              currentTarget: c,
              preventDefault: a.noop
            }));
        },
        _findActive: function (b) {
          return !1 === b ? a() : this.tabs.eq(b);
        },
        _getIndex: function (b) {
          return (
            'string' == typeof b &&
              (b = this.anchors.index(
                this.anchors.filter("[href$='" + a.ui.escapeSelector(b) + "']")
              )),
            b
          );
        },
        _destroy: function () {
          this.xhr && this.xhr.abort(),
            this.tablist.removeAttr('role').off(this.eventNamespace),
            this.anchors.removeAttr('role tabIndex').removeUniqueId(),
            this.tabs.add(this.panels).each(function () {
              a.data(this, 'ui-tabs-destroy')
                ? a(this).remove()
                : a(this).removeAttr(
                    'role tabIndex aria-live aria-busy aria-selected aria-labelledby aria-hidden aria-expanded'
                  );
            }),
            this.tabs.each(function () {
              var b = a(this),
                c = b.data('ui-tabs-aria-controls');
              c
                ? b.attr('aria-controls', c).removeData('ui-tabs-aria-controls')
                : b.removeAttr('aria-controls');
            }),
            this.panels.show(),
            'content' !== this.options.heightStyle &&
              this.panels.css('height', '');
        },
        enable: function (b) {
          var c = this.options.disabled;
          !1 !== c &&
            (void 0 === b
              ? (c = !1)
              : ((b = this._getIndex(b)),
                (c = a.isArray(c)
                  ? a.map(c, function (a) {
                      return a !== b ? a : null;
                    })
                  : a.map(this.tabs, function (a, c) {
                      return c !== b ? c : null;
                    }))),
            this._setOptionDisabled(c));
        },
        disable: function (b) {
          var c = this.options.disabled;
          if (!0 !== c) {
            if (void 0 === b) c = !0;
            else {
              if (((b = this._getIndex(b)), -1 !== a.inArray(b, c))) return;
              c = a.isArray(c) ? a.merge([b], c).sort() : [b];
            }
            this._setOptionDisabled(c);
          }
        },
        load: function (b, c) {
          b = this._getIndex(b);
          var d = this,
            e = this.tabs.eq(b),
            f = e.find('.ui-tabs-anchor'),
            g = this._getPanelForTab(e),
            h = { tab: e, panel: g },
            i = function (a, b) {
              'abort' === b && d.panels.stop(!1, !0),
                d._removeClass(e, 'ui-tabs-loading'),
                g.removeAttr('aria-busy'),
                a === d.xhr && delete d.xhr;
            };
          this._isLocal(f[0]) ||
            ((this.xhr = a.ajax(this._ajaxSettings(f, c, h))),
            this.xhr &&
              'canceled' !== this.xhr.statusText &&
              (this._addClass(e, 'ui-tabs-loading'),
              g.attr('aria-busy', 'true'),
              this.xhr
                .done(function (a, b, e) {
                  setTimeout(function () {
                    g.html(a), d._trigger('load', c, h), i(e, b);
                  }, 1);
                })
                .fail(function (a, b) {
                  setTimeout(function () {
                    i(a, b);
                  }, 1);
                })));
        },
        _ajaxSettings: function (b, c, d) {
          var e = this;
          return {
            url: b.attr('href').replace(/#.*$/, ''),
            beforeSend: function (b, f) {
              return e._trigger(
                'beforeLoad',
                c,
                a.extend({ jqXHR: b, ajaxSettings: f }, d)
              );
            }
          };
        },
        _getPanelForTab: function (b) {
          var c = a(b).attr('aria-controls');
          return this.element.find(this._sanitizeSelector('#' + c));
        }
      }),
      !1 !== a.uiBackCompat &&
        a.widget('ui.tabs', a.ui.tabs, {
          _processTabs: function () {
            this._superApply(arguments), this._addClass(this.tabs, 'ui-tab');
          }
        }),
      a.ui.tabs,
      a.widget('ui.tooltip', {
        version: '1.12.1',
        options: {
          classes: { 'ui-tooltip': 'ui-corner-all ui-widget-shadow' },
          content: function () {
            var b = a(this).attr('title') || '';
            return a('<a>').text(b).html();
          },
          hide: !0,
          items: '[title]:not([disabled])',
          position: {
            my: 'left top+15',
            at: 'left bottom',
            collision: 'flipfit flip'
          },
          show: !0,
          track: !1,
          close: null,
          open: null
        },
        _addDescribedBy: function (b, c) {
          var d = (b.attr('aria-describedby') || '').split(/\s+/);
          d.push(c),
            b
              .data('ui-tooltip-id', c)
              .attr('aria-describedby', a.trim(d.join(' ')));
        },
        _removeDescribedBy: function (b) {
          var c = b.data('ui-tooltip-id'),
            d = (b.attr('aria-describedby') || '').split(/\s+/),
            e = a.inArray(c, d);
          -1 !== e && d.splice(e, 1),
            b.removeData('ui-tooltip-id'),
            (d = a.trim(d.join(' '))),
            d
              ? b.attr('aria-describedby', d)
              : b.removeAttr('aria-describedby');
        },
        _create: function () {
          this._on({ mouseover: 'open', focusin: 'open' }),
            (this.tooltips = {}),
            (this.parents = {}),
            (this.liveRegion = a('<div>')
              .attr({
                role: 'log',
                'aria-live': 'assertive',
                'aria-relevant': 'additions'
              })
              .appendTo(this.document[0].body)),
            this._addClass(
              this.liveRegion,
              null,
              'ui-helper-hidden-accessible'
            ),
            (this.disabledTitles = a([]));
        },
        _setOption: function (b, c) {
          var d = this;
          this._super(b, c),
            'content' === b &&
              a.each(this.tooltips, function (a, b) {
                d._updateContent(b.element);
              });
        },
        _setOptionDisabled: function (a) {
          this[a ? '_disable' : '_enable']();
        },
        _disable: function () {
          var b = this;
          a.each(this.tooltips, function (c, d) {
            var e = a.Event('blur');
            (e.target = e.currentTarget = d.element[0]), b.close(e, !0);
          }),
            (this.disabledTitles = this.disabledTitles.add(
              this.element
                .find(this.options.items)
                .addBack()
                .filter(function () {
                  var b = a(this);
                  return b.is('[title]')
                    ? b
                        .data('ui-tooltip-title', b.attr('title'))
                        .removeAttr('title')
                    : void 0;
                })
            ));
        },
        _enable: function () {
          this.disabledTitles.each(function () {
            var b = a(this);
            b.data('ui-tooltip-title') &&
              b.attr('title', b.data('ui-tooltip-title'));
          }),
            (this.disabledTitles = a([]));
        },
        open: function (b) {
          var c = this,
            d = a(b ? b.target : this.element).closest(this.options.items);
          d.length &&
            !d.data('ui-tooltip-id') &&
            (d.attr('title') && d.data('ui-tooltip-title', d.attr('title')),
            d.data('ui-tooltip-open', !0),
            b &&
              'mouseover' === b.type &&
              d.parents().each(function () {
                var b,
                  d = a(this);
                d.data('ui-tooltip-open') &&
                  ((b = a.Event('blur')),
                  (b.target = b.currentTarget = this),
                  c.close(b, !0)),
                  d.attr('title') &&
                    (d.uniqueId(),
                    (c.parents[this.id] = {
                      element: this,
                      title: d.attr('title')
                    }),
                    d.attr('title', ''));
              }),
            this._registerCloseHandlers(b, d),
            this._updateContent(d, b));
        },
        _updateContent: function (a, b) {
          var c,
            d = this.options.content,
            e = this,
            f = b ? b.type : null;
          return 'string' == typeof d || d.nodeType || d.jquery
            ? this._open(b, a, d)
            : void (
                (c = d.call(a[0], function (c) {
                  e._delay(function () {
                    a.data('ui-tooltip-open') &&
                      (b && (b.type = f), this._open(b, a, c));
                  });
                })) && this._open(b, a, c)
              );
        },
        _open: function (b, c, d) {
          function e(a) {
            (j.of = a), g.is(':hidden') || g.position(j);
          }
          var f,
            g,
            h,
            i,
            j = a.extend({}, this.options.position);
          if (d) {
            if ((f = this._find(c)))
              return void f.tooltip.find('.ui-tooltip-content').html(d);
            c.is('[title]') &&
              (b && 'mouseover' === b.type
                ? c.attr('title', '')
                : c.removeAttr('title')),
              (f = this._tooltip(c)),
              (g = f.tooltip),
              this._addDescribedBy(c, g.attr('id')),
              g.find('.ui-tooltip-content').html(d),
              this.liveRegion.children().hide(),
              (i = a('<div>').html(g.find('.ui-tooltip-content').html())),
              i.removeAttr('name').find('[name]').removeAttr('name'),
              i.removeAttr('id').find('[id]').removeAttr('id'),
              i.appendTo(this.liveRegion),
              this.options.track && b && /^mouse/.test(b.type)
                ? (this._on(this.document, { mousemove: e }), e(b))
                : g.position(a.extend({ of: c }, this.options.position)),
              g.hide(),
              this._show(g, this.options.show),
              this.options.track &&
                this.options.show &&
                this.options.show.delay &&
                (h = this.delayedShow =
                  setInterval(function () {
                    g.is(':visible') && (e(j.of), clearInterval(h));
                  }, a.fx.interval)),
              this._trigger('open', b, { tooltip: g });
          }
        },
        _registerCloseHandlers: function (b, c) {
          var d = {
            keyup: function (b) {
              if (b.keyCode === a.ui.keyCode.ESCAPE) {
                var d = a.Event(b);
                (d.currentTarget = c[0]), this.close(d, !0);
              }
            }
          };
          c[0] !== this.element[0] &&
            (d.remove = function () {
              this._removeTooltip(this._find(c).tooltip);
            }),
            (b && 'mouseover' !== b.type) || (d.mouseleave = 'close'),
            (b && 'focusin' !== b.type) || (d.focusout = 'close'),
            this._on(!0, c, d);
        },
        close: function (b) {
          var c,
            d = this,
            e = a(b ? b.currentTarget : this.element),
            f = this._find(e);
          return f
            ? ((c = f.tooltip),
              void (
                f.closing ||
                (clearInterval(this.delayedShow),
                e.data('ui-tooltip-title') &&
                  !e.attr('title') &&
                  e.attr('title', e.data('ui-tooltip-title')),
                this._removeDescribedBy(e),
                (f.hiding = !0),
                c.stop(!0),
                this._hide(c, this.options.hide, function () {
                  d._removeTooltip(a(this));
                }),
                e.removeData('ui-tooltip-open'),
                this._off(e, 'mouseleave focusout keyup'),
                e[0] !== this.element[0] && this._off(e, 'remove'),
                this._off(this.document, 'mousemove'),
                b &&
                  'mouseleave' === b.type &&
                  a.each(this.parents, function (b, c) {
                    a(c.element).attr('title', c.title), delete d.parents[b];
                  }),
                (f.closing = !0),
                this._trigger('close', b, { tooltip: c }),
                f.hiding || (f.closing = !1))
              ))
            : void e.removeData('ui-tooltip-open');
        },
        _tooltip: function (b) {
          var c = a('<div>').attr('role', 'tooltip'),
            d = a('<div>').appendTo(c),
            e = c.uniqueId().attr('id');
          return (
            this._addClass(d, 'ui-tooltip-content'),
            this._addClass(c, 'ui-tooltip', 'ui-widget ui-widget-content'),
            c.appendTo(this._appendTo(b)),
            (this.tooltips[e] = { element: b, tooltip: c })
          );
        },
        _find: function (a) {
          var b = a.data('ui-tooltip-id');
          return b ? this.tooltips[b] : null;
        },
        _removeTooltip: function (a) {
          a.remove(), delete this.tooltips[a.attr('id')];
        },
        _appendTo: function (a) {
          var b = a.closest('.ui-front, dialog');
          return b.length || (b = this.document[0].body), b;
        },
        _destroy: function () {
          var b = this;
          a.each(this.tooltips, function (c, d) {
            var e = a.Event('blur'),
              f = d.element;
            (e.target = e.currentTarget = f[0]),
              b.close(e, !0),
              a('#' + c).remove(),
              f.data('ui-tooltip-title') &&
                (f.attr('title') || f.attr('title', f.data('ui-tooltip-title')),
                f.removeData('ui-tooltip-title'));
          }),
            this.liveRegion.remove();
        }
      }),
      !1 !== a.uiBackCompat &&
        a.widget('ui.tooltip', a.ui.tooltip, {
          options: { tooltipClass: null },
          _tooltip: function () {
            var a = this._superApply(arguments);
            return (
              this.options.tooltipClass &&
                a.tooltip.addClass(this.options.tooltipClass),
              a
            );
          }
        }),
      a.ui.tooltip;
  });
